import { getLanguageTranslation } from "../translations/get-language-translation";
import {
  DescriptionImageScreeny110GcCabrio1,
  EpicaLiteGLB,
  EpicaLiteUSDZ,
  Image20101,
  Image20102,
  Image20103,
  Image20104,
  Image20105,
  Image20201,
  Image20202,
  Image20203,
  Image20204,
  Image20205,
  Image20401,
  Image20402,
  Image20403,
  Image20404,
  Image20405,
  ImageArmonyPlus1,
  ImageArmonyPlus2,
  ImageArmonyPlus3,
  ImageArmonyPlus4,
  ImageArmonyPlus5,
  ImageBaseLite1,
  ImageBaseLite2,
  ImageBaseLite3,
  ImageBaseLite4,
  ImageBaseLite5,
  ImageBaseLite6,
  ImageBaseLite7,
  ImageBasePlus1,
  ImageBasePlus2,
  ImageBasePlus3,
  ImageBasePlus4,
  ImageBasePlus5,
  ImageBasePlus6,
  ImageDomea1,
  ImageDomea2,
  ImageDomea3,
  ImageDomea4,
  ImageDomea5,
  ImageDomea6,
  ImageEly1,
  ImageEly2,
  ImageEly3,
  ImageEly4,
  ImageEly5,
  ImageEly6,
  ImageEly7,
  ImageEly8,
  ImageEpica1,
  ImageEpica2,
  ImageEpica3,
  ImageEpica4,
  ImageEpicaLite1,
  ImageEpicaLite2,
  ImageEpicaLite3,
  ImageEpicaLite4,
  ImageEpicaLite5,
  ImageEpicaLite6,
  ImageEpicaLite7,
  ImageEpicaLite8,
  ImageEpicaLite9,
  ImageGenova1,
  ImageGenova2,
  ImageGenova3,
  ImageGenova4,
  ImageGiant1,
  ImageGiant2,
  ImageGiant3,
  ImageGiant4,
  ImageGiant5,
  ImageGiotto1,
  ImageGiotto2,
  ImageGiotto3,
  ImageGiotto4,
  ImageGiotto5,
  ImageGiottoPlus1,
  ImageGiottoPlus2,
  ImageGiottoPlus3,
  ImageGiottoPlus4,
  ImageGlass1,
  ImageGlass10,
  ImageGlass11,
  ImageGlass12,
  ImageGlass2,
  ImageGlass3,
  ImageGlass4,
  ImageGlass5,
  ImageGlass6,
  ImageGlass7,
  ImageGlass8,
  ImageGlass9,
  ImageGrandangolo1,
  ImageGrandangolo2,
  ImageGrandangolo3,
  ImageGrandangolo4,
  ImageHelixBq1,
  ImageHelixBq2,
  ImageHelixBq3,
  ImageHelixBq4,
  ImageHelixBq5,
  ImageHelixBq6,
  ImageHelixPs1,
  ImageHelixPs2,
  ImageHelixPs3,
  ImageHelixPs4,
  ImageHelixPs5,
  ImageMiniHelixBq1,
  ImageMiniHelixBq2,
  ImageMiniHelixBq3,
  ImageMiniHelixBq4,
  ImageMiniHelixPs1,
  ImageMiniHelixPs2,
  ImageMiniHelixPs3,
  ImageMiniHelixPs4,
  ImageNewRoma1,
  ImageNewRoma2,
  ImageNewRoma3,
  ImageNewRoma4,
  ImageNewRoma5,
  ImageOmbralsun1,
  ImageOmbralsun2,
  ImageOmbralsun3,
  ImageOmbralsun4,
  ImageOmbralsun5,
  ImagePatioV41,
  ImagePatioV42,
  ImagePatioV43,
  ImagePatioV44,
  ImagePatioV45,
  ImagePatioV46,
  ImageRPitch1,
  ImageRPitch2,
  ImageRPitch3,
  ImageRPitch4,
  ImageScreeny110Gc1,
  ImageScreeny110Gc2,
  ImageScreeny110Gc3,
  ImageScreeny110Gc4,
  ImageScreeny110Gc5,
  ImageScreeny110Gc6,
  ImageScreeny110Gc7,
  ImageScreeny110GcCabrio1,
  ImageScreeny110GcCabrio2,
  ImageScreeny110GcCabrio3,
  ImageScreeny110GcCabrio4,
  ImageScreeny110GcCabrio5,
  ImageScreeny110GcCabrio6,
  ImageScreeny110GcCabrio7,
  ImageScreeny110GcCabrio8,
  ImageScreeny110GpzUnicaAm1,
  ImageScreeny110GpzUnicaAm2,
  ImageScreeny110GpzUnicaAm3,
  ImageScreeny110GpzUnicaAm4,
  ImageScreeny110GpzUnicaAm5,
  ImageScreeny110GpzUnicaAm6,
  ImageScreeny110GpzUnicaAm7,
  ImageScreeny130Ga1,
  ImageScreeny130Ga2,
  ImageScreeny130Ga3,
  ImageScreeny130Ga4,
  ImageScreeny130Ga5,
  ImageScreeny130Ga6,
  ImageScreeny130GpzI1,
  ImageScreeny130GpzI2,
  ImageScreeny130GpzI3,
  ImageScreeny130GpzI4,
  ImageScreeny130GpzI5,
  ImageScreeny130GpzI6,
  ImageScreeny130GpzMb1,
  ImageScreeny130GpzMb2,
  ImageScreeny130GpzMb3,
  ImageScreeny130GpzMb4,
  ImageScreeny130GpzTensTo1,
  ImageScreeny130GpzTensTo2,
  ImageScreeny130GpzTensTo3,
  ImageScreeny130GpzTensTo4,
  ImageScreeny130GpzTensTo5,
  ImageScreeny130GpzUnicaM1,
  ImageScreeny130GpzUnicaM2,
  ImageScreeny130GpzUnicaM3,
  ImageScreeny130GpzUnicaM4,
  ImageScreeny130GpzUnicaM5,
  ImageScreeny130GpzUnicaM6,
  ImageScreeny130GpzUnicaM7,
  ImageScreeny150GpzUnica1,
  ImageScreeny150GpzUnica2,
  ImageScreeny150GpzUnica3,
  ImageScreeny150GpzUnica4,
  ImageScreeny150GpzUnica5,
  ImageScreeny150Storm1,
  ImageScreeny150Storm2,
  ImageScreeny150Storm3,
  ImageScreeny150Storm4,
  ImageScreeny150Storm5,
  ImageScreeny150Storm6,
  ImageScreeny55GpXs1,
  ImageScreeny55GpXs2,
  ImageScreeny55GpXs3,
  ImageScreeny55GpXs4,
  ImageScreeny55GpXs5,
  ImageScreeny55GpXs6,
  ImageScreeny85GpzS1,
  ImageScreeny85GpzS2,
  ImageScreeny85GpzS3,
  ImageScreeny85GpzS4,
  ImageScreeny85GpzS5,
  ImageScreeny85GpzS6,
  ImageSirio1,
  ImageSirio2,
  ImageSirio3,
  ImageSirio4,
  ImageSirio5,
  ImageSirio6,
  ImageSpace1,
  ImageSpace2,
  ImageSpace3,
  ImageSpace4,
  ImageSpace5,
  ImageSpace6,
  ImageSpace7,
  ImageVerso1,
  ImageVerso2,
  ImageVerso3,
  ImageVerso4,
  ImageVerso5,
  ImageZenith1,
  ImageZenith2,
  ImageZenith3,
  ImageZenith4,
  ImageZenith5,
  ImageZenith6,
  TechnicalSheet2010,
  TechnicalSheet2020,
  TechnicalSheet2040,
  TechnicalSheetArmonyPlus,
  TechnicalSheetBaseLite,
  TechnicalSheetBasePlus,
  TechnicalSheetDomea,
  TechnicalSheetEly,
  TechnicalSheetEpica,
  TechnicalSheetEpicaLite,
  TechnicalSheetGenova,
  TechnicalSheetGiant,
  TechnicalSheetGiotto,
  TechnicalSheetGiottoPlus,
  TechnicalSheetGlass,
  TechnicalSheetGrandangolo,
  TechnicalSheetHelixBq,
  TechnicalSheetHelixPs,
  TechnicalSheetMiniHelixBq,
  TechnicalSheetMiniHelixBs,
  TechnicalSheetNewRoma,
  TechnicalSheetOmbralsun,
  TechnicalSheetPatioV4,
  TechnicalSheetRPitch,
  TechnicalSheetScreeny110Gc,
  TechnicalSheetScreeny110GcCabrio,
  TechnicalSheetScreeny110GpzUnicaAm,
  TechnicalSheetScreeny130Ga,
  TechnicalSheetScreeny130GpzI,
  TechnicalSheetScreeny130GpzMb,
  TechnicalSheetScreeny130GpzTensTo,
  TechnicalSheetScreeny130GpzUnicaM,
  TechnicalSheetScreeny150GpzUnica,
  TechnicalSheetScreeny150Storm,
  TechnicalSheetScreeny55GpXs,
  TechnicalSheetScreeny85GpzS,
  TechnicalSheetSirio,
  TechnicalSheetSpace,
  TechnicalSheetVerso,
  TechnicalSheetZenith,
} from "./index";

export const createBaseLiteEpicaInfoPointsEnIt = (
  createPositionPoint,
  createInfoPoint
) => [
  createPositionPoint(
    [-4871.66, -681.74, 842.95],
    "intro",
    getLanguageTranslation("Introduzione")
  ),
  createPositionPoint(
    [473.38, -1586.28, 4715.28],
    "base-plus-epica",
    "Base Plus - Epica"
  ),
  createPositionPoint(
    [850.05, -1726.83, -4605.53],
    "base-lite-armony-plus",
    "Base Lite - Armony Plus"
  ),
  createPositionPoint([-2699.61, -348.4, -4184.2], "percorso-1", ""),
  createPositionPoint(
    [-4524.52, -669.08, 2012.0],
    "parigi-plus-screeny-130-gpz-tens-to",
    "Epica Lite - 130 GPZ TO"
  ),
  createInfoPoint(
    [4418.58, -627.23, 2231.34],
    {
      type: "info",
      title: "",
      noCloseIcon: true,
      popupClass: "popup-info_container",
      sectionTitle: "EPICA",
      description:
        "<p>" +
        getLanguageTranslation(
          "EPICA è una tenda a bracci estensibili con cassonetto, caratterizzata da linee semplici ed essenziali. Gli elementi di fissaggio e le viterie sono totalmente nascosti alla vista. Pur avendo ingombri contenuti, raggiunge una sporgenza di 410cm con braccio Giant. Il prodotto viene completato con un’ampia gamma di optional come il kit luci e il terminale a cassonetto."
        ) +
        "</p>",
      images: [ImageEpica1, ImageEpica2, ImageEpica3, ImageEpica4],
      buttons: [
        {
          text: getLanguageTranslation("Scheda Tecnica"),
          href: TechnicalSheetEpica,
        },
      ],
    },
    undefined,
    "<p><strong>EPICA</strong><br />" +
      getLanguageTranslation(
        "EPICA è una tenda a bracci estensibili con cassonetto, caratterizzata da linee semplici ed essenziali. Gli elementi di fissaggio e le viterie sono totalmente nascosti alla vista."
      ) +
      "</p>"
  ),
  createInfoPoint(
    [4321.87, -606.98, -2426.41],
    {
      type: "info",
      title: "",
      noCloseIcon: true,
      popupClass: "popup-info_container",
      sectionTitle: "BASE LITE",
      description:
        "<p>" +
        getLanguageTranslation(
          "BASE LITE è una tenda a bracci estensibili con cassonetto su supporti laterali fissi con contropiastra di ancoraggio, bracci balteus lite, doppio cavetto inguainato o a cinghia. I supporti consentono di installare la tenda sia a parete che a soffitto e la regolazione può essere eseguita in un unica soluzione grazie al sistema con vite passante. Tutti i componenti sono realizzati in alluminio verniciato che garantiscono resa estetica e durabilità."
        ) +
        "</p>",
      images: [
        ImageBaseLite1,
        ImageBaseLite2,
        ImageBaseLite3,
        ImageBaseLite4,
        ImageBaseLite5,
        ImageBaseLite6,
        ImageBaseLite7,
      ],
      buttons: [
        {
          text: getLanguageTranslation("Scheda Tecnica"),
          href: TechnicalSheetBaseLite,
        },
      ],
    },
    undefined,
    "<p><strong>BASE LITE</strong><br />" +
      getLanguageTranslation(
        "BASE LITE è una tenda a bracci estensibili con cassonetto su supporti laterali fissi con contropiastra di ancoraggio, bracci balteus lite, doppio cavetto inguainato o a cinghia."
      ) +
      "</p>"
  ),
];

export const createBasePlusEpicaInfoPointsEnIt = (
  createPositionPoint,
  createInfoPoint
) => [
  createPositionPoint(
    [-4857.0, -622.58, -980.18],
    "intro",
    getLanguageTranslation("Introduzione")
  ),
  createPositionPoint(
    [1493.21, -1623.12, -4482.63],
    "base-lite-epica",
    "Base Lite - Epica"
  ),
  createPositionPoint(
    [574.89, -1513.87, 4725.81],
    "base-plus-domea",
    "Base Plus - Domea"
  ),
  createPositionPoint(
    [-4944.34, -651.76, 182.0],
    "parigi-plus-screeny-130-gpz-tens-to",
    "Epica Lite - 130 GPZ TO"
  ),
  createInfoPoint(
    [4473.5, -553.98, 2142.61],
    {
      type: "info",
      title: "",
      noCloseIcon: true,
      popupClass: "popup-info_container",
      sectionTitle: "BASE PLUS",
      description:
        "<p>" +
        getLanguageTranslation(
          "BASE PLUS è una tenda a bracci estensibili con cassonetto su supporti laterali fissi con contropiastra di ancoraggio, bracci Balteus o spin. Il cassonetto, ispezionabile, viene fornito in tre profili separati. I supporti consentono di installare la tenda sia a parete che a soffitto e la regolazione può essere eseguita in un unica soluzione grazie al sistema con vite passante. Tutti i componenti sono realizzati in alluminio verniciato che garantiscono resa estetica e durabilità."
        ) +
        "</p>",
      images: [
        ImageBasePlus1,
        ImageBasePlus2,
        ImageBasePlus3,
        ImageBasePlus4,
        ImageBasePlus5,
        ImageBasePlus6,
      ],
      buttons: [
        {
          text: getLanguageTranslation("Scheda Tecnica"),
          href: TechnicalSheetBasePlus,
        },
      ],
    },
    undefined,
    "<p><strong>BASE PLUS</strong><br />" +
      getLanguageTranslation(
        "BASE PLUS è una tenda a bracci estensibili con cassonetto su supporti laterali fissi con contropiastra di ancoraggio, bracci Balteus o spin. Il cassonetto, ispezionabile, viene fornito in tre profili separati."
      ) +
      "</p>"
  ),
  createInfoPoint(
    [4243.78, -533.34, -2574.1],
    {
      type: "info",
      title: "",
      noCloseIcon: true,
      popupClass: "popup-info_container",
      sectionTitle: "EPICA",
      description:
        "<p>" +
        getLanguageTranslation(
          "EPICA è una tenda a bracci estensibili con cassonetto, caratterizzata da linee semplici ed essenziali. Gli elementi di fissaggio e le viterie sono totalmente nascosti alla vista. Pur avendo ingombri contenuti, raggiunge una sporgenza di 410cm con braccio Giant. Il prodotto viene completato con un’ampia gamma di optional come il kit luci e il terminale a cassonetto."
        ) +
        "</p>",
      images: [ImageEpica1, ImageEpica2, ImageEpica3, ImageEpica4],
      buttons: [
        {
          text: getLanguageTranslation("Scheda Tecnica"),
          href: TechnicalSheetEpica,
        },
      ],
    },
    undefined,
    "<p><strong>EPICA</strong><br />" +
      getLanguageTranslation(
        "EPICA è una tenda a bracci estensibili con cassonetto, caratterizzata da linee semplici ed essenziali. Gli elementi di fissaggio e le viterie sono totalmente nascosti alla vista."
      ) +
      "</p>"
  ),
];

export const createBaseLiteArmonyPlusInfoPointsEnIt = (
  createPositionPoint,
  createInfoPoint
) => [
  createPositionPoint(
    [-4578.22, -488.07, 1931.81],
    "intro",
    getLanguageTranslation("Introduzione")
  ),
  createPositionPoint([-3368.98, -797.98, -3601.02], "percorso-1", ""),
  createPositionPoint(
    [588.94, -1487.33, 4731.15],
    "base-lite-epica",
    "Base Lite - Epica"
  ),
  createPositionPoint(
    [-51.32, -1264.94, -4831.6],
    "armony-plus-2040",
    "Armony Plus - 2040"
  ),
  createInfoPoint(
    [4231.28, -579.23, 2583.38],
    {
      type: "info",
      title: "",
      noCloseIcon: true,
      popupClass: "popup-info_container",
      sectionTitle: "BASE LITE",
      description:
        "<p>" +
        getLanguageTranslation(
          "BASE LITE è una tenda a bracci estensibili con cassonetto su supporti laterali fissi con contropiastra di ancoraggio, bracci balteus lite, doppio cavetto inguainato o a cinghia. I supporti consentono di installare la tenda sia a parete che a soffitto e la regolazione può essere eseguita in un unica soluzione grazie al sistema con vite passante. Tutti i componenti sono realizzati in alluminio verniciato che garantiscono resa estetica e durabilità."
        ) +
        "</p>",
      images: [
        ImageBaseLite1,
        ImageBaseLite2,
        ImageBaseLite3,
        ImageBaseLite4,
        ImageBaseLite5,
        ImageBaseLite6,
        ImageBaseLite7,
      ],
      buttons: [
        {
          text: getLanguageTranslation("Scheda Tecnica"),
          href: TechnicalSheetBaseLite,
        },
      ],
    },
    undefined,
    "<p><strong>BASE LITE</strong><br />" +
      getLanguageTranslation(
        "BASE LITE è una tenda a bracci estensibili con cassonetto su supporti laterali fissi con contropiastra di ancoraggio, bracci balteus lite, doppio cavetto inguainato o a cinghia."
      ) +
      "</p>"
  ),
  createInfoPoint(
    [4041.82, -576.71, -2875.68],
    {
      type: "info",
      title: "",
      noCloseIcon: true,
      popupClass: "popup-info_container",
      sectionTitle: "ARMONY PLUS",
      description:
        "<p>" +
        getLanguageTranslation(
          "ARMONY PLUS é una tenda a bracci estensibili con cassonetto a scomparsa totale. Design e stile uniti alla funzionalità e alla tecnologia di un sistema ribaltabile BAT che permette la chiusura ermetica del cassonetto anche nella sua massima inclinazione inoltre, grazie ad un profilo di guarnizione il cassonetto risulta essere perfettamente aderente al muro impedendo l’infiltrazione di acque ed impurità."
        ) +
        "</p>",
      images: [
        ImageArmonyPlus1,
        ImageArmonyPlus2,
        ImageArmonyPlus3,
        ImageArmonyPlus4,
        ImageArmonyPlus5,
      ],
      buttons: [
        {
          text: getLanguageTranslation("Scheda Tecnica"),
          href: TechnicalSheetArmonyPlus,
        },
      ],
    },
    undefined,
    "<p><strong>ARMONY PLUS</strong><br />" +
      getLanguageTranslation(
        "ARMONY PLUS é una tenda a bracci estensibili con cassonetto a scomparsa totale."
      ) +
      "</p>"
  ),
];

export const createBasePlusDomeaInfoPointsEnIt = (
  createPositionPoint,
  createInfoPoint
) => [
  createPositionPoint(
    [-4414.58, -552.98, -2258.51],
    "intro",
    getLanguageTranslation("Introduzione")
  ),
  createPositionPoint(
    [20.36, -1402, -4794.33],
    "base-plus-epica",
    "Base Plus - Epica"
  ),
  createPositionPoint(
    [1422.41, -1402, 4566.77],
    "domea-grandangolo",
    "Domea - Grandangolo"
  ),
  createPositionPoint(
    [-4656.5, -647.34, -1675.36],
    "parigi-plus-screeny-130-gpz-tens-to",
    "Epica Lite - 130 GPZ TO"
  ),
  createInfoPoint(
    [3945.14, -705.92, -2978.99],
    {
      type: "info",
      title: "",
      noCloseIcon: true,
      popupClass: "popup-info_container",
      sectionTitle: "BASE PLUS",
      description:
        "<p>" +
        getLanguageTranslation(
          "BASE PLUS è una tenda a bracci estensibili con cassonetto su supporti laterali fissi con contropiastra di ancoraggio, bracci Balteus o spin. Il cassonetto, ispezionabile, viene fornito in tre profili separati. I supporti consentono di installare la tenda sia a parete che a soffitto e la regolazione può essere eseguita in un unica soluzione grazie al sistema con vite passante. Tutti i componenti sono realizzati in alluminio verniciato che garantiscono resa estetica e durabilità."
        ) +
        "</p>",
      images: [
        ImageBasePlus1,
        ImageBasePlus2,
        ImageBasePlus3,
        ImageBasePlus4,
        ImageBasePlus5,
        ImageBasePlus6,
      ],
      buttons: [
        {
          text: getLanguageTranslation("Scheda Tecnica"),
          href: TechnicalSheetBasePlus,
        },
      ],
    },
    undefined,
    "<p><strong>BASE PLUS</strong><br />" +
      getLanguageTranslation(
        "BASE PLUS è una tenda a bracci estensibili con cassonetto su supporti laterali fissi con contropiastra di ancoraggio, bracci Balteus o spin. Il cassonetto, ispezionabile, viene fornito in tre profili separati."
      ) +
      "</p>"
  ),
  createInfoPoint(
    [4089.86, -615.68, 2793.24],
    {
      type: "info",
      title: "",
      noCloseIcon: true,
      popupClass: "popup-info_container",
      sectionTitle: "DOMEA",
      description:
        "<p>" +
        getLanguageTranslation(
          "DOMEA, disegnata da Robby Cantarutti, designer di fama internazionale, è il primo prodotto della nuova “feel-experience” di BAT. Le sue forme accattivanti, che richiamano la tradizione e simboleggiano un connubio perfetto tra innovazione e classicità, forte di nuovi contenuti tecnologici, fanno di DOMEA uno dei prodotti di punta della gamma delle tende a cassonetto. Caratterizzata dall’estrema facilità di montaggio, regolazione e installazione, garantisce standard di sicurezza e qualità elevati per un modello di questa categoria."
        ) +
        "</p>",
      images: [
        ImageDomea1,
        ImageDomea2,
        ImageDomea3,
        ImageDomea4,
        ImageDomea5,
        ImageDomea6,
      ],
      buttons: [
        {
          text: getLanguageTranslation("Scheda Tecnica"),
          href: TechnicalSheetDomea,
        },
      ],
    },
    undefined,
    "<p><strong>DOMEA</strong><br />" +
      getLanguageTranslation(
        "DOMEA, disegnata da Robby Cantarutti, designer di fama internazionale, è il primo prodotto della nuova “feel-experience” di BAT. Le sue forme accattivanti, che richiamano la tradizione e simboleggiano un connubio perfetto tra innovazione e classicità, forte di nuovi contenuti tecnologici, fanno di DOMEA uno dei prodotti di punta della gamma delle tende a cassonetto."
      ) +
      "</p>"
  ),
];

export const createDomeaGrandangoloInfoPointsEnIt = (
  createPositionPoint,
  createInfoPoint
) => [
  createPositionPoint([-3361.89, -279.31, 3683.99], "percorso-5", ""),
  createPositionPoint(
    [1266.92, -1392.64, -4621.26],
    "base-plus-domea",
    "Base Plus - Domea"
  ),
  createPositionPoint([-396.4, -1221.52, 4825.76], "percorso-4", ""),
  createInfoPoint(
    [4020.23, -689.74, -2884.38],
    {
      type: "info",
      title: "",
      noCloseIcon: true,
      popupClass: "popup-info_container",
      sectionTitle: "DOMEA",
      description:
        "<p>" +
        getLanguageTranslation(
          "DOMEA, disegnata da Robby Cantarutti, designer di fama internazionale, è il primo prodotto della nuova “feel-experience” di BAT. Le sue forme accattivanti, che richiamano la tradizione e simboleggiano un connubio perfetto tra innovazione e classicità, forte di nuovi contenuti tecnologici, fanno di DOMEA uno dei prodotti di punta della gamma delle tende a cassonetto. Caratterizzata dall’estrema facilità di montaggio, regolazione e installazione, garantisce standard di sicurezza e qualità elevati per un modello di questa categoria."
        ) +
        "</p>",
      images: [
        ImageDomea1,
        ImageDomea2,
        ImageDomea3,
        ImageDomea4,
        ImageDomea5,
        ImageDomea6,
      ],
      buttons: [
        {
          text: getLanguageTranslation("Scheda Tecnica"),
          href: TechnicalSheetDomea,
        },
      ],
    },
    undefined,
    "<p><strong>DOMEA</strong><br />" +
      getLanguageTranslation(
        "DOMEA, disegnata da Robby Cantarutti, designer di fama internazionale, è il primo prodotto della nuova “feel-experience” di BAT. Le sue forme accattivanti, che richiamano la tradizione e simboleggiano un connubio perfetto tra innovazione e classicità, forte di nuovi contenuti tecnologici, fanno di DOMEA uno dei prodotti di punta della gamma delle tende a cassonetto."
      ) +
      "</p>"
  ),
  createInfoPoint(
    [3694.36, 765.62, 3277.46],
    {
      type: "info",
      title: "",
      noCloseIcon: true,
      popupClass: "popup-info_container",
      sectionTitle: "GRANDANGOLO",
      description:
        "<p>" +
        getLanguageTranslation(
          "GRANDANGOLO è il naturale completamento della gamma di tende a braccia di BAT.La sua particolare configurazione, tramite l'inclinazione del braccio, permette di raggiungere angoli di ombreggiatura maggiori a quelli delle tende tradizionali mantenendo comunque alte le sue prestazioni. Disponibile sia con il supporto Helix che con quello Gold, GRANDANGOLO è compatibile con tutti i sistemi barra quadra 40mm di BAT."
        ) +
        " </p>",
      images: [
        ImageGrandangolo1,
        ImageGrandangolo2,
        ImageGrandangolo3,
        ImageGrandangolo4,
      ],
      buttons: [
        {
          text: getLanguageTranslation("Scheda Tecnica"),
          href: TechnicalSheetGrandangolo,
        },
      ],
    },
    undefined,
    "<p><strong>GRANDANGOLO</strong><br />" +
      getLanguageTranslation(
        "GRANDANGOLO è il naturale completamento della gamma di tende a braccia di BAT."
      ) +
      "</p>"
  ),
];

export const createNewElyInfoPointsEnIt = (
  createPositionPoint,
  createInfoPoint
) => [
  createPositionPoint([24.02, -714.5, -4945.19], "ombralsun", "Ombralsun"),

  createPositionPoint([-984.35, -437.96, -4878.43], "percorso-7", ""),
  createPositionPoint([1267.45, -458.81, -4805.6], "percorso-8", ""),
  createPositionPoint(
    [-4627.27, -349.1, -1840.35],
    "giotto-plus-giotto",
    "Giotto Plus - Giotto"
  ),
  createPositionPoint([-4400.88, -1020.27, 2121.55], "Glass 1.0", "glass-1"),
  createPositionPoint([483.16, -801.58, 4909.26], "percorso-5", ""),
  createInfoPoint(
    [3763.57, -1533.65, -2903.36],
    {
      type: "info",
      title: "",
      noCloseIcon: true,
      popupClass: "popup-info_container",
      sectionTitle: "ELY",
      description:
        "<p>" +
        getLanguageTranslation(
          "ELY è una nuova tenda verticale per uso esterno. Grazie alla sua struttura lineare, alla facilità di assemblaggio e installazione e all’innovativo ed accattivante design, Ely risulta essere la soluzione ideale per soddisfare le più esigenti richieste di protezione solare ed arredo esterno."
        ) +
        " </p>",
      images: [
        ImageEly1,
        ImageEly2,
        ImageEly3,
        ImageEly4,
        ImageEly5,
        ImageEly6,
        ImageEly7,
        ImageEly8,
      ],
      buttons: [
        {
          text: getLanguageTranslation("Scheda Tecnica"),
          href: TechnicalSheetEly,
        },
      ],
    },
    undefined,
    "<p><strong>ELY</strong><br />" +
      getLanguageTranslation(
        "ELY è una nuova tenda verticale per uso esterno. Grazie alla sua struttura lineare, alla facilità di assemblaggio e installazione e all’innovativo ed accattivante design, Ely risulta essere la soluzione ideale per soddisfare le più esigenti richieste di protezione solare ed arredo esterno."
      ) +
      "</p>"
  ),
];

export const createGiantZenithInfoPointsEnIt = (
  createPositionPoint,
  createInfoPoint
) => [
  createPositionPoint([2979.1, -893.24, -3905.06], "percorso-1", ""),
  createPositionPoint([-4598.53, -1921.01, 331.33], "2010", "2010"),
  createPositionPoint(
    [-3027.72, -1499.7, -3675.17],
    "2020-2040",
    "2020 - 2040"
  ),
  createInfoPoint(
    [4392.84, -480.96, 2319.93],
    {
      type: "info",
      title: "",
      noCloseIcon: true,
      popupClass: "popup-info_container",
      sectionTitle: "GIANT",
      description:
        "<p>" +
        getLanguageTranslation(
          "GIANT BQ é una tenda a bracci su barra quadra in acciaio 50mm. I supporti in alluminio estruso garantiscono resistenza e compattezza e i tappi in alluminio verniciati ne caratterizzano eleganza e discrezione. Compattezza e design la rendono perfetta per coperture di terrazze pubbliche e per protezione di spazi e patii privati. Dispone di un braccio tipo spin, creato “ad hoc” con snodi e spalla forgiati."
        ) +
        "</p>",
      images: [ImageGiant1, ImageGiant2, ImageGiant3, ImageGiant4, ImageGiant5],
      buttons: [
        {
          text: getLanguageTranslation("Scheda Tecnica"),
          href: TechnicalSheetGiant,
        },
      ],
    },
    undefined,
    "<p><strong>GIANT</strong><br />" +
      getLanguageTranslation(
        "GIANT BQ é una tenda a bracci su barra quadra in acciaio 50mm. I supporti in alluminio estruso garantiscono resistenza e compattezza e i tappi in alluminio verniciati ne caratterizzano eleganza e discrezione."
      ) +
      "</p>"
  ),
  createInfoPoint(
    [4272.05, -572.04, -2522.16],
    {
      type: "info",
      title: "",
      noCloseIcon: true,
      popupClass: "popup-info_container",
      sectionTitle: "ZENITH",
      description:
        "<p>" +
        getLanguageTranslation(
          "ZENITH è una tenda a bracci con cassonetto fisso su barra rettangolare 50x30mm a scomparsa totale. Funzionale ed affidabile è dotata di sistema ribaltabile BAT che garantisce la perfetta chiusura del terminale anche in caso di massima inclinazione."
        ) +
        " </p>",
      images: [
        ImageZenith1,
        ImageZenith2,
        ImageZenith3,
        ImageZenith4,
        ImageZenith5,
        ImageZenith6,
      ],
      buttons: [
        {
          text: getLanguageTranslation("Scheda Tecnica"),
          href: TechnicalSheetZenith,
        },
      ],
    },
    undefined,
    "<p><strong>ZENITH</strong><br />" +
      getLanguageTranslation(
        "ZENITH è una tenda a bracci con cassonetto fisso su barra rettangolare 50x30mm a scomparsa totale."
      ) +
      "</p>"
  ),
];

export const createGiottoPlusGiottoInfoPointsEnIt = (
  createPositionPoint,
  createInfoPoint
) => [
  createPositionPoint([-4502.22, -388.62, -2128.65], "percorso-6", ""),
  createPositionPoint([1264.99, -264.51, 4819.59], "ombralsun", "Ombralsun"),

  createInfoPoint(
    [3900.07, -241.62, -3102.93],
    {
      type: "info",
      title: "",
      noCloseIcon: true,
      popupClass: "popup-info_container",
      sectionTitle: "GIOTTO",
      description:
        "<p>" +
        getLanguageTranslation(
          "GIOTTO è un prodotto per la copertura dei giardini d’inverno, dei tetti in vetro e delle verande in generale. BAT, forte dell’esperienza acquisita nel settore, ha progettato e sviluppato un prodotto che unisce soluzioni tecniche innovative ad un design estremamente interessante. Giotto funziona esclusivamente tramite motorizzazione."
        ) +
        "</p>",
      images: [
        ImageGiotto1,
        ImageGiotto2,
        ImageGiotto3,
        ImageGiotto4,
        ImageGiotto5,
      ],
      buttons: [
        {
          text: getLanguageTranslation("Scheda Tecnica"),
          href: TechnicalSheetGiotto,
        },
      ],
    },
    undefined,
    "<p><strong>GIOTTO</strong><br />" +
      getLanguageTranslation(
        "GIOTTO è un prodotto per la copertura dei giardini d’inverno, dei tetti in vetro e delle verande in generale."
      ) +
      "</p>"
  ),

  createInfoPoint(
    [3926.32, -239.91, 3070.63],
    {
      type: "info",
      title: "",
      noCloseIcon: true,
      popupClass: "popup-info_container",
      sectionTitle: "GIOTTO PLUS",
      description:
        "<p>" +
        getLanguageTranslation(
          "GIOTTO PLUS é la versione evoluta di Giotto, della quale mantiene tutte le caratteristiche tecniche. Sistema di protezione solare ideale per la copertura di terrazze, verande ed attici, può essere installata a piacere sia a parete che a soffitto e poggia, nella parte frontale su due colonne di design; per irrigidire la struttura vi è la possibilità di inserire un traverso che unisca le due colonne. È un sistema estremamente versatile ideale sia per gli ambienti privati che per quelli pubblici. Tenda con cassonetto dal design accattivante a scomparsa totale."
        ) +
        "</p>",
      images: [
        ImageGiottoPlus1,
        ImageGiottoPlus2,
        ImageGiottoPlus3,
        ImageGiottoPlus4,
      ],
      buttons: [
        {
          text: getLanguageTranslation("Scheda Tecnica"),
          href: TechnicalSheetGiottoPlus,
        },
      ],
    },
    undefined,
    "<p><strong>GIOTTO PLUS</strong><br />" +
      getLanguageTranslation(
        "GIOTTO PLUS é la versione evoluta di Giotto, della quale mantiene tutte le caratteristiche tecniche. Sistema di protezione solare ideale per la copertura di terrazze, verande ed attici, può essere installata a piacere sia a parete che a soffitto e poggia, nella parte frontale su due colonne di design; per irrigidire la struttura vi è la possibilità di inserire un traverso che unisca le due colonne."
      ) +
      "</p>"
  ),
];

export const createGlass1InfoPointsEnIt = (
  createPositionPoint,
  createInfoPoint
) => [
  createPositionPoint(
    [4618.56, -501.86, -1822.16],
    "patio-v4-r-pitch",
    "Patio V4 - R Pitch"
  ),
  createPositionPoint([-4362.04, -1018.47, 2198.57], "new-ely", "Ely"),
  createPositionPoint([-1473.69, -518.41, 4743.04], "ombralsun", "Ombralsun"),
  createPositionPoint([-2398.71, -385.18, 4364.78], "percorso-8", ""),
  createPositionPoint([-3478.08, -1001.29, -3434.8], "percorso-5", ""),
  createInfoPoint(
    [3829.91, -2194.92, 2334.46],
    {
      type: "info",
      title: "",
      noCloseIcon: true,
      popupClass: "popup-info_container",
      sectionTitle: "GLASS 1.0",
      description:
        "<p>" +
        getLanguageTranslation(
          "Binario inferiore alto soli 18 mm. Movimentazione delle ante a sinistra o a destra, con cambio verso rapido anche in fase d’opera. Regolazione in altezza fino a 8 mm per ogni singola anta. Sistema scomponibile per una facile pulizia. In presenza di forti piogge o piogge accompagnate da vento non è garantita la completa impermeabilità."
        ) +
        "</p>",
      images: [
        ImageGlass1,
        ImageGlass2,
        ImageGlass3,
        ImageGlass4,
        ImageGlass5,
        ImageGlass6,
        ImageGlass7,
        ImageGlass8,
        ImageGlass9,
        ImageGlass10,
        ImageGlass11,
        ImageGlass12,
      ],
      buttons: [
        {
          text: getLanguageTranslation("Scheda Tecnica"),
          href: TechnicalSheetGlass,
        },
      ],
    },
    undefined,
    "<p><strong>GLASS 1.0</strong><br />" +
      getLanguageTranslation(
        "Binario inferiore alto soli 18 mm. Movimentazione delle ante a sinistra o a destra, con cambio verso rapido anche in fase d’opera."
      ) +
      "</p>"
  ),
];

export const createMiniHelixBqMiniHelixPsInfoPointsEnIt = (
  createPositionPoint,
  createInfoPoint
) => [
  createPositionPoint(
    [-603.92, -373.92, -4944.88],
    "intro",
    getLanguageTranslation("Introduzione")
  ),
  createPositionPoint(
    [326.13, -836.34, -4910.57],
    "helix-bq-helix-ps",
    "Helix BQ - Helix PS"
  ),
  createInfoPoint(
    [4165.34, -264.34, 2734.7],
    {
      type: "info",
      title: "",
      noCloseIcon: true,
      popupClass: "popup-info_container",
      sectionTitle: "MINI HELIX BQ",
      description:
        "<p>" +
        getLanguageTranslation(
          "MINI HELIX BQ è una tenda a bracci su barra quadra da 40 in acciaio zincato e verniciato. Sorella minore dell’ormai nota Helix BQ, dalla quale eredita l’apprezzato sistema di regolazione, si caratterizza da una spiccata personalità, data dal design pulito, estremamente sobrio e gradevole; è compatibile con le braccia della famiglia ECO, sia a cinghia che doppio cavetto. Come optional può essere proposta con il supporto avvolgitore GOLD, che ne completa la finitura, rendendola veramente unica nel suo genere."
        ) +
        "</p>",
      images: [
        ImageMiniHelixBq1,
        ImageMiniHelixBq2,
        ImageMiniHelixBq3,
        ImageMiniHelixBq4,
      ],
      buttons: [
        {
          text: getLanguageTranslation("Scheda Tecnica"),
          href: TechnicalSheetMiniHelixBq,
        },
      ],
    },
    undefined,
    "<p><strong>MINI HELIX BQ</strong><br />" +
      getLanguageTranslation(
        "MINI HELIX PS è una tenda a supporti indipendenti. Concettualmente riprende le caratteristiche tecnico- meccaniche della sorella maggiore, Helix PS, come l’estrema facilità e versatilità di installazione e regolazione. Caratterizzata da un disegno gradevole e decisamente contemporaneo, al quale è stata sottoposta un’attenzione maniacale, è compatibile con le braccia della famiglia ECO, sia a cinghia che doppio cavetto."
      ) +
      "</p>"
  ),
  createInfoPoint(
    [4191.72, -225.38, -2696.89],
    {
      type: "info",
      title: "",
      noCloseIcon: true,
      popupClass: "popup-info_container",
      sectionTitle: "MINI HELIX PS",
      description:
        "<p>" +
        getLanguageTranslation(
          "MINI HELIX PS è una tenda a supporti indipendenti. Concettualmente riprende le caratteristiche tecnico- meccaniche della sorella maggiore, Helix PS, come l’estrema facilità e versatilità di installazione e regolazione."
        ) +
        "</p>",
      images: [
        ImageMiniHelixPs1,
        ImageMiniHelixPs2,
        ImageMiniHelixPs3,
        ImageMiniHelixPs4,
      ],
      buttons: [
        {
          text: getLanguageTranslation("Scheda Tecnica"),
          href: TechnicalSheetMiniHelixBs,
        },
      ],
    },
    undefined,
    "<p><strong>MINI HELIX PS</strong><br />" +
      getLanguageTranslation(
        "MINI HELIX PS è una tenda a supporti indipendenti. Concettualmente riprende le caratteristiche tecnico- meccaniche della sorella maggiore, Helix PS, come l’estrema facilità e versatilità di installazione e regolazione."
      ) +
      "</p>"
  ),
];
export const createHelixBqHelixPsInfoPointsEnIt = (
  createPositionPoint,
  createInfoPoint
) => [
  createPositionPoint(
    [-1472.32, -1022.29, -4661.76],
    "intro",
    getLanguageTranslation("Introduzione")
  ),
  createPositionPoint(
    [536.27, -659.92, -4922.84],
    "parigi-plus-screeny-130-gpz-tens-to",
    "Epica Lite - 130 GPZ TO"
  ),
  createPositionPoint(
    [-4163.52, -616.3, 2679.67],
    "screeny-110-gpz-unica-am-screeny-55-gp-xs",
    "Screeny 110 GPZ Unica AM - Screeny 55 GPXS"
  ),
  createPositionPoint([-4972.08, -473.76, -54.62], "percorso-2", ""),
  createPositionPoint(
    [382.62, -930.8, 4888.82],
    "mini-helix-bq-mini-helix-ps",
    "Mini Helix BQ - Mini Helix PS"
  ),

  createInfoPoint(
    [4436.64, -233.3, 2270.8],
    {
      type: "info",
      title: "",
      noCloseIcon: true,
      popupClass: "popup-info_container",
      sectionTitle: "HELIX BQ",
      description:
        "<p>" +
        getLanguageTranslation(
          "HELIX BQ tenda su barra quadra 40mm; è caratterizzata da un supporto in pressofusione, del tipo cosidetto a “cono” dal disegno curato e dalle prestazioni importanti: inclinazione in continuo, particolarmente agevole, da 0° a 90° gradi, sporgenza fino a 3,60m. In abbinamento con il supporto avvolgitore Gold, risulta essere una delle barre quadre più accattivanti presenti nel mercato."
        ) +
        "</p>",
      images: [
        ImageHelixBq1,
        ImageHelixBq2,
        ImageHelixBq3,
        ImageHelixBq4,
        ImageHelixBq5,
        ImageHelixBq6,
      ],
      buttons: [
        {
          text: getLanguageTranslation("Scheda Tecnica"),
          href: TechnicalSheetHelixBq,
        },
      ],
    },
    undefined,
    "<p><strong>HELIX BQ</strong><br />" +
      getLanguageTranslation(
        "HELIX BQ tenda su barra quadra 40mm; è caratterizzata da un supporto in pressofusione, del tipo cosidetto a “cono” dal disegno curato e dalle prestazioni importanti: inclinazione in continuo, particolarmente agevole, da 0° a 90° gradi, sporgenza fino a 3,60m."
      ) +
      "</p>"
  ),
  createInfoPoint(
    [4204.11, -4.75, -2693.71],
    {
      type: "info",
      title: "",
      noCloseIcon: true,
      popupClass: "popup-info_container",
      sectionTitle: "HELIX PS",
      description:
        "<p>" +
        getLanguageTranslation(
          "HELIX PS tenda a supporti indipendenti; è caratterizzata da un supporto in pressofusione, del tipo cosidetto a 'cono' dal disegno curato e dalle prestazioni importanti: inclinazione in continuo, particolarmente agevole, da 0° a 90°. Versatile e facile da installare, dal design robusto ma al contempo elegante."
        ) +
        "</p>",
      images: [
        ImageHelixPs1,
        ImageHelixPs2,
        ImageHelixPs3,
        ImageHelixPs4,
        ImageHelixPs5,
      ],
      buttons: [
        {
          text: getLanguageTranslation("Scheda Tecnica"),
          href: TechnicalSheetHelixPs,
        },
      ],
    },
    undefined,
    "<p><strong>HELIX PS</strong><br />" +
      getLanguageTranslation(
        "HELIX PS tenda a supporti indipendenti; è caratterizzata da un supporto in pressofusione, del tipo cosidetto a 'cono' dal disegno curato e dalle prestazioni importanti: inclinazione in continuo, particolarmente agevole, da 0° a 90°."
      ) +
      "</p>"
  ),
];

export const createIntroInfoPointsEnIt = (createPositionPoint) => [
  createPositionPoint(
    [-1164.57, -488.18, -4829.57],
    "mini-helix-bq-mini-helix-ps",
    "Mini Helix BQ - Mini Helix PS"
  ),
  createPositionPoint(
    [-1923.68, -753.4, -4547.75],
    "helix-bq-helix-ps",
    "Helix BQ - Helix PS"
  ),
  createPositionPoint(
    [1120.52, -335.41, -4855.42],
    "screeny-130-ga-screeny-130-gpz-mb",
    "Screeny 130 GA - Screeny 130 GPZ MB"
  ),
  createPositionPoint(
    [-1158.21, -541.93, 4824.7],
    "base-lite-epica",
    "Base Lite - Epica"
  ),
  createPositionPoint(
    [-3114.94, -425.82, 3877.65],
    "domea-grandangolo",
    "Domea - Grandangolo"
  ),
  createPositionPoint(
    [2365.7, -470.23, -4370.94],
    "screeny-110-gpz-unica-am-screeny-55-gp-xs",
    "Screeny 110 GPZ Unica AM - Screeny 55 GPXS"
  ),
  createPositionPoint(
    [-4457.08, -1471.98, 1697.44],
    "parigi-plus-screeny-130-gpz-tens-to",
    "Epica Lite - 130 GPZ TO"
  ),
  createPositionPoint(
    [-2493.73, -530.69, 4296.34],
    "base-plus-domea",
    "Base Plus - Domea"
  ),
  createPositionPoint([4485.58, -431.4, 2152.27], "percorso-1", ""),
  createPositionPoint([4314.3, -431.4, -2486.97], "percorso-2", ""),
];

export const create2010InfoPointsEnIt = (
  createPositionPoint,
  createInfoPoint
) => [
  createPositionPoint([-3547.16, -770.81, 3429.18], "percorso-1", ""),
  createPositionPoint(
    [-4229.52, -2655.55, 93.8],
    "giant-zenith",
    "Giant - Zenith"
  ),
  createPositionPoint([720.13, -1308.96, 4761.23], "2020-2040", "2020 - 2040"),
  createInfoPoint(
    [4354.99, -289.93, -2426.15],
    {
      type: "info",
      title: "",
      noCloseIcon: true,
      popupClass: "popup-info_container",
      sectionTitle: "2010",
      description:
        "<p>" +
        getLanguageTranslation(
          "2010 è una tenda a bracci estensibili compatta su barra quadra 40mm in acciaio zincato e verniciato con bracci a doppio cavetto inguainato o cinghia e supporto braccio con regolazione a cono."
        ) +
        "</p>",
      images: [Image20101, Image20102, Image20103, Image20104, Image20105],
      buttons: [
        {
          text: getLanguageTranslation("Scheda Tecnica"),
          href: TechnicalSheet2010,
        },
      ],
    },
    undefined,
    "<p><strong>2010</strong><br />" +
      getLanguageTranslation(
        "2010 è una tenda a bracci estensibili compatta su barra quadra 40mm in acciaio zincato e verniciato con bracci a doppio cavetto inguainato o cinghia e supporto braccio con regolazione a cono."
      ) +
      "</p> "
  ),
  createInfoPoint(
    [4389.54, -162.77, 2369.33],
    {
      type: "info",
      title: "",
      noCloseIcon: true,
      popupClass: "popup-info_container",
      sectionTitle: "2020",
      description:
        "<p>" +
        getLanguageTranslation(
          "2020 è una tenda a bracci estensibili compatta su barra quadra 40mm in acciaio zincato e verniciato, bracci a doppio cavetto inguainato o a cinghia e supporto braccio in estrusione di alluminio. E’ una tenda modulare: aggiungendo ulteriori profili si può raggiungere una larghezza maggiore. Il terminale frontale è intercambiabile con gli altri terminali BAT. Dispone come optional di un tettuccio di copertura in alluminio estruso e di una staffa speciale per il montaggio sottotetto."
        ) +
        "</p>",
      images: [Image20201, Image20202, Image20203, Image20204, Image20205],
      buttons: [
        {
          text: getLanguageTranslation("Scheda Tecnica"),
          href: TechnicalSheet2020,
        },
      ],
    },
    undefined,
    "<p><strong>2020</strong><br />" +
      getLanguageTranslation(
        "2020 è una tenda a bracci estensibili compatta su barra quadra 40mm in acciaio zincato e verniciato, bracci a doppio cavetto inguainato o a cinghia e supporto braccio in estrusione di alluminio. E’ una tenda modulare: aggiungendo ulteriori profili si può raggiungere una larghezza maggiore."
      ) +
      "</p>"
  ),
];

export const createArmonyPlus2040InfoPointsEnIt = (
  createPositionPoint,
  createInfoPoint
) => [
  createPositionPoint(
    [1596.97, -1528.5, 4478.6],
    "base-lite-armony-plus",
    "Base Lite - Armony Plus"
  ),
  createPositionPoint([-4595.81, -1122.19, -1600.53], "percorso-1", ""),
  createPositionPoint(
    [1656.41, -1555.52, -4446.67],
    "2020-2040",
    "2020 - 2040"
  ),
  createInfoPoint(
    [4391.67, -367.16, 2347.48],
    {
      type: "info",
      title: "",
      noCloseIcon: true,
      popupClass: "popup-info_container",
      sectionTitle: "ARMONY PLUS",
      description:
        "<p>" +
        getLanguageTranslation(
          "ARMONY PLUS é una tenda a bracci estensibili con cassonetto a scomparsa totale. Design e stile uniti alla funzionalità e alla tecnologia di un sistema ribaltabile BAT che permette la chiusura ermetica del cassonetto anche nella sua massima inclinazione inoltre, grazie ad un profilo di guarnizione il cassonetto risulta essere perfettamente aderente al muro impedendo l’infiltrazione di acque ed impurità."
        ) +
        "</p>",
      images: [
        ImageArmonyPlus1,
        ImageArmonyPlus2,
        ImageArmonyPlus3,
        ImageArmonyPlus4,
        ImageArmonyPlus5,
      ],
      buttons: [
        {
          text: getLanguageTranslation("Scheda Tecnica"),
          href: TechnicalSheetArmonyPlus,
        },
      ],
    },
    undefined,
    "<p><strong>ARMONY PLUS</strong><br />" +
      getLanguageTranslation(
        "ARMONY PLUS é una tenda a bracci estensibili con cassonetto a scomparsa totale."
      ) +
      "</p>"
  ),
  createInfoPoint(
    [4390.0, -341.69, -2352.97],
    {
      type: "info",
      title: "",
      noCloseIcon: true,
      popupClass: "popup-info_container",
      sectionTitle: "2040",
      description:
        "<p>" +
        getLanguageTranslation(
          "2040 è una tenda a bracci estensibili su barra quadra 40mm in acciaio zincato e verniciato con cassonetto a scomparsa parziale e con bracci a doppio cavetto inguainato o a cinghia. Questo modello viene fornito di un supporto braccio basculante brevettato che consente di regolare la tenda senza dover inclinare il cassonetto."
        ) +
        "</p>",
      images: [Image20401, Image20402, Image20403, Image20404, Image20405],
      buttons: [
        {
          text: getLanguageTranslation("Scheda Tecnica"),
          href: TechnicalSheet2040,
        },
      ],
    },
    undefined,
    "<p><strong>2040</strong><br />" +
      getLanguageTranslation(
        "2040 è una tenda a bracci estensibili su barra quadra 40mm in acciaio zincato e verniciato con cassonetto a scomparsa parziale e con bracci a doppio cavetto inguainato o a cinghia."
      ) +
      "</p>"
  ),
];

export const create20202040InfoPointsEnIt = (
  createPositionPoint,
  createInfoPoint
) => [
  createPositionPoint(
    [-4703.85, -1002.68, 1330.3],
    "percorso-1",
    "847.22, -1454.69, 4699.55"
  ),
  createPositionPoint(
    [847.22, -1454.69, 4699.55],
    "armony-plus-2040",
    "Armony Plus - 2040"
  ),
  createPositionPoint([682.07, -1590.97, -4682.81], "2010", "2010"),
  createPositionPoint(
    [-2157.93, -1363.73, -4287.47],
    "giant-zenith",
    "Giant - Zenith"
  ),
  createInfoPoint(
    [4409.81, -522.27, 2275.87],
    {
      type: "info",
      title: "",
      noCloseIcon: true,
      popupClass: "popup-info_container",
      sectionTitle: "2040",
      description:
        "<p>" +
        getLanguageTranslation(
          "2040 è una tenda a bracci estensibili su barra quadra 40mm in acciaio zincato e verniciato con cassonetto a scomparsa parziale e con bracci a doppio cavetto inguainato o a cinghia. Questo modello viene fornito di un supporto braccio basculante brevettato che consente di regolare la tenda senza dover inclinare il cassonetto."
        ) +
        "</p>",
      images: [Image20401, Image20402, Image20403, Image20404, Image20405],
      buttons: [
        {
          text: getLanguageTranslation("Scheda Tecnica"),
          href: TechnicalSheet2040,
        },
      ],
    },
    undefined,
    "<p><strong>2040</strong><br />" +
      getLanguageTranslation(
        "2040 è una tenda a bracci estensibili su barra quadra 40mm in acciaio zincato e verniciato con cassonetto a scomparsa parziale e con bracci a doppio cavetto inguainato o a cinghia."
      ) +
      "</p>"
  ),
  createInfoPoint(
    [4313.43, -355.55, -2500.1],
    {
      type: "info",
      title: "",
      noCloseIcon: true,
      popupClass: "popup-info_container",
      sectionTitle: "2020",
      description:
        "<p>" +
        getLanguageTranslation(
          "2020 è una tenda a bracci estensibili compatta su barra quadra 40mm in acciaio zincato e verniciato, bracci a doppio cavetto inguainato o a cinghia e supporto braccio in estrusione di alluminio. E’ una tenda modulare: aggiungendo ulteriori profili si può raggiungere una larghezza maggiore. Il terminale frontale è intercambiabile con gli altri terminali BAT. Dispone come optional di un tettuccio di copertura in alluminio estruso e di una staffa speciale per il montaggio sottotetto."
        ) +
        "</p>",
      images: [Image20201, Image20202, Image20203, Image20204, Image20205],
      buttons: [
        {
          text: getLanguageTranslation("Scheda Tecnica"),
          href: TechnicalSheet2020,
        },
      ],
    },
    undefined,
    "<p><strong>2020</strong><br />" +
      getLanguageTranslation(
        "2020 è una tenda a bracci estensibili compatta su barra quadra 40mm in acciaio zincato e verniciato, bracci a doppio cavetto inguainato o a cinghia e supporto braccio in estrusione di alluminio. E’ una tenda modulare: aggiungendo ulteriori profili si può raggiungere una larghezza maggiore."
      ) +
      "</p>"
  ),
];

export const createOmbralsunInfoPointsEnIt = (
  createPositionPoint,
  createInfoPoint
) => [
  createPositionPoint([2973.96, -1280.62, 3798.09], "percorso-7", ""),
  createPositionPoint([-2759.06, -1110.05, 4010.93], "percorso-8", ""),
  createPositionPoint(
    [4851.58, -389.85, -1126.59],
    "giotto-plus-giotto",
    "Giotto Plus - Giotto"
  ),
  createPositionPoint([2106.19, -516.01, -4497.1], "glass-1", "Glass 1.0"),
  createPositionPoint([174.32, -706.52, -4938.26], "new-ely", "Ely"),
  createPositionPoint(
    [4682.24, -432.96, 1679.0],
    "sirio-space",
    "Sirio - Space"
  ),
  createInfoPoint(
    [4464.15, -2011.87, -987.97],
    {
      type: "info",
      title: "",
      noCloseIcon: true,
      popupClass: "popup-info_container",
      sectionTitle: "OMBRALSUN",
      description:
        "<p>" +
        getLanguageTranslation(
          "OMBRALSUN, per la sue dimensioni, compattezza e facilità di installazione e regolazione, si posiziona sicuramente in vetta alla già ampia gamma dei prodotti per terrazzi e patio completandone la gamma. Grazie ad un solo tubo avvolgitore “Rollerbat” Ø 80mm, riesce a coprire una superficie di ca 36m2, mantenendo comunque contenuti gli ingombri; supporti e staffe di ancoraggio sono studiati perché tutte le installazioni siano semplici e veloci. Il sistema è completato da un cassonetto integrale che racchiude, una volta ritratti, entrambi i lati della tenda."
        ) +
        "</p>",
      images: [
        ImageOmbralsun1,
        ImageOmbralsun2,
        ImageOmbralsun3,
        ImageOmbralsun4,
        ImageOmbralsun5,
      ],
      buttons: [
        {
          text: getLanguageTranslation("Scheda Tecnica"),
          href: TechnicalSheetOmbralsun,
        },
      ],
    },
    undefined,
    "<p><strong>OMBRALSUN</strong><br />" +
      getLanguageTranslation(
        "OMBRALSUN, per la sue dimensioni, compattezza e facilità di installazione e regolazione, si posiziona sicuramente in vetta alla già ampia gamma dei prodotti per terrazzi e patio completandone la gamma."
      ) +
      "</p>"
  ),
];

export const createPatioV4RPitchInfoPointsEnIt = (
  createPositionPoint,
  createInfoPoint
) => [
  createPositionPoint([789.61, -1068.88, 4810.42], "percorso-6", ""),
  createPositionPoint([599.35, -420.8, -4941.46], "percorso-5", ""),
  createInfoPoint(
    [4060.46, -410.95, -2882.94],
    {
      type: "info",
      title: "",
      noCloseIcon: true,
      popupClass: "popup-info_container",
      sectionTitle: "PATIO V4",
      description:
        "<p>" +
        getLanguageTranslation(
          "Una struttura per la copertura di verande, terrazze, attici da abbinarsi ad una struttura portante di supporto. Il tessuto a scorrimento manuale è caratterizzato dalla classica conformazione ad onda e si può raccogliere in entrambe le direzioni. La particolare attenzione posta alla soluzione tecnica e al design la rende adattabile ad una vasta gamma di strutture di sostegno."
        ) +
        "</p> ",
      images: [
        ImagePatioV41,
        ImagePatioV42,
        ImagePatioV43,
        ImagePatioV44,
        ImagePatioV45,
        ImagePatioV46,
      ],
      buttons: [
        {
          text: getLanguageTranslation("Scheda Tecnica"),
          href: TechnicalSheetPatioV4,
        },
      ],
    },
    undefined,
    "<p><strong>PATIO V4</strong><br />" +
      getLanguageTranslation(
        "Una struttura per la copertura di verande, terrazze, attici da abbinarsi ad una struttura portante di supporto."
      ) +
      "</p> "
  ),
  createInfoPoint(
    [4396.57, -65.89, 2363.9],
    {
      type: "info",
      title: "",
      noCloseIcon: true,
      popupClass: "popup-info_container",
      sectionTitle: "R-PITCH",
      description:
        "<p>" +
        getLanguageTranslation(
          "Una tenda a bracci estensibili su barra quadra 40mm in acciaio zincato e verniciato con bracci a doppio cavetto inguainato o a cinghia e supporto braccio a cremagliera con inclinazione singola di ogni braccio. Tenda modulare: aggiungendo ulteriori profili si possono raggiungere misure diverse. Il terminale frontale è intercambiabile con quelli del catalogo BAT. Dispone come optional di un tettuccio di copertura in alluminio estruso e di una staffa speciale per il montaggio sottotetto."
        ) +
        "</p> ",
      images: [ImageRPitch1, ImageRPitch2, ImageRPitch3, ImageRPitch4],
      buttons: [
        {
          text: getLanguageTranslation("Scheda Tecnica"),
          href: TechnicalSheetRPitch,
        },
      ],
    },
    undefined,
    "<p><strong>R-PITCH</strong><br />" +
      getLanguageTranslation(
        "Una tenda a bracci estensibili su barra quadra 40mm in acciaio zincato e verniciato con bracci a doppio cavetto inguainato o a cinghia e supporto braccio a cremagliera con inclinazione singola di ogni braccio."
      ) +
      "</p> "
  ),
];

export const createPercorso1InfoPointsEnIt = (createPositionPoint) => [
  createPositionPoint([-3871.87, -603.63, 3088.68], "2010", "2010"),
  createPositionPoint(
    [-3268.98, -995.96, 3644.07],
    "giant-zenith",
    "Giant - Zenith"
  ),
  createPositionPoint([-4789.27, -801.17, 1166.13], "2020-2040", "2020 - 2040"),
  createPositionPoint(
    [-4859.17, -779.9, -847.63],
    "armony-plus-2040",
    "Armony Plus - 2040"
  ),
  createPositionPoint(
    [-3295.38, -604.93, -3704.29],
    "base-lite-epica",
    "Base Lite - Epica"
  ),
  createPositionPoint(
    [-2347.92, -392.18, -4390.35],
    "base-plus-epica",
    "Base Plus - Epica"
  ),
  createPositionPoint(
    [-1594.35, -309.39, -4723.37],
    "domea-grandangolo",
    "Domea - Grandangolo"
  ),
  createPositionPoint(
    [1793.31, -675.58, -4607.64],
    "intro",
    getLanguageTranslation("Introduzione")
  ),
  createPositionPoint([4932.74, -535.76, 567.59], "percorso-2", ""),
];

export const createPercorso2InfoPointsEnIt = (createPositionPoint) => [
  createPositionPoint(
    [4030.84, -433.63, -2923.63],
    "intro",
    getLanguageTranslation("Introduzione")
  ),
  createPositionPoint([766.09, -579.23, -4896.1], "percorso-1", ""),
  createPositionPoint(
    [4967.5, -440.07, -218.14],
    "helix-bq-helix-ps",
    "Helix BQ - Helix PS"
  ),
];
export const createPercorso4InfoPointsEnIt = (createPositionPoint) => [
  createPositionPoint([-4910.44, -240.27, 854.9], "percorso-1", ""),
  createPositionPoint([3945.41, -333.75, 3041.21], "percorso-5", ""),
  createPositionPoint(
    [-4295.94, -1664.13, -1932.2],
    "domea-grandangolo",
    "Domea - Grandangolo"
  ),
];
export const createPercorso5InfoPointsEnIt = (createPositionPoint) => [
  createPositionPoint([-3136.65, -1245.15, 3679.21], "percorso-4", ""),
  createPositionPoint([4964.12, -472.34, 209.83], "new-ely", "Ely"),
  createPositionPoint([4972.69, -303.79, -293.31], "ombralsun", "Ombralsun"),
  createPositionPoint([4145.89, -476.79, -2737.11], "glass-1", "Glass 1.0"),
  createPositionPoint(
    [715.31, -310.61, -4929.75],
    "patio-v4-r-pitch",
    "Patio V4 - R Pitch"
  ),
];
export const createPercorso6InfoPointsEnIt = (createPositionPoint) => [
  createPositionPoint([-250.08, -340.75, 4973.51], "percorso-5", ""),
  createPositionPoint(
    [4750.83, -334.96, 1510.94],
    "giotto-plus-giotto",
    "Giotto Plus - Giotto"
  ),
  createPositionPoint(
    [1117.52, -1174.8, 4724.81],
    "patio-v4-r-pitch",
    "Patio V4 - R Pitch"
  ),
];
export const createPercorso7InfoPointsEnIt = (createPositionPoint) => [
  createPositionPoint([-4656.21, -1207.38, 1338.1], "percorso-8", ""),
  createPositionPoint([-1016.77, -459.8, -4870.69], "new-ely", "Ely"),
  createPositionPoint([-864.86, -1293.54, -4742.71], "ombralsun", "Ombralsun"),
  createPositionPoint(
    [4927.04, -499.44, 631.0],
    "sirio-space",
    "Sirio - Space"
  ),
];
export const createPercorso8InfoPointsEnIt = (createPositionPoint) => [
  createPositionPoint([796.78, -1225.75, 4772.82], "percorso-7", ""),
  createPositionPoint([4902.67, -469.46, 807.55], "new-ely", "Ely"),
  createPositionPoint([3710.75, -1196.81, 3119.33], "ombralsun", "Ombralsun"),
  createPositionPoint([4429.12, -353.5, 2275.17], "glass-1", "Glass 1.0"),
  createPositionPoint(
    [-191.65, -386.33, 4974.78],
    "sirio-space",
    "Sirio - Space"
  ),
];

export const createScreeny110GcScreeny130GpzIInfoPointsEnIt = (
  createPositionPoint,
  createInfoPoint
) => [
  createPositionPoint(
    [-442.4, -1774.52, 4647.46],
    "screeny-110-gc-screeny-130-gpz-unica-m",
    "Screeny 110GC - Screeny 130 GPZ Unica M"
  ),
  createPositionPoint(
    [-1166.18, -2589.76, -4108.22],
    "screeny-130-ga-screeny-130-gpz-mb",
    "Screeny 130 GA - Screeny 130 GPZ MB"
  ),
  createInfoPoint(
    [4829.56, -853.34, -942.47],
    {
      type: "info",
      title: "",
      noCloseIcon: true,
      popupClass: "popup-info_container",
      sectionTitle: "SCREENY 130 GPZ I",
      description:
        "<p>" +
        getLanguageTranslation(
          "Tenda con cassonetto da 130mm disponibile nelle varianti Quadro e Tondo dotata di sistema di guida ZIP, composta da due profili con compensazione fino ad un max di 17 mm su ogni guida. Adatta per essere installata ad incasso e per applicazioni che presentano fuori squadra."
        ) +
        "</p>",
      images: [
        ImageScreeny130GpzI1,
        ImageScreeny130GpzI2,
        ImageScreeny130GpzI3,
        ImageScreeny130GpzI4,
        ImageScreeny130GpzI5,
        ImageScreeny130GpzI6,
      ],
      buttons: [
        {
          text: getLanguageTranslation("Scheda Tecnica"),
          href: TechnicalSheetScreeny130GpzI,
        },
      ],
    },
    undefined,
    "<p><strong>SCREENY 130 GPZ I</strong><br />" +
      getLanguageTranslation(
        "Tenda con cassonetto da 130mm disponibile nelle varianti Quadro e Tondo dotata di sistema di guida ZIP, composta da due profili con compensazione fino ad un max di 17 mm su ogni guida. Adatta per essere installata ad incasso e per applicazioni che presentano fuori squadra."
      ) +
      "</p>"
  ),

  createInfoPoint(
    [4808.14, -837.2, 1068.06],
    {
      type: "info",
      title: "",
      noCloseIcon: true,
      popupClass: "popup-info_container",
      sectionTitle: "SCREENY 110 GC",
      description:
        "<p>" +
        getLanguageTranslation(
          "Tenda con cassonetto da 110mm disponibile nelle varianti Quadro e Tondo, dotata di guida cavetto inox Ø4mm."
        ) +
        "</p>",
      images: [
        ImageScreeny110Gc1,
        ImageScreeny110Gc2,
        ImageScreeny110Gc3,
        ImageScreeny110Gc4,
        ImageScreeny110Gc5,
        ImageScreeny110Gc6,
        ImageScreeny110Gc7,
      ],
      buttons: [
        {
          text: getLanguageTranslation("Scheda Tecnica"),
          href: TechnicalSheetScreeny110Gc,
        },
      ],
    },
    undefined,
    "<p><strong>SCREENY 110 GC</strong><br />" +
      getLanguageTranslation(
        "Tenda con cassonetto da 110mm disponibile nelle varianti Quadro e Tondo, dotata di guida cavetto inox Ø4mm."
      ) +
      "</p>"
  ),
];

export const createScreeny150GpzUnicaScreeny110GcCabrioInfoPointsEnIt = (
  createPositionPoint,
  createInfoPoint
) => {
  return [
    createPositionPoint(
      [2007.49, -398.94, -4560.45],
      "intro",
      getLanguageTranslation("Introduzione")
    ),
    createPositionPoint(
      [-3976.58, -2611.24, -1522.47],
      "screeny-110-gpz-unica-am-screeny-55-gp-xs",
      "Screeny 110 GPZ Unica AM - Screeny 55 GPXS"
    ),
    createPositionPoint(
      [-4306.58, -802.1, 2402.08],
      "verso-genova",
      "Verso Cassonetto - Genova"
    ),
    createPositionPoint([-3514.68, -640.06, 3483.51], "new-roma", "New Roma"),
    createPositionPoint(
      [364.6, -956.39, 4884.68],
      "screeny-130-ga-screeny-130-gpz-mb",
      "Screeny 130 GA - Screeny 130 GPZ MB"
    ),
    createInfoPoint(
      [4023.95, -879.51, -2823.1],
      {
        type: "info",
        title: "",
        noCloseIcon: true,
        popupClass: "popup-info_container",
        sectionTitle: "SCREENY 110 GC CABRIO",
        description:
          "<p>" +
          getLanguageTranslation(
            "Tenda senza cassonetto, dotata di guida cavetto inox Ø4mm. Realizzabile sia nella versione singola che doppia."
          ) +
          "</p>",
        descriptionImages: [DescriptionImageScreeny110GcCabrio1],
        images: [
          ImageScreeny110GcCabrio1,
          ImageScreeny110GcCabrio2,
          ImageScreeny110GcCabrio3,
          ImageScreeny110GcCabrio4,
          ImageScreeny110GcCabrio5,
          ImageScreeny110GcCabrio6,
          ImageScreeny110GcCabrio7,
          ImageScreeny110GcCabrio8,
        ],
        buttons: [
          {
            text: getLanguageTranslation("Scheda Tecnica"),
            href: TechnicalSheetScreeny110GcCabrio,
          },
        ],
      },
      undefined,
      "<p><strong>SCREENY 110 GC CABRIO</strong><br />" +
        getLanguageTranslation(
          "Tenda senza cassonetto, dotata di guida cavetto inox Ø4mm. Realizzabile sia nella versione singola che doppia."
        ) +
        "</p>"
    ),
    createInfoPoint(
      [4775.6, -976.86, 1082.33],
      {
        type: "info",
        title: "",
        noCloseIcon: true,
        popupClass: "popup-info_container",
        sectionTitle: "SCREENY 150 GPZ UNICA",
        description:
          "<p>" +
          getLanguageTranslation(
            "Tenda con cassonetto da 150mm disponibile nella sola variante Tondo, dotata di sistema di guida ZIP. Adatta per installazioni su grandi fori luce, sia a nicchia che a parete. La guida è composta da due profili: supporto e copertina; è veloce ed agevole da installare ed ispezionare."
          ) +
          "</p>",
        images: [
          ImageScreeny150GpzUnica1,
          ImageScreeny150GpzUnica2,
          ImageScreeny150GpzUnica3,
          ImageScreeny150GpzUnica4,
          ImageScreeny150GpzUnica5,
        ],
        buttons: [
          {
            text: getLanguageTranslation("Scheda Tecnica"),
            href: TechnicalSheetScreeny150GpzUnica,
          },
        ],
      },
      undefined,
      "<p><strong>SCREENY 150 GPZ UNICA</strong><br />" +
        getLanguageTranslation(
          "Tenda con cassonetto da 150mm disponibile nella sola variante Tondo, dotata di sistema di guida ZIP. Adatta per installazioni su grandi fori luce, sia a nicchia che a parete. La guida è composta da due profili: supporto e copertina; è veloce ed agevole da installare ed ispezionare."
        ) +
        "</p>"
    ),
  ];
};

export const createScreeny130GaScreeny130GpzMbInfoPointsEnIt = (
  createPositionPoint,
  createInfoPoint
) => [
  createPositionPoint(
    [-3910.01, -3106.11, -62.61],
    "screeny-110-gc-screeny-130-gpz-i",
    "Screeny 110GC - Screeny 130 GPZ I"
  ),
  createPositionPoint(
    [409.5, -934.31, -4886.72],
    "screeny-150-gpz-unica-screeny-110-gc-cabrio",
    "Screeny 150 GPZ Unica - Screeny 110 GC Cabrio"
  ),
  createPositionPoint(
    [-95.45, -4734.07, 1597.58],
    "screeny-110-gc-screeny-130-gpz-i",
    "Screeny 110GC - Screeny 130 GPZ I"
  ),
  createInfoPoint(
    [3999.9, -944.11, -2836.57],
    {
      type: "info",
      title: "",
      noCloseIcon: true,
      popupClass: "popup-info_container",
      sectionTitle: "SCREENY 130 GPZ MB",
      description:
        "<p>" +
        getLanguageTranslation(
          "Tenda con cassonetto da 130mm disponibile nella sola variante Quadro, dotata di guida profilo da incasso con sistema ZIP e cassonetto autoportante. Adatta per l’integrazione nei sistemi per serramento, migliorando il risparmio energetico."
        ) +
        "</p>",
      images: [
        ImageScreeny130GpzMb1,
        ImageScreeny130GpzMb2,
        ImageScreeny130GpzMb3,
        ImageScreeny130GpzMb4,
      ],
      buttons: [
        {
          text: getLanguageTranslation("Scheda Tecnica"),
          href: TechnicalSheetScreeny130GpzMb,
        },
      ],
    },
    undefined,
    "<p><strong>SCREENY 130 GPZ MB</strong><br />" +
      getLanguageTranslation(
        "Tenda con cassonetto da 130mm disponibile nella sola variante Quadro, dotata di guida profilo da incasso con sistema ZIP e cassonetto autoportante. Adatta per l’integrazione nei sistemi per serramento, migliorando il risparmio energetico."
      ) +
      "</p>"
  ),
  createInfoPoint(
    [4763.73, -967.98, 1133.43],
    {
      type: "info",
      title: "",
      noCloseIcon: true,
      popupClass: "popup-info_container",
      sectionTitle: "SCREENY 130 GA",
      description:
        "<p>" +
        getLanguageTranslation(
          "Tenda con cassonetto da 130mm disponibile nelle varianti Quadro e Tondo, dotata di guida asta inox Ø10mm."
        ) +
        "</p>",
      images: [
        ImageScreeny130Ga1,
        ImageScreeny130Ga2,
        ImageScreeny130Ga3,
        ImageScreeny130Ga4,
        ImageScreeny130Ga5,
        ImageScreeny130Ga6,
      ],
      buttons: [
        {
          text: getLanguageTranslation("Scheda Tecnica"),
          href: TechnicalSheetScreeny130Ga,
        },
      ],
    },
    undefined,
    "<p><strong>SCREENY 130 GA</strong><br />" +
      getLanguageTranslation(
        "Tenda con cassonetto da 130mm disponibile nelle varianti Quadro e Tondo, dotata di guida asta inox Ø10mm."
      ) +
      "</p>"
  ),
];

export const createScreeny110GcScreeny130GpzUnicaMInfoPointsEnIt = (
  createPositionPoint,
  createInfoPoint
) => [
  createPositionPoint(
    [-598.04, -2025.71, 4523.51],
    "screeny-150-storm-screeny-130-gpz-unica-m",
    "Screeny Storm - Screeny 130 GPZ Unica M"
  ),
  createPositionPoint(
    [784.28, -1809.25, -4585.21],
    "screeny-110-gc-screeny-130-gpz-i",
    "Screeny 110GC - Screeny 130 GPZ I"
  ),
  createInfoPoint(
    [4812.95, -992.23, -870.97],
    {
      type: "info",
      title: "",
      noCloseIcon: true,
      popupClass: "popup-info_container",
      sectionTitle: "SCREENY 110 GC",
      description:
        "<p>" +
        getLanguageTranslation(
          "Tenda con cassonetto da 110mm disponibile nelle varianti Quadro e Tondo, dotata di guida cavetto inox Ø4mm."
        ) +
        "</p>",
      images: [
        ImageScreeny110Gc1,
        ImageScreeny110Gc2,
        ImageScreeny110Gc3,
        ImageScreeny110Gc4,
        ImageScreeny110Gc5,
        ImageScreeny110Gc6,
        ImageScreeny110Gc7,
      ],
      buttons: [
        {
          text: getLanguageTranslation("Scheda Tecnica"),
          href: TechnicalSheetScreeny110Gc,
        },
      ],
    },
    undefined,
    "<p><strong>SCREENY 110 GC</strong><br />" +
      getLanguageTranslation(
        "Tenda con cassonetto da 110mm disponibile nelle varianti Quadro e Tondo, dotata di guida cavetto inox Ø4mm."
      ) +
      "</p>"
  ),
  createInfoPoint(
    [4811.7, -954.13, 927.27],
    {
      type: "info",
      title: "",
      noCloseIcon: true,
      popupClass: "popup-info_container",
      sectionTitle: "SCREENY 130 GPZ UNICA M",
      description:
        "<p>" +
        getLanguageTranslation(
          "Tenda con cassonetto da 130mm disponibile nelle varianti Quadro e Tondo dotata di sistema di guida ZIP. Adatta per installazioni su infissi, sia a nicchia che a parete; veloce ed agevole da installare ed ispezionare. La guida è composta da due profili: supporto e copertina; è sviluppata per i soli sistemi motorizzati riducendo la larghezza a 36 mm."
        ) +
        "</p>",
      images: [
        ImageScreeny130GpzUnicaM1,
        ImageScreeny130GpzUnicaM2,
        ImageScreeny130GpzUnicaM3,
        ImageScreeny130GpzUnicaM4,
        ImageScreeny130GpzUnicaM5,
        ImageScreeny130GpzUnicaM6,
        ImageScreeny130GpzUnicaM7,
      ],
      buttons: [
        {
          text: getLanguageTranslation("Scheda Tecnica"),
          href: TechnicalSheetScreeny130GpzUnicaM,
        },
      ],
    },
    undefined,
    "<p><strong>SCREENY 130 GPZ UNICA M</strong><br />" +
      getLanguageTranslation(
        "Tenda con cassonetto da 130mm disponibile nelle varianti Quadro e Tondo dotata di sistema di guida ZIP."
      ) +
      "</p>"
  ),
];

export const createScreeny110GpzUnicaAmScreeny55GpXsInfoPointsEnIt = (
  createPositionPoint,
  createInfoPoint
) => {
  return [
    // createPositionPoint([-511.77, -1363.59, -4778.66], "havant", "Havant"),
    createPositionPoint(
      [2122.13, -1998.52, -4052.12],
      "screeny-110-gpz-unica-am-screeny-85-gpz-s",
      "Screeny 110 GPZ Unica AM - Screeny 85 GPZS"
    ),
    createPositionPoint(
      [-459.39, -1899.29, 4600.53],
      "screeny-150-gpz-unica-screeny-110-gc-cabrio",
      "Screeny 150 GPZ Unica - Screeny 110 GC Cabrio"
    ),
    createInfoPoint(
      [4041.41, -882.98, -2794.95],
      {
        type: "info",
        title: "",
        noCloseIcon: true,
        popupClass: "popup-info_container",
        sectionTitle: "SCREENY 110 GPZ UNICA AM",
        description:
          "<p>" +
          getLanguageTranslation(
            "Tenda con cassonetto da 110mm disponibile nelle varianti Quadro e Tondo dotata di sistema di guida ZIP e cassonetto autoportante. Adatta per installazioni su infissi, sia a nicchia che a parete; veloce ed agevole da installare ed ispezionare. La guida è composta da due profili: supporto e copertina; è sviluppata per sistemi sia ad argano che motorizzati."
          ) +
          "</p>",
        images: [
          ImageScreeny110GpzUnicaAm1,
          ImageScreeny110GpzUnicaAm2,
          ImageScreeny110GpzUnicaAm3,
          ImageScreeny110GpzUnicaAm4,
          ImageScreeny110GpzUnicaAm5,
          ImageScreeny110GpzUnicaAm6,
          ImageScreeny110GpzUnicaAm7,
        ],
        buttons: [
          {
            text: getLanguageTranslation("Scheda Tecnica"),
            href: TechnicalSheetScreeny110GpzUnicaAm,
          },
        ],
      },
      undefined,
      "<p><strong>SCREENY 110 GPZ UNICA AM</strong><br />" +
        getLanguageTranslation(
          "Tenda con cassonetto da 110mm disponibile nelle varianti Quadro e Tondo dotata di sistema di guida ZIP e cassonetto autoportante. Adatta per installazioni su infissi, sia a nicchia che a parete; veloce ed agevole da installare ed ispezionare."
        ) +
        "</p>"
    ),
    createInfoPoint(
      [4857.23, -1014.3, 563.36],
      {
        type: "info",
        title: "",
        noCloseIcon: true,
        popupClass: "popup-info_container",
        sectionTitle: "SCREENY 55 GPXS",
        description:
          "<p>" +
          getLanguageTranslation(
            "Tenda con cassonetto da 55mm disponibile nella sola variante Quadro, dotata di guida profilo e cassonetto autoportante."
          ) +
          "</p>",
        images: [
          ImageScreeny55GpXs1,
          ImageScreeny55GpXs2,
          ImageScreeny55GpXs3,
          ImageScreeny55GpXs4,
          ImageScreeny55GpXs5,
          ImageScreeny55GpXs6,
        ],
        buttons: [
          {
            text: getLanguageTranslation("Scheda Tecnica"),
            href: TechnicalSheetScreeny55GpXs,
          },
        ],
      },
      undefined,
      "<p><strong>SCREENY 55 GPXS</strong><br />" +
        getLanguageTranslation(
          "Tenda con cassonetto da 55mm disponibile nella sola variante Quadro, dotata di guida profilo e cassonetto autoportante."
        ) +
        "</p>"
    ),
  ];
};

export const createParigiPlusScreeny130GpzTensToInfoPointsEnIt = (
  createPositionPoint,
  createInfoPoint,
  createVrPoint,
  createVideoPoint
) => [
  createPositionPoint(
    [-2715.06, -1672.31, 3843.19],
    "intro",
    getLanguageTranslation("Introduzione")
  ),
  createPositionPoint(
    [756.94, -770.79, 4874.62],
    "helix-bq-helix-ps",
    "Helix BQ - Helix PS"
  ),
  createPositionPoint([-4834.62, -405.91, -1183.32], "percorso-1", ""),
  createPositionPoint([-4244.72, -344.38, 2606.71], "percorso-2", ""),
  createPositionPoint(
    [-1492.56, -831.77, -4695.51],
    "base-lite-epica",
    "Base Lite - Epica"
  ),
  createPositionPoint(
    [1721.56, -698.95, -4632.23],
    "base-plus-domea",
    "Base Plus - Domea"
  ),
  createInfoPoint(
    [4831.31, -490.56, -1157.0],
    {
      type: "info",
      title: "",
      noCloseIcon: true,
      popupClass: "popup-info_container",
      sectionTitle: "SCREENY 130 GPZ TO",
      description:
        "<p>" +
        getLanguageTranslation(
          "Tenda con cassonetto da 130mm disponibile nella sola variante Quadro, dotata di sistema di guida ZIP. Adatta, grazie al sistema di tensionamento , per installazioni come copertura dei giardini d’inverno, dei tetti in vetro e delle verande in generale. Funzionamento esclusivamente motorizzato."
        ) +
        "</p>",
      images: [
        ImageScreeny130GpzTensTo1,
        ImageScreeny130GpzTensTo2,
        ImageScreeny130GpzTensTo3,
        ImageScreeny130GpzTensTo4,
        ImageScreeny130GpzTensTo5,
      ],
      buttons: [
        {
          text: getLanguageTranslation("Scheda Tecnica"),
          href: TechnicalSheetScreeny130GpzTensTo,
        },
      ],
    },
    undefined,
    "<p><strong>SCREENY 130 GPZ TO</strong><br />" +
      getLanguageTranslation(
        "Tenda con cassonetto da 130mm disponibile nella sola variante Quadro, dotata di sistema di guida ZIP."
      ) +
      "</p>"
  ),
  createVrPoint([3940.82, -949.93, 2917.05], EpicaLiteGLB, EpicaLiteUSDZ, undefined, "EPICA LITE"),
  createVideoPoint(
    [3199.88, 1081.85, 3678.07],
    "Video",
    "https://www.youtube.com/watch?v=rYrZhH8tMn4"
  ),
  createInfoPoint(
    [4237.43, 1.78, 2642.61],
    {
      type: "info",
      title: "",
      noCloseIcon: true,
      popupClass: "popup-info_container",
      sectionTitle: "EPICA LITE",
      description:
        "<p>" +
        getLanguageTranslation(
          "EPICA LITE è una tenda a bracci estensibili con cassonetto su supporti laterali fissi con contropiastra di ancoraggio, bracci Balteus o spin. Il cassonetto, ispezionabile, viene fornito in tre profili separati. I supporti consentono di installare la tenda sia a parete che a soffitto e la regolazione può essere eseguita in un’unica soluzione grazie al sistema con vite passante."
        ) +
        "</p>",
      images: [
        ImageEpicaLite1,
        ImageEpicaLite2,
        ImageEpicaLite3,
        ImageEpicaLite4,
        ImageEpicaLite5,
        ImageEpicaLite6,
        ImageEpicaLite7,
        ImageEpicaLite8,
        ImageEpicaLite9,
      ],
      buttons: [
        {
          text: getLanguageTranslation("Scheda Tecnica"),
          href: TechnicalSheetEpicaLite,
        },
      ],
    },
    undefined,
    "<p><strong>EPICA LITE</strong><br />" +
      getLanguageTranslation(
        "EPICA LITE è una tenda a bracci estensibili con cassonetto su supporti laterali fissi con contropiastra di ancoraggio, bracci Balteus o spin."
      ) +
      "</p>"
  ),
];

export const createNewRomaInfoPointsEnIt = (
  createPositionPoint,
  createInfoPoint
) => [
  createPositionPoint(
    [602.43, -2238.58, 4423.59],
    "verso-genova",
    "Verso Cassonetto - Genova"
  ),
  createPositionPoint(
    [-3950.22, -2738.85, -1353.92],
    "screeny-150-storm-screeny-130-gpz-unica-m",
    "Screeny Storm - Screeny 130 GPZ Unica M"
  ),
  createInfoPoint(
    [4661.12, -625.47, -1670.53],
    {
      type: "info",
      title: "",
      noCloseIcon: true,
      popupClass: "popup-info_container",
      sectionTitle: "NEW ROMA",
      description:
        "<p>" +
        getLanguageTranslation(
          "NEW ROMA è una tenda verticale dal design particolarmente curato dedicata alla posa su balconi. Questo modello può essere con oppure senza braccetti in tre diverse opzioni di funzionamento: con arganello, con molla di richiamo e motorizzata. Il braccetto è regolabile su posizioni fisse da 0° a 180° tramite una apposita manopola di bloccaggio e prevede una ulteriore rotazione che consente un eventuale allineamento alla ringhiera. L’installazione è possibile sia a parete che a soffitto. E’ disponibile un cassonetto in alluminio opzionale."
        ) +
        "</p>",
      images: [
        ImageNewRoma1,
        ImageNewRoma2,
        ImageNewRoma3,
        ImageNewRoma4,
        ImageNewRoma5,
      ],
      buttons: [
        {
          text: getLanguageTranslation("Scheda Tecnica"),
          href: TechnicalSheetNewRoma,
        },
      ],
    },
    undefined,
    "<p><strong>NEW ROMA</strong><br />" +
      getLanguageTranslation(
        "NEW ROMA è una tenda verticale dal design particolarmente curato dedicata alla posa su balconi. Questo modello può essere con oppure senza braccetti in tre diverse opzioni di funzionamento: con arganello, con molla di richiamo e motorizzata."
      ) +
      "</p>"
  ),
  createInfoPoint(
    [4545.67, -1292.96, 1607.37],
    {
      type: "info",
      title: "",
      noCloseIcon: true,
      popupClass: "popup-info_container",
      sectionTitle: "GENOVA",
      description:
        "<p>" +
        getLanguageTranslation(
          "GENOVA è una tenda verticale con guide laterali dedicata alla posa su balconi. Questo modello può essere con oppure senza braccetti. Il braccetto scorre a caduta nelle guide e viene poi fissato nella posizione desiderata (regolabile fino a 160°) tramite una apposita manopola di bloccaggio. L’installazione è sia a parete che a soffitto."
        ) +
        "</p>",
      images: [ImageGenova1, ImageGenova2, ImageGenova3, ImageGenova4],
      buttons: [
        {
          text: getLanguageTranslation("Scheda Tecnica"),
          href: TechnicalSheetGenova,
        },
      ],
    },
    undefined,
    "<p><strong>GENOVA</strong><br />" +
      getLanguageTranslation(
        "GENOVA è una tenda verticale con guide laterali dedicata alla posa su balconi. Questo modello può essere con oppure senza braccetti."
      ) +
      "</p>"
  ),
];

export const createVersoGenovaInfoPointsEnIt = (
  createPositionPoint,
  createInfoPoint
) => [
  createPositionPoint([945.99, -2221.91, -4375.29], "new-roma", "New Roma"),
  createInfoPoint(
    [4715.74, -1151.07, 1172.01],
    {
      type: "info",
      title: "",
      noCloseIcon: true,
      popupClass: "popup-info_container",
      sectionTitle: "VERSO",
      description:
        "<p>" +
        getLanguageTranslation(
          "VERSO è una tenda dedicata alla posa su balconi e finestre con braccetti a molla. Di semplice e veloce installazione prevede anche un cassonetto opzionale per la protezione del tessuto. Grazie alla conformazione del braccio si adatta particolarmente alla installazione a nicchia."
        ) +
        "</p>",
      images: [ImageVerso1, ImageVerso2, ImageVerso3, ImageVerso4, ImageVerso5],
      buttons: [
        {
          text: getLanguageTranslation("Scheda Tecnica"),
          href: TechnicalSheetVerso,
        },
      ],
    },
    undefined,
    "<p><strong>VERSO</strong><br />" +
      getLanguageTranslation(
        "VERSO è una tenda dedicata alla posa su balconi e finestre con braccetti a molla. Di semplice e veloce installazione prevede anche un cassonetto opzionale per la protezione del tessuto."
      ) +
      "</p>"
  ),
  createInfoPoint(
    [4470.2, -1107.0, -1936.16],
    {
      type: "info",
      title: "",
      noCloseIcon: true,
      popupClass: "popup-info_container",
      sectionTitle: "GENOVA",
      description:
        "<p>" +
        getLanguageTranslation(
          "GENOVA è una tenda verticale con guide laterali dedicata alla posa su balconi. Questo modello può essere con oppure senza braccetti. Il braccetto scorre a caduta nelle guide e viene poi fissato nella posizione desiderata (regolabile fino a 160°) tramite una apposita manopola di bloccaggio. L’installazione è sia a parete che a soffitto."
        ) +
        "</p>",
      images: [ImageGenova1, ImageGenova2, ImageGenova3, ImageGenova4],
      buttons: [
        {
          text: getLanguageTranslation("Scheda Tecnica"),
          href: TechnicalSheetGenova,
        },
      ],
    },
    undefined,
    "<p><strong>GENOVA</strong><br />" +
      getLanguageTranslation(
        "GENOVA è una tenda verticale con guide laterali dedicata alla posa su balconi. Questo modello può essere con oppure senza braccetti."
      ) +
      "</p>"
  ),
];

export const createHavantInfoPointsEnIt = (createPositionPoint) => [
  createPositionPoint(
    [-202.17, -1482.63, -4762.3],
    "verso-genova",
    "Verso Cassonetto - Genova"
  ),
  createPositionPoint(
    [-4297.86, -2512.8, -403.92],
    "screeny-110-gpz-unica-am-screeny-85-gpz-s",
    "Screeny 110 GPZ Unica AM - Screeny 85 GPZS"
  ),
];

export const createScreeny110GpzUnicaAmScreeny85GpzSInfoPointsEnIt = (
  createPositionPoint,
  createInfoPoint
) => [
  createPositionPoint(
    [-4767.58, -1466.6, 187.67],
    "verso-genova",
    "Verso Cassonetto - Genova"
  ),
  createPositionPoint(
    [163.98, -1931.38, 4602.52],
    "screeny-110-gpz-unica-am-screeny-55-gp-xs",
    "Screeny 110 GPZ Unica AM - Screeny 55 GPXS"
  ),
  createInfoPoint(
    [4825.23, -1040.14, 732.63],
    {
      type: "info",
      title: "",
      noCloseIcon: true,
      popupClass: "popup-info_container",
      sectionTitle: "SCREENY 110 GPZ UNICA AM",
      description:
        "<p>" +
        getLanguageTranslation(
          "Tenda con cassonetto da 110mm disponibile nelle varianti Quadro e Tondo dotata di sistema di guida ZIP e cassonetto autoportante. Adatta per installazioni su infissi, sia a nicchia che a parete; veloce ed agevole da installare ed ispezionare. La guida è composta da due profili: supporto e copertina; è sviluppata per sistemi sia ad argano che motorizzati."
        ) +
        "</p>",
      images: [
        ImageScreeny110GpzUnicaAm1,
        ImageScreeny110GpzUnicaAm2,
        ImageScreeny110GpzUnicaAm3,
        ImageScreeny110GpzUnicaAm4,
        ImageScreeny110GpzUnicaAm5,
        ImageScreeny110GpzUnicaAm6,
        ImageScreeny110GpzUnicaAm7,
      ],
      buttons: [
        {
          text: getLanguageTranslation("Scheda Tecnica"),
          href: TechnicalSheetScreeny110GpzUnicaAm,
        },
      ],
    },
    undefined,
    "<p><strong>SCREENY 110 GPZ UNICA AM</strong><br />" +
      getLanguageTranslation(
        "Tenda con cassonetto da 110mm disponibile nelle varianti Quadro e Tondo dotata di sistema di guida ZIP e cassonetto autoportante. Adatta per installazioni su infissi, sia a nicchia che a parete; veloce ed agevole da installare ed ispezionare."
      ) +
      "</p>"
  ),
  createInfoPoint(
    [3956.49, -923.45, -2906.05],
    {
      type: "info",
      title: "",
      noCloseIcon: true,
      popupClass: "popup-info_container",
      sectionTitle: "SCREENY 85 GPZ S",
      description:
        "<p>" +
        getLanguageTranslation(
          "Tenda con cassonetto da 85mm disponibile nelle varianti Quadro e Tondo, dotata di sistema di guida ZIP e cassonetto autoportante. Realizzabile sia nella versione singola che doppia."
        ) +
        "</p>",
      images: [
        ImageScreeny85GpzS1,
        ImageScreeny85GpzS2,
        ImageScreeny85GpzS3,
        ImageScreeny85GpzS4,
        ImageScreeny85GpzS5,
        ImageScreeny85GpzS6,
      ],
      buttons: [
        {
          text: getLanguageTranslation("Scheda Tecnica"),
          href: TechnicalSheetScreeny85GpzS,
        },
      ],
    },
    undefined,
    "<p><strong>SCREENY 85 GPZ S</strong><br />" +
      getLanguageTranslation(
        "Tenda con cassonetto da 85mm disponibile nelle varianti Quadro e Tondo, dotata di sistema di guida ZIP e cassonetto autoportante. Realizzabile sia nella versione singola che doppia."
      ) +
      "</p>"
  ),
];

export const createScreeny150StormScreeny130GpzUnicaMEnIt = (
  createPositionPoint,
  createInfoPoint
) => [
  createPositionPoint(
    [-24.55, -1798.16, -4660.63],
    "screeny-110-gc-screeny-130-gpz-unica-m",
    "Screeny 110GC - Screeny 130 GPZ Unica M"
  ),
  createPositionPoint([-1340.91, -3125.18, 3655.15], "new-roma", "New Roma"),
  createInfoPoint(
    [4852.85, -714.51, -936.9],
    {
      type: "info",
      title: "",
      noCloseIcon: true,
      popupClass: "popup-info_container",
      sectionTitle: "SCREENY 130 GPZ UNICA M",
      description:
        "<p>" +
        getLanguageTranslation(
          "Tenda con cassonetto da 130mm disponibile nelle varianti Quadro e Tondo dotata di sistema di guida ZIP. Adatta per installazioni su infissi, sia a nicchia che a parete; veloce ed agevole da installare ed ispezionare. La guida è composta da due profili: supporto e copertina; è sviluppata per i soli sistemi motorizzati riducendo la larghezza a 36 mm."
        ) +
        "</p>",
      images: [
        ImageScreeny130GpzUnicaM1,
        ImageScreeny130GpzUnicaM2,
        ImageScreeny130GpzUnicaM3,
        ImageScreeny130GpzUnicaM4,
        ImageScreeny130GpzUnicaM5,
        ImageScreeny130GpzUnicaM6,
        ImageScreeny130GpzUnicaM7,
      ],
      buttons: [
        {
          text: getLanguageTranslation("Scheda Tecnica"),
          href: TechnicalSheetScreeny130GpzUnicaM,
        },
      ],
    },
    undefined,
    "<p><strong>SCREENY 130 GPZ UNICA M</strong><br />" +
      getLanguageTranslation(
        "Tenda con cassonetto da 130mm disponibile nelle varianti Quadro e Tondo dotata di sistema di guida ZIP."
      ) +
      "</p>"
  ),
  createInfoPoint(
    [4840.67, -746.97, 989.3],
    {
      type: "info",
      title: "",
      noCloseIcon: true,
      popupClass: "popup-info_container",
      sectionTitle: "SCREENY STORM",
      description:
        "<p>" +
        getLanguageTranslation(
          "Tenda con cassonetto da 110mm, 130mm disponibile nelle varianti Quadro e Tondo, con cassonetto da 150 mm disponibile nella sola variante Tondo, dotata di sistema di guida ZIP. Adatta per installazioni sia a nicchia che a parete; veloce ed agevole da installare ed ispezionare. La guida è composta da due profili: supporto e copertina ed è studiata per accogliere il profilo terminale che vi rimane connesso sia sotto l’azione di elevate pressioni del vento sia sotto l’azione di urti. È sviluppata per sistemi sia ad argano che motorizzati."
        ) +
        "</p>",
      images: [
        ImageScreeny150Storm1,
        ImageScreeny150Storm2,
        ImageScreeny150Storm3,
        ImageScreeny150Storm4,
        ImageScreeny150Storm5,
        ImageScreeny150Storm6,
      ],
      buttons: [
        {
          text: getLanguageTranslation("Scheda Tecnica"),
          href: TechnicalSheetScreeny150Storm,
        },
      ],
    },
    undefined,
    "<p><strong>SCREENY STORM</strong><br />" +
      getLanguageTranslation(
        "Tenda con cassonetto da 110mm, 130mm disponibile nelle varianti Quadro e Tondo, con cassonetto da 150 mm disponibile nella sola variante Tondo, dotata di sistema di guida ZIP. Adatta per installazioni sia a nicchia che a parete; veloce ed agevole da installare ed ispezionare."
      ) +
      "</p>"
  ),
];

export const createSirioSpaceInfoPointsEnIt = (
  createPositionPoint,
  createInfoPoint, 
  createVideoPoint
) => [
  createPositionPoint([1789.1, -472.17, -4635.01], "ombralsun", "Ombralsun"),
  createPositionPoint([540.49, -564.59, -4933.58], "percorso-7", ""),
  createPositionPoint([-198.99, -454.57, -4966.79], "percorso-8", ""),
  createInfoPoint(
    [4089.33, -305.71, -2848.77],
    {
      type: "info",
      title: "",
      noCloseIcon: true,
      popupClass: "popup-info_container",
      sectionTitle: "SIRIO",
      description:
        "<p>" +
        getLanguageTranslation(
          "SIRIO Struttura di copertura per giardini d’inverno, tetti in vetro e verande. Dotata di sistema di guida Zip e di sistema di tensionamento del telo con molla. Presenza di optional luci e terminale a cassonetto. Funzionamento esclusivamente motorizzato."
        ) +
        "</p>",
      images: [
        ImageSirio1,
        ImageSirio2,
        ImageSirio3,
        ImageSirio4,
        ImageSirio5,
        ImageSirio6,
      ],
      buttons: [
        {
          text: getLanguageTranslation("Scheda Tecnica"),
          href: TechnicalSheetSirio,
        },
      ],
    },
    undefined,
    "<p><strong>SIRIO</strong><br />" +
      getLanguageTranslation(
        "SIRIO Struttura di copertura per giardini d’inverno, tetti in vetro e verande. Dotata di sistema di guida Zip e di sistema di tensionamento del telo con molla."
      ) +
      "</p>"
  ),
  createVideoPoint(
    [3354.58, 167.25, -3697.52],
    "Video",
    "https://www.youtube.com/watch?v=C1RcttDd3D8"
  ),
  createInfoPoint(
    [4082.37, -328.41, 2857.86],
    {
      type: "info",
      title: "",
      noCloseIcon: true,
      popupClass: "popup-info_container",
      sectionTitle: "SPACE",
      description:
        "<p>" +
        getLanguageTranslation(
          "Struttura di copertura per verande e attici. Il tessuto scorre nella parte superiore su guide, regolabili in larghezza e contenenti il sistema di tensionamento con molle a gas. Sono disponibili degli optional come il sistema di blocco meccanico e il sistema flottante per il tubo avvolgitore. Dimensioni massime 500x700 cm e con guida centrale anche 700x700 cm."
        ) +
        "</p>",
      images: [
        ImageSpace1,
        ImageSpace2,
        ImageSpace3,
        ImageSpace4,
        ImageSpace5,
        ImageSpace6,
        ImageSpace7,
      ],
      buttons: [
        {
          text: getLanguageTranslation("Scheda Tecnica"),
          href: TechnicalSheetSpace,
        },
      ],
    },
    undefined,
    "<p><strong>SPACE</strong><br />" +
      getLanguageTranslation(
        "Struttura di copertura per verande e attici. Il tessuto scorre nella parte superiore su guide, regolabili in larghezza e contenenti il sistema di tensionamento con molle a gas."
      ) +
      "</p>"
  ),
];
