import { TechnicalSpecificsType } from "../../types";
import { getTechnicalSpecificsBooleanRoof } from "./get-technical-specifics-boolean-roof";
import { getLanguageCode } from "../get-language-code";

export const getScreeny130GpzUnicaMScreeny110GcImageName = (
  technicalSpecifics: TechnicalSpecificsType
): string =>
  `screeny-130-gpz-unica-m-screeny-110-gc${getTechnicalSpecificsBooleanRoof(
    technicalSpecifics
  )}${getLanguageCode()}`;
