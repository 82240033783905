import { TechnicalSpecificsType } from "../types";
import { getNewItaliaImageName } from "./image-names/get-new-italia-image-name";
import { get130GpzToImageName } from "./image-names/get130-gpz-to-image-name";
import { get20202010ImageName } from "./image-names/get20202010-image-name";
import { get20202040ImageName } from "./image-names/get20202040-image-name";
import { get2040GiantImageName } from "./image-names/get2040-giant-image-name";
import { getArmonyPlus2040ImageName } from "./image-names/get-armony-plus2040-image-name";
import { getArmonyPlusGiantImageName } from "./image-names/get-armony-plus-giant-image-name";
import { getBaseLiteArmonyPlusImageName } from "./image-names/get-base-lite-armony-plus-image-name";
import { getBasePlusEpicaImageName } from "./image-names/get-base-plus-epica-image-name";
import { getBravoVersoCassonettoImageName } from "./image-names/get-bravo-verso-cassonetto-image-name";
import { getDomeaBasePlusImageName } from "./image-names/get-domea-base-plus-image-name";
import { getElyImageName } from "./image-names/get-ely-image-name";
import { getEpicaBaseLiteImageName } from "./image-names/get-epica-base-lite-image-name";
import { getEpicaGiantImageName } from "./image-names/get-epica-giant-image-name";
import { getGiantZenithImageName } from "./image-names/get-giant-zenith-image-name";
import { getGiottoPlusGiottoImageName } from "./image-names/get-giotto-plus-giotto-image-name";
import { getHavantImageName } from "./image-names/get-havant-image-name";
import { getEpicaEpicaLiteImageName } from "./image-names/get-epica-epica-lite-image-name";
import { getEpicaLiteImageName } from "./image-names/get-epica-lite-image-name";
import { getHelixBqHelixPsImageName } from "./image-names/get-helix-bq-helix-ps-image-name";
import { getIntroImageName } from "./image-names/get-intro-image-name";
import { getLevaIncastroBqDomeaImageName } from "./image-names/get-leva-incastro-bq-domea-image-name";
import { getLineGlassImageName } from "./image-names/get-line-glass-image-name";
import { getMiniHelixBqMiniHelixPsImageName } from "./image-names/get-mini-helix-bq-mini-helix-ps-image-name";
import { getMiniHelixPsHelixPsImageName } from "./image-names/get-mini-helix-ps-helix-ps-image-name";
import { getOmbralsunImageName } from "./image-names/get-ombralsun-image-name";
import { getParigiPlusParigiPlusLiteImageName } from "./image-names/get-parigi-plus-parigi-plus-lite-image-name";
import { getPatioV4ArmonyPlusImageName } from "./image-names/get-patio-v4-armony-plus-image-name";
import { getPercorso1ImageName } from "./image-names/get-percorso1-image-name";
import { getPercorso2ImageName } from "./image-names/get-percorso2-image-name";
import { getPercorso3ImageName } from "./image-names/get-percorso3-image-name";
import { getPercorso4ImageName } from "./image-names/get-percorso4-image-name";
import { getPercorso5ImageName } from "./image-names/get-percorso5-image-name";
import { getPercorso6ImageName } from "./image-names/get-percorso6-image-name";
import { getPercorso7ImageName } from "./image-names/get-percorso7-image-name";
import { getPercorso8ImageName } from "./image-names/get-percorso8-image-name";
import { getPercorso9ImageName } from "./image-names/get-percorso9-image-name";
import { getRPitch130GpzToImageName } from "./image-names/get-r-pitch130-gpz-to-image-name";
import { getR90RPitchImageName } from "./image-names/get-r90-r-pitch-image-name";
import { getScreeny110GcScreeny130GpzIImageName } from "./image-names/get-screeny110-gc-screeny130-gpz-i-image-name";
import { getScreeny110GpzUnicaAmScreeny110GcCabrioImageName } from "./image-names/get-screeny110-gpz-unica-am-screeny110-gc-cabrio-image-name";
import { getScreeny110GpzUnicaAmScreeny130GpzUnicaMImageName } from "./image-names/get-screeny110-gpz-unica-am-screeny130-gpz-unica-m-image-name";
import { getScreeny130GaScreeny130GpzMbImageName } from "./image-names/get-screeny130-ga-screeny130-gpz-mb-image-name";
import { getScreeny130GpzToImageName } from "./image-names/get-screeny130-gpz-to-image-name";
import { getScreeny130GpzUnicaMScreeny110GcImageName } from "./image-names/get-screeny130-gpz-unica-m-screeny110-gc-image-name";
import { getScreeny150GpzUnicaAmScreeny110GcCabrioImageName } from "./image-names/get-screeny150-gpz-unica-am-screeny110-gc-cabrio-image-name";
import { getScreeny150StormScreeny130GpzUnicaMImageName } from "./image-names/get-screeny150-storm-screeny130-gpz-unica-m-image-name";
import { getSirioImageName } from "./image-names/get-sirio-image-name";
import { getSpaceImageName } from "./image-names/get-space-image-name";
import { getSpaceR90ImageName } from "./image-names/get-space-r90-image-name";
import { getSpaceSirioImageName } from "./image-names/get-space-sirio-image-name";
import { getVersoCassonettoImageName } from "./image-names/get-verso-cassonetto-image-name";
import { getVersoCassonettoMiniVersoImageName } from "./image-names/get-verso-cassonetto-mini-verso-image-name";
import { getZenith2040ImageName } from "./image-names/get-zenith2040-image-name";
import { getPatioV4RPitchImageName } from "./image-names/get-patio-v4-r-pitch-image-name";
import { getNewElyImageName } from "./image-names/get-new-ely-image-name";
import { getGlass1ImageName } from "./image-names/get-glass-1-image-name";
import { getSirioSpaceImageName } from "./image-names/get-sirio-space-image-name";
import { getBaseLiteEpicaImageName } from "./image-names/get-base-lite-epica-image-name";
import { get2010ImageName } from "./image-names/get-2010-image-name";
import { getScreeny150GpzUnicaScreeny110GcCabrioImageName } from "./image-names/get-screeny-150-gpz-unica-screeny-110-gc-cabrio-image-name";
import { getDomeaGrandangoloImageName } from "./image-names/get-domea-grandangolo-image-name";
import { getBasePlusDomeaImageName } from "./image-names/get-base-plus-domea-image-name";
import { getScreeny110GcScreeny130GpzUnicaMImageName } from "./image-names/get-screeny110-gc-screeny130-gpz-unica-m-image-name";
import { getScreeny110GpzUnicaAmScreeny55GpXsImageName } from "./image-names/get-screeny-110-gpz-unica-am-screeny-55-gp-xs-image-name";
import { getParigiPlusScreeny130GpzTensToImageName } from "./image-names/get-parigi-plus-screeny-130-gpz-tens-to-image-name";
import { getNewRomaImageName } from "./image-names/get-new-roma-image-name";
import { getVersoGenovaImageName } from "./image-names/get-verso-genova-image-name";
import { getScreeny110GpzUnicaAmScreeny85SpzSImageName } from "./image-names/get-screeny-110-gpz-unica-am-screeny-85-spz-s-image-name";

export const setPanoramaImageName = (
  technicalSpecifics: TechnicalSpecificsType,
  setImageName
) => {
  console.log(technicalSpecifics.position);
  let o = "";
  switch (technicalSpecifics.position) {
    case "intro":
      o = getIntroImageName(technicalSpecifics);
      break;
    case "new-italia":
      o = getNewItaliaImageName(technicalSpecifics);
      break;
    case "130-gpz-to":
      o = get130GpzToImageName(technicalSpecifics);
      break;
    case "2020-2010":
      o = get20202010ImageName(technicalSpecifics);
      break;
    case "2020-2040":
      o = get20202040ImageName(technicalSpecifics);
      break;
    case "2040-giant":
      o = get2040GiantImageName(technicalSpecifics);
      break;
    case "armony-plus-2040":
      o = getArmonyPlus2040ImageName(technicalSpecifics);
      break;
    case "armony-plus-giant":
      o = getArmonyPlusGiantImageName(technicalSpecifics);
      break;
    case "base-lite-armony-plus":
      o = getBaseLiteArmonyPlusImageName(technicalSpecifics);
      break;
    case "base-plus-epica":
      o = getBasePlusEpicaImageName(technicalSpecifics);
      break;
    case "bravo-verso-cassonetto":
      o = getBravoVersoCassonettoImageName(technicalSpecifics);
      break;
    case "domea-base-plus":
      o = getDomeaBasePlusImageName(technicalSpecifics);
      break;
    case "ely":
      o = getElyImageName(technicalSpecifics);
      break;
    case "epica-lite":
      o = getEpicaLiteImageName(technicalSpecifics);
      break;
    case "epica-epica-lite":
      o = getEpicaEpicaLiteImageName(technicalSpecifics);
      break;
    case "epica-base-lite":
      o = getEpicaBaseLiteImageName(technicalSpecifics);
      break;
    case "epica-giant":
      o = getEpicaGiantImageName(technicalSpecifics);
      break;
    case "giant-zenith":
      o = getGiantZenithImageName(technicalSpecifics);
      break;
    case "giotto-plus-giotto":
      o = getGiottoPlusGiottoImageName(technicalSpecifics);
      break;
    case "havant":
      o = getHavantImageName(technicalSpecifics);
      break;
    case "helix-bq-helix-ps":
      o = getHelixBqHelixPsImageName(technicalSpecifics);
      break;
    case "leva-incastro-bq-domea":
      o = getLevaIncastroBqDomeaImageName(technicalSpecifics);
      break;
    case "line-glass-1.0":
      o = getLineGlassImageName(technicalSpecifics);
      break;
    case "mini-helix-bq-mini-helix-ps":
      o = getMiniHelixBqMiniHelixPsImageName(technicalSpecifics);
      break;
    case "mini-helix-ps-helix-ps":
      o = getMiniHelixPsHelixPsImageName(technicalSpecifics);
      break;
    case "ombralsun":
      o = getOmbralsunImageName(technicalSpecifics);
      break;
    case "parigi-plus-parigi-plus-lite":
      o = getParigiPlusParigiPlusLiteImageName(technicalSpecifics);
      break;
    case "patio-v4-armony-plus":
      o = getPatioV4ArmonyPlusImageName(technicalSpecifics);
      break;
    case "r-pitch-130-gpz-to":
      o = getRPitch130GpzToImageName(technicalSpecifics);
      break;
    case "r90-r-pitch":
      o = getR90RPitchImageName(technicalSpecifics);
      break;
    case "screeny-110-gc-screeny-130-gpz-i":
      o = getScreeny110GcScreeny130GpzIImageName(technicalSpecifics);
      break;
    case "screeny-110-gpz-unica-am-screeny-110-gc-cabrio":
      o = getScreeny110GpzUnicaAmScreeny110GcCabrioImageName(
        technicalSpecifics
      );
      break;
    case "screeny-110-gpz-unica-am-screeny-130-gpz-unica-m":
      o = getScreeny110GpzUnicaAmScreeny130GpzUnicaMImageName(
        technicalSpecifics
      );
      break;
    case "screeny-130-ga-screeny-130-gpz-mb":
      o = getScreeny130GaScreeny130GpzMbImageName(technicalSpecifics);
      break;
    case "screeny-130-gpz-to":
      o = getScreeny130GpzToImageName(technicalSpecifics);
      break;
    case "screeny-130-gpz-unica-m-screeny-110-gc":
      o = getScreeny130GpzUnicaMScreeny110GcImageName(technicalSpecifics);
      break;
    case "screeny-150-gpz-unica-am-screeny-110-gc-cabrio":
      o = getScreeny150GpzUnicaAmScreeny110GcCabrioImageName(
        technicalSpecifics
      );
      break;
    case "screeny-150-storm-screeny-130-gpz-unica-m":
      o = getScreeny150StormScreeny130GpzUnicaMImageName(technicalSpecifics);
      break;
    case "sirio":
      o = getSirioImageName(technicalSpecifics);
      break;
    case "space":
      o = getSpaceImageName(technicalSpecifics);
      break;
    case "space-r90":
      o = getSpaceR90ImageName(technicalSpecifics);
      break;
    case "space-sirio":
      o = getSpaceSirioImageName(technicalSpecifics);
      break;
    case "verso-cassonetto":
      o = getVersoCassonettoImageName(technicalSpecifics);
      break;
    case "verso-cassonetto-mini-verso":
      o = getVersoCassonettoMiniVersoImageName(technicalSpecifics);
      break;
    case "zenith-2040":
      o = getZenith2040ImageName(technicalSpecifics);
      break;
    case "percorso-1":
      o = getPercorso1ImageName(technicalSpecifics);
      break;
    case "percorso-2":
      o = getPercorso2ImageName(technicalSpecifics);
      break;
    case "percorso-3":
      o = getPercorso3ImageName(technicalSpecifics);
      break;
    case "percorso-4":
      o = getPercorso4ImageName(technicalSpecifics);
      break;
    case "percorso-5":
      o = getPercorso5ImageName(technicalSpecifics);
      break;
    case "percorso-6":
      o = getPercorso6ImageName(technicalSpecifics);
      break;
    case "percorso-7":
      o = getPercorso7ImageName(technicalSpecifics);
      break;
    case "percorso-8":
      o = getPercorso8ImageName(technicalSpecifics);
      break;
    case "percorso-9":
      o = getPercorso9ImageName(technicalSpecifics);
      break;

    //  IT & EN
    case "patio-v4-r-pitch":
      o = getPatioV4RPitchImageName(technicalSpecifics);
      break;
    case "new-ely":
      o = getNewElyImageName(technicalSpecifics);
      break;
    case "glass-1":
      o = getGlass1ImageName(technicalSpecifics);
      break;
    case "sirio-space":
      o = getSirioSpaceImageName(technicalSpecifics);
      break;
    case "base-lite-epica":
      o = getBaseLiteEpicaImageName(technicalSpecifics);
      break;
    case "2010":
      o = get2010ImageName(technicalSpecifics);
      break;
    case "screeny-150-gpz-unica-screeny-110-gc-cabrio":
      o = getScreeny150GpzUnicaScreeny110GcCabrioImageName(technicalSpecifics);
      break;
    case "domea-grandangolo":
      o = getDomeaGrandangoloImageName(technicalSpecifics);
      break;
    case "base-plus-domea":
      o = getBasePlusDomeaImageName(technicalSpecifics);
      break;
    case "screeny-110-gc-screeny-130-gpz-unica-m":
      o = getScreeny110GcScreeny130GpzUnicaMImageName(technicalSpecifics);
      break;
    case "screeny-110-gpz-unica-am-screeny-55-gp-xs":
      o = getScreeny110GpzUnicaAmScreeny55GpXsImageName(technicalSpecifics);
      break;
    case "parigi-plus-screeny-130-gpz-tens-to":
      o = getParigiPlusScreeny130GpzTensToImageName(technicalSpecifics);
      break;
    case "new-roma":
      o = getNewRomaImageName(technicalSpecifics);
      break;
    case "verso-genova":
      o = getVersoGenovaImageName(technicalSpecifics);
      break;
    case "screeny-110-gpz-unica-am-screeny-85-gpz-s":
      o = getScreeny110GpzUnicaAmScreeny85SpzSImageName(technicalSpecifics);
      break;

    default:
      o = getIntroImageName(technicalSpecifics);
  }
  console.log("New image name: " + o);
  setImageName(o);
  return o;
};
