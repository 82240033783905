//  @ts-ignore
import React, { useEffect } from "react";

const Loading: React.FC<{ isLoading: boolean }> = ({ isLoading }) => (
  <div
    className={isLoading ? "loading-container-show" : "loading-container-hide"}
  >
    <div className="cube-loader">
      <div className="cube cube1" />
      <div className="cube cube2" />
      <div className="cube cube3" />
      <div className="cube cube4" />
      <div className="cube cube5" />
      <div className="cube cube6" />
      <div className="cube cube7" />
      <div className="cube cube8" />
      <div className="cube cube9" />
    </div>
    <div>
      <h4>LOADING</h4>
    </div>
  </div>
);

export default Loading;
