// @ts-ignore
import React, { useEffect, useRef, useState } from "react";
import Loading from "./loading";
import "reactjs-popup/dist/index.css";
import PanoramaImage from "./panorama-image";
import { setPanoramaImageName } from "./utils/set-panorama-image-name";
import { toggleMode } from "./utils/toggle-mode";
import { toggleFullscreen } from "./utils/toggle-fullscreen";
import { createInfoPoints } from "./utils/create-info-points";
import { changeImage } from "./utils/change-image";
import HamburgerMenu from "./hamburger-menu";
import CustomToaster from "./custom-toaster";
import { TechnicalSpecificsType } from "./types";
import LoadImagesByImage from "./load-images-by-image";

const PanoramaContainer: React.FC<{ panoramaImages: any }> = ({
  panoramaImages,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const [firstLoading, setFirstLoading] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [imageName, setImageName] = useState<string>("");
  const [viewer, setViewer] = useState<any | undefined>(undefined);
  const [imagePanorama, setImagePanorama] = useState<any | undefined>(
    undefined
  );
  const [isNormalMode, setIsNormalMode] = useState(true);
  const [popupInfos, setPopupInfos] = useState(undefined);
  const [isModelChanged, setIsModelChanged] = useState<boolean>(true);
  const [oldModel, setOldModel] = useState<string>("");
  const [
    technicalSpecifics,
    setTechnicalSpecifics,
  ] = useState<TechnicalSpecificsType>({
    model: "bat",
    color: "",
    time: "",
    position: "intro",
    led: false,
    roof: "open",
  });

  const infoSpots = createInfoPoints(
    setPopupInfos,
    setTechnicalSpecifics,
    technicalSpecifics
  );

  useEffect(() => {
    if (firstLoading === false) {
      //  Se si vuole far caricare tutte le immagini (una alla volta), scommentare il codice qui sotto
      // console.log('Downloading this array of images:', panoramaImages)
      // fetchOthersImages(panoramaImages, imageName).then(() => setIsImagesLoaded(true))
    }
  }, [firstLoading]);

  useEffect(() => {
    changeImage(
      ref,
      panoramaImages,
      imageName,
      viewer,
      setIsLoading,
      isModelChanged,
      technicalSpecifics,
      infoSpots,
      firstLoading,
      setViewer,
      setImagePanorama,
      setFirstLoading,
      setTechnicalSpecifics
    );
  }, [imageName]);

  useEffect(() => {
    if (oldModel === "") {
      setIsModelChanged(true);
      setOldModel(technicalSpecifics.position);
    } else {
      setIsModelChanged(!(technicalSpecifics.position === oldModel));
      setOldModel(technicalSpecifics.position);
    }
    setPanoramaImageName(technicalSpecifics, setImageName);
  }, [technicalSpecifics]);

  useEffect(() => {
    setTechnicalSpecifics({
      model: "bat",
      color: "",
      time: "",
      position: "intro",
      led: false,
      roof: "open",
    });
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setPopupInfos({
        title: "",
        type: "instructions",
        popupClass: "popup-instructions_container",
      });
    }, 3000);
  }, []);

  return (
    <>
      <CustomToaster />
      <HamburgerMenu
        setPopupInfos={setPopupInfos}
        setTechnicalSpecifics={setTechnicalSpecifics}
        technicalSpecifics={technicalSpecifics}
      />
      <LoadImagesByImage
        images={panoramaImages}
        technicalSpecifics={technicalSpecifics}
      />
      <Loading isLoading={isLoading} />
      {firstLoading &&
      viewer !== undefined &&
      imagePanorama !== undefined ? null : (
        <PanoramaImage
          reference={ref}
          toggleMode={() => toggleMode(viewer, isNormalMode, setIsNormalMode)}
          popupInfos={popupInfos}
          setPopupInfos={setPopupInfos}
          setTechnicalSpecifics={setTechnicalSpecifics}
          technicalSpecifics={technicalSpecifics}
          toggleFullscreen={() => toggleFullscreen()}
        />
      )}
    </>
  );
};

export default PanoramaContainer;
