import { LOCALSTORAGE_LANGUAGE } from "../consts";
import { getLanguage } from "./get-language-code";

export const initialPositions = {
  intro: [4988.92, 244.81, -27.96],
  "patio-v4-r-pitch": [4982.0, -239.01, -164.32],
  "giotto-plus-giotto": [4986.18, -45.09, -229.86],
  "mini-helix-bq-mini-helix-ps": [4985.1, -116.84, -196.66],
  "new-ely": [4721.19, -1550.21, -535.77],
  "glass-1": [4945.85, -711.97, -17.19],
  "sirio-space": [4988.31, -46.11, -188.5],
  "base-lite-epica": [4989.5, -44.81, -166.83],
  ombralsun: [4988.1, -301.67, 0.94],
  "giant-zenith": [4997.78, 55.06, -1.05],
  "screeny-110-gc-screeny-130-gpz-i": [4996.79, -77.62, 3.12],
  "2010": [4997.88, 34.35, -14.72],
  "armony-plus-2040": [4994.98, -69.04, -44.11],
  "base-plus-epica": [4995.3, -118.96, -0.56],
  "2020-2040": [4995.03, -102.38, -18.09],
  "screeny-150-gpz-unica-screeny-110-gc-cabrio": [4994.1, -128.66, 16.21],
  "screeny-130-ga-screeny-130-gpz-mb": [4987.31, 156.66, 124.75],
  "helix-bq-helix-ps": [4980.02, -308.61, 149.96],
  "domea-grandangolo": [4993.48, 133.81, -24.1],
  "base-lite-armony-plus": [4973.29, -272.94, -305.01],
  "base-plus-domea": [4980.72, 384.55, -79.61],
  "screeny-110-gc-screeny-130-gpz-unica-m": [4994.38, -71.07, 53.91],
  "screeny-110-gpz-unica-am-screeny-55-gp-xs": [4990.89, 14.48, -162.93],
  "parigi-plus-screeny-130-gpz-tens-to": [4991.6, -193.17, -15.44],
  "new-roma": [4981.16, 201.39, 208.46],
  "verso-genova": [4978.92, 45.86, -367.81],
  havant: [4996.2, -74.83, -16.43],
  "screeny-110-gpz-unica-am-screeny-85-gpz-s": [4990.53, -200.56, -30.4],
  "screeny-150-storm-screeny-130-gpz-unica-m": [4993.32, 49.94, -89.99],
  "percorso-1": [-4086.19, -190.74, 2861.89],
  "percorso-2": [3697.35, 41.26, -3361.74],
  // "percorso-3": [],
  "percorso-4": [-4595.0, -195.52, -1941.22],
  "percorso-5": [4587.5, -321.37, -1946.98],
  "percorso-6": [3132.27, -171.94, 3879.84],
  "percorso-7": [3268.31, -142.17, -3771.18],
  "percorso-8": [4416.22, -38.41, 2327.71],
};

export const initialPositionsGmq = {
  intro: [4988.92, 244.81, -27.96],
  "r-pitch-130-gpz-to": [4956.24, 617.08, 33.24],
  "space-r90": [4952.83, 507.69, -345.39],
  "percorso-1": [3646.51, 164.26, 3405.75],
  "percorso-2": [-129.26, 312.92, 4980.93],
  "epica-giant": [4954.82, 630.99, -28.92],
  "zenith-2040": [4953.09, 650.86, -16.98],
  "percorso-3": [-4549.78, 385.43, -2035.6],
  "percorso-4": [4421.7, -228.4, 2300.54],
  "percorso-5": [3545.07, 433.51, 3487.65],
  havant: [4976.0, 37.12, 430.19],
  ombralsun: [4990.69, 230.41, -4.99],
  "screeny-130-gpz-unica-m-screeny-110-gc": [4960.29, 577.88, 44.39],
  "screeny-110-gc-screeny-130-gpz-i": [4895.5, 994.35, 13.98],
  "screeny-130-ga-screeny-130-gpz-mb": [4905.47, 918.14, 113.16],
  "screeny-130-gpz-unica-am-screeny-110-gc-cabrio": [4903.8, 947.63, -33.07],
};

export const initialPositionsUsa = {
  intro: [4988.92, 244.81, -27.96],
  space: [3752.21, 84.36, 3295.34],
  "r90-r-pitch": [4949.11, 652.67, -88.89],
  "patio-v4-armony-plus": [4948.28, 620.36, 177.75],
  "new-italia": [4927.65, 572.29, 572.14],
  "2040-giant": [4932.44, 791.62, 78.58],
  "percorso-1": [4920.93, 496.17, -672.06],
  "percorso-2": [-275.86, 407.03, 4968.38],
  "epica-lite": [3906.73, 448.44, -3074.77],
};

export const initialPositionsEs = {
  intro: [4988.92, 244.81, -27.96],
  "mini-helix-ps-helix-ps": [4937.01, 698.37, -216.62],
  "130-gpz-to": [4658.56, 836.67, -1595.3],
  "2020-2010": [4932.45, 789.37, -87.07],
  "armony-plus-giant": [4949.09, 789.87, -87.02],
  "epica-base-lite": [4949.09, 789.87, -87.02],
  "domea-base-plus": [4949.09, 789.87, -87.02],
  "line-glass-1.0": [4951.98, 664.65, -7.18],
  ely: [4938.03, -625.71, -361.14],
  "space-sirio": [4972.25, 459.81, 82.96],
  "giotto-plus-giotto": [4831.84, 1247.0, -143.38],
  "bravo-verso-cassonetto": [4935.36, 796.38, -4.89],
  "verso-cassonetto-mini-verso": [4840.74, 1229.54, 67.45],
  "screeny-110-gpz-unica-am-screeny-130-gpz-unica-m": [4925.32, 824.64, -89.23],
  "screeny-110-gc-screeny-130-gpz-i": [4921.17, 868.72, 1.11],
  "screeny-130-ga-screeny-130-gpz-mb": [4930.17, 792.93, 116.92],
  "screeny-150-gpz-unica-am-screeny-110-gc-cabrio": [4952.66, 700.34, -35.46],
  "percorso-1": [4110.74, -68.85, 2832.87],
  "percorso-2": [-4811.11, 368.29, -1280.89],
  "percorso-3": [4995.78, 14.17, -69.82],
  "percorso-4": [2245.19, 234.75, 4449.62],
  "percorso-5": [-4888.92, 442.76, 914.54],
  "percorso-6": [4803.64, 425.09, -1290.18],
  "percorso-7": [3146.52, 111.67, 3871.34],
  "percorso-8": [3259.29, 347.33, -3768.13],
  "percorso-9": [3087.51, -1.57, 3924.69],
};

export const initialPositionsFr = {
  intro: [4988.92, 244.81, -27.96],
  "130-gpz-to": [3802.46, 243.78, 3223.34],
  "leva-incastro-bq-domea": [4949.02, 675.21, 39.65],
  "domea-base-plus": [4951.37, 575.83, -219.4],
  "base-plus-epica": [4952.42, 643.63, -46.18],
  "epica-base-lite": [4947.19, 620.99, -197.18],
  "base-lite-armony-plus": [4946.79, 640.99, -159.61],
  "armony-plus-2040": [4942.02, 737.25, -33.27],
  "2020-2040": [4933.09, 802.3, 25.81],
  "2020-2010": [4950.87, 640.67, -82.49],
  "giant-zenith": [4950.87, 640.67, -82.49],
  "percorso-1": [3675.61, -151.63, 3377.36],
  "percorso-2": [-4958.08, 147.33, 577.41],
  "percorso-3": [137.0, 61.96, 4990.39],
  "percorso-4": [3539.56, 674.23, -3452.92],
  "percorso-5": [4089.77, 644.71, 2787.08],
  "percorso-6": [3235.66, 507.67, 3767.44],
  "percorso-8": [3899.0, 569.29, -3061.62],
  ely: [4419.28, 101.65, 2316.24],
  sirio: [4324.08, 658.85, 2408.57],
  ombralsun: [4983.67, 394.17, -13.17],
  "verso-cassonetto": [4767.89, 566.91, 1358.81],
  "screeny-150-storm-screeny-130-gpz-unica-m": [4984.0, 316.66, -67.99],
  "screeny-110-gc-screeny-130-gpz-i": [4959.06, 600.55, 16.72],
  "screeny-130-ga-screeny-130-gpz-mb": [4942.53, 668.75, 178.15],
  "screeny-110-gpz-unica-am-screeny-110-gc-cabrio": [4948.7, 643.32, 117.85],
  "mini-helix-bq-mini-helix-ps": [4934.58, 727.93, 196.19],
  "helix-bq-helix-ps": [4950.55, 550.38, 292.39],
  "parigi-plus-parigi-plus-lite": [4962.48, 516.54, -141.17],
};

export const getInitialPosition: (position: string) => number[] = (
  position
) => {
  const lang = getLanguage();

  if (lang === "us") return initialPositionsUsa[position];
  if (lang === "sv") return initialPositionsGmq[position];
  if (lang === "es") return initialPositionsEs[position];
  if (lang === "fr") return initialPositionsFr[position];

  return initialPositions[position];
};
