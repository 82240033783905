import { TechnicalSpecificsType } from "../../types";
import { getTechnicalSpecificsBooleanRoof } from "./get-technical-specifics-boolean-roof";
import { getLanguageCode } from "../get-language-code";

export const getScreeny110GpzUnicaAmScreeny85SpzSImageName = (
  technicalSpecifics: TechnicalSpecificsType
): string =>
  `screeny-110-gpz-unica-am-screeny-85-gpz-s${getTechnicalSpecificsBooleanRoof(
    technicalSpecifics
  )}${getLanguageCode()}`;
