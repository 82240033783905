import { TechnicalSpecificsType } from "../types";
import { ChangePosition } from "../icons";
import { getLanguageTranslation } from "./translations/get-language-translation";

export const useCreatePositionPoint: (
  setTechnicalSpecifics: any,
  technicalSpecifics: TechnicalSpecificsType
) => (
  position: number[],
  destination: string,
  title?: string,
  customTechnicalSpecifics?: () => Record<string, any>
) => Record<string, any> = (setTechnicalSpecifics, technicalSpecifics) => (
  position,
  destination,
  title = getLanguageTranslation("Cambia Posizione"),
  customTechnicalSpecifics
) => ({
  position,
  text: title,
  // image: Circle,
  image: ChangePosition,
  type: "position",
  description: destination,
  clickListener: () => {
    if (!!customTechnicalSpecifics) {
      setTechnicalSpecifics({
        ...technicalSpecifics,
        ...customTechnicalSpecifics(),
        position: destination,
      });
    } else {
      setTechnicalSpecifics({
        ...technicalSpecifics,
        position: destination,
      });
    }
  },
});
