import { TechnicalSpecificsType } from "../types";
import { getLanguageCode } from "../utils/get-language-code";

export const getImagesByTechnicalSpecifics = (
  technicalSpecifics: TechnicalSpecificsType,
  images: Record<string, string>
): string[] => {
  const allImages = Object.entries(images).reduce((o, [key, value]) => {
    o.push({
      text: key,
      value,
    });
    return o;
  }, [] as Array<{ text: string; value: string }>);

  const lang = getLanguageCode();

  const languagesImage = allImages
    .filter((i) => i.text.includes(lang))
    .map((i) => i.value);

  switch (technicalSpecifics.position) {
    case "intro": {
      const allowed = [
        "intro",
        "percorso-1",
        "percorso-2",
        "percorso-3",
        "percorso-4",
        "percorso-5",
        "r-pitch-130-gpz-to",
        "mini-helix-bq-mini-helix-ps",
        "helix-bq-helix-ps",
        "screeny-130-ga-screeny-130-gpz-mb",
        "base-lite-epica",
        "domea-grandangolo",
        "screeny-110-gpz-unica-am-screeny-55-gp-xs",
        "parigi-plus-screeny-130-gpz-tens-to",
        "base-plus-domea",
        "space",
        "mini-helix-ps-helix-ps",
        "130-gpz-to",
        "domea-base-plus",
      ];
      return languagesImage.filter((i) => allowed.some((a) => a.includes(i)));
    }
    case "new-italia": {
      const allowed = ["intro", "space"];
      return languagesImage.filter((i) => allowed.some((a) => a.includes(i)));
    }
    case "130-gpz-to": {
      const allowed = [
        "intro",
        "percorso-1",
        "percorso-2",
        "percorso-3",
        "percorso-5",
        "base-plus-epica",
        "leva-incastro-bq-domea",
        "domea-base-plus",
        "mini-helix-ps-helix-ps",
      ];
      return languagesImage.filter((i) => allowed.some((a) => a.includes(i)));
    }
    case "2020-2010": {
      const allowed = [
        "armony-plus-giant",
        "intro",
        "epica-base-lite",
        "percorso-2",
        "2020-2040",
        "armony-plus-2040",
        "percorso-2",
        "giant-zenith",
      ];
      return languagesImage.filter((i) => allowed.some((a) => a.includes(i)));
    }
    case "2020-2040": {
      const allowed = [
        "percorso-1",
        "percorso-2",
        "armony-plus-2040",
        "2010",
        "giant-zenith",
        "2020-2010",
        "base-lite-armony-plus",
      ];
      return languagesImage.filter((i) => allowed.some((a) => a.includes(i)));
    }
    case "2040-giant": {
      const allowed = ["patio-v4-armony-plus", "percorso-2", "r90-r-pitch"];
      return languagesImage.filter((i) => allowed.some((a) => a.includes(i)));
    }
    case "armony-plus-2040": {
      const allowed = [
        "base-lite-armony-plus",
        "percorso-1",
        "percorso-2",
        "2020-2040",
        "2020-2010",
        "epica-base-lite",
      ];
      return languagesImage.filter((i) => allowed.some((a) => a.includes(i)));
    }
    case "armony-plus-giant": {
      const allowed = [
        "intro",
        "2020-2010",
        "epica-base-lite",
        "domea-base-plus",
        "percorso-2",
      ];
      return languagesImage.filter((i) => allowed.some((a) => a.includes(i)));
    }
    case "base-lite-armony-plus": {
      const allowed = [
        "intro",
        "percorso-1",
        "percorso-2",
        "base-lite-epica",
        "epica-base-lite",
        "base-plus-epica",
        "armony-plus-2040",
        "2020-2040",
      ];
      return languagesImage.filter((i) => allowed.some((a) => a.includes(i)));
    }
    case "base-plus-epica": {
      const allowed = [
        "intro",
        "percorso-1",
        "base-lite-armony-plus",
        "domea-base-plus",
        "leva-incastro-bq-domea",
        "130-gpz-to",
        "epica-base-lite",
        "base-lite-epica",
        "base-plus-domea",
        "parigi-plus-screeny-130-gpz-tens-to",
      ];
      return languagesImage.filter((i) => allowed.some((a) => a.includes(i)));
    }
    case "bravo-verso-cassonetto": {
      const allowed = [
        "screeny-110-gpz-unica-am-screeny-130-gpz-unica-m",
        "screeny-110-gc-screeny-130-gpz-i",
        "percorso-3",
      ];
      return languagesImage.filter((i) => allowed.some((a) => a.includes(i)));
    }
    case "domea-base-plus": {
      const allowed = [
        "intro",
        "epica-base-lite",
        "armony-plus-giant",
        "percorso-1",
        "percorso-5",
        "leva-incastro-bq-domea",
        "130-gpz-to",
        "base-plus-epica",
      ];
      return languagesImage.filter((i) => allowed.some((a) => a.includes(i)));
    }
    case "ely": {
      const allowed = [
        "sirio",
        "percorso-6",
        "percorso-7",
        "percorso-8",
        "percorso-9",
        "line-glass-1.0",
      ];
      return languagesImage.filter((i) => allowed.some((a) => a.includes(i)));
    }
    case "epica-base-lite": {
      const allowed = [
        "percorso-1",
        "percorso-2",
        "percorso-5",
        "armony-plus-2040",
        "base-lite-armony-plus",
        "base-plus-epica",
        "domea-base-plus",
        "armony-plus-giant",
        "2020-2010",
      ];
      return languagesImage.filter((i) => allowed.some((a) => a.includes(i)));
    }
    case "epica-giant": {
      const allowed = ["percorso-1", "percorso-2", "zenith-2040"];
      return languagesImage.filter((i) => allowed.some((a) => a.includes(i)));
    }
    case "giant-zenith": {
      const allowed = [
        "percorso-1",
        "percorso-2",
        "2010",
        "2020-2040",
        "2020-2010",
      ];
      return languagesImage.filter((i) => allowed.some((a) => a.includes(i)));
    }
    case "giotto-plus-giotto": {
      const allowed = [
        "line-glass-1.0",
        "percorso-6",
        "percorso-9",
        "ombralsun",
      ];
      return languagesImage.filter((i) => allowed.some((a) => a.includes(i)));
    }
    case "havant": {
      const allowed = [
        "percorso-4",
        "ombralsun",
        "verso-genova",
        "screeny-110-gpz-unica-am-screeny-85-gpz-s",
      ];
      return languagesImage.filter((i) => allowed.some((a) => a.includes(i)));
    }
    case "helix-bq-helix-ps": {
      const allowed = [
        "intro",
        "percorso-2",
        "percorso-4",
        "mini-helix-bq-mini-helix-ps",
        "parigi-plus-screeny-130-gpz-tens-to",
        "screeny-110-gpz-unica-am-screeny-55-gp-xs",
      ];
      return languagesImage.filter((i) => allowed.some((a) => a.includes(i)));
    }
    case "leva-incastro-bq-domea": {
      const allowed = [
        "domea-base-plus",
        "base-plus-epica",
        "percorso-1",
        "percorso-5",
      ];
      return languagesImage.filter((i) => allowed.some((a) => a.includes(i)));
    }
    case "line-glass-1.0": {
      const allowed = [
        "percorso-6",
        "percorso-7",
        "percorso-8",
        "percorso-9",
        "ely",
      ];
      return languagesImage.filter((i) => allowed.some((a) => a.includes(i)));
    }
    case "mini-helix-bq-mini-helix-ps": {
      const allowed = ["intro", "percorso-4", "helix-bq-helix-ps"];
      return languagesImage.filter((i) => allowed.some((a) => a.includes(i)));
    }
    case "mini-helix-ps-helix-ps": {
      const allowed = ["intro", "130-gpz-to", "percorso-1", "percorso-4"];
      return languagesImage.filter((i) => allowed.some((a) => a.includes(i)));
    }
    case "ombralsun": {
      const allowed = [
        "screeny-130-gpz-unica-m-screeny-110-gc",
        "sirio",
        "percorso-4",
        "percorso-7",
        "percorso-8",
        "giotto-plus-giotto",
        "glass-1",
        "new-ely",
        "sirio-space",
      ];
      return languagesImage.filter((i) => allowed.some((a) => a.includes(i)));
    }
    case "parigi-plus-parigi-plus-lite": {
      const allowed = ["intro", "130-gpz-to", "helix-bq-heliz-ps"];
      return languagesImage.filter((i) => allowed.some((a) => a.includes(i)));
    }
    case "patio-v4-armony-plus": {
      const allowed = ["r90-r-pitch", "percorso-1", "percorso-2", "2040-giant"];
      return languagesImage.filter((i) => allowed.some((a) => a.includes(i)));
    }
    case "r-pitch-130-gpz-to": {
      const allowed = ["intro", "percorso-1"];
      return languagesImage.filter((i) => allowed.some((a) => a.includes(i)));
    }
    case "r90-r-pitch": {
      const allowed = [
        "space",
        "percorso-1",
        "2040-giant",
        "patio-v4-armony-plus",
      ];
      return languagesImage.filter((i) => allowed.some((a) => a.includes(i)));
    }
    case "screeny-110-gc-screeny-130-gpz-i": {
      const allowed = [
        "screeny-110-gpz-unica-am-screeny-130-gpz-unica-m",
        "screeny-110-gpz-unica-am-screeny-110-gc-cabrio",
        "screeny-110-gc-screeny-130-gpz-unica-m",
        "screeny-130-gpz-unica-m-screeny-110-gc",
        "screeny-130-ga-screeny-130-gpz-mb",
        "screeny-150-gpz-unica-am-screeny-110-gc-cabrio",
        "screeny-150-storm-screeny-130-gpz-unica-m",
        "verso-cassonetto",
      ];
      return languagesImage.filter((i) => allowed.some((a) => a.includes(i)));
    }
    case "screeny-110-gpz-unica-am-screeny-110-gc-cabrio": {
      const allowed = [
        "percorso-3",
        "percorso-4",
        "percorso-5",
        "screeny-130-ga-screeny-130-gpz-mb",
      ];
      return languagesImage.filter((i) => allowed.some((a) => a.includes(i)));
    }
    case "screeny-110-gpz-unica-am-screeny-130-gpz-unica-m": {
      const allowed = [
        "bravo-verso-cassonetto",
        "screeny-110-gc-screeny-130-gpz-i",
        "screeny-130-ga-screeny-130-gpz-mb",
        "percorso-3",
      ];
      return languagesImage.filter((i) => allowed.some((a) => a.includes(i)));
    }
    case "screeny-130-ga-screeny-130-gpz-mb": {
      const allowed = [
        "percorso-3",
        "screeny-110-gc-screeny-130-gpz-i",
        "screeny-110-gpz-unica-am-screeny-110-gc-cabrio",
        "screeny-150-gpz-unica-am-screeny-110-gc-cabrio",
        "screeny-150-gpz-unica-screeny-110-gc-cabrio",
      ];
      return languagesImage.filter((i) => allowed.some((a) => a.includes(i)));
    }
    case "screeny-130-gpz-to": {
      const allowed = [];
      return languagesImage.filter((i) => allowed.some((a) => a.includes(i)));
    }
    case "screeny-130-gpz-unica-m-screeny-110-gc": {
      const allowed = ["ombralsun", "screeny-110-gc-screeny-130-gpz-i"];
      return languagesImage.filter((i) => allowed.some((a) => a.includes(i)));
    }
    case "screeny-150-gpz-unica-am-screeny-110-gc-cabrio": {
      const allowed = [
        "screeny-110-gc-screeny-130-gpz-i",
        "screeny-130-ga-screeny-130-gpz-mb",
        "percorso-3",
        "percorso-4",
      ];
      return languagesImage.filter((i) => allowed.some((a) => a.includes(i)));
    }
    case "screeny-150-storm-screeny-130-gpz-unica-m": {
      const allowed = [
        "screeny-110-gc-screeny-130-gpz-unica-m",
        "new-roma",
        "verso-cassonetto",
        "percorso-3",
        "screeny-110-gc-screeny-110-gpz-i",
      ];
      return languagesImage.filter((i) => allowed.some((a) => a.includes(i)));
    }
    case "sirio": {
      const allowed = ["ely", "percorso-8", "ombralsun"];
      return languagesImage.filter((i) => allowed.some((a) => a.includes(i)));
    }
    case "space": {
      const allowed = ["intro", "r90-r-pitch", "percorso-1"];
      return languagesImage.filter((i) => allowed.some((a) => a.includes(i)));
    }
    case "space-r90": {
      const allowed = ["intro", "percorso-5", "r-pitch-130-gpz-to"];
      return languagesImage.filter((i) => allowed.some((a) => a.includes(i)));
    }
    case "space-sirio": {
      const allowed = ["percorso-8"];
      return languagesImage.filter((i) => allowed.some((a) => a.includes(i)));
    }
    case "verso-cassonetto": {
      const allowed = [
        "screeny-150-storm-screeny-130-gpz-unica-m",
        "screeny-110-gc-screeny-130-gpz-i",
        "percorso-3",
      ];
      return languagesImage.filter((i) => allowed.some((a) => a.includes(i)));
    }
    case "verso-cassonetto-mini-verso": {
      const allowed = [
        "bravo-verso-cassonetto",
        "screeny-110-gpz-unica-am-screeny-130-gpz-unica-m",
        "screeny-110-gc-screeny-130-gpz-i",
      ];
      return languagesImage.filter((i) => allowed.some((a) => a.includes(i)));
    }
    case "zenith-2040": {
      const allowed = ["percorso-3", "percorso-2", "epica-giant"];
      return languagesImage.filter((i) => allowed.some((a) => a.includes(i)));
    }
    case "percorso-1": {
      const allowed = [
        "intro",
        "percorso-2",
        "epica-giant",
        "r-pitch-130-gpz-to",
        "130-gpz-to",
        "percorso-5",
        "epica-base-lite",
        "base-lite-armony-plus",
        "base-plus-epica",
        "domea-base-plus",
        "leva-incastro-bq-domea",
        "2010",
        "giant-zenith",
        "2020-2040",
        "armony-plus-2040",
        "base-lite-epica",
        "domea-grandangolo",
        "r90-r-pitch",
        "space",
        "patio-v4-armony-plus",
        "armony-plus-giant",
      ];
      return languagesImage.filter((i) => allowed.some((a) => a.includes(i)));
    }
    case "percorso-2": {
      const allowed = [
        "intro",
        "percorso-1",
        "percorso-3",
        "epica-giant",
        "zenith-2040",
        "2020-2010",
        "armony-plus-2040",
        "2020-2040",
        "helix-bq-helix-ps",
        "patio-v4-armony-plus",
        "2040-giant",
        "armony-plus-giant",
        "epica-base-lite",
      ];
      return languagesImage.filter((i) => allowed.some((a) => a.includes(i)));
    }
    case "percorso-3": {
      const allowed = [
        "intro",
        "percorso-2",
        "percorso-4",
        "zenith-2040",
        "verso-cassonetto",
        "screeny-110-gpz-unica-am-screeny-110-gc-cabrio",
        "bravo-verso-cassonetto",
        "screeny-110-gpz-unica-am-screeny-130-gpz-unica-m",
        "screeny-150-gpz-unica-am-screeny-110-gc-cabrio",
        "screeny-130-ga-screeny-130-gpz-mb",
      ];
      return languagesImage.filter((i) => allowed.some((a) => a.includes(i)));
    }
    case "percorso-4": {
      const allowed = [
        "intro",
        "percorso-1",
        "percorso-3",
        "percorso-5",
        "ombralsun",
        "screeny-110-gpz-unica-am-screeny-110-gc-cabrio",
        "helix-bq-helix-ps",
        "mini-helix-bq-mini-helix-ps",
        "domea-grandangolo",
        "mini-helix-ps-helix-ps",
        "bravo-verso-cassonetto",
      ];
      return languagesImage.filter((i) => allowed.some((a) => a.includes(i)));
    }
    case "percorso-5": {
      const allowed = [
        "intro",
        "percorso-1",
        "percorso-4",
        "percorso-6",
        "leva-incastro-bq-domea",
        "new-ely",
        "ombralsun",
        "glass-1",
        "patio-v4-r-pitch",
        "domea-base-plus",
      ];
      return languagesImage.filter((i) => allowed.some((a) => a.includes(i)));
    }
    case "percorso-6": {
      const allowed = [
        "percorso-5",
        "giotto-plus-giotto",
        "patio-v4-r-pitch",
        "line-glass-1.0",
        "ely",
      ];
      return languagesImage.filter((i) => allowed.some((a) => a.includes(i)));
    }
    case "percorso-7": {
      const allowed = [
        "percorso-8",
        "percorso-9",
        "new-ely",
        "ombralsun",
        "sirio-space",
        "line-glass-1.0",
        "ely",
      ];
      return languagesImage.filter((i) => allowed.some((a) => a.includes(i)));
    }
    case "percorso-8": {
      const allowed = [
        "percorso-5",
        "percorso-7",
        "percorso-9",
        "new-ely",
        "ombralsun",
        "glass-1",
        "sirio-space",
        "ely",
        "space-sirio",
        "line-glass-1.0",
      ];
      return languagesImage.filter((i) => allowed.some((a) => a.includes(i)));
    }
    case "percorso-9": {
      const allowed = [
        "percorso-7",
        "percorso-8",
        "line-glass-1.0",
        "giotto-plus-giotto",
        "ely",
      ];
      return languagesImage.filter((i) => allowed.some((a) => a.includes(i)));
    }
    case "patio-v4-r-pitch": {
      const allowed = ["percorso-6", "percorso-5"];
      return languagesImage.filter((i) => allowed.some((a) => a.includes(i)));
    }
    case "new-ely": {
      const allowed = [
        "ombralsun",
        "percorso-5",
        "percorso-7",
        "percorso-8",
        "giotto-plus-giotto",
        "Glass 1.0",
      ];
      return languagesImage.filter((i) => allowed.some((a) => a.includes(i)));
    }
    case "glass-1": {
      const allowed = [
        "patio-v4-r-pitch",
        "new-ely",
        "ombralsun",
        "percorso-5",
        "percorso-8",
      ];
      return languagesImage.filter((i) => allowed.some((a) => a.includes(i)));
    }
    case "sirio-space": {
      const allowed = ["ombralsun", "percorso-7", "percorso-8"];
      return languagesImage.filter((i) => allowed.some((a) => a.includes(i)));
    }
    case "base-lite-epica": {
      const allowed = [
        "intro",
        "base-plus-epica",
        "base-lite-armony-plus",
        "percorso-1",
        "parigi-plus-screeny-130-gpz-tens-to",
      ];
      return languagesImage.filter((i) => allowed.some((a) => a.includes(i)));
    }
    case "2010": {
      const allowed = ["percorso-1", "giant-zenith", "2020-2040"];
      return languagesImage.filter((i) => allowed.some((a) => a.includes(i)));
    }
    case "screeny-150-gpz-unica-screeny-110-gc-cabrio": {
      const allowed = [
        "intro",
        "new-roma",
        "verso-genova",
        "screeny-130-ga-screeny-130-gpz-mb",
        "screeny-110-gpz-unica-am-screeny-55-gp-xs",
      ];
      return languagesImage.filter((i) => allowed.some((a) => a.includes(i)));
    }
    case "domea-grandangolo": {
      const allowed = ["percorso-5", "base-plus-domea", "percorso-4"];
      return languagesImage.filter((i) => allowed.some((a) => a.includes(i)));
    }
    case "base-plus-domea": {
      const allowed = [
        "intro",
        "base-plus-epica",
        "domea-grandangolo",
        "parigi-plus-screeny-130-gpz-tens-to",
      ];
      return languagesImage.filter((i) => allowed.some((a) => a.includes(i)));
    }
    case "screeny-110-gc-screeny-130-gpz-unica-m": {
      const allowed = [
        "screeny-150-storm-screeny-130-gpz-unica-m",
        "screeny-110-gc-screeny-130-gpz-i",
      ];
      return languagesImage.filter((i) => allowed.some((a) => a.includes(i)));
    }
    case "screeny-110-gpz-unica-am-screeny-55-gp-xs": {
      const allowed = [
        "screeny-110-gpz-unica-am-screeny-85-gpz-s",
        "screeny-150-gpz-unica-screeny-110-gc-cabrio",
      ];
      return languagesImage.filter((i) => allowed.some((a) => a.includes(i)));
    }
    case "parigi-plus-screeny-130-gpz-tens-to": {
      const allowed = [
        "intro",
        "helix-bq-helix-ps",
        "percorso-1",
        "percorso-2",
        "base-lite-epica",
        "base-plus-domea",
      ];
      return languagesImage.filter((i) => allowed.some((a) => a.includes(i)));
    }
    case "new-roma": {
      const allowed = [
        "verso-genova",
        "screeny-150-storm-screeny-130-gpz-unica-m",
      ];
      return languagesImage.filter((i) => allowed.some((a) => a.includes(i)));
    }
    case "verso-genova": {
      const allowed = ["new-roma"];
      return languagesImage.filter((i) => allowed.some((a) => a.includes(i)));
    }
    case "screeny-110-gpz-unica-am-screeny-85-gpz-s": {
      const allowed = [
        "verso-genova",
        "screeny-110-gpz-unica-am-screeny-55-gp-xs",
      ];
      return languagesImage.filter((i) => allowed.some((a) => a.includes(i)));
    }
    default:
      return [];
  }
};
