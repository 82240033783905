import { getLanguage } from "../get-language-code";

export const getLanguageTranslation = (entry: string) => {
  let language = "it";
  if (typeof window !== `undefined`) language = getLanguage();
  if (language === "it") return entry;
  if (language === "en" || language === "us") {
    switch (entry) {
      case "Dopo aver cambiato lingua, lo showroom verrà ricaricato.":
        return "After changing the language, the showroom will be reloaded.";
      //  MENU
      case "APERTO":
        return "OPEN";
      case "CHIUSO":
        return "CLOSE";
      case "MAPPA":
        return "MAP";
      case "POSIZIONE":
        return "POSITION";
      case "LINGUA":
        return "LANGUAGE";
      case "PRODOTTI":
        return "PRODUCTS";
      case "PERCORSO":
        return "PATH";
      case "COLORI":
        return "COLORS";

      //  LANGUAGES
      case "Italiano":
        return "Italian";
      case "Inglese":
        return "English";
      case "Spagnolo":
        return "Spanish";
      case "Francese":
        return "French";
      case "Svedese":
        return "Swedish";

      //  INFO
      case "Scheda Tecnica":
        return "Technical Sheet";

      //  FORM
      case "Vuoi più informazioni sui prodotti BAT?":
        return "Do you want more information on BAT products?";
      case "Scrivici: ti offriamo un preventivo gratuito e senza impegno.":
        return "Write to us: we offer you a free, no-obligation quote.";
      case "Ti rispondiamo entro 24/48H":
        return "We will reply to you within 24 / 48H";
      case "Qualcosa è andato storto.":
        return "Something went wrong";
      case "Grazie per averci contattato!":
        return "Thanks for contacting us!";
      case "Il tuo nome":
        return "Your name";
      case "Il tuo indirizzo e-mail *":
        return "Your e-mail address *";
      case "Telefono *":
        return "Your phone *";
      case "La tua città *":
        return "Your city *";
      case "Vuoi raccontarci la tua esigenza?":
        return "Do you want to tell us your need?";
      case "Acconsento al trattamento dei miei dati personali":
        return "I agree to the treatment of my personal data";
      case "Regolamento UE 2016/679":
        return "EU Regulation 2016/679";
      case "Desidero un preventivo gratuito":
        return "I would like a free quote";
      case "Riceverai una risposta entro 24-48H":
        return "You will receive a reply within 24-48H";

      //  HELP
      case "Ambiente a 360°":
        return "360° environment";
      case "È possibile esplorare liberamente l'ambiente cliccando e trascinando il cursore, spostandosi attraverso i punti di movimento.":
        return "You can freely explore the environment by clicking and dragging the cursor, moving through the movement points.";
      case "Interattivo":
        return "Interactive";
      case "In ogni prodotto sono disponibili vari punti di informazione, con tutti i materiali relativi al prodotto che si sta visualizzando.":
        return "In each product there are various information points, with all the materials related to the product you are viewing.";
      case "Immersivo":
        return "Immersive";
      case "È possibile ingrandire o rimpicciolire a piacimento le immagini, per una visione più specifica del prodotto.":
        return "You can zoom in or out as you like, for a more specific view of the product.";
      case "Immagine non trovata. Verrai riportato all'introduzione.":
        return "Image not found. You will be taken back to the introduction.";
      case "Cambia Posizione":
        return "Change Position";

      //  INTRO
      case "Introduzione":
        return "Introduction";

      //  DOMEA
      case "DOMEA, disegnata da Robby Cantarutti, designer di fama internazionale, è il primo prodotto della nuova “feel-experience” di BAT. Le sue forme accattivanti, che richiamano la tradizione e simboleggiano un connubio perfetto tra innovazione e classicità, forte di nuovi contenuti tecnologici, fanno di DOMEA uno dei prodotti di punta della gamma delle tende a cassonetto. Caratterizzata dall’estrema facilità di montaggio, regolazione e installazione, garantisce standard di sicurezza e qualità elevati per un modello di questa categoria.":
        return "DOMEA, designed by Robby Cantarutti, internationally renowned designer, is the first Bat’s “feel experience” product. Its appealing shapes recall the tradition, symbolizing a perfect combination between innovation and classical, with many new technological features, which lead DOMEA to be one of the top products of the cassette model range. DOMEA is extremely easy to be assembled, adjusted and installed ensuring however the highest safety and quality standards.";
      case "DOMEA, disegnata da Robby Cantarutti, designer di fama internazionale, è il primo prodotto della nuova “feel-experience” di BAT. Le sue forme accattivanti, che richiamano la tradizione e simboleggiano un connubio perfetto tra innovazione e classicità, forte di nuovi contenuti tecnologici, fanno di DOMEA uno dei prodotti di punta della gamma delle tende a cassonetto.":
        return "DOMEA, designed by Robby Cantarutti, internationally renowned designer, is the first Bat’s “feel experience” product. Its appealing shapes recall the tradition, symbolizing a perfect combination between innovation and classical, with many new technological features, which lead DOMEA to be one of the top products of the cassette model range.";

      // BASE PLUS
      case "BASE PLUS è una tenda a bracci estensibili con cassonetto su supporti laterali fissi con contropiastra di ancoraggio, bracci Balteus o spin. Il cassonetto, ispezionabile, viene fornito in tre profili separati. I supporti consentono di installare la tenda sia a parete che a soffitto e la regolazione può essere eseguita in un unica soluzione grazie al sistema con vite passante. Tutti i componenti sono realizzati in alluminio verniciato che garantiscono resa estetica e durabilità.":
        return "BASE PLUS is a full cassette folding arm awning on two side brackets and wall accroaching brackets, Balteus or spin arms. The inspectable cassette is supplied in three different profiles. The brackets allow for either a wall or a ceiling installation and,and the adjustment can be carried out in a single solution thanks to the screwin system. All components are made of coated aluminum that guarantee aesthetic and durability.";
      case "BASE PLUS è una tenda a bracci estensibili con cassonetto su supporti laterali fissi con contropiastra di ancoraggio, bracci Balteus o spin. Il cassonetto, ispezionabile, viene fornito in tre profili separati.":
        return "BASE PLUS is a full cassette folding arm awning on two side brackets and wall accroaching brackets, Balteus or spin arms. The inspectable cassette is supplied in three different profiles.";

      //  ARMONY PLUS
      case "ARMONY PLUS é una tenda a bracci estensibili con cassonetto a scomparsa totale. Design e stile uniti alla funzionalità e alla tecnologia di un sistema ribaltabile BAT che permette la chiusura ermetica del cassonetto anche nella sua massima inclinazione inoltre, grazie ad un profilo di guarnizione il cassonetto risulta essere perfettamente aderente al muro impedendo l’infiltrazione di acque ed impurità.":
        return "ARMONY PLUS is a full cassette folding arm awning. Design and style added to the function and technology of a BAT tipping system that allows the complete and hermethic closure of the box even at the maximum inclination. More of that, thanks to a gasket profile, the box will be closed to the wall and will avoid water and impurities penetration.";
      case "ARMONY PLUS é una tenda a bracci estensibili con cassonetto a scomparsa totale.":
        return "ARMONY PLUS is a full cassette folding arm awning.";

      //  BASE LITE
      case "BASE LITE è una tenda a bracci estensibili con cassonetto su supporti laterali fissi con contropiastra di ancoraggio, bracci balteus lite, doppio cavetto inguainato o a cinghia. I supporti consentono di installare la tenda sia a parete che a soffitto e la regolazione può essere eseguita in un unica soluzione grazie al sistema con vite passante. Tutti i componenti sono realizzati in alluminio verniciato che garantiscono resa estetica e durabilità.":
        return "BASE LITE is a full cassette folding arm awning on fixed independent side brackets and wall accroaching plate with balteus, double covered cable or belt arms. The brackets let this model to be installed either on the wall or on the ceiling and the adjustment can be carried out in a single solution thanks to the screw- in system. All components are made of coated aluminum that guarantee aesthetic and durability.";
      case "BASE LITE è una tenda a bracci estensibili con cassonetto su supporti laterali fissi con contropiastra di ancoraggio, bracci balteus lite, doppio cavetto inguainato o a cinghia.":
        return "BASE LITE is a full cassette folding arm awning on fixed independent side brackets and wall accroaching plate with balteus, double covered cable or belt arms.";

      //  EPICA
      case "EPICA è una tenda a bracci estensibili con cassonetto, caratterizzata da linee semplici ed essenziali. Gli elementi di fissaggio e le viterie sono totalmente nascosti alla vista. Pur avendo ingombri contenuti, raggiunge una sporgenza di 410cm con braccio Giant. Il prodotto viene completato con un’ampia gamma di optional come il kit luci e il terminale a cassonetto.":
        return "EPICA is a folding arm awning, with cassette, characterized by a simple ed essential design. Its fixing elements and the screws are completely hidden. Even though its small dimensions, it can have a 410cm projection by using Giant arm. The product can be completed with a large variety of optionals like light kit and box front bar.";
      case "EPICA è una tenda a bracci estensibili con cassonetto, caratterizzata da linee semplici ed essenziali. Gli elementi di fissaggio e le viterie sono totalmente nascosti alla vista.":
        return "EPICA is a folding arm awning, with cassette, characterized by a simple ed essential design. Its fixing elements and the screws are completely hidden.";

      //  EPICA LITE
      case "EPICA LITE è una tenda a bracci estensibili con cassonetto su supporti laterali fissi con contropiastra di ancoraggio, bracci Balteus o spin. Il cassonetto, ispezionabile, viene fornito in tre profili separati. I supporti consentono di installare la tenda sia a parete che a soffitto e la regolazione può essere eseguita in un’unica soluzione grazie al sistema con vite passante.":
        return "EPICA LITE is a full cassette folding arms awning on two side brackets and wall accroaching brackets, Balteus or spin arms. The inspectable cassette is supplied in three different profiles. The brackets allow for either a wall or a ceiling installation and, the adjustment can be carried out in a single solution thanks to the screw-in system. ";
      case "EPICA LITE è una tenda a bracci estensibili con cassonetto su supporti laterali fissi con contropiastra di ancoraggio, bracci Balteus o spin.":
        return "EPICA LITE is a full cassette folding arms awning on two side brackets and wall accroaching brackets, Balteus or spin arms.";

      //  GRANDANGOLO
      case "GRANDANGOLO è il naturale completamento della gamma di tende a braccia di BAT.La sua particolare configurazione, tramite l'inclinazione del braccio, permette di raggiungere angoli di ombreggiatura maggiori a quelli delle tende tradizionali mantenendo comunque alte le sue prestazioni. Disponibile sia con il supporto Helix che con quello Gold, GRANDANGOLO è compatibile con tutti i sistemi barra quadra 40mm di BAT.":
        return "GRANDANGOLO is the natural integration of the BAT folding arm range of awnings. Its particular configuration, through the arm inclination, allows to reach wider shading angles than classic awnings, keeping anyhow high standard performances. It is available both with Helix support than with the Gold. GRANDANGOLO is compatible with all BAT 40mm square bar systems.";
      case "GRANDANGOLO è il naturale completamento della gamma di tende a braccia di BAT.":
        return "GRANDANGOLO is the natural integration of the BAT folding arm range of awnings.";

      //  ELY
      case "ELY è una nuova tenda verticale per uso esterno. Grazie alla sua struttura lineare, alla facilità di assemblaggio e installazione e all’innovativo ed accattivante design, Ely risulta essere la soluzione ideale per soddisfare le più esigenti richieste di protezione solare ed arredo esterno.":
        return "ELY is a new vertical awning. Thanks to its linear structure, assembling and installation ease and innovative design, it is able to satisfy the most demanding solar protection and exterior design needs.";

      //  GIANT
      case "GIANT BQ é una tenda a bracci su barra quadra in acciaio 50mm. I supporti in alluminio estruso garantiscono resistenza e compattezza e i tappi in alluminio verniciati ne caratterizzano eleganza e discrezione. Compattezza e design la rendono perfetta per coperture di terrazze pubbliche e per protezione di spazi e patii privati. Dispone di un braccio tipo spin, creato “ad hoc” con snodi e spalla forgiati.":
        return "GIANT BQ is a retractable awning on square steel bar 50mm. The aluminum brackets grants strength and compactness and the covers in painted aluminum gives it a great elegance. Volume and design make of this awning a perfect cover for public and private spaces. The awning gets a Spin arm created “ad hoc” with casted shoulder and elbow.";
      case "GIANT BQ é una tenda a bracci su barra quadra in acciaio 50mm. I supporti in alluminio estruso garantiscono resistenza e compattezza e i tappi in alluminio verniciati ne caratterizzano eleganza e discrezione.":
        return "GIANT BQ is a retractable awning on square steel bar 50mm. The aluminum brackets grants strength and compactness and the covers in painted aluminum gives it a great elegance.";

      //  ZENITH
      case "ZENITH è una tenda a bracci con cassonetto fisso su barra rettangolare 50x30mm a scomparsa totale. Funzionale ed affidabile è dotata di sistema ribaltabile BAT che garantisce la perfetta chiusura del terminale anche in caso di massima inclinazione.":
        return "ZENITH is a retrectable awning with full closing box on a rectangular bar 50x30mm. Functional and reliable this awning gets tipping ystem BAT brackets that grant a complete perfect closing of the front bar even by max inclinations.";
      case "ZENITH è una tenda a bracci con cassonetto fisso su barra rettangolare 50x30mm a scomparsa totale.":
        return "ZENITH is a retrectable awning with full closing box on a rectangular bar 50x30mm.";

      //  GIOTTO
      case "GIOTTO è un prodotto per la copertura dei giardini d’inverno, dei tetti in vetro e delle verande in generale. BAT, forte dell’esperienza acquisita nel settore, ha progettato e sviluppato un prodotto che unisce soluzioni tecniche innovative ad un design estremamente interessante. Giotto funziona esclusivamente tramite motorizzazione.":
        return "GIOTTO is a product studied for glass roofs, wintergardens or veranda covering. BAT, thanks to the expertise acquired through the years, designed and developed a product in which innovative technical solutions join nice design. Giotto works only through a motorized system.";
      case "GIOTTO è un prodotto per la copertura dei giardini d’inverno, dei tetti in vetro e delle verande in generale.":
        return "GIOTTO is a product studied for glass roofs, wintergardens or veranda covering.";

      //  GIOTTO PLUS
      case "GIOTTO PLUS é la versione evoluta di Giotto, della quale mantiene tutte le caratteristiche tecniche. Sistema di protezione solare ideale per la copertura di terrazze, verande ed attici, può essere installata a piacere sia a parete che a soffitto e poggia, nella parte frontale su due colonne di design; per irrigidire la struttura vi è la possibilità di inserire un traverso che unisca le due colonne. È un sistema estremamente versatile ideale sia per gli ambienti privati che per quelli pubblici. Tenda con cassonetto dal design accattivante a scomparsa totale.":
        return "GIOTTO PLUS is the evolution of the Giotto mainting all his technical caractheristics. Sun protection system for terraces, verandas and attics. It can be fixed on the wall or ceiling and rests on two painted alluminium columns; to reinforce the structure you can install a cross bar between the two columns. We are talking about a versatile system indicated for private or public installations. A fully cassette awning with an engaging design.";
      case "GIOTTO PLUS é la versione evoluta di Giotto, della quale mantiene tutte le caratteristiche tecniche. Sistema di protezione solare ideale per la copertura di terrazze, verande ed attici, può essere installata a piacere sia a parete che a soffitto e poggia, nella parte frontale su due colonne di design; per irrigidire la struttura vi è la possibilità di inserire un traverso che unisca le due colonne.":
        return "GIOTTO PLUS is the evolution of the Giotto mainting all his technical caractheristics. Sun protection system for terraces, verandas and attics. It can be fixed on the wall or ceiling and rests on two painted alluminium columns; to reinforce the structure you can install a cross bar between the two columns.";

      //  MINI HELIX BQ
      case "MINI HELIX BQ è una tenda a bracci su barra quadra da 40 in acciaio zincato e verniciato. Sorella minore dell’ormai nota Helix BQ, dalla quale eredita l’apprezzato sistema di regolazione, si caratterizza da una spiccata personalità, data dal design pulito, estremamente sobrio e gradevole; è compatibile con le braccia della famiglia ECO, sia a cinghia che doppio cavetto. Come optional può essere proposta con il supporto avvolgitore GOLD, che ne completa la finitura, rendendola veramente unica nel suo genere.":
        return "MINI HELIX BQ is a retractable awning on a 40mm galvanized and painted steel square bar. It’s a smaller version of the already well known Helix BQ, with the acclaimed control system. Characterized by a strong personality, due to its simple, pleasant and clean design; it’s compatible with the ECO type arms, available with belt or double stainless steel cables. The GOLD roller blind support, can be proposed as an optional, which completes the finish, making it truly unique.";
      case "MINI HELIX BQ è una tenda a bracci su barra quadra da 40 in acciaio zincato e verniciato. Sorella minore dell’ormai nota Helix BQ, dalla quale eredita l’apprezzato sistema di regolazione, si caratterizza da una spiccata personalità, data dal design pulito, estremamente sobrio e gradevole; è compatibile con le braccia della famiglia ECO, sia a cinghia che doppio cavetto.":
        return "MINI HELIX BQ is a retractable awning on a 40mm galvanized and painted steel square bar. It’s a smaller version of the already well known Helix BQ, with the acclaimed control system. Characterized by a strong personality, due to its simple, pleasant and clean design; it’s compatible with the ECO type arms, available with belt or double stainless steel cables.";

      // MINI HELIX PS
      case "MINI HELIX PS è una tenda a supporti indipendenti. Concettualmente riprende le caratteristiche tecnico- meccaniche della sorella maggiore, Helix PS, come l’estrema facilità e versatilità di installazione e regolazione. Caratterizzata da un disegno gradevole e decisamente contemporaneo, al quale è stata sottoposta un’attenzione maniacale, è compatibile con le braccia della famiglia ECO, sia a cinghia che doppio cavetto.":
        return "MINI HELIX PS is a retractable awning with independent supports. Conceptually it resumes the technical and mechanical characteristics of the bigger version, Helix PS; such as the easy and versatility of installation and adjustment. Characterized by a pleasant and decidedly contemporary design. it’s compatible with the ECO type arms, available with belt or double stainless steel cables.";
      case "MINI HELIX PS è una tenda a supporti indipendenti. Concettualmente riprende le caratteristiche tecnico- meccaniche della sorella maggiore, Helix PS, come l’estrema facilità e versatilità di installazione e regolazione.":
        return "MINI HELIX PS is a retractable awning with independent supports. Conceptually it resumes the technical and mechanical characteristics of the bigger version, Helix PS; such as the easy and versatility of installation and adjustment.";

      //  HELIX BQ
      case "HELIX BQ tenda su barra quadra 40mm; è caratterizzata da un supporto in pressofusione, del tipo cosidetto a “cono” dal disegno curato e dalle prestazioni importanti: inclinazione in continuo, particolarmente agevole, da 0° a 90° gradi, sporgenza fino a 3,60m. In abbinamento con il supporto avvolgitore Gold, risulta essere una delle barre quadre più accattivanti presenti nel mercato.":
        return "HELIX BQ awning on square bar 40mm; its peculiarity is the die casted support, of the so-called “cono” it has an accurate design and important performances: continuous inclination, particularly manageable up to 90°, projection up to 3,60m. Combined with the blind roller support “Gold”, appears to be one of the most appealing in the market.";
      case "HELIX BQ tenda su barra quadra 40mm; è caratterizzata da un supporto in pressofusione, del tipo cosidetto a “cono” dal disegno curato e dalle prestazioni importanti: inclinazione in continuo, particolarmente agevole, da 0° a 90° gradi, sporgenza fino a 3,60m.":
        return "HELIX BQ awning on square bar 40mm; its peculiarity is the die casted support, of the so-called “cono” it has an accurate design and important performances: continuous inclination, particularly manageable up to 90°, projection up to 3,60m.";

      //  HELIX PS
      case "HELIX PS tenda a supporti indipendenti; è caratterizzata da un supporto in pressofusione, del tipo cosidetto a 'cono' dal disegno curato e dalle prestazioni importanti: inclinazione in continuo, particolarmente agevole, da 0° a 90°. Versatile e facile da installare, dal design robusto ma al contempo elegante.":
        return "HELIX PS separate supports awaning; its peculiarity is the die casted support of the so-called 'cono' it has an accurate design and important performances: continuous inclination, particularly manageable, up to 90°. Versatile and easy to install, elegant but tough at the same time.";
      case "HELIX PS tenda a supporti indipendenti; è caratterizzata da un supporto in pressofusione, del tipo cosidetto a 'cono' dal disegno curato e dalle prestazioni importanti: inclinazione in continuo, particolarmente agevole, da 0° a 90°.":
        return "HELIX PS separate supports awaning; its peculiarity is the die casted support of the so-called 'cono' it has an accurate design and important performances: continuous inclination, particularly manageable, up to 90°.";

      //  2010
      case "2010 è una tenda a bracci estensibili compatta su barra quadra 40mm in acciaio zincato e verniciato con bracci a doppio cavetto inguainato o cinghia e supporto braccio con regolazione a cono":
        return "2010 is a folding arm awning on a 40mm zinced and painted steel square bar with covered double cable or belt arms and arm support with conus-shaped adjustment.";

      //  2020
      case "2020 è una tenda a bracci estensibili compatta su barra quadra 40mm in acciaio zincato e verniciato, bracci a doppio cavetto inguainato o a cinghia e supporto braccio in estrusione di alluminio. E’ una tenda modulare: aggiungendo ulteriori profili si può raggiungere una larghezza maggiore. Il terminale frontale è intercambiabile con gli altri terminali BAT. Dispone come optional di un tettuccio di copertura in alluminio estruso e di una staffa speciale per il montaggio sottotetto.":
        return "2020 is a compact folding arm awning on 40mm galvanized and painted square bar, double covered cables or belt arms with extruded aluminium arm bracket. The awning is modular and it can reach larger dimensions by adding more profiles. The front bar is interchangeable with those in the BAT catalogue. An extruded aluminium hood and a special bracket for under- roof mounting is available optionally.";
      case "2020 è una tenda a bracci estensibili compatta su barra quadra 40mm in acciaio zincato e verniciato, bracci a doppio cavetto inguainato o a cinghia e supporto braccio in estrusione di alluminio. E’ una tenda modulare: aggiungendo ulteriori profili si può raggiungere una larghezza maggiore.":
        return "2020 is a compact folding arm awning on 40mm galvanized and painted square bar, double covered cables or belt arms with extruded aluminium arm bracket. The awning is modular and it can reach larger dimensions by adding more profiles.";

      //  2040
      case "2040 è una tenda a bracci estensibili su barra quadra 40mm in acciaio zincato e verniciato con cassonetto a scomparsa parziale e con bracci a doppio cavetto inguainato o a cinghia. Questo modello viene fornito di un supporto braccio basculante brevettato che consente di regolare la tenda senza dover inclinare il cassonetto.":
        return "2040 is a folding arm awning on a 40mm galvanized and painted steel square bar with half cassette and double covered cables or belt arms. This model includes a patented tipping system which allows to adjust the awning’s inclination without acting on the cassette itself.";
      case "2040 è una tenda a bracci estensibili su barra quadra 40mm in acciaio zincato e verniciato con cassonetto a scomparsa parziale e con bracci a doppio cavetto inguainato o a cinghia.":
        return "2040 is a folding arm awning on a 40mm galvanized and painted steel square bar with half cassette and double covered cables or belt arms.";

      //  OMBRALSUN
      case "OMBRALSUN, per la sue dimensioni, compattezza e facilità di installazione e regolazione, si posiziona sicuramente in vetta alla già ampia gamma dei prodotti per terrazzi e patio completandone la gamma. Grazie ad un solo tubo avvolgitore “Rollerbat” Ø 80mm, riesce a coprire una superficie di ca 36m2, mantenendo comunque contenuti gli ingombri; supporti e staffe di ancoraggio sono studiati perché tutte le installazioni siano semplici e veloci. Il sistema è completato da un cassonetto integrale che racchiude, una volta ritratti, entrambi i lati della tenda.":
        return "OMBRALSUN, for its dimension, compactness and ease of installation and adjustment, is surely on top of the already wide range of products for patios and balconies. Thanks to just one roller tube “rollerbat” Ø 80mm, it can cover a surface of about 36m2 Keeping anyhow limited encumbrances; supports and anchor brackets are studied to ease and speed all installations. The system is completed by a full cassette that encloses both sides of the awning , when closed.";
      case "OMBRALSUN, per la sue dimensioni, compattezza e facilità di installazione e regolazione, si posiziona sicuramente in vetta alla già ampia gamma dei prodotti per terrazzi e patio completandone la gamma.":
        return "OMBRALSUN, for its dimension, compactness and ease of installation and adjustment, is surely on top of the already wide range of products for patios and balconies.";

      //  PATIO V4
      case "Una struttura per la copertura di verande, terrazze, attici da abbinarsi ad una struttura portante di supporto. Il tessuto a scorrimento manuale è caratterizzato dalla classica conformazione ad onda e si può raccogliere in entrambe le direzioni. La particolare attenzione posta alla soluzione tecnica e al design la rende adattabile ad una vasta gamma di strutture di sostegno.":
        return "PATIO V4 is a structure to cover verandas, terraces, attics to be combined to a supporting structure. The fabric is moved manually, can be packed on both sides and is characterized by typical waving shape. The particular attention paid to the technical solution and design makes it adaptable to a wide range of support structures.";
      case "Una struttura per la copertura di verande, terrazze, attici da abbinarsi ad una struttura portante di supporto.":
        return "PATIO V4 is a structure to cover verandas, terraces, attics to be combined to a supporting structure.";

      //  R PITCH
      case "Una tenda a bracci estensibili su barra quadra 40mm in acciaio zincato e verniciato con bracci a doppio cavetto inguainato o a cinghia e supporto braccio a cremagliera con inclinazione singola di ogni braccio. Tenda modulare: aggiungendo ulteriori profili si possono raggiungere misure diverse. Il terminale frontale è intercambiabile con quelli del catalogo BAT. Dispone come optional di un tettuccio di copertura in alluminio estruso e di una staffa speciale per il montaggio sottotetto.":
        return "R-PITCH is a folding arm awning on 40mm galvanized and painted steel square bar with double covered cable or belt arms and gear rack extruded arm support with inclination adjustment on the single arm. The awning is modular and larger sizes can be reached by adding more profiles together. The terminal bar is interchangeable with those in the BAT catalogue. A protection hood in extruded aluminum, a special bracket for under-roof mounting and a balance support are also available.";
      case "Una tenda a bracci estensibili su barra quadra 40mm in acciaio zincato e verniciato con bracci a doppio cavetto inguainato o a cinghia e supporto braccio a cremagliera con inclinazione singola di ogni braccio.":
        return "R-PITCH is a folding arm awning on 40mm galvanized and painted steel square bar with double covered cable or belt arms and gear rack extruded arm support with inclination adjustment on the single arm.";

      //  SIRIO
      case "SIRIO Struttura di copertura per giardini d’inverno, tetti in vetro e verande. Dotata di sistema di guida Zip e di sistema di tensionamento del telo con molla. Presenza di optional luci e terminale a cassonetto. Funzionamento esclusivamente motorizzato.":
        return "SIRIO covering structure for winter garden, glass roofs and porch. Equipped with zip guide rail and fabric tensioning system with spring. Optional lights and front bar cassette available. Motorized operation only.";
      case "SIRIO Struttura di copertura per giardini d’inverno, tetti in vetro e verande. Dotata di sistema di guida Zip e di sistema di tensionamento del telo con molla.":
        return "SIRIO covering structure for winter garden, glass roofs and porch. Equipped with zip guide rail and fabric tensioning system with spring.";

      //  SPACE
      case "Struttura di copertura per verande e attici. Il tessuto scorre nella parte superiore su guide, regolabili in larghezza e contenenti il sistema di tensionamento con molle a gas. Sono disponibili degli optional come il sistema di blocco meccanico e il sistema flottante per il tubo avvolgitore. Dimensioni massime 500x700 cm e con guida centrale anche 700x700 cm.":
        return "Covering structure for verandas and penthouses. The fabric slides on the top side, into guides which are adjustable in width and contain the tension system with gas springs. Double installation for wall and ceiling, it is based on a fixed portal, whose columns are adjustable in width to meet all the customer needs. Options such as the mechanical locking system and floating system for the winding tube are available. Maximum dimensions 500x550 cm and with central guide also 700x550 cm.";
      case "Struttura di copertura per verande e attici. Il tessuto scorre nella parte superiore su guide, regolabili in larghezza e contenenti il sistema di tensionamento con molle a gas.":
        return "Covering structure for verandas and penthouses. The fabric slides on the top side, into guides which are adjustable in width and contain the tension system with gas springs.";

      //  NEW ROMA
      case "NEW ROMA è una tenda verticale dal design particolarmente curato dedicata alla posa su balconi. Questo modello può essere con oppure senza braccetti in tre diverse opzioni di funzionamento: con arganello, con molla di richiamo e motorizzata. Il braccetto è regolabile su posizioni fisse da 0° a 180° tramite una apposita manopola di bloccaggio e prevede una ulteriore rotazione che consente un eventuale allineamento alla ringhiera. L’installazione è possibile sia a parete che a soffitto. E’ disponibile un cassonetto in alluminio opzionale.":
        return "NEW ROMA is a vertical awning of nice design drop awning for balconies installation. This model can be operated in three different ways: using a reduction winch, using a preassembled spring or by a tubular motor. This model can be set on fixed positions from 0° to 180° operating on a dedicated handle and a further rotation will help the awning to line up with the banister. The installation can be done either on the wall or on the ceiling. An optional aluminum cassette is also available.";
      case "NEW ROMA è una tenda verticale dal design particolarmente curato dedicata alla posa su balconi. Questo modello può essere con oppure senza braccetti in tre diverse opzioni di funzionamento: con arganello, con molla di richiamo e motorizzata.":
        return "NEW ROMA is a vertical awning of nice design drop awning for balconies installation. This model can be operated in three different ways: using a reduction winch, using a preassembled spring or by a tubular motor.";

      //  GENOVA
      case "GENOVA è una tenda verticale con guide laterali dedicata alla posa su balconi. Questo modello può essere con oppure senza braccetti. Il braccetto scorre a caduta nelle guide e viene poi fissato nella posizione desiderata (regolabile fino a 160°) tramite una apposita manopola di bloccaggio. L’installazione è sia a parete che a soffitto.":
        return "GENOVA is a drop awning on side aluminum profile guides for balconies. This model may be with or without arms. The arms slide inside the guides and locked in the desidered position (adjustable up to 160°) acting on a dedicated locking handle. The installation may be on the wal as well as on the ceiling.";
      case "GENOVA è una tenda verticale con guide laterali dedicata alla posa su balconi. Questo modello può essere con oppure senza braccetti.":
        return "GENOVA is a drop awning on side aluminum profile guides for balconies. This model may be with or without arms.";

      //  VERSO
      case "VERSO è una tenda dedicata alla posa su balconi e finestre con braccetti a molla. Di semplice e veloce installazione prevede anche un cassonetto opzionale per la protezione del tessuto. Grazie alla conformazione del braccio si adatta particolarmente alla installazione a nicchia.":
        return "VERSO is an appropriate awning for balconies and window installation with spring arms. Easy and quick installation. An optional cassette is available for fabric protection. Thanks to the arm shape it can be installed inside niche.";
      case "VERSO è una tenda dedicata alla posa su balconi e finestre con braccetti a molla. Di semplice e veloce installazione prevede anche un cassonetto opzionale per la protezione del tessuto.":
        return "VERSO is an appropriate awning for balconies and window installation with spring arms. Easy and quick installation. An optional cassette is available for fabric protection.";

      //  GLASS
      case "Binario inferiore alto soli 18 mm. Movimentazione delle ante a sinistra o a destra, con cambio verso rapido anche in fase d’opera. Regolazione in altezza fino a 8 mm per ogni singola anta. Sistema scomponibile per una facile pulizia. In presenza di forti piogge o piogge accompagnate da vento non è garantita la completa impermeabilità.":
        return "The lower track is only 18 mm high. The doors can be slid to the left or the right and the direction can quickly be changed, even during the installation phase. The height of each door can be adjusted by up to 8 mm. The system is modular to make cleaning easier. It is not guaranteed to be 100% waterproof in the event of heavy rain or high winds and rain.";
      case "Binario inferiore alto soli 18 mm. Movimentazione delle ante a sinistra o a destra, con cambio verso rapido anche in fase d’opera.":
        return "The lower track is only 18 mm high. The doors can be slid to the left or the right and the direction can quickly be changed, even during the installation phase.";

      //  130 GPZ I
      case "Tenda con cassonetto da 130mm disponibile nelle varianti Quadro e Tondo dotata di sistema di guida ZIP, composta da due profili con compensazione fino ad un max di 17 mm su ogni guida. Adatta per essere installata ad incasso e per applicazioni che presentano fuori squadra.":
        return "130mm cassette screen available in Square and Round versions, equipped with ZIP guide system composed by two profiles with a 17mm maximum compensation on each guide. Suitable for built-in or/and out of square installations.";

      //  110 GC
      case "Tenda con cassonetto da 110mm disponibile nelle varianti Quadro e Tondo, dotata di guida cavetto inox Ø4mm.":
        return "110mm cassette screen available in Square and Round versions, equipped with stainless steel cable guide Ø4 mm.";

      //  110 GC CABRIO
      case "Tenda senza cassonetto, dotata di guida cavetto inox Ø4mm. Realizzabile sia nella versione singola che doppia.":
        return "Screen without cassette, equipped with cable guide Ø4mm. It’s available both in single and double version.";

      //  150 GPZ UNICA
      case "Tenda con cassonetto da 150mm disponibile nella sola variante Tondo, dotata di sistema di guida ZIP. Adatta per installazioni su grandi fori luce, sia a nicchia che a parete. La guida è composta da due profili: supporto e copertina; è veloce ed agevole da installare ed ispezionare.":
        return "150mm cassette screen available in Round version only, equipped with ZIP guide system. Suitable for both niche and wall installation in big opening; fast and easy to install and inspect. The guide is composed by two profiles: support and cover; fast and easy to install and inspect.";

      //  130 GPZ MB
      case "Tenda con cassonetto da 130mm disponibile nella sola variante Quadro, dotata di guida profilo da incasso con sistema ZIP e cassonetto autoportante. Adatta per l’integrazione nei sistemi per serramento, migliorando il risparmio energetico.":
        return "130mm cassette screen available in Square version only, equipped with ZIP guide profile and self-standing profile. Is suitable for integration in the door or windows systems, improving energy savings.";

      //  130 GA
      case "Tenda con cassonetto da 130mm disponibile nelle varianti Quadro e Tondo, dotata di guida asta inox Ø10mm.":
        return "130mm cassette screen available in Square and Round versions, equipped with stainless steel rod guide Ø10mm.";

      //  130 GPZ UNICA M
      case "Tenda con cassonetto da 130mm disponibile nelle varianti Quadro e Tondo dotata di sistema di guida ZIP. Adatta per installazioni su infissi, sia a nicchia che a parete; veloce ed agevole da installare ed ispezionare. La guida è composta da due profili: supporto e copertina; è sviluppata per i soli sistemi motorizzati riducendo la larghezza a 36 mm.":
        return "130mm cassette screen available in Square and Round versions, equipped with ZIP guide system and self-standing cassette. Suitable for both niche and wall installation of fixtures; fast and easy to install and inspect. The guide is composed by two profiles: support and cover; developed only for motorized systems reducing the width at 36mm.";
      case "Tenda con cassonetto da 130mm disponibile nelle varianti Quadro e Tondo dotata di sistema di guida ZIP.":
        return "130mm cassette screen available in Square and Round versions, equipped with ZIP guide system and self-standing cassette.";

      //  110 GPZ UNICA AM
      case "Tenda con cassonetto da 110mm disponibile nelle varianti Quadro e Tondo dotata di sistema di guida ZIP e cassonetto autoportante. Adatta per installazioni su infissi, sia a nicchia che a parete; veloce ed agevole da installare ed ispezionare. La guida è composta da due profili: supporto e copertina; è sviluppata per sistemi sia ad argano che motorizzati.":
        return "110mm cassette screen available in Square and Round versions, equipped with ZIP guide system and self-standing cassette. Suitable for both niche and wall installation of fixtures; fast and easy to install and inspect. The guide is composed by two profiles: support and cover; developed both for manual and motorized systems.";
      case "Tenda con cassonetto da 110mm disponibile nelle varianti Quadro e Tondo dotata di sistema di guida ZIP e cassonetto autoportante. Adatta per installazioni su infissi, sia a nicchia che a parete; veloce ed agevole da installare ed ispezionare.":
        return "110mm cassette screen available in Square and Round versions, equipped with ZIP guide system and self-standing cassette. Suitable for both niche and wall installation of fixtures; fast and easy to install and inspect.";

      //  55 GPXS
      case "Tenda con cassonetto da 55mm disponibile nella sola variante Quadro, dotata di guida profilo e cassonetto autoportante.":
        return "55mm cassette screen available in Square version only, equipped with inox profile guide and self-standing cassette version.";

      //  130 GPZ TO
      case "Tenda con cassonetto da 130mm disponibile nella sola variante Quadro, dotata di sistema di guida ZIP. Adatta, grazie al sistema di tensionamento , per installazioni come copertura dei giardini d’inverno, dei tetti in vetro e delle verande in generale. Funzionamento esclusivamente motorizzato.":
        return "130mm cassette screen available in Square version only, equipped with ZIP guide system. Thanks to the tightening system is suitable for winter gardens covering, glass roofs and verandas. With motor only.";
      case "Tenda con cassonetto da 130mm disponibile nella sola variante Quadro, dotata di sistema di guida ZIP.":
        return "130mm cassette screen available in Square version only, equipped with ZIP guide system.";

      //  85 GPZ S
      case "Tenda con cassonetto da 85mm disponibile nelle varianti Quadro e Tondo, dotata di guida profilo e cassonetto autoportante.":
        return "85mm cassette screen available in Square and Round versions, equipped with ZIP guide profile and self-standing cassette. It’s available both in single and in double version.";

      //  150 STORM
      case "Tenda con cassonetto da 110mm, 130mm disponibile nelle varianti Quadro e Tondo, con cassonetto da 150 mm disponibile nella sola variante Tondo, dotata di sistema di guida ZIP. Adatta per installazioni sia a nicchia che a parete; veloce ed agevole da installare ed ispezionare. La guida è composta da due profili: supporto e copertina ed è studiata per accogliere il profilo terminale che vi rimane connesso sia sotto l’azione di elevate pressioni del vento sia sotto l’azione di urti. È sviluppata per sistemi sia ad argano che motorizzati.":
        return "110mm or 130mm cassette screen available in Square and Round versions, with 150 mm cassette screen available in round version only, equipped with ZIP guide system. Suitable for both niche and wall installation; fast and easy to install and inspect. The guide is composed by two profiles: support and cover. The front bar is into the guide, this keeps the edges of the screen secure under pressure from high winds and shock from impact. Developed both for manual and motorized systems.";
      case "Tenda con cassonetto da 110mm, 130mm disponibile nelle varianti Quadro e Tondo, con cassonetto da 150 mm disponibile nella sola variante Tondo, dotata di sistema di guida ZIP. Adatta per installazioni sia a nicchia che a parete; veloce ed agevole da installare ed ispezionare.":
        return "110mm or 130mm cassette screen available in Square and Round versions, with 150 mm cassette screen available in round version only, equipped with ZIP guide system. Suitable for both niche and wall installation; fast and easy to install and inspect.";

      default:
        return entry;
    }
  }
  if (language === "es") {
    switch (entry) {
      case "Dopo aver cambiato lingua, lo showroom verrà ricaricato.":
        return "After changing the language, the showroom will be reloaded.";
      //  MENU
      case "APERTO":
        return "ABIERTO";
      case "CHIUSO":
        return "CERRADO";
      case "MAPPA":
        return "MAPA";
      case "POSIZIONE":
        return "POSICIÓN";
      case "LINGUA":
        return "IDIOMA";
      case "PRODOTTI":
        return "PRODUCTOS";
      case "PERCORSO":
        return "SENDERO";
      case "COLORI":
        return "COLORES";

      //  LANGUAGES
      case "Italiano":
        return "Italiano";
      case "Inglese":
        return "Inglés";
      case "Spagnolo":
        return "Español";
      case "Francese":
        return "Francés";
      case "Svedese":
        return "Sueco";

      //  INFO
      case "Scheda Tecnica":
        return "Hoja Técnica";

      //  FORM
      case "Vuoi più informazioni sui prodotti BAT?":
        return "Quieres más información sobre los productos BAT?";
      case "Scrivici: ti offriamo un preventivo gratuito e senza impegno.":
        return "Escríbenos: te ofrecemos un estimado gratis y sin obligación.";
      case "Ti rispondiamo entro 24/48H":
        return "Te respondemos dentro de 24/48H";
      case "Qualcosa è andato storto.":
        return "Algo salió mal.";
      case "Grazie per averci contattato!":
        return "Gracias por contactarnos!";
      case "Il tuo nome":
        return "Your namTu nombre";
      case "Il tuo indirizzo e-mail *":
        return "Tu dirección de correo electrónico *";
      case "Telefono *":
        return "Teléfono *";
      case "La tua città *":
        return "Tu ciudad *";
      case "Vuoi raccontarci la tua esigenza?":
        return "Quieres decirnos tus necesidades?";
      case "Acconsento al trattamento dei miei dati personali":
        return "Autorizo ​​el uso de datos personales";
      case "Regolamento UE 2016/679":
        return "Regulación UE 2016/679";
      case "Desidero un preventivo gratuito":
        return "Deseo un estimado gratis";
      case "Riceverai una risposta entro 24-48H":
        return "Obtendrás una respuesta dentro de 24/48H";

      //  HELP
      case "Ambiente a 360°":
        return "Ambiente en 360°";
      case "È possibile esplorare liberamente l'ambiente cliccando e trascinando il cursore, spostandosi attraverso i punti di movimento.":
        return "Puedes explorar libremente el entorno pulsando y arrastrando el cursor, moviéndose a través de los puntos de movimiento.";
      case "Interattivo":
        return "Interactivo";
      case "In ogni prodotto sono disponibili vari punti di informazione, con tutti i materiali relativi al prodotto che si sta visualizzando.":
        return "Cada producto dispone de varios puntos de información, con todo el material relacionado con el producto que está viendo.";
      case "Immersivo":
        return "Inmersivo";
      case "È possibile ingrandire o rimpicciolire a piacimento le immagini, per una visione più specifica del prodotto.":
        return "Puedes acercar y alejar las imágenes a voluntad, para una visión más específica del producto.";
      case "Immagine non trovata. Verrai riportato all'introduzione.":
        return "Imagen no encontrada. Volverá a la introducción.";
      case "Cambia Posizione":
        return "Cambiar de Posición";

      //  INTRO
      case "Introduzione":
        return "Introducción";

      //  DOMEA
      case "DOMEA, disegnata da Robby Cantarutti, designer di fama internazionale, è il primo prodotto della nuova “feel-experience” di BAT. Le sue forme accattivanti, che richiamano la tradizione e simboleggiano un connubio perfetto tra innovazione e classicità, forte di nuovi contenuti tecnologici, fanno di DOMEA uno dei prodotti di punta della gamma delle tende a cassonetto. Caratterizzata dall’estrema facilità di montaggio, regolazione e installazione, garantisce standard di sicurezza e qualità elevati per un modello di questa categoria.":
        return "DOMEA, diseñado por Robby Cantarutti, diseñador de fama internacional, es el primer producto de la nueva “feel-experience” de BAT. Sus formas cautivadoras, que recuerdan la tradición y simbolizan la relación perfecta entre innovación y clasicismo, reforzado con nuevos contenidos tecnológicos, hacen de DOMEA uno de los productos punteros en la gama de los cofres de brazos extensibles. Caracterizada por la extrema facilidad de montaje, regulación e instalación, garantiza los estándares de seguridad y elevada calidad de un modelo de esta categoría.";
      case "DOMEA, disegnata da Robby Cantarutti, designer di fama internazionale, è il primo prodotto della nuova “feel-experience” di BAT. Le sue forme accattivanti, che richiamano la tradizione e simboleggiano un connubio perfetto tra innovazione e classicità, forte di nuovi contenuti tecnologici, fanno di DOMEA uno dei prodotti di punta della gamma delle tende a cassonetto.":
        return "DOMEA, diseñado por Robby Cantarutti, diseñador de fama internacional, es el primer producto de la nueva “feel-experience” de BAT. Sus formas cautivadoras, que recuerdan la tradición y simbolizan la relación perfecta entre innovación y clasicismo, reforzado con nuevos contenidos tecnológicos, hacen de DOMEA uno de los productos punteros en la gama de los cofres de brazos extensibles.";

      // BASE PLUS
      case "BASE PLUS è una tenda a bracci estensibili con cassonetto su supporti laterali fissi con contropiastra di ancoraggio, bracci Balteus o spin. Il cassonetto, ispezionabile, viene fornito in tre profili separati. I supporti consentono di installare la tenda sia a parete che a soffitto e la regolazione può essere eseguita in un unica soluzione grazie al sistema con vite passante. Tutti i componenti sono realizzati in alluminio verniciato che garantiscono resa estetica e durabilità.":
        return "BASE PLUS es un cofre de brazos extensibles, con soportes laterales fijos y soportes de anclaje, brazos balteus lite o spin. El cofre, inspeccionable, se suministra en tres perfiles separados. Los soportes permiten la instalación del toldo tanto a pared como a techo y la regulación se puede realizar gracias al sistema del tornillo pasante. Todos los componentes están hechos en aluminio lacado, lo que garantiza la estética y la durabilidad.";
      case "BASE PLUS è una tenda a bracci estensibili con cassonetto su supporti laterali fissi con contropiastra di ancoraggio, bracci Balteus o spin. Il cassonetto, ispezionabile, viene fornito in tre profili separati.":
        return "BASE PLUS es un cofre de brazos extensibles, con soportes laterales fijos y soportes de anclaje, brazos balteus lite o spin. El cofre, inspeccionable, se suministra en tres perfiles separados.";

      //  ARMONY PLUS
      case "ARMONY PLUS é una tenda a bracci estensibili con cassonetto a scomparsa totale. Design e stile uniti alla funzionalità e alla tecnologia di un sistema ribaltabile BAT che permette la chiusura ermetica del cassonetto anche nella sua massima inclinazione inoltre, grazie ad un profilo di guarnizione il cassonetto risulta essere perfettamente aderente al muro impedendo l’infiltrazione di acque ed impurità.":
        return "ARMONY PLUS es un cofre de brazos extensibles con cierre total. Diseño y estilo unidos a la funcionalidad y a la tecnología del sistema abatible BAT que permite el cierre hermético del cofre incluso en el caso de inclinaciones máximas. Por otra parte, y gracias a un perfil complementario, el cajón puede estar totalmente pegado a la pared impidiendo la filtración de agua y suciedad.";
      case "ARMONY PLUS é una tenda a bracci estensibili con cassonetto a scomparsa totale.":
        return "ARMONY PLUS es un cofre de brazos extensibles con cierre total.";

      //  BASE LITE
      case "BASE LITE è una tenda a bracci estensibili con cassonetto su supporti laterali fissi con contropiastra di ancoraggio, bracci balteus lite, doppio cavetto inguainato o a cinghia. I supporti consentono di installare la tenda sia a parete che a soffitto e la regolazione può essere eseguita in un unica soluzione grazie al sistema con vite passante. Tutti i componenti sono realizzati in alluminio verniciato che garantiscono resa estetica e durabilità.":
        return "BASE LITE es un cofre de brazos extensibles, con soportes laterales fijos y soportes de anclaje, brazos balteus lite de doble cable o de cinta. Los soportes permiten la instalación del toldo tanto a pared como a techo y la regulación se puede realizar gracias al sistema del tornillo pasante. Todos los componentes están hechos en aluminio lacado, lo que garantiza la estética y la durabilidad. ";
      case "BASE LITE è una tenda a bracci estensibili con cassonetto su supporti laterali fissi con contropiastra di ancoraggio, bracci balteus lite, doppio cavetto inguainato o a cinghia.":
        return "BASE LITE es un cofre de brazos extensibles, con soportes laterales fijos y soportes de anclaje, brazos balteus lite de doble cable o de cinta.";

      //  EPICA
      case "EPICA è una tenda a bracci estensibili con cassonetto, caratterizzata da linee semplici ed essenziali. Gli elementi di fissaggio e le viterie sono totalmente nascosti alla vista. Pur avendo ingombri contenuti, raggiunge una sporgenza di 410cm con braccio Giant. Il prodotto viene completato con un’ampia gamma di optional come il kit luci e il terminale a cassonetto.":
        return "EPICA es un cofre de brazos extensibles, caracterizado por un diseño sencillo y elegante. Los elementos de fijación y los tornillos están totalmente escondidos a la vista. Aunque tenga dimensiones pequeñas el cofre puede llegar a tener una salida de 410 cm con brazo Giant. El modelo se completa con una gran variedad de opciones como el kit de luces y el terminal cofre.";
      case "EPICA è una tenda a bracci estensibili con cassonetto, caratterizzata da linee semplici ed essenziali. Gli elementi di fissaggio e le viterie sono totalmente nascosti alla vista.":
        return "EPICA es un cofre de brazos extensibles, caracterizado por un diseño sencillo y elegante. Los elementos de fijación y los tornillos están totalmente escondidos a la vista.";

      //  EPICA LITE
      case "EPICA LITE è una tenda a bracci estensibili con cassonetto su supporti laterali fissi con contropiastra di ancoraggio, bracci Balteus o spin. Il cassonetto, ispezionabile, viene fornito in tre profili separati. I supporti consentono di installare la tenda sia a parete che a soffitto e la regolazione può essere eseguita in un’unica soluzione grazie al sistema con vite passante.":
        return "EPICA LITE es un toldo de brazo extensible con cofre con soportes laterales fijos con placa de anclaje, brazos Balteus o spin. El cofre, inspeccionable, se suministra con tres perfiles separados. Los soportes permiten instalar el toldo tanto a pared como a techo y la regulación pueden ser realizada en una única solución gracias a la sistema con tornillo pasante. ";
      case "EPICA LITE è una tenda a bracci estensibili con cassonetto su supporti laterali fissi con contropiastra di ancoraggio, bracci Balteus o spin.":
        return "EPICA LITE es un toldo de brazo extensible con cofre con soportes laterales fijos con placa de anclaje, brazos Balteus o spin.";

      //  ELY
      case "ELY è una nuova tenda verticale per uso esterno. Grazie alla sua struttura lineare, alla facilità di assemblaggio e installazione e all’innovativo ed accattivante design, Ely risulta essere la soluzione ideale per soddisfare le più esigenti richieste di protezione solare ed arredo esterno.":
        return "ELY es un nuevo toldo vertical para el exterior. La regulabilidad y la atención depositada en la fase proyectiva en las soluciones estilísticas y, sobre todo, la facilidad de montaje e instalación hacen de Ely un producto capaz de satisfacer las más exigentes soluciones de protección solar y decoración para todo tipo de fachadas o jardín.";

      //  GIANT
      case "GIANT BQ é una tenda a bracci su barra quadra in acciaio 50mm. I supporti in alluminio estruso garantiscono resistenza e compattezza e i tappi in alluminio verniciati ne caratterizzano eleganza e discrezione. Compattezza e design la rendono perfetta per coperture di terrazze pubbliche e per protezione di spazi e patii privati. Dispone di un braccio tipo spin, creato “ad hoc” con snodi e spalla forgiati.":
        return "GIANT es un toldo de brazos extensibles sobre barra cuadrada de acero 50mm. Los soportes de aluminio extruido garantizan la resistencia y la solidez, y las tapas de aluminio lacado les dan elegancia y discreción. Solidez y diseño son las características que lo convierten en la solución ideal para coberturas de terrazas públicas y para proteger espacios y patios privados. Dispone de un brazo tipo spin, construido expresamente con accesorios forjados.";
      case "GIANT BQ é una tenda a bracci su barra quadra in acciaio 50mm. I supporti in alluminio estruso garantiscono resistenza e compattezza e i tappi in alluminio verniciati ne caratterizzano eleganza e discrezione.":
        return "GIANT es un toldo de brazos extensibles sobre barra cuadrada de acero 50mm. Los soportes de aluminio extruido garantizan la resistencia y la solidez, y las tapas de aluminio lacado les dan elegancia y discreción.";

      //  GIOTTO
      case "GIOTTO è un prodotto per la copertura dei giardini d’inverno, dei tetti in vetro e delle verande in generale. BAT, forte dell’esperienza acquisita nel settore, ha progettato e sviluppato un prodotto che unisce soluzioni tecniche innovative ad un design estremamente interessante. Giotto funziona esclusivamente tramite motorizzazione.":
        return "GIOTTO es un producto para la cobertura de invernaderos, techos de cristal y galerías en general. BAT, gracias a la experiencia adquirida en el sector, ha proyectado y desarrollado un producto que une soluciones innovadoras y un diseño extremadamente interesante. Giotto funciona exclusivamente a través de un sistema motorizado.";
      case "GIOTTO è un prodotto per la copertura dei giardini d’inverno, dei tetti in vetro e delle verande in generale.":
        return "GIOTTO es un producto para la cobertura de invernaderos, techos de cristal y galerías en general.";

      //  GIOTTO PLUS
      case "GIOTTO PLUS é la versione evoluta di Giotto, della quale mantiene tutte le caratteristiche tecniche. Sistema di protezione solare ideale per la copertura di terrazze, verande ed attici, può essere installata a piacere sia a parete che a soffitto e poggia, nella parte frontale su due colonne di design; per irrigidire la struttura vi è la possibilità di inserire un traverso che unisca le due colonne. È un sistema estremamente versatile ideale sia per gli ambienti privati che per quelli pubblici. Tenda con cassonetto dal design accattivante a scomparsa totale.":
        return "GIOTTO PLUS versión evolucionada del Giotto, del cual mantiene todas sus características técnicas. Sistema de protección solar ideal para el cubrimiento de terrazas, techos y áticos, puede ser instalado tanto a pared como a techo, e incluso apoyada en la parte frontal sobre dos pies de aluminio lacado; para rigidizar la estructura también existe la posibilidad de utilizar un travesaño que una los dos pies. Se trata de un sistema extremadamente versátil ideal tanto para ambientes privados como para los públicos. Veranda de diseño cautivador y cierre total.";
      case "GIOTTO PLUS é la versione evoluta di Giotto, della quale mantiene tutte le caratteristiche tecniche. Sistema di protezione solare ideale per la copertura di terrazze, verande ed attici, può essere installata a piacere sia a parete che a soffitto e poggia, nella parte frontale su due colonne di design; per irrigidire la struttura vi è la possibilità di inserire un traverso che unisca le due colonne.":
        return "GIOTTO PLUS versión evolucionada del Giotto, del cual mantiene todas sus características técnicas. Sistema de protección solar ideal para el cubrimiento de terrazas, techos y áticos, puede ser instalado tanto a pared como a techo, e incluso apoyada en la parte frontal sobre dos pies de aluminio lacado; para rigidizar la estructura también existe la posibilidad de utilizar un travesaño que una los dos pies.";

      // MINI HELIX PS
      case "MINI HELIX PS è una tenda a supporti indipendenti. Concettualmente riprende le caratteristiche tecnico- meccaniche della sorella maggiore, Helix PS, come l’estrema facilità e versatilità di installazione e regolazione. Caratterizzata da un disegno gradevole e decisamente contemporaneo, al quale è stata sottoposta un’attenzione maniacale, è compatibile con le braccia della famiglia ECO, sia a cinghia che doppio cavetto.":
        return "MINI HELIX PS es un toldo de brazos extensibles de gama economica sobre soportes independientes laterales que permiten la cómoda regulación del ángulo del brazo. Los brazos están con cinta.";
      case "MINI HELIX PS è una tenda a supporti indipendenti. Concettualmente riprende le caratteristiche tecnico- meccaniche della sorella maggiore, Helix PS, come l’estrema facilità e versatilità di installazione e regolazione.":
        return "MINI HELIX PS es un toldo de brazos extensibles de gama economica sobre soportes independientes laterales que permiten la cómoda regulación del ángulo del brazo.";

      //  HELIX PS
      case "HELIX PS tenda a supporti indipendenti; è caratterizzata da un supporto in pressofusione, del tipo cosidetto a 'cono' dal disegno curato e dalle prestazioni importanti: inclinazione in continuo, particolarmente agevole, da 0° a 90°. Versatile e facile da installare, dal design robusto ma al contempo elegante.":
        return "HELIX PS es un toldo de soportes independientes; está caracterizado por un soporte de inyección, del tipo conocido como “cono”, de diseño cuidado e importantes prestaciones: inclinación en continuo, particularmente ágil, desde 0 a 90 grados. Versátil y fácil de instalar, de diseño robusto pero al mismo tiempo elegante.";
      case "HELIX PS tenda a supporti indipendenti; è caratterizzata da un supporto in pressofusione, del tipo cosidetto a 'cono' dal disegno curato e dalle prestazioni importanti: inclinazione in continuo, particolarmente agevole, da 0° a 90°.":
        return "HELIX PS es un toldo de soportes independientes; está caracterizado por un soporte de inyección, del tipo conocido como “cono”, de diseño cuidado e importantes prestaciones: inclinación en continuo, particularmente ágil, desde 0 a 90 grados.";

      //  2010
      case "2010 è una tenda a bracci estensibili compatta su barra quadra 40mm in acciaio zincato e verniciato con bracci a doppio cavetto inguainato o cinghia e supporto braccio con regolazione a cono":
        return "2010 es un toldo con brazos extensibles sobre barra cuadrada 40mm en acero galvanizado y con brazos con cinta y soporte de brazo con regulación a cono.";

      //  2020
      case "2020 è una tenda a bracci estensibili compatta su barra quadra 40mm in acciaio zincato e verniciato, bracci a doppio cavetto inguainato o a cinghia e supporto braccio in estrusione di alluminio. E’ una tenda modulare: aggiungendo ulteriori profili si può raggiungere una larghezza maggiore. Il terminale frontale è intercambiabile con gli altri terminali BAT. Dispone come optional di un tettuccio di copertura in alluminio estruso e di una staffa speciale per il montaggio sottotetto.":
        return "2020 es un toldo con brazos extensibles sobre barra cuadrada de 40mm en acero galvanizado y lacado, brazos con cinta y soporte de brazo en extrusión de aluminio. Es un toldo modular: añadiendo más perfiles se puede alcanzar una mayor longitud. El terminal frontal se puede cambiar con otros terminales BAT. Dispone de la opción de un pequeño tejadillo de aluminio extruido y de una abrazadera especial para el montaje bajotecho";
      case "2020 è una tenda a bracci estensibili compatta su barra quadra 40mm in acciaio zincato e verniciato, bracci a doppio cavetto inguainato o a cinghia e supporto braccio in estrusione di alluminio. E’ una tenda modulare: aggiungendo ulteriori profili si può raggiungere una larghezza maggiore.":
        return "2020 es un toldo con brazos extensibles sobre barra cuadrada de 40mm en acero galvanizado y lacado, brazos con cinta y soporte de brazo en extrusión de aluminio.";

      //  SIRIO
      case "SIRIO Struttura di copertura per giardini d’inverno, tetti in vetro e verande. Dotata di sistema di guida Zip e di sistema di tensionamento del telo con molla. Presenza di optional luci e terminale a cassonetto. Funzionamento esclusivamente motorizzato.":
        return "SIRIO WALL Estructura de protección solar para terrazas, espacios acristalados y verandas. Dotada de sistemas de guía Zip y de tensión a muelle. Posibilidad opcionalmente de luces y terminal cofre. Instalación frontal o a techo. Apoya en la parte frontal sobre dos columnas, regulables en proyección para solucionar cualquier problemática en la instalación. Las guías son rigidizadas por un travesaño. Funcionamiento exclusivamente motorizado.";
      case "SIRIO Struttura di copertura per giardini d’inverno, tetti in vetro e verande. Dotata di sistema di guida Zip e di sistema di tensionamento del telo con molla.":
        return "SIRIO WALL Estructura de protección solar para terrazas, espacios acristalados y verandas. Dotada de sistemas de guía Zip y de tensión a muelle.";

      //  SPACE
      case "Struttura di copertura per verande e attici. Il tessuto scorre nella parte superiore su guide, regolabili in larghezza e contenenti il sistema di tensionamento con molle a gas. Sono disponibili degli optional come il sistema di blocco meccanico e il sistema flottante per il tubo avvolgitore. Dimensioni massime 500x700 cm e con guida centrale anche 700x700 cm.":
        return "SPACE WALL Pérgola para protección solar de terrazas y áticos. El tejido desliza por la parte superior sobre unas guías cuya posición se puede regular, además incorpora el sistema de tensión con pistones de gas. Instalable tanto a pared como a techo, apoya en una estructura fija, cuyas columnas son ajustables en su ancho para satisfacer todas las necesidades. Existen opciones disponibles como el sistema de bloqueo mecánico así como el sistema flotante para el tubo de enrolle. Dimensiones máximas de 500x550 cm y con guía central hasta 700x550 cm.";
      case "Struttura di copertura per verande e attici. Il tessuto scorre nella parte superiore su guide, regolabili in larghezza e contenenti il sistema di tensionamento con molle a gas.":
        return "SPACE WALL Pérgola para protección solar de terrazas y áticos. El tejido desliza por la parte superior sobre unas guías cuya posición se puede regular, además incorpora el sistema de tensión con pistones de gas.";

      //  VERSO
      case "VERSO è una tenda dedicata alla posa su balconi e finestre con braccetti a molla. Di semplice e veloce installazione prevede anche un cassonetto opzionale per la protezione del tessuto. Grazie alla conformazione del braccio si adatta particolarmente alla installazione a nicchia.":
        return "VERSO es un toldo de ventana con brazos tensionados. Fácil y rapida instalación, con o sin cofre para la protección de su lona. Gracias a la forma de su brazo se adapta particularmente bien entre paredes.";
      case "VERSO è una tenda dedicata alla posa su balconi e finestre con braccetti a molla. Di semplice e veloce installazione prevede anche un cassonetto opzionale per la protezione del tessuto.":
        return "VERSO es un toldo de ventana con brazos tensionados. Fácil y rapida instalación, con o sin cofre para la protección de su lona.";

      //  GLASS
      case "Binario inferiore alto soli 18 mm. Movimentazione delle ante a sinistra o a destra, con cambio verso rapido anche in fase d’opera. Regolazione in altezza fino a 8 mm per ogni singola anta. Sistema scomponibile per una facile pulizia. In presenza di forti piogge o piogge accompagnate da vento non è garantita la completa impermeabilità.":
        return "Carril inferior de solo 18 mm de altura. Desplazamiento de las puertas hacia la izquierda o la derecha, con cambio de sentido rápido incluso en fase de instalación. Regulación en altura hasta 8 mm para cada puerta. Sistema desmontable para una limpieza fácil. En presencia de fuertes lluvias o lluvias acompañadas de viento no se asegura la completa estanqueidad.";
      case "Binario inferiore alto soli 18 mm. Movimentazione delle ante a sinistra o a destra, con cambio verso rapido anche in fase d’opera.":
        return "Carril inferior de solo 18 mm de altura. Desplazamiento de las puertas hacia la izquierda o la derecha, con cambio de sentido rápido incluso en fase de instalación.";

      //  130 GPZ I
      case "Tenda con cassonetto da 130mm disponibile nelle varianti Quadro e Tondo dotata di sistema di guida ZIP, composta da due profili con compensazione fino ad un max di 17 mm su ogni guida. Adatta per essere installata ad incasso e per applicazioni che presentano fuori squadra.":
        return "Toldo con cofre de 130mm disponible en las variantes Cuadradro y Redondo, dotada de sistema guía ZIP, hecha por dos perfiles con compensación hasta 17mm en cada guía. Adapta para ser instalada en nicho y en aplicaciones que presenten fueras escuadras.";

      //  110 GC
      case "Tenda con cassonetto da 110mm disponibile nelle varianti Quadro e Tondo, dotata di guida cavetto inox Ø4mm.":
        return "Toldo con cofre de 110mm disponible en las variantes Cuadrado y Redondo, dotada de guía con cable inox Ø4mm.";

      //  110 GC CABRIO
      case "Tenda senza cassonetto, dotata di guida cavetto inox Ø4mm. Realizzabile sia nella versione singola che doppia.":
        return "Toldo sin cofre, dotada de guía con cable inox Ø4mm. Realizable tanto en las versiones simple como doble.";

      //  150 GPZ UNICA
      case "Tenda con cassonetto da 150mm disponibile nella sola variante Tondo, dotata di sistema di guida ZIP. Adatta per installazioni su grandi fori luce, sia a nicchia che a parete. La guida è composta da due profili: supporto e copertina; è veloce ed agevole da installare ed ispezionare.":
        return "Toldo con cofre de 150mm disponible en la sola variante Redondo, dotada de sistema de guía ZIP. Adaptada para instalaciones con grandes luces, sea entreparedes o a frontal. La guía está compuesta de dos perfiles: soporte y cubierta; es rápida y ágil de instalar e inspeccionar.";

      //  130 GPZ MB
      case "Tenda con cassonetto da 130mm disponibile nella sola variante Quadro, dotata di guida profilo da incasso con sistema ZIP e cassonetto autoportante. Adatta per l’integrazione nei sistemi per serramento, migliorando il risparmio energetico.":
        return "Toldo con cofre de 130mm disponible en la sola variante Cuadrado, dotada de guía perfil con sistema ZIP y cofre autoportante. Adaptada para su integración en los sistemas de cerramiento mejorando la eficiencia energética.";

      //  130 GA
      case "Tenda con cassonetto da 130mm disponibile nelle varianti Quadro e Tondo, dotata di guida asta inox Ø10mm.":
        return "Toldo con cofre de 130mm disponible en las variantes Cuadrado y Redondo, dotada de sistema de guía varilla inox Ø10mm.";

      //  130 GPZ UNICA M
      case "Tenda con cassonetto da 130mm disponibile nelle varianti Quadro e Tondo dotata di sistema di guida ZIP. Adatta per installazioni su infissi, sia a nicchia che a parete; veloce ed agevole da installare ed ispezionare. La guida è composta da due profili: supporto e copertina; è sviluppata per i soli sistemi motorizzati riducendo la larghezza a 36 mm.":
        return "Toldo con cofre de 130mm disponible en las variantes Cuadrado y Redondo, dotada de sistema de guía ZIP. Adaptada para instalaciones sobre fijos, sea entreparedes o a frontal, rápida y ágil de instalar y inspeccionar. La guía está compuesta por dos perfiles: soporte y cubierta, está desarrollada para sistemas tanto manuales como motorizados.";
      case "Tenda con cassonetto da 130mm disponibile nelle varianti Quadro e Tondo dotata di sistema di guida ZIP.":
        return "Toldo con cofre de 130mm disponible en las variantes Cuadrado y Redondo, dotada de sistema de guía ZIP.";

      //  110 GPZ UNICA AM
      case "Tenda con cassonetto da 110mm disponibile nelle varianti Quadro e Tondo dotata di sistema di guida ZIP e cassonetto autoportante. Adatta per installazioni su infissi, sia a nicchia che a parete; veloce ed agevole da installare ed ispezionare. La guida è composta da due profili: supporto e copertina; è sviluppata per sistemi sia ad argano che motorizzati.":
        return "Toldo con cofre de 110mm disponible en las variantes Cuadrado y Redondo, dotada de sistema de guía ZIP y cofre autoportante. Adaptada para instalaciones sobre fijos, sea entreparedes o a frontal, rápida y ágil de instalar y inspeccionar. La guía está compuesta por dos perfiles: soporte y cubierta, está desarrollada para sistemas tanto manuales como motorizados.";
      case "Tenda con cassonetto da 110mm disponibile nelle varianti Quadro e Tondo dotata di sistema di guida ZIP e cassonetto autoportante. Adatta per installazioni su infissi, sia a nicchia che a parete; veloce ed agevole da installare ed ispezionare.":
        return "Toldo con cofre de 110mm disponible en las variantes Cuadrado y Redondo, dotada de sistema de guía ZIP y cofre autoportante. Adaptada para instalaciones sobre fijos, sea entreparedes o a frontal, rápida y ágil de instalar y inspeccionar.";

      //  130 GPZ TO
      case "Tenda con cassonetto da 130mm disponibile nella sola variante Quadro, dotata di sistema di guida ZIP. Adatta, grazie al sistema di tensionamento , per installazioni come copertura dei giardini d’inverno, dei tetti in vetro e delle verande in generale. Funzionamento esclusivamente motorizzato.":
        return "Toldo con cofre de 130mm disponible en la sola variante Cuadrado, dotada de sistema de guía ZIP. Gracias al sistema de tensionamiento, es adapta a cubrir jardines de invierno, techos de vidrio o de monteras en general. El funcionameinto es sólo con motor.";
      case "Tenda con cassonetto da 130mm disponibile nella sola variante Quadro, dotata di sistema di guida ZIP.":
        return "Toldo con cofre de 130mm disponible en la sola variante Cuadrado, dotada de sistema de guía ZIP.";

      default:
        return entry;
    }
  }
  if (language === "fr") {
    switch (entry) {
      case "Dopo aver cambiato lingua, lo showroom verrà ricaricato.":
        return "Après le changement de langue, la salle d'exposition sera rechargée.";
      //  MENU
      case "APERTO":
        return "OUVRIR";
      case "CHIUSO":
        return "FERMÉ";
      case "MAPPA":
        return "CARTE";
      case "POSIZIONE":
        return "POSITION";
      case "LINGUA":
        return "LANGUE";
      case "PRODOTTI":
        return "PRODUITS";
      case "PERCORSO":
        return "ROUTE";
      case "COLORI":
        return "COLEURS";

      //  LANGUAGES
      case "Italiano":
        return "Italien";
      case "Inglese":
        return "Anglais";
      case "Spagnolo":
        return "Espagnol";
      case "Francese":
        return "Français";
      case "Svedese":
        return "Suédois";

      //  INFO
      case "Scheda Tecnica":
        return "Fiche technique";

      //  FORM
      case "Vuoi più informazioni sui prodotti BAT?":
        return "Vous voulez plus d'informations sur les produits BAT?";
      case "Scrivici: ti offriamo un preventivo gratuito e senza impegno.":
        return "Écrivez-nous : nous vous offrons un devis gratuit et sans engagement.";
      case "Ti rispondiamo entro 24/48H":
        return "Nous répondons dans les 24/48H";
      case "Qualcosa è andato storto.":
        return "Quelque chose a mal tourné.";
      case "Grazie per averci contattato!":
        return "Merci de nous avoir contactés!";
      case "Il tuo nome":
        return "Votre nom";
      case "Il tuo indirizzo e-mail *":
        return "Votre adresse électronique *";
      case "Telefono *":
        return "Téléphone *";
      case "La tua città *":
        return "Votre ville *";
      case "Vuoi raccontarci la tua esigenza?":
        return "Vous voulez nous parler de votre besoin?";
      case "Acconsento al trattamento dei miei dati personali":
        return "J'accepte le traitement de mes données personnelles";
      case "Regolamento UE 2016/679":
        return "Règlement européen 2016/679";
      case "Desidero un preventivo gratuito":
        return "Je souhaite un devis gratuit";
      case "Riceverai una risposta entro 24-48H":
        return "Vous recevrez une réponse dans les 24-48H";

      //  HELP
      case "Ambiente a 360°":
        return "Environnement à 360°";
      case "È possibile esplorare liberamente l'ambiente cliccando e trascinando il cursore, spostandosi attraverso i punti di movimento.":
        return "Vous pouvez explorer librement l'environnement en cliquant et en faisant glisser le curseur, en vous déplaçant grâce aux points de mouvement.";
      case "Interattivo":
        return "Interactive";
      case "In ogni prodotto sono disponibili vari punti di informazione, con tutti i materiali relativi al prodotto che si sta visualizzando.":
        return "Dans chaque produit, il y a plusieurs points d'information, avec tous les matériaux liés au produit que vous consultez.";
      case "Immersivo":
        return "Immersive";
      case "È possibile ingrandire o rimpicciolire a piacimento le immagini, per una visione più specifica del prodotto.":
        return "Vous pouvez zoomer et dézoomer les images à volonté, pour obtenir une vue plus précise du produit.";
      case "Immagine non trovata. Verrai riportato all'introduzione.":
        return "Image non trouvée. Vous serez ramené à l'introduction.";
      case "Cambia Posizione":
        return "Changement de position";

      //  INTRO
      case "Introduzione":
        return "Introduction";

      //  DOMEA
      case "DOMEA, disegnata da Robby Cantarutti, designer di fama internazionale, è il primo prodotto della nuova “feel-experience” di BAT. Le sue forme accattivanti, che richiamano la tradizione e simboleggiano un connubio perfetto tra innovazione e classicità, forte di nuovi contenuti tecnologici, fanno di DOMEA uno dei prodotti di punta della gamma delle tende a cassonetto. Caratterizzata dall’estrema facilità di montaggio, regolazione e installazione, garantisce standard di sicurezza e qualità elevati per un modello di questa categoria.":
        return "DOMEA, dessiné par Robby Cantarutti, designer de notoriété internationale, est le premier produit de la “feel-experience” de BAT. Ses formes attirantes, qui rappelle la tradition et symbolise l’union entre l’innovation et le classisisme. Domea intégre toutes les dernières technologies. DOMEA est un produit haut de gamme dans la ligne coffre. Il est caractérisé par son extrême facilité de montage, de règlage et d’installation. Il garantit un niveau de fiabilité et de qualité.";
      case "DOMEA, disegnata da Robby Cantarutti, designer di fama internazionale, è il primo prodotto della nuova “feel-experience” di BAT. Le sue forme accattivanti, che richiamano la tradizione e simboleggiano un connubio perfetto tra innovazione e classicità, forte di nuovi contenuti tecnologici, fanno di DOMEA uno dei prodotti di punta della gamma delle tende a cassonetto.":
        return "DOMEA, dessiné par Robby Cantarutti, designer de notoriété internationale, est le premier produit de la “feel-experience” de BAT. Ses formes attirantes, qui rappelle la tradition et symbolise l’union entre l’innovation et le classisisme. DOMEA intégre toutes les dernières technologies. DOMEA est un produit haut de gamme dans la ligne coffre.";

      // BASE PLUS
      case "BASE PLUS è una tenda a bracci estensibili con cassonetto su supporti laterali fissi con contropiastra di ancoraggio, bracci Balteus o spin. Il cassonetto, ispezionabile, viene fornito in tre profili separati. I supporti consentono di installare la tenda sia a parete che a soffitto e la regolazione può essere eseguita in un unica soluzione grazie al sistema con vite passante. Tutti i componenti sono realizzati in alluminio verniciato che garantiscono resa estetica e durabilità.":
        return "BASE PLUS est un store coffre à bras extensibles qui est fixé sur deux supports latéraux avec une contre plaque de fixation. Celui-ci est fourni en trois profils séparés et les supports peuvent être fixés au mur ou au plafond. Tous les composants sont réalisés en Aluminium ce qui garantit un produit de qualité et durable";
      case "BASE PLUS è una tenda a bracci estensibili con cassonetto su supporti laterali fissi con contropiastra di ancoraggio, bracci Balteus o spin. Il cassonetto, ispezionabile, viene fornito in tre profili separati.":
        return "BASE PLUS est un store coffre à bras extensibles qui est fixé sur deux supports latéraux avec une contre plaque de fixation. Celui-ci est fourni en trois profils séparés et les supports peuvent être fixés au mur ou au plafond.";

      //  ARMONY PLUS
      case "ARMONY PLUS é una tenda a bracci estensibili con cassonetto a scomparsa totale. Design e stile uniti alla funzionalità e alla tecnologia di un sistema ribaltabile BAT che permette la chiusura ermetica del cassonetto anche nella sua massima inclinazione inoltre, grazie ad un profilo di guarnizione il cassonetto risulta essere perfettamente aderente al muro impedendo l’infiltrazione di acque ed impurità.":
        return "ARMONY PLUS est un store coffre intégral. Design et style unis à la fonction et technologie d’un système renversable BAT qui permet la fermeture hermétique du coffre même dans le cas d’une inclinaison maxi, entre autre, grâce à son joint de butée le coffre sera complètement adhérent au mur en évitant l’infiltration d’eau et des impuretés.";
      case "ARMONY PLUS é una tenda a bracci estensibili con cassonetto a scomparsa totale.":
        return "ARMONY PLUS est un store coffre intégral.";

      //  BASE LITE
      case "BASE LITE è una tenda a bracci estensibili con cassonetto su supporti laterali fissi con contropiastra di ancoraggio, bracci balteus lite, doppio cavetto inguainato o a cinghia. I supporti consentono di installare la tenda sia a parete che a soffitto e la regolazione può essere eseguita in un unica soluzione grazie al sistema con vite passante. Tutti i componenti sono realizzati in alluminio verniciato che garantiscono resa estetica e durabilità.":
        return "BASE LITE est un store coffre à bras extensibles fixé sur deux supports latéraux Utilisé avec des bras de la gamme “LITE” à câble. Les supports consentent un montage au mur ou plafond et le réglage peut être fait d’une seule fois, graçe au système vis passante Tous les composants sont réalisées en aluminium laqué qui garantissent une performance esthétique et durée.";
      case "BASE LITE è una tenda a bracci estensibili con cassonetto su supporti laterali fissi con contropiastra di ancoraggio, bracci balteus lite, doppio cavetto inguainato o a cinghia.":
        return "BASE LITE est un store coffre à bras extensibles fixé sur deux supports latéraux Utilisé avec des bras de la gamme “LITE” à câble.";

      //  EPICA
      case "EPICA è una tenda a bracci estensibili con cassonetto, caratterizzata da linee semplici ed essenziali. Gli elementi di fissaggio e le viterie sono totalmente nascosti alla vista. Pur avendo ingombri contenuti, raggiunge una sporgenza di 410cm con braccio Giant. Il prodotto viene completato con un’ampia gamma di optional come il kit luci e il terminale a cassonetto.":
        return "EPICA est un store coffre à bras extensibles caractérisé par un design simple et essentiel. Les éléments de fixation et les vis sont complètement cachés à la vue. Malgré les petites dimensions, il a une avancée de 410cm avec le bras Giant. Le produit se complète avec une grande variété d’options comme par exemple le kit illumination et la barre de charge coffre.";
      case "EPICA è una tenda a bracci estensibili con cassonetto, caratterizzata da linee semplici ed essenziali. Gli elementi di fissaggio e le viterie sono totalmente nascosti alla vista.":
        return "EPICA est un store coffre à bras extensibles caractérisé par un design simple et essentiel. Les éléments de fixation et les vis sont complètement cachés à la vue.";

      //  EPICA LITE
      case "EPICA LITE è una tenda a bracci estensibili con cassonetto su supporti laterali fissi con contropiastra di ancoraggio, bracci Balteus o spin. Il cassonetto, ispezionabile, viene fornito in tre profili separati. I supporti consentono di installare la tenda sia a parete che a soffitto e la regolazione può essere eseguita in un’unica soluzione grazie al sistema con vite passante.":
        return "EPICA LITE est un store avec coffre reposant sur deux supports latéraux d'accrochage muraux. Il est équipé de bras Balteus et son coffre inspectable est fourni en trois profils différents. Les supports permettent une intallation mural ou en plafond, le réglage peut être réalisé simplement grâce à un innovant système de vissage.";
      case "EPICA LITE è una tenda a bracci estensibili con cassonetto su supporti laterali fissi con contropiastra di ancoraggio, bracci Balteus o spin.":
        return "EPICA LITE est un store avec coffre reposant sur deux supports latéraux d'accrochage muraux.";

      //  ELY
      case "ELY è una nuova tenda verticale per uso esterno. Grazie alla sua struttura lineare, alla facilità di assemblaggio e installazione e all’innovativo ed accattivante design, Ely risulta essere la soluzione ideale per soddisfare le più esigenti richieste di protezione solare ed arredo esterno.":
        return "ELY est un nouveau store vertical pour extérieur. Grâce à sa structure, à sa facilité d’assemblage, d’installation et à son nouveau design, il est la solution idéale pour satisfaire les solutions les plus exigeantes de protection solaire et ameublement extérieur.";

      //  GIANT
      case "GIANT BQ é una tenda a bracci su barra quadra in acciaio 50mm. I supporti in alluminio estruso garantiscono resistenza e compattezza e i tappi in alluminio verniciati ne caratterizzano eleganza e discrezione. Compattezza e design la rendono perfetta per coperture di terrazze pubbliche e per protezione di spazi e patii privati. Dispone di un braccio tipo spin, creato “ad hoc” con snodi e spalla forgiati.":
        return "GIANT BQ est un store banne sur barre carrée en acier 50mm. Les supports en aluminium extrudés garantissent résistance et volume. Les bouchons en aluminium laqués caractérisent son élégance. Volume et design en font une couverture parfaite pour les terrasses publiques et les espaces privés. Le store dispose d’un bras Spin, crée spécialement avec un coude et une épaule forgé.";
      case "GIANT BQ é una tenda a bracci su barra quadra in acciaio 50mm. I supporti in alluminio estruso garantiscono resistenza e compattezza e i tappi in alluminio verniciati ne caratterizzano eleganza e discrezione.":
        return "GIANT BQ est un store banne sur barre carrée en acier 50mm. Les supports en aluminium extrudés garantissent résistance et volume. Les bouchons en aluminium laqués caractérisent son élégance.";

      //  ZENITH
      case "ZENITH è una tenda a bracci con cassonetto fisso su barra rettangolare 50x30mm a scomparsa totale. Funzionale ed affidabile è dotata di sistema ribaltabile BAT che garantisce la perfetta chiusura del terminale anche in caso di massima inclinazione.":
        return "ZENITH est un store coffre intégral avec une barre rectangulaire 50x30mm. Fonctionnel et fiable, ce store est doté de supports à système basculant BAT qui garantissent une fermeture parfaite de la barre de charge même dans le cas d’une inclinaison maximale.";
      case "ZENITH è una tenda a bracci con cassonetto fisso su barra rettangolare 50x30mm a scomparsa totale.":
        return "ZENITH est un store coffre intégral avec une barre rectangulaire 50x30mm.";

      //  MINI HELIX BQ
      case "MINI HELIX BQ è una tenda a bracci su barra quadra da 40 in acciaio zincato e verniciato. Sorella minore dell’ormai nota Helix BQ, dalla quale eredita l’apprezzato sistema di regolazione, si caratterizza da una spiccata personalità, data dal design pulito, estremamente sobrio e gradevole; è compatibile con le braccia della famiglia ECO, sia a cinghia che doppio cavetto. Come optional può essere proposta con il supporto avvolgitore GOLD, che ne completa la finitura, rendendola veramente unica nel suo genere.":
        return "MINI HELIX BQ est un store monobloc sur une barre carrée 40mm en acier galvanisé laqué. Frère cadet et connu de l’Helix BQ il hérite du système de réglage apprécié. Il est caractérisé par une forte personnalité, donné par un design épuré, très simple et agréable. Il est compatible avec les bras de la famille ECO, à double câble. En option le support de tube Gold complète la finition et le rend vraiment unique.";
      case "MINI HELIX BQ è una tenda a bracci su barra quadra da 40 in acciaio zincato e verniciato. Sorella minore dell’ormai nota Helix BQ, dalla quale eredita l’apprezzato sistema di regolazione, si caratterizza da una spiccata personalità, data dal design pulito, estremamente sobrio e gradevole; è compatibile con le braccia della famiglia ECO, sia a cinghia che doppio cavetto.":
        return "MINI HELIX BQ est un store monobloc sur une barre carrée 40mm en acier galvanisé laqué. Frère cadet et connu de l’Helix BQ il hérite du système de réglage apprécié. Il est caractérisé par une forte personnalité, donné par un design épuré, très simple et agréable. Il est compatible avec les bras de la famille ECO, à double câble.";

      // MINI HELIX PS
      case "MINI HELIX PS è una tenda a supporti indipendenti. Concettualmente riprende le caratteristiche tecnico- meccaniche della sorella maggiore, Helix PS, come l’estrema facilità e versatilità di installazione e regolazione. Caratterizzata da un disegno gradevole e decisamente contemporaneo, al quale è stata sottoposta un’attenzione maniacale, è compatibile con le braccia della famiglia ECO, sia a cinghia che doppio cavetto.":
        return "MINI HELIX PS est un store avec des supports indépendants. Conceptuellement il reprend les caractéristiques techniques et mécaniques de son frère aîné, l’Helix PS, tels que la facilité et la polyvalence d’installation et de réglage. Caractérisé par un design agréable et contemporain. Il est compatible avec les bras de la famille ECO, à double câble.";
      case "MINI HELIX PS è una tenda a supporti indipendenti. Concettualmente riprende le caratteristiche tecnico- meccaniche della sorella maggiore, Helix PS, come l’estrema facilità e versatilità di installazione e regolazione.":
        return "MINI HELIX PS est un store avec des supports indépendants. Conceptuellement il reprend les caractéristiques techniques et mécaniques de son frère aîné, l’Helix PS, tels que la facilité et la polyvalence d’installation et de réglage.";

      //  HELIX BQ
      case "HELIX BQ tenda su barra quadra 40mm; è caratterizzata da un supporto in pressofusione, del tipo cosidetto a “cono” dal disegno curato e dalle prestazioni importanti: inclinazione in continuo, particolarmente agevole, da 0° a 90° gradi, sporgenza fino a 3,60m. In abbinamento con il supporto avvolgitore Gold, risulta essere una delle barre quadre più accattivanti presenti nel mercato.":
        return "HELIX BQ store monobloc sur barre carrée 40mm, il est caractérisé par son support en injection, de type”cone”, son design est soigné. Ces prestations sont importantes: inclinaison continue, très pratique, de 0° à 90° degrés, projection jusqu’à 3.60m. Le support Gold est adaptable, et le rend alors très élégant.";
      case "HELIX BQ tenda su barra quadra 40mm; è caratterizzata da un supporto in pressofusione, del tipo cosidetto a “cono” dal disegno curato e dalle prestazioni importanti: inclinazione in continuo, particolarmente agevole, da 0° a 90° gradi, sporgenza fino a 3,60m.":
        return "HELIX BQ store monobloc sur barre carrée 40mm, il est caractérisé par son support en injection, de type”cone”, son design est soigné. Ces prestations sont importantes: inclinaison continue, très pratique, de 0° à 90° degrés, projection jusqu’à 3.60m.";

      //  HELIX PS
      case "HELIX PS tenda a supporti indipendenti; è caratterizzata da un supporto in pressofusione, del tipo cosidetto a 'cono' dal disegno curato e dalle prestazioni importanti: inclinazione in continuo, particolarmente agevole, da 0° a 90°. Versatile e facile da installare, dal design robusto ma al contempo elegante.":
        return "HELIX PS est un store indépendant, il est caractérisé par son support fabriqué par injection, de type”cone”, son design est soigné. Ces prestations sont nombreuses: inclinaison continue, de 0° à 90°, une projection jusqu’à 3,60m. Une facilité d’installation pour combler l’élégance et la robustesse.";
      case "HELIX PS tenda a supporti indipendenti; è caratterizzata da un supporto in pressofusione, del tipo cosidetto a 'cono' dal disegno curato e dalle prestazioni importanti: inclinazione in continuo, particolarmente agevole, da 0° a 90°.":
        return "HELIX PS est un store indépendant, il est caractérisé par son support fabriqué par injection, de type”cone”, son design est soigné. Ces prestations sont nombreuses: inclinaison continue, de 0° à 90°, une projection jusqu’à 3,60m.";

      //  2010
      case "2010 è una tenda a bracci estensibili compatta su barra quadra 40mm in acciaio zincato e verniciato con bracci a doppio cavetto inguainato o cinghia e supporto braccio con regolazione a cono":
        return "2010 est un store à bras extensibles compact et installé sur une barre carrée 40mm en acier zingué laqué avec des bras à double câble gainés ou avec une chaîne en inox. Il comprend un support de bras avec réglage conique.";

      //  2020
      case "2020 è una tenda a bracci estensibili compatta su barra quadra 40mm in acciaio zincato e verniciato, bracci a doppio cavetto inguainato o a cinghia e supporto braccio in estrusione di alluminio. E’ una tenda modulare: aggiungendo ulteriori profili si può raggiungere una larghezza maggiore. Il terminale frontale è intercambiabile con gli altri terminali BAT. Dispone come optional di un tettuccio di copertura in alluminio estruso e di una staffa speciale per il montaggio sottotetto.":
        return "2020 est un store à bras extensibles compact et installé sur une barre carrée 40mm en acier zingué laqué avec des bras à double câble gainés ou ave une chaîne en inox et comprenant un support pour le bras en aluminium extrudé. Le store devient modulaire: en ajoutant d’autres profils. Afin d’obtenir la dimension souhaitée. La barre de charge frontale est compatible avec d’autres Modèles BAT.";
      case "2020 è una tenda a bracci estensibili compatta su barra quadra 40mm in acciaio zincato e verniciato, bracci a doppio cavetto inguainato o a cinghia e supporto braccio in estrusione di alluminio. E’ una tenda modulare: aggiungendo ulteriori profili si può raggiungere una larghezza maggiore.":
        return "2020 est un store à bras extensibles compact et installé sur une barre carrée 40mm en acier zingué laqué avec des bras à double câble gainés ou ave une chaîne en inox et comprenant un support pour le bras en aluminium extrudé. Le store devient modulaire: en ajoutant d’autres profils.";

      //  2040
      case "2040 è una tenda a bracci estensibili su barra quadra 40mm in acciaio zincato e verniciato con cassonetto a scomparsa parziale e con bracci a doppio cavetto inguainato o a cinghia. Questo modello viene fornito di un supporto braccio basculante brevettato che consente di regolare la tenda senza dover inclinare il cassonetto.":
        return "2040 est un store banne à bras extensibles sur barre carrée 40mm en acier zingué et laqué, avec des bras à double câble gainé ou chaîne inox avec un semi coffre. Le support de bras à basculement breveté permet le réglage de l’inclinaison du store sans modifier la position du coffre.";
      case "2040 è una tenda a bracci estensibili su barra quadra 40mm in acciaio zincato e verniciato con cassonetto a scomparsa parziale e con bracci a doppio cavetto inguainato o a cinghia.":
        return "2040 est un store banne à bras extensibles sur barre carrée 40mm en acier zingué et laqué, avec des bras à double câble gainé ou chaîne inox avec un semi coffre.";

      //  OMBRALSUN
      case "OMBRALSUN, per la sue dimensioni, compattezza e facilità di installazione e regolazione, si posiziona sicuramente in vetta alla già ampia gamma dei prodotti per terrazzi e patio completandone la gamma. Grazie ad un solo tubo avvolgitore “Rollerbat” Ø 80mm, riesce a coprire una superficie di ca 36m2, mantenendo comunque contenuti gli ingombri; supporti e staffe di ancoraggio sono studiati perché tutte le installazioni siano semplici e veloci. Il sistema è completato da un cassonetto integrale che racchiude, una volta ritratti, entrambi i lati della tenda.":
        return "OMBRALSUN, par ses dimensions, sa facilité d’installation et de réglage, il se positionne au sommet de la gamme de produit pour terrasse et patio. Il est doté d’un seul tube Ø 80mm rollerbat et couvre une surface de 36m2, en ayant des encombrements réduits. Les pieds sont conçus pour une simplicité et facilité de pose. Le système est complété par un coffre intégral, qui une fois fermé protège les deux cotés du stores.";
      case "OMBRALSUN, per la sue dimensioni, compattezza e facilità di installazione e regolazione, si posiziona sicuramente in vetta alla già ampia gamma dei prodotti per terrazzi e patio completandone la gamma.":
        return "OMBRALSUN, par ses dimensions, sa facilité d’installation et de réglage, il se positionne au sommet de la gamme de produit pour terrasse et patio.";

      //  SIRIO
      case "SIRIO Struttura di copertura per giardini d’inverno, tetti in vetro e verande. Dotata di sistema di guida Zip e di sistema di tensionamento del telo con molla. Presenza di optional luci e terminale a cassonetto. Funzionamento esclusivamente motorizzato.":
        return "SIRIO WALL Structure de couverture pour terrasses et vérandas. Equipé d’un système de guide Zip et d’un système de tension avec ressort. Possibilité d’éclairage et barre de charge coffre en option et terminal de boîte. Peut être installé aussi bien au mur qu’au plafond. Il repose en façade sur deux colonnes, réglables en saillie pour répondre à toutes les demandes possibles. Fonctionnement motorisé uniquement.";
      case "SIRIO Struttura di copertura per giardini d’inverno, tetti in vetro e verande. Dotata di sistema di guida Zip e di sistema di tensionamento del telo con molla.":
        return "SIRIO WALL Structure de couverture pour terrasses et vérandas. Equipé d’un système de guide Zip et d’un système de tension avec ressort.";

      //  VERSO
      case "VERSO è una tenda dedicata alla posa su balconi e finestre con braccetti a molla. Di semplice e veloce installazione prevede anche un cassonetto opzionale per la protezione del tessuto. Grazie alla conformazione del braccio si adatta particolarmente alla installazione a nicchia.":
        return "VERSO est un store conçu pour une installation sur les balcons et sur les fenêtres. Montage facile et rapide. Il dispose également d’un coffre de protection pour le tissu. Grâce à la forme des bras, il peut être installé en tableau.";
      case "VERSO è una tenda dedicata alla posa su balconi e finestre con braccetti a molla. Di semplice e veloce installazione prevede anche un cassonetto opzionale per la protezione del tessuto.":
        return "VERSO est un store conçu pour une installation sur les balcons et sur les fenêtres. Montage facile et rapide. Il dispose également d’un coffre de protection pour le tissu.";

      //  130 GPZ I
      case "Tenda con cassonetto da 130mm disponibile nelle varianti Quadro e Tondo dotata di sistema di guida ZIP, composta da due profili con compensazione fino ad un max di 17 mm su ogni guida. Adatta per essere installata ad incasso e per applicazioni che presentano fuori squadra.":
        return "Store avec un coffre de 130mm disponible dans les variantes Carré et Rond equipé d’un système Zip, composé par deux profils qui compense jusqu’à 17 mm par chaque coulisse. Adaptable sur des constructions avec réservation et coulisse permettant une pose sur des murs avec faux équerrages.";

      //  110 GC
      case "Tenda con cassonetto da 110mm disponibile nelle varianti Quadro e Tondo, dotata di guida cavetto inox Ø4mm.":
        return "Store avec un coffre de 110mm disponible dans les variantes Carré et Rond, equipé de guide câble inox Ø4mm.";

      //  110 GC CABRIO
      case "Tenda senza cassonetto, dotata di guida cavetto inox Ø4mm. Realizzabile sia nella versione singola che doppia.":
        return "Store avec un coffre de 130mm disponible dans les variantes Carré et Rond, equipé de guide câble inox Ø4mm.";

      //  150 GPZ UNICA
      case "Tenda con cassonetto da 150mm disponibile nella sola variante Tondo, dotata di sistema di guida ZIP. Adatta per installazioni su grandi fori luce, sia a nicchia che a parete. La guida è composta da due profili: supporto e copertina; è veloce ed agevole da installare ed ispezionare.":
        return "150mm cassette screen available in Round version only, equipped with ZIP guide system. Suitable for both niche and wall installation in big opening; fast and easy to install and inspect. The guide is composed by two profiles: support and cover; fast and easy to install and inspect.";

      //  130 GPZ MB
      case "Tenda con cassonetto da 130mm disponibile nella sola variante Quadro, dotata di guida profilo da incasso con sistema ZIP e cassonetto autoportante. Adatta per l’integrazione nei sistemi per serramento, migliorando il risparmio energetico.":
        return "Store avec cassette de 130 mm disponible uniquement dans la version Quadro, équipé d'un guide profilé encastré avec système ZIP et cassette autoportante. Convient à l'intégration dans les systèmes de fenêtres, améliorant ainsi les économies d'énergie.";

      //  130 GA
      case "Tenda con cassonetto da 130mm disponibile nelle varianti Quadro e Tondo, dotata di guida asta inox Ø10mm.":
        return "Store avec caisson de 130mm disponible dans les versions Quadro et Tondo, équipé d'un guide tige en acier inoxydable de Ø10mm.";

      //  130 GPZ UNICA M
      case "Tenda con cassonetto da 130mm disponibile nelle varianti Quadro e Tondo dotata di sistema di guida ZIP. Adatta per installazioni su infissi, sia a nicchia che a parete; veloce ed agevole da installare ed ispezionare. La guida è composta da due profili: supporto e copertina; è sviluppata per i soli sistemi motorizzati riducendo la larghezza a 36 mm.":
        return "Store avec un coffre de 130mm disponible dans les variantes Carré et Rond, equipé d’un système de guide ZIP. Il s’adapte à tous les types d’installations. Il est rapide et facile à installer et inspecter. Le guide est composé de deux profils: porteur et couverture; il a été développé pour seulement les systèmes motorisés en reduisant la largeur de 36 mm";
      case "Tenda con cassonetto da 130mm disponibile nelle varianti Quadro e Tondo dotata di sistema di guida ZIP.":
        return "Store avec un coffre de 130mm disponible dans les variantes Carré et Rond, equipé d’un système de guide ZIP.";

      //  110 GPZ UNICA AM
      case "Tenda con cassonetto da 110mm disponibile nelle varianti Quadro e Tondo dotata di sistema di guida ZIP e cassonetto autoportante. Adatta per installazioni su infissi, sia a nicchia che a parete; veloce ed agevole da installare ed ispezionare. La guida è composta da due profili: supporto e copertina; è sviluppata per sistemi sia ad argano che motorizzati.":
        return "Store avec un coffre de 110mm disponible dans les variantes Carré et Rond, equipé d’un système de guide libre et d’un coffre autoportante. Il s’adapte à tous les types d’installations. Il est rapide et facile à installer et inspecter. Le guide est composé de deux profils: porteur et couverture; il a été développé pour des systèmes à treuil ou motorisé.";
      case "Tenda con cassonetto da 110mm disponibile nelle varianti Quadro e Tondo dotata di sistema di guida ZIP e cassonetto autoportante. Adatta per installazioni su infissi, sia a nicchia che a parete; veloce ed agevole da installare ed ispezionare.":
        return "Store avec un coffre de 110mm disponible dans les variantes Carré et Rond, equipé d’un système de guide libre et d’un coffre autoportante. Il s’adapte à tous les types d’installations. Il est rapide et facile à installer et inspecter.";

      //  130 GPZ TO
      case "Tenda con cassonetto da 130mm disponibile nella sola variante Quadro, dotata di sistema di guida ZIP. Adatta, grazie al sistema di tensionamento , per installazioni come copertura dei giardini d’inverno, dei tetti in vetro e delle verande in generale. Funzionamento esclusivamente motorizzato.":
        return "Store avec un coffre de 130mm est disponible dans la seule variante Carré, equipé d’un système de guide ZIP. Grâce à son système de tension, il s’adapte pour les installations sur des vérandas d’hiver, toits en verre et vérandas en général. Fonctionnement uniquement motorisé.";
      case "Tenda con cassonetto da 130mm disponibile nella sola variante Quadro, dotata di sistema di guida ZIP.":
        return "Store avec un coffre de 130mm est disponible dans la seule variante Carré, equipé d’un système de guide ZIP.";

      //  150 STORM
      case "Tenda con cassonetto da 110mm, 130mm disponibile nelle varianti Quadro e Tondo, con cassonetto da 150 mm disponibile nella sola variante Tondo, dotata di sistema di guida ZIP. Adatta per installazioni sia a nicchia che a parete; veloce ed agevole da installare ed ispezionare. La guida è composta da due profili: supporto e copertina ed è studiata per accogliere il profilo terminale che vi rimane connesso sia sotto l’azione di elevate pressioni del vento sia sotto l’azione di urti. È sviluppata per sistemi sia ad argano che motorizzati.":
        return "Screeny Storm dispose d’une guide conçue pour accueillir le profil terminal qui lui reste toujours connecté aussi bien sous l’action du vent fort que sous l’action des chocs. La toile coulisse maintenue horizontalement par un guide interne résistant aux hautes températures et fixé par des ressorts de compensation en acier inoxydable. Les bouchons télescopiques ne sont pas fixés au profil, ce qui réduit par conséquent les contraintes de la toile dans les zones d’angle. Grâce à la possibilité d’ajouter des distanciels réglables, l’installation du profil guide est plus facile et plus précise.";
      case "Tenda con cassonetto da 110mm, 130mm disponibile nelle varianti Quadro e Tondo, con cassonetto da 150 mm disponibile nella sola variante Tondo, dotata di sistema di guida ZIP. Adatta per installazioni sia a nicchia che a parete; veloce ed agevole da installare ed ispezionare.":
        return "Screeny Storm dispose d’une guide conçue pour accueillir le profil terminal qui lui reste toujours connecté aussi bien sous l’action du vent fort que sous l’action des chocs. La toile coulisse maintenue horizontalement par un guide interne résistant aux hautes températures et fixé par des ressorts de compensation en acier inoxydable.";

      default:
        return entry;
    }
  }
  if (language === "sv") {
    switch (entry) {
      case "Dopo aver cambiato lingua, lo showroom verrà ricaricato.":
        return "När du har bytt språk laddas utställningsrummet om.";
      //  MENU
      case "APERTO":
        return "ÖPPEN";
      case "CHIUSO":
        return "LÖST";
      case "MAPPA":
        return "KARTA";
      case "POSIZIONE":
        return "POSITION";
      case "LINGUA":
        return "SPRAK";
      case "PRODOTTI":
        return "PRODUKTER";
      case "PERCORSO":
        return "RUTT";
      case "COLORI":
        return "FARGER";

      //  LANGUAGES
      case "Italiano":
        return "Italienska";
      case "Inglese":
        return "Engelska";
      case "Spagnolo":
        return "Spanska";
      case "Francese":
        return "Franska";
      case "Svedese":
        return "Svenska";

      //  INFO
      case "Scheda Tecnica":
        return "Tekniskt Datablad";

      //  FORM
      case "Vuoi più informazioni sui prodotti BAT?":
        return "Vill du ha mer information om BAT produkter??";
      case "Scrivici: ti offriamo un preventivo gratuito e senza impegno.":
        return "Skriv till oss: vi erbjuder dig en kostnadsfri offert utan förpliktelser.";
      case "Ti rispondiamo entro 24/48H":
        return "Vi svarar inom 24/48H timmar";
      case "Qualcosa è andato storto.":
        return "Något gick fel";
      case "Grazie per averci contattato!":
        return "Tack för att du kontaktar oss!";
      case "Il tuo nome":
        return "Ditt namn";
      case "Il tuo indirizzo e-mail *":
        return "Din e-postadress *";
      case "Telefono *":
        return "Telefon *";
      case "La tua città *":
        return "La tua città *";
      case "Vuoi raccontarci la tua esigenza?":
        return "Du vill berätta om ditt behov?";
      case "Acconsento al trattamento dei miei dati personali":
        return "Jag godkänner behandlingen av mina personuppgifter";
      case "Regolamento UE 2016/679":
        return "EU-förordning 2016/679";
      case "Desidero un preventivo gratuito":
        return "Jag vill ha en kostnadsfri offert";
      case "Riceverai una risposta entro 24-48H":
        return "Du får ett svar inom 24-48 timmar.";

      //  HELP
      case "Ambiente a 360°":
        return "360°-miljö";
      case "È possibile esplorare liberamente l'ambiente cliccando e trascinando il cursore, spostandosi attraverso i punti di movimento.":
        return "Du kan fritt utforska miljön genom att klicka och dra markören och flytta dig genom rörelsepunkterna.";
      case "Interattivo":
        return "Interaktiv";
      case "In ogni prodotto sono disponibili vari punti di informazione, con tutti i materiali relativi al prodotto che si sta visualizzando.":
        return "I varje produkt finns det olika informationspunkter med allt material som rör den produkt du tittar på.";
      case "Immersivo":
        return "Fördjupande";
      case "È possibile ingrandire o rimpicciolire a piacimento le immagini, per una visione più specifica del prodotto.":
        return "Du kan zooma in och ut på bilderna när du vill för att få en mer specifik bild av produkten.";
      case "Immagine non trovata. Verrai riportato all'introduzione.":
        return "Bilden hittades inte. Du kommer tillbaka till inledningen..";
      case "Cambia Posizione":
        return "Ändra position";

      //  INTRO
      case "Introduzione":
        return "Introduktion";

      //  EPICA
      case "EPICA è una tenda a bracci estensibili con cassonetto, caratterizzata da linee semplici ed essenziali. Gli elementi di fissaggio e le viterie sono totalmente nascosti alla vista. Pur avendo ingombri contenuti, raggiunge una sporgenza di 410cm con braccio Giant. Il prodotto viene completato con un’ampia gamma di optional come il kit luci e il terminale a cassonetto.":
        return "EPICA är en vikarmsmarkis i kassett med ren och stram design. Alla fästelement är helt dolda. Trots sin lilla storlek kan man ha upptill 410 cm utfall med Giant armar. EPICA kan kompletteras med flera tillägg såsom ljuskit och frontprofil gardin.";
      case "EPICA è una tenda a bracci estensibili con cassonetto, caratterizzata da linee semplici ed essenziali. Gli elementi di fissaggio e le viterie sono totalmente nascosti alla vista.":
        return "EPICA är en vikarmsmarkis i kassett med ren och stram design. Alla fästelement är helt dolda. Trots sin lilla storlek kan man ha upptill 410 cm utfall med Giant armar.";

      //  EPICA LITE
      case "EPICA LITE è una tenda a bracci estensibili con cassonetto su supporti laterali fissi con contropiastra di ancoraggio, bracci Balteus o spin. Il cassonetto, ispezionabile, viene fornito in tre profili separati. I supporti consentono di installare la tenda sia a parete che a soffitto e la regolazione può essere eseguita in un’unica soluzione grazie al sistema con vite passante.":
        return "Epica Lite är en terrassmarkis i helkassett med beslag i ytterkanterna och Balteus armar. Kassetten består av 3 profiler som kan öppnas för inspektion eller service efter installation. Kassetten kan monteras både på vägg och tak och inställning av vinkeln i höjdled sker med endast en enskruvs lösning.";
      case "EPICA LITE è una tenda a bracci estensibili con cassonetto su supporti laterali fissi con contropiastra di ancoraggio, bracci Balteus o spin.":
        return "Epica Lite är en terrassmarkis i helkassett med beslag i ytterkanterna och Balteus armar.";

      //  GIANT
      case "GIANT BQ é una tenda a bracci su barra quadra in acciaio 50mm. I supporti in alluminio estruso garantiscono resistenza e compattezza e i tappi in alluminio verniciati ne caratterizzano eleganza e discrezione. Compattezza e design la rendono perfetta per coperture di terrazze pubbliche e per protezione di spazi e patii privati. Dispone di un braccio tipo spin, creato “ad hoc” con snodi e spalla forgiati.":
        return "GIANT är en vikarmsmarkis med 50mm bärrör i stål. Konsoler i aluminium med styrka och kompakt utformning ger ett elegant utseende. Storleken och designen på denna markis gör den perfekt för både privat och offentlig miljö. Armarna är speciella “Spin” armar utvecklade till denna markis med gjutna armklockor och knäled.";
      case "GIANT BQ é una tenda a bracci su barra quadra in acciaio 50mm. I supporti in alluminio estruso garantiscono resistenza e compattezza e i tappi in alluminio verniciati ne caratterizzano eleganza e discrezione.":
        return "GIANT är en vikarmsmarkis med 50mm bärrör i stål. Konsoler i aluminium med styrka och kompakt utformning ger ett elegant utseende.";

      //  ZENITH
      case "ZENITH è una tenda a bracci con cassonetto fisso su barra rettangolare 50x30mm a scomparsa totale. Funzionale ed affidabile è dotata di sistema ribaltabile BAT che garantisce la perfetta chiusura del terminale anche in caso di massima inclinazione.":
        return "ZENITH är en vikarmsmarkis med helkassett med ett bärrör 50x30mm. Funktionell och pålitlig samt utrustad med en speciell tipp fuktion som garanterar perfekt stängning även vid stora utfall.";
      case "ZENITH è una tenda a bracci con cassonetto fisso su barra rettangolare 50x30mm a scomparsa totale.":
        return "ZENITH är en vikarmsmarkis med helkassett med ett bärrör 50x30mm.";

      //  2040
      case "2040 è una tenda a bracci estensibili su barra quadra 40mm in acciaio zincato e verniciato con cassonetto a scomparsa parziale e con bracci a doppio cavetto inguainato o a cinghia. Questo modello viene fornito di un supporto braccio basculante brevettato che consente di regolare la tenda senza dover inclinare il cassonetto.":
        return "2040 är en vikarmsmarkis i semikassett med 40 mm galvat och pulverlackat bärrör samt armar med band i knäled. Modellen är utrustad med ett patenterat tipp system som gör att man ändrar markisens lutningsvinkel utan att behöva justera själva kassetten.";
      case "2040 è una tenda a bracci estensibili su barra quadra 40mm in acciaio zincato e verniciato con cassonetto a scomparsa parziale e con bracci a doppio cavetto inguainato o a cinghia.":
        return "2040 är en vikarmsmarkis i semikassett med 40 mm galvat och pulverlackat bärrör samt armar med band i knäled.";

      //  OMBRALSUN
      case "OMBRALSUN, per la sue dimensioni, compattezza e facilità di installazione e regolazione, si posiziona sicuramente in vetta alla già ampia gamma dei prodotti per terrazzi e patio completandone la gamma. Grazie ad un solo tubo avvolgitore “Rollerbat” Ø 80mm, riesce a coprire una superficie di ca 36m2, mantenendo comunque contenuti gli ingombri; supporti e staffe di ancoraggio sono studiati perché tutte le installazioni siano semplici e veloci. Il sistema è completato da un cassonetto integrale che racchiude, una volta ritratti, entrambi i lati della tenda.":
        return "OMBRALSUN är en produkt i toppklass i denna kategori, tack vare den stora ytan som täcks och enkelheten i installation och inställning. Med ett gemensamt dukrör “Rollerbat” Ø 80mm kan den täcka en totalyta på 36m2. Trots små dimensioner så är fästen och och stolpar dimensionerade för belastningen och för att underlätta installationen. Systemet är av typ helkassett som gör att bägge sidor blir täckta vid stängt läge.";
      case "OMBRALSUN, per la sue dimensioni, compattezza e facilità di installazione e regolazione, si posiziona sicuramente in vetta alla già ampia gamma dei prodotti per terrazzi e patio completandone la gamma.":
        return "OMBRALSUN är en produkt i toppklass i denna kategori, tack vare den stora ytan som täcks och enkelheten i installation och inställning.";

      //  R PITCH
      case "Una tenda a bracci estensibili su barra quadra 40mm in acciaio zincato e verniciato con bracci a doppio cavetto inguainato o a cinghia e supporto braccio a cremagliera con inclinazione singola di ogni braccio. Tenda modulare: aggiungendo ulteriori profili si possono raggiungere misure diverse. Il terminale frontale è intercambiabile con quelli del catalogo BAT. Dispone come optional di un tettuccio di copertura in alluminio estruso e di una staffa speciale per il montaggio sottotetto.":
        return "R-PITCH är en vikarmsmarkis med galvat och lackat bärrör 40x40mm samt med band i knäleden. Armarna är individuellt ställbara i vinkel (pitch kontroll). Markisen kan seriekopplas eller skarvas för att nå större bredder. Frontprofilen är utbytbar till flera andra BAT frontprofiler. Markisen kan också utrustas med ett skyddstak i aluminium, special konsoler för undertaks montage och dukstöd vid större bredder.";
      case "Una tenda a bracci estensibili su barra quadra 40mm in acciaio zincato e verniciato con bracci a doppio cavetto inguainato o a cinghia e supporto braccio a cremagliera con inclinazione singola di ogni braccio.":
        return "R-PITCH är en vikarmsmarkis med galvat och lackat bärrör 40x40mm samt med band i knäleden. Armarna är individuellt ställbara i vinkel (pitch kontroll).";

      //  R 90
      case "R-90 è una tenda a bracci estensibili su barra quadra 40mm in acciaio zincato e verniciato con bracci a doppio cavetto inguainato a catena inox o a cinghia. Il sistema risulta estremamente versatile e dall’ottimo rapporto qualità prezzo. Disponibili molti optional come il tettuccio di copertura in alluminio estruso e di una staffa speciale per il montaggio sottotetto.":
        return "R-90 är en vikarmsmarkis med galvat och lackat bärrör 40x40mm samt med band i knäleden. Armarna är individuellt ställbara i vinkel (pitch kontroll). Markisen kan seriekopplas eller skarvas för att nå större bredder. Frontprofilen är utbytbar till flera andra BAT frontprofiler. Markisen kan också utrustas med ett skyddstak i aluminium, special konsoler för undertaks montage och dukstöd vid större bredder.";
      case "R-90 è una tenda a bracci estensibili su barra quadra 40mm in acciaio zincato e verniciato con bracci a doppio cavetto inguainato a catena inox o a cinghia.":
        return "R-90 är en vikarmsmarkis med galvat och lackat bärrör 40x40mm samt med band i knäleden. Armarna är individuellt ställbara i vinkel (pitch kontroll).";

      //  SPACE
      case "Struttura di copertura per verande e attici. Il tessuto scorre nella parte superiore su guide, regolabili in larghezza e contenenti il sistema di tensionamento con molle a gas. Sono disponibili degli optional come il sistema di blocco meccanico e il sistema flottante per il tubo avvolgitore. Dimensioni massime 500x700 cm e con guida centrale anche 700x700 cm.":
        return "SPACE takmodell för glastak och uterum. Duken löper horisontallt i styrskenor som är ställbara i bredd och har ett system för dukspänning med gasfjädrar. Kan utrustas med system för att mekaniskt låsa frontprofilen och ett flexande system för dukröret. Maxmått är 500x700 cm med två styrskenor och med en extra styrsken 700x700 cm.";
      case "Struttura di copertura per verande e attici. Il tessuto scorre nella parte superiore su guide, regolabili in larghezza e contenenti il sistema di tensionamento con molle a gas.":
        return "SPACE takmodell för glastak och uterum. Duken löper horisontallt i styrskenor som är ställbara i bredd och har ett system för dukspänning med gasfjädrar.";

      //  130 GPZ I
      case "Tenda con cassonetto da 130mm disponibile nelle varianti Quadro e Tondo dotata di sistema di guida ZIP, composta da due profili con compensazione fino ad un max di 17 mm su ogni guida. Adatta per essere installata ad incasso e per applicazioni che presentano fuori squadra.":
        return "110mm Screen kassett endast i rund version, utrustad med två stycken ZIP styrskenor med 17 mm kompensations möjlighet på varje styrskena.Lämplig för inbyggnads montage eller nisch.";

      //  110 GC
      case "Tenda con cassonetto da 110mm disponibile nelle varianti Quadro e Tondo, dotata di guida cavetto inox Ø4mm.":
        return "110 mm kassett finns som fyrkantig och rundad version, utrustad med Ø4mm rostfri kabelledare.";

      //  110 GC CABRIO
      case "Tenda senza cassonetto, dotata di guida cavetto inox Ø4mm. Realizzabile sia nella versione singola che doppia.":
        return "110 mm kassett finns som fyrkantig och rundad version, utrustad med Ø4mm rostfri kabelledare.";

      //  130 GPZ MB
      case "Tenda con cassonetto da 130mm disponibile nella sola variante Quadro, dotata di guida profilo da incasso con sistema ZIP e cassonetto autoportante. Adatta per l’integrazione nei sistemi per serramento, migliorando il risparmio energetico.":
        return "130mm screenkassett endast i fyrkantig version, utrustad med infälld profilstyrning med ZIP-system och självbärande kassett. Lämplig för integration i fönstersystem, vilket medför förbättrad energiförbrukning.";

      //  130 GA
      case "Tenda con cassonetto da 130mm disponibile nelle varianti Quadro e Tondo, dotata di guida asta inox Ø10mm.":
        return "130 mm screenkassett finns som fyrkantig och rundad version, försedd med en Ø10 mm stång i rostfritt stål.";

      //  130 GPZ UNICA M
      case "Tenda con cassonetto da 130mm disponibile nelle varianti Quadro e Tondo dotata di sistema di guida ZIP. Adatta per installazioni su infissi, sia a nicchia che a parete; veloce ed agevole da installare ed ispezionare. La guida è composta da due profili: supporto e copertina; è sviluppata per i soli sistemi motorizzati riducendo la larghezza a 36 mm.":
        return "130mm Screen kassett endast i rund version, utrustad med styc ken ZIP styrskenor och kassett monterad på styrskenorna. Passande både för vägg och nischmontage, snabb och enkel attmontera och serva. Styrskenan består av två profiler: skena ochtäcklock; utvecklad endast för motorisering vilket minskar bredden med36 mm.";
      case "Tenda con cassonetto da 130mm disponibile nelle varianti Quadro e Tondo dotata di sistema di guida ZIP.":
        return "130mm Screen kassett endast i rund version, utrustad med styc ken ZIP styrskenor och kassett monterad på styrskenorna.";

      //  110 GPZ UNICA AM
      case "Tenda con cassonetto da 110mm disponibile nelle varianti Quadro e Tondo dotata di sistema di guida ZIP e cassonetto autoportante. Adatta per installazioni su infissi, sia a nicchia che a parete; veloce ed agevole da installare ed ispezionare. La guida è composta da due profili: supporto e copertina; è sviluppata per sistemi sia ad argano che motorizzati.":
        return "110 mm kassett finns som fyrkantig och rundad version, utrustad med ett fritt styrsystem och en självbärande kassett. Den passar till alla typer av installationer. Den är enkel och snabb att installera och inspektera. Guiden är sammansatt av två profiler: Kassett och kassettlock; den har utvecklats för vev eller motoriserat system.";
      case "Tenda con cassonetto da 110mm disponibile nelle varianti Quadro e Tondo dotata di sistema di guida ZIP e cassonetto autoportante. Adatta per installazioni su infissi, sia a nicchia che a parete; veloce ed agevole da installare ed ispezionare.":
        return "110 mm kassett finns som fyrkantig och rundad version, utrustad med ett fritt styrsystem och en självbärande kassett. Den passar till alla typer av installationer.";

      //  130 GPZ TO
      case "Tenda con cassonetto da 130mm disponibile nella sola variante Quadro, dotata di sistema di guida ZIP. Adatta, grazie al sistema di tensionamento , per installazioni come copertura dei giardini d’inverno, dei tetti in vetro e delle verande in generale. Funzionamento esclusivamente motorizzato.":
        return "130mm screenkassett endast i fyrkantig version, utrustad med zip styckrenor. Tack vare sitt spännsystem är den lämplig för installationer på vinterverandor, glastak och verandor i allmänhet. Endast motoriserad drift.";
      case "Tenda con cassonetto da 130mm disponibile nella sola variante Quadro, dotata di sistema di guida ZIP.":
        return "130mm screenkassett endast i fyrkantig version, utrustad med zip styckrenor.";

      default:
        return entry;
    }
  }
  return entry;
};
