//  @ts-ignore
import React, { useState } from "react";
import { CloseWhite } from "../../icons";
import { mapPositions } from "../map-positions";
import { TechnicalSpecificsType } from "../../types";
import { LOCALSTORAGE_LANGUAGE } from "../../consts";
import { getLanguage } from "../../utils/get-language-code";

const Map: React.FC<{
  currentPosition: string;
  map: any;
  setPopupInfos: any;
  setTechnicalSpecifics: (v: TechnicalSpecificsType) => any;
  technicalSpecifics: TechnicalSpecificsType;
}> = ({
  currentPosition,
  map,
  setPopupInfos,
  setTechnicalSpecifics,
  technicalSpecifics,
}) => {
  const [hoverTitle, setHoverTitle] = useState<string | undefined>(undefined);
  return (
    <div className="map-container">
      {mapPositions
        .filter((p) => p.lang === getLanguage())
        .map((p, i) => (
          <div
            id={p.name}
            onClick={() => {
              setTechnicalSpecifics({
                ...technicalSpecifics,
                position: p.name,
              });
              setPopupInfos(null);
            }}
            key={"info_" + i}
            className={`positions-point${p.type === "path" ? " path" : ""}${
              currentPosition === p.name ? " active" : ""
            }`}
            style={{
              top: p.top,
              bottom: p.bottom,
              left: p.left,
              right: p.right,
            }}
            onMouseEnter={() => setHoverTitle(p.title)}
          />
        ))}
      <img src={map} alt="map" className="map" />
      <div className="positions-title__container">
        <span className="positions-title__text">{hoverTitle}</span>
      </div>
    </div>
  );
};

export default Map;
