import { TechnicalSpecificsType } from "../types";
import { addInfospot } from "./add-infospot";
import { getInitialPosition } from "./get-initial-position";
import toast from "react-hot-toast";
import { getLanguageTranslation } from "./translations/get-language-translation";

let panolens = null,
  three = null;
if (typeof window !== `undefined`) {
  panolens = require("panolens");
  three = require("three");
}
const { Viewer, ImagePanorama } =
  panolens !== null ? panolens : { Viewer: null, ImagePanorama: null };
const { MathUtils } = three !== null ? three : { MathUtils: null };

export const changeImage = (
  ref,
  panoramaImages,
  imageName,
  viewer,
  setIsLoading,
  isModelChanged,
  technicalSpecifics: TechnicalSpecificsType,
  infoSpots,
  firstLoading,
  setViewer,
  setImagePanorama,
  setFirstLoading,
  setTechnicalSpecifics
) => {
  if (ref.current !== undefined && ref.current !== null) {
    setIsLoading(true);
    fetch(panoramaImages[imageName])
      .then(() => {
        let view = viewer;
        if (view === undefined) {
          view = new Viewer({
            container: ref.current,
            output: "console",
            autoHideInfospot: false,
            controlButtons: [],
          });
          const control = view.getControl();
          control.rotateLeft(-90 * MathUtils.DEG2RAD);
        } else {
          // view.dispose()
        }

        let ip = new ImagePanorama(panoramaImages[imageName]);
        ip = addInfospot(ip, infoSpots, technicalSpecifics.position, view);

        if (firstLoading) ip.animationDuration = 0;
        else ip.animationDuration = 2500;

        view.add(ip);
        if (!firstLoading && !ip.isInfospotVisible)
          setTimeout(
            () => ip.toggleInfospotVisibility(),
            ip.animationDuration / 2
          );

        if (isModelChanged) {
          const initialPosition = getInitialPosition(
            technicalSpecifics.position
          );
          if (initialPosition && initialPosition.length !== 0) {
            ip.addEventListener("enter-fade-start", function () {
              view.tweenControlCenter(new three.Vector3(...initialPosition), 0);
            });
          }
        }

        setViewer(view);
        setImagePanorama(ip);
        view.setPanorama(ip);
        setFirstLoading(false);
        setIsLoading(false);
        console.log(panoramaImages[imageName]);
        console.log("imageName", imageName);
        if (
          panoramaImages[imageName] === undefined &&
          imageName !== undefined &&
          imageName !== null &&
          imageName !== ""
        ) {
          toast(
            getLanguageTranslation(
              "Immagine non trovata. Verrai riportato all'introduzione."
            )
          );
          setTechnicalSpecifics({
            model: "bat",
            color: "",
            time: "",
            position: "intro",
            led: false,
            roof: "open",
          });
        }
      })
      .catch((e) => {
        setIsLoading(false);
        if (imageName !== undefined && imageName !== null && imageName !== "") {
          toast(
            e.message === "Cannot read property 'image' of null"
              ? getLanguageTranslation(
                  "Immagine non trovata. Verrai riportato all'introduzione."
                )
              : e.message
          );
          setTechnicalSpecifics({
            model: "bat",
            color: "",
            time: "",
            position: "intro",
            led: false,
            roof: "open",
          });
        }
      });
  }
};
