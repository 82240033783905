import { getLanguageTranslation } from "../utils/translations/get-language-translation";

interface PositionTitle {
  title: string;
  type: "product" | "path";
  name: string;
  lang: "it" | "en" | "us" | "es" | "sv" | "fr";
}

export const positionsTitles: PositionTitle[] = [
  {
    title: getLanguageTranslation("Introduzione"),
    type: "product",
    name: "intro",
    lang: "us",
  },
  {
    title: "R 90 - R Pitch",
    type: "product",
    name: "r90-r-pitch",
    lang: "us",
  },
  {
    title: "Patio V4 - Armony Plus",
    type: "product",
    name: "patio-v4-armony-plus",
    lang: "us",
  },
  {
    title: "2040 - Giant",
    type: "product",
    name: "2040-giant",
    lang: "us",
  },
  /*{
    title: "New Italia",
    type: "product",
    name: "new-italia",
    lang: "us",
  },*/
  {
    title: "Space",
    type: "product",
    name: "space",
    lang: "us",
  },
  {
    title: "Epica Lite",
    type: "product",
    name: "epica-lite",
    lang: "us",
  },
  {
    name: "percorso-1",
    type: "path",
    title: "R 90 - R Pitch - Patio V4 - Armony Plus",
    lang: "us",
  },
  {
    name: "percorso-2",
    type: "path",
    title: "Patio V4 - Armony Plus - 2040 - Giant",
    lang: "us",
  },
  {
    title: getLanguageTranslation("Introduzione"),
    type: "product",
    name: "intro",
    lang: "es",
  },
  {
    title: "Epica Lite - 130 GPZ TO",
    type: "product",
    name: "130-gpz-to",
    lang: "es",
  },
  {
    title: "Domea - Base Plus",
    type: "product",
    name: "domea-base-plus",
    lang: "es",
  },
  {
    title: "Epica - Base Lite",
    type: "product",
    name: "epica-base-lite",
    lang: "es",
  },
  {
    title: "Armony Plus - Giant",
    type: "product",
    name: "armony-plus-giant",
    lang: "es",
  },
  {
    title: "2020 - 2010",
    type: "product",
    name: "2020-2010",
    lang: "es",
  },
  {
    title: "Bravo - Verso Cassonetto",
    type: "product",
    name: "bravo-verso-cassonetto",
    lang: "es",
  },
  /*{
    title: "Verso Cassonetto - Mini Verso",
    type: "product",
    name: "verso-cassonetto-mini-verso",
    lang: "es",
  },*/
  {
    name: "screeny-110-gpz-unica-am-screeny-130-gpz-unica-m",
    type: "product",
    title: "Screeny 110 GPZ UNICA AM - Screeny 130 GPZ UNICA M",
    lang: "es",
  },
  {
    name: "screeny-110-gc-screeny-130-gpz-i",
    type: "product",
    title: "Screeny 110 GC - Screeny 130 GPZ I",
    lang: "es",
  },
  {
    name: "screeny-130-ga-screeny-130-gpz-mb",
    type: "product",
    title: "Screeny 130 GA - Screeny 130 GPZ MB",
    lang: "es",
  },
  {
    name: "screeny-150-gpz-unica-am-screeny-110-gc-cabrio",
    type: "product",
    title: "Screeny 150 GPZ UNICA AM - Screeny 110 GC CABRIO",
    lang: "es",
  },
  {
    name: "mini-helix-ps-helix-ps",
    type: "product",
    title: "Mini Helix Ps - Helix Ps",
    lang: "es",
  },
  {
    name: "ely",
    type: "product",
    title: "Ely",
    lang: "es",
  },
  {
    name: "line-glass-1.0",
    type: "product",
    title: "Line Glass 1.0",
    lang: "es",
  },
  {
    name: "space-sirio",
    type: "product",
    title: "Space - Sirio",
    lang: "es",
  },
  {
    name: "giotto-plus-giotto",
    type: "product",
    title: "Giotto Plus - Giotto",
    lang: "es",
  },
  {
    name: "percorso-1",
    type: "path",
    title: "130 GPZ TO - Domea - Base Plus",
    lang: "es",
  },
  {
    name: "percorso-2",
    type: "path",
    title: "Armony Plus - Giant - 2020 - 2010",
    lang: "es",
  },
  {
    name: "percorso-3",
    type: "path",
    title: "Screeny",
    lang: "es",
  },
  {
    name: "percorso-4",
    type: "path",
    title: "Mini Helix Ps - Helix Ps",
    lang: "es",
  },
  {
    name: "percorso-5",
    type: "path",
    title: "Domea - Base Plus",
    lang: "es",
  },
  {
    name: "percorso-6",
    type: "path",
    title: "Ely - Line Glass 1.0",
    lang: "es",
  },
  {
    name: "percorso-7",
    type: "path",
    title: "Ely",
    lang: "es",
  },
  {
    name: "percorso-8",
    type: "path",
    title: "Space - Sirio",
    lang: "es",
  },
  {
    name: "percorso-9",
    type: "path",
    title: "Giotto Plus - Giotto - Line Glass 1.0",
    lang: "es",
  },
  {
    title: getLanguageTranslation("Introduzione"),
    type: "product",
    name: "intro",
    lang: "fr",
  },
  {
    name: "130-gpz-to",
    type: "product",
    title: "130 GPZ TO",
    lang: "fr",
  },
  {
    name: "leva-incastro-bq-domea",
    type: "product",
    title: /*"LEVA INCASTRO BQ - DOMEA"*/ "DOMEA",
    lang: "fr",
  },
  {
    name: "domea-base-plus",
    type: "product",
    title: "DOMEA - BASE PLUS",
    lang: "fr",
  },
  {
    name: "base-plus-epica",
    type: "product",
    title: "BASE PLUS - EPICA",
    lang: "fr",
  },
  {
    name: "epica-base-lite",
    type: "product",
    title: "EPICA - BASE LITE",
    lang: "fr",
  },
  {
    name: "base-lite-armony-plus",
    type: "product",
    title: "BASE LITE - ARMONY PLUS",
    lang: "fr",
  },
  {
    name: "armony-plus-2040",
    type: "product",
    title: "ARMONY PLUS - 2040",
    lang: "fr",
  },
  {
    name: "2020-2040",
    type: "product",
    title: "2040 - 2020",
    lang: "fr",
  },
  {
    name: "2020-2010",
    type: "product",
    title: "2020 - 2010",
    lang: "fr",
  },
  {
    name: "giant-zenith",
    type: "product",
    title: "GIANT - ZENITH",
    lang: "fr",
  },
  {
    name: "verso-cassonetto",
    type: "product",
    title: "VERSO CASSONETTO",
    lang: "fr",
  },
  {
    name: "screeny-150-storm-screeny-130-gpz-unica-m",
    type: "product",
    title:
      /*"SCREENY 150 STORM - SCREENY 130 GPZ UNICA M"*/ "SCREENY 130 GPZ UNICA M",
    lang: "fr",
  },
  {
    name: "screeny-110-gc-screeny-130-gpz-i",
    type: "product",
    title: "SCREENY 110 GC - SCREENY 130 GPZ I",
    lang: "fr",
  },
  {
    name: "screeny-130-ga-screeny-130-gpz-mb",
    type: "product",
    title: "SCREENY 130 GA - SCREENY 130 GPZ MB",
    lang: "fr",
  },
  {
    name: "screeny-110-gpz-unica-am-screeny-110-gc-cabrio",
    type: "product",
    title: "SCREENY 110 GPZ UNICA AM - SCREENY 110 GC CABRIO",
    lang: "fr",
  },
  {
    name: "mini-helix-bq-mini-helix-ps",
    type: "product",
    title: "MINI HELIX BQ - MINI HELIX PS",
    lang: "fr",
  },
  {
    name: "helix-bq-helix-ps",
    type: "product",
    title: "HELIX BQ - HELIX PS",
    lang: "fr",
  },
  /*{
    name: "parigi-plus-parigi-plus-lite",
    type: "product",
    title: "PARIGI PLUS - PARIGI PLUS LITE",
    lang: "fr",
  },*/
  {
    name: "ely",
    type: "product",
    title: "ELY",
    lang: "fr",
  },
  {
    name: "epica-lite",
    type: "product",
    title: "EPICA LITE",
    lang: "fr",
  },
  {
    name: "sirio",
    type: "product",
    title: "SIRIO",
    lang: "fr",
  },
  {
    name: "ombralsun",
    type: "product",
    title: "OMBRALSUN",
    lang: "fr",
  },
  {
    name: "percorso-1",
    type: "path",
    title: "Base Plus - Epica - 130 GPZ TO",
    lang: "fr",
  },
  {
    name: "percorso-2",
    type: "path",
    title: "2020 - 2010",
    lang: "fr",
  },
  {
    name: "percorso-3",
    type: "path",
    title: "Screeny",
    lang: "fr",
  },
  {
    name: "percorso-4",
    type: "path",
    title: "Helix Bq - Helix Ps",
    lang: "fr",
  },
  {
    name: "percorso-5",
    type: "path",
    title: "Leva Incastro BQ - Domea",
    lang: "fr",
  },
  {
    name: "percorso-6",
    type: "path",
    title: "Ely",
    lang: "fr",
  },
  {
    name: "percorso-8",
    type: "path",
    title: "Sirio",
    lang: "fr",
  },
  {
    title: getLanguageTranslation("Introduzione"),
    type: "product",
    name: "intro",
    lang: "sv",
  },
  /*{
    name: "space-r90",
    type: "product",
    title: "SPACE - R 90",
    lang: "sv",
  },*/
  {
    name: "r-pitch-130-gpz-to",
    type: "product",
    title: "R PITCH - 130 GPZ TO",
    lang: "sv",
  },
  {
    name: "epica-giant",
    type: "product",
    title: "EPICA - GIANT",
    lang: "sv",
  },
  {
    name: "zenith-2040",
    type: "product",
    title: "ZENITH - 2040",
    lang: "sv",
  },
  {
    name: "epica-epica-lite",
    type: "product",
    title: "EPICA - EPICA LITE",
    lang: "sv",
  },
  {
    name: "ombralsun",
    type: "product",
    title: "OMBRALSUN",
    lang: "sv",
  },
  /*{
    name: "havant",
    type: "product",
    title: "HAVANT",
    lang: "sv",
  },*/
  {
    name: "screeny-130-gpz-unica-m-screeny-110-gc",
    type: "product",
    title: "SCREENY 130 GPZ UNICA M - SCREENY 110 GC",
    lang: "sv",
  },
  {
    name: "screeny-110-gc-screeny-130-gpz-i",
    type: "product",
    title: "SCREENY 110 GC - SCREENY 130 GPZ I",
    lang: "sv",
  },
  {
    name: "screeny-130-ga-screeny-130-gpz-mb",
    type: "product",
    title: "SCREENY 130 GA - SCREENY 130 GPZ MB",
    lang: "sv",
  },
  {
    name: "screeny-110-gpz-unica-am-screeny-110-gc-cabrio",
    type: "product",
    title: "SCREENY 110 GPZ UNICA AM - SCREENY 110 GC CABRIO",
    lang: "sv",
  },
  {
    name: "percorso-1",
    type: "path",
    title: "Epica - Giant",
    lang: "sv",
  },
  {
    name: "percorso-2",
    type: "path",
    title: "Epica - Giant - Zenith - 2040",
    lang: "sv",
  },
  {
    name: "percorso-3",
    type: "path",
    title: "Zenith - 2040",
    lang: "sv",
  },
  {
    name: "percorso-4",
    type: "path",
    title: "Havant",
    lang: "sv",
  },
  {
    name: "percorso-5",
    type: "path",
    title: "Space - R 90",
    lang: "sv",
  },
  //  IT & EN
  {
    title: getLanguageTranslation("Introduzione"),
    type: "product",
    name: "intro",
    lang: "it",
  },
  {
    title: "Patio V4 - R Pitch",
    type: "product",
    name: "patio-v4-r-pitch",
    lang: "it",
  },
  {
    title: "Giotto Plus - Giotto",
    type: "product",
    name: "giotto-plus-giotto",
    lang: "it",
  },
  {
    title: "Mini Helix BQ - Mini Helix PS",
    type: "product",
    name: "mini-helix-bq-mini-helix-ps",
    lang: "it",
  },
  {
    title: "Ely",
    type: "product",
    name: "new-ely",
    lang: "it",
  },
  {
    title: "Glass 1.0",
    type: "product",
    name: "glass-1",
    lang: "it",
  },
  {
    title: "Sirio - Space",
    type: "product",
    name: "sirio-space",
    lang: "it",
  },
  {
    title: "Base Lite - Epica",
    type: "product",
    name: "base-lite-epica",
    lang: "it",
  },
  {
    title: "Ombralsun",
    type: "product",
    name: "ombralsun",
    lang: "it",
  },
  {
    title: "Giant - Zenith",
    type: "product",
    name: "giant-zenith",
    lang: "it",
  },
  {
    title: "Screeny 110GC - Screeny 130 GPZ I",
    type: "product",
    name: "screeny-110-gc-screeny-130-gpz-i",
    lang: "it",
  },
  {
    title: "2010",
    type: "product",
    name: "2010",
    lang: "it",
  },
  {
    title: "Armony Plus - 2040",
    type: "product",
    name: "armony-plus-2040",
    lang: "it",
  },
  {
    title: "Base Plus - Epica",
    type: "product",
    name: "base-plus-epica",
    lang: "it",
  },
  {
    title: "2020 - 2040",
    type: "product",
    name: "2020-2040",
    lang: "it",
  },
  {
    title: "Screeny Storm - Screeny 130 GPZ Unica M",
    type: "product",
    name: "screeny-150-storm-screeny-130-gpz-unica-m",
    lang: "it",
  },
  {
    title: "Screeny 150 GPZ Unica AM - Screeny 110 GC Cabrio",
    type: "product",
    name: "screeny-150-gpz-unica-screeny-110-gc-cabrio",
    lang: "it",
  },
  {
    title: "Screeny 130 GA - Screeny 130 GPZ MB",
    type: "product",
    name: "screeny-130-ga-screeny-130-gpz-mb",
    lang: "it",
  },
  {
    title: "Helix BQ - Helix PS",
    type: "product",
    name: "helix-bq-helix-ps",
    lang: "it",
  },
  {
    title: "Domea - Grandangolo",
    type: "product",
    name: "domea-grandangolo",
    lang: "it",
  },
  {
    title: "Base Lite - Armony Plus",
    type: "product",
    name: "base-lite-armony-plus",
    lang: "it",
  },
  {
    title: "Base Plus - Domea",
    type: "product",
    name: "base-plus-domea",
    lang: "it",
  },
  {
    title: "Screeny 110GC - Screeny 130 GPZ Unica M",
    type: "product",
    name: "screeny-110-gc-screeny-130-gpz-unica-m",
    lang: "it",
  },
  {
    title: "Screeny 110 GPZ Unica AM - Screeny 55 GPXS",
    type: "product",
    name: "screeny-110-gpz-unica-am-screeny-55-gp-xs",
    lang: "it",
  },
  {
    title: "Epica Lite - 130 GPZ TO",
    type: "product",
    name: "parigi-plus-screeny-130-gpz-tens-to",
    lang: "it",
  },
  {
    title: "New Roma",
    type: "product",
    name: "new-roma",
    lang: "it",
  },
  {
    title: "Verso Cassonetto - Genova",
    type: "product",
    name: "verso-genova",
    lang: "it",
  },
  // {
  //   title: "Havant",
  //   type: "product",
  //   name: "havant"
  // },
  {
    title: "Screeny 110 GPZ Unica AM - Screeny 85 GPZS",
    type: "product",
    name: "screeny-110-gpz-unica-am-screeny-85-gpz-s",
    lang: "it",
  },

  {
    name: "percorso-1",
    type: "path",
    title: "2020 - 2040 - Giant - Zenith",
    lang: "it",
  },
  {
    name: "percorso-2",
    type: "path",
    title: "Screeny",
    lang: "it",
  },
  // {
  //   name: "percorso-3",
  //   type: "path",
  //   title: "Helix - Screeny"
  // },
  {
    name: "percorso-4",
    type: "path",
    title: "Grandangolo - Ely",
    lang: "it",
  },
  {
    name: "percorso-5",
    type: "path",
    title: "Grandangolo - Glass 1.0",
    lang: "it",
  },
  {
    name: "percorso-6",
    type: "path",
    title: "Patio V4 - R Pitch - Giotto Plus - Giotto",
    lang: "it",
  },
  {
    name: "percorso-7",
    type: "path",
    title: "Ombralsun - Sirio - Space",
    lang: "it",
  },
  {
    name: "percorso-8",
    type: "path",
    title: "Ombralsun - Mini Helix BQ - Mini Helix PS",
    lang: "it",
  },

  {
    title: getLanguageTranslation("Introduzione"),
    type: "product",
    name: "intro",
    lang: "en",
  },
  {
    title: "Patio V4 - R Pitch",
    type: "product",
    name: "patio-v4-r-pitch",
    lang: "en",
  },
  {
    title: "Giotto Plus - Giotto",
    type: "product",
    name: "giotto-plus-giotto",
    lang: "en",
  },
  {
    title: "Mini Helix BQ - Mini Helix PS",
    type: "product",
    name: "mini-helix-bq-mini-helix-ps",
    lang: "en",
  },
  {
    title: "Ely",
    type: "product",
    name: "new-ely",
    lang: "en",
  },
  {
    title: "Glass 1.0",
    type: "product",
    name: "glass-1",
    lang: "en",
  },
  {
    title: "Sirio - Space",
    type: "product",
    name: "sirio-space",
    lang: "en",
  },
  {
    title: "Base Lite - Epica",
    type: "product",
    name: "base-lite-epica",
    lang: "en",
  },
  {
    title: "Ombralsun",
    type: "product",
    name: "ombralsun",
    lang: "en",
  },
  {
    title: "Giant - Zenith",
    type: "product",
    name: "giant-zenith",
    lang: "en",
  },
  {
    title: "Screeny 110GC - Screeny 130 GPZ I",
    type: "product",
    name: "screeny-110-gc-screeny-130-gpz-i",
    lang: "en",
  },
  {
    title: "2010",
    type: "product",
    name: "2010",
    lang: "en",
  },
  {
    title: "Armony Plus - 2040",
    type: "product",
    name: "armony-plus-2040",
    lang: "en",
  },
  {
    title: "Base Plus - Epica",
    type: "product",
    name: "base-plus-epica",
    lang: "en",
  },
  {
    title: "2020 - 2040",
    type: "product",
    name: "2020-2040",
    lang: "en",
  },
  {
    title: "Screeny Storm - Screeny 130 GPZ Unica M",
    type: "product",
    name: "screeny-150-storm-screeny-130-gpz-unica-m",
    lang: "en",
  },
  {
    title: "Screeny 150 GPZ Unica AM - Screeny 110 GC Cabrio",
    type: "product",
    name: "screeny-150-gpz-unica-screeny-110-gc-cabrio",
    lang: "en",
  },
  {
    title: "Screeny 130 GA - Screeny 130 GPZ MB",
    type: "product",
    name: "screeny-130-ga-screeny-130-gpz-mb",
    lang: "en",
  },
  {
    title: "Helix BQ - Helix PS",
    type: "product",
    name: "helix-bq-helix-ps",
    lang: "en",
  },
  {
    title: "Domea - Grandangolo",
    type: "product",
    name: "domea-grandangolo",
    lang: "en",
  },
  {
    title: "Base Lite - Armony Plus",
    type: "product",
    name: "base-lite-armony-plus",
    lang: "en",
  },
  {
    title: "Base Plus - Domea",
    type: "product",
    name: "base-plus-domea",
    lang: "en",
  },
  {
    title: "Screeny 110GC - Screeny 130 GPZ Unica M",
    type: "product",
    name: "screeny-110-gc-screeny-130-gpz-unica-m",
    lang: "en",
  },
  {
    title: "Screeny 110 GPZ Unica AM - Screeny 55 GPXS",
    type: "product",
    name: "screeny-110-gpz-unica-am-screeny-55-gp-xs",
    lang: "en",
  },
  {
    title: "Epica Lite - 130 GPZ TO",
    type: "product",
    name: "parigi-plus-screeny-130-gpz-tens-to",
    lang: "en",
  },
  {
    title: "New Roma",
    type: "product",
    name: "new-roma",
    lang: "en",
  },
  {
    title: "Verso Cassonetto - Genova",
    type: "product",
    name: "verso-genova",
    lang: "en",
  },
  // {
  //   title: "Havant",
  //   type: "product",
  //   name: "havant"
  // },
  {
    title: "Screeny 110 GPZ Unica AM - Screeny 85 GPZS",
    type: "product",
    name: "screeny-110-gpz-unica-am-screeny-85-gpz-s",
    lang: "en",
  },

  {
    name: "percorso-1",
    type: "path",
    title: "2020 - 2040 - Giant - Zenith",
    lang: "en",
  },
  {
    name: "percorso-2",
    type: "path",
    title: "Screeny",
    lang: "en",
  },
  // {
  //   name: "percorso-3",
  //   type: "path",
  //   title: "Helix - Screeny"
  // },
  {
    name: "percorso-4",
    type: "path",
    title: "Grandangolo - Ely",
    lang: "en",
  },
  {
    name: "percorso-5",
    type: "path",
    title: "Grandangolo - Glass 1.0",
    lang: "en",
  },
  {
    name: "percorso-6",
    type: "path",
    title: "Patio V4 - R Pitch - Giotto Plus - Giotto",
    lang: "en",
  },
  {
    name: "percorso-7",
    type: "path",
    title: "Ombralsun - Sirio - Space",
    lang: "en",
  },
  {
    name: "percorso-8",
    type: "path",
    title: "Ombralsun - Mini Helix BQ - Mini Helix PS",
    lang: "en",
  },
];

interface MapPosition {
  title: string;
  type: "product" | "path";
  name: string;
  top: string;
  bottom: string;
  left: string;
  right: string;
  lang: "it" | "en" | "us" | "es" | "sv" | "fr";
}

export const mapPositions: MapPosition[] = [
  {
    title: getLanguageTranslation("Introduzione"),
    type: "product",
    name: "intro",
    top: "30%",
    right: "",
    bottom: "",
    left: "31%",
    lang: "us",
  },
  {
    name: "r90-r-pitch",
    type: "product",
    title: "R 90 - R Pitch",
    top: "33%",
    right: "",
    bottom: "",
    left: "9%",
    lang: "us",
  },
  {
    name: "patio-v4-armony-plus",
    type: "product",
    title: "Patio V4 - Armony Plus",
    top: "23%",
    right: "",
    bottom: "",
    left: "9%",
    lang: "us",
  },
  {
    name: "2040-giant",
    type: "product",
    title: "2040 - Giant",
    top: "12%",
    right: "",
    bottom: "",
    left: "9%",
    lang: "us",
  },
  {
    name: "space",
    type: "product",
    title: "Space",
    top: "35%",
    right: "",
    bottom: "",
    left: "22%",
    lang: "us",
  },
  {
    name: "epica-lite",
    type: "product",
    title: "Epica Lite",
    top: "35%",
    right: "",
    bottom: "",
    left: "36%",
    lang: "us",
  },
  /*{
    name: "new-italia",
    type: "product",
    title: "New Italia",
    top: "35%",
    right: "",
    bottom: "",
    left: "44%",
    lang: "us",
  },*/
  {
    name: "percorso-1",
    type: "path",
    title: "",
    top: "30%",
    right: "",
    bottom: "",
    left: "18%",
    lang: "us",
  },
  {
    name: "percorso-2",
    type: "path",
    title: "",
    top: "20%",
    right: "",
    bottom: "",
    left: "17%",
    lang: "us",
  },
  {
    title: getLanguageTranslation("Introduzione"),
    type: "product",
    name: "intro",
    top: "30%",
    right: "",
    bottom: "",
    left: "31%",
    lang: "es",
  },
  {
    name: "130-gpz-to",
    type: "product",
    title: "Epica Lite - 130 GPZ TO",
    top: "34%",
    right: "",
    bottom: "",
    left: "27%",
    lang: "es",
  },
  {
    name: "domea-base-plus",
    type: "product",
    title: "Domea - Base Plus",
    top: "39%",
    right: "",
    bottom: "",
    left: "9%",
    lang: "es",
  },
  {
    name: "epica-base-lite",
    type: "product",
    title: "Epica - Base Lite",
    top: "28%",
    right: "",
    bottom: "",
    left: "9%",
    lang: "es",
  },
  {
    name: "armony-plus-giant",
    type: "product",
    title: "Armony Plus - Giant",
    top: "18%",
    right: "",
    bottom: "",
    left: "9%",
    lang: "es",
  },
  {
    name: "2020-2010",
    type: "product",
    title: "2020 - 2010",
    top: "7%",
    right: "",
    bottom: "",
    left: "9%",
    lang: "es",
  },
  {
    name: "bravo-verso-cassonetto",
    type: "product",
    title: "Bravo - Verso Cassonetto",
    top: "7%",
    right: "30%",
    bottom: "",
    left: "",
    lang: "es",
  },
  /*{
    name: "verso-cassonetto-mini-verso",
    type: "product",
    title: "Verso Cassonetto - Mini Verso",
    top: "7%",
    right: "23%",
    bottom: "",
    left: "",
    lang: "es",
  },*/
  {
    name: "screeny-110-gpz-unica-am-screeny-130-gpz-unica-m",
    type: "product",
    title: "Screeny 110 GPZ UNICA AM - Screeny 130 GPZ UNICA M",
    top: "10%",
    right: "16%",
    bottom: "",
    left: "",
    lang: "es",
  },
  {
    name: "screeny-110-gc-screeny-130-gpz-i",
    type: "product",
    title: "Screeny 110 GC - Screeny 130 GPZ I",
    top: "19%",
    right: "16%",
    bottom: "",
    left: "",
    lang: "es",
  },
  {
    name: "screeny-130-ga-screeny-130-gpz-mb",
    type: "product",
    title: "Screeny 130 GA - Screeny 130 GPZ MB",
    top: "24%",
    right: "20%",
    bottom: "",
    left: "",
    lang: "es",
  },
  {
    name: "screeny-150-gpz-unica-am-screeny-110-gc-cabrio",
    type: "product",
    title: "Screeny 150 GPZ UNICA AM - Screeny 110 GC CABRIO",
    top: "24%",
    right: "34%",
    bottom: "",
    left: "",
    lang: "es",
  },
  {
    name: "mini-helix-ps-helix-ps",
    type: "product",
    title: "Mini Helix Ps - Helix Ps",
    top: "34%",
    right: "45%",
    bottom: "",
    left: "",
    lang: "es",
  },
  {
    name: "ely",
    type: "product",
    title: "Ely",
    top: "",
    right: "",
    bottom: "35%",
    left: "41%",
    lang: "es",
  },
  {
    name: "line-glass-1.0",
    type: "product",
    title: "Line Glass 1.0",
    top: "",
    right: "",
    bottom: "28%",
    left: "37%",
    lang: "es",
  },
  {
    name: "space-sirio",
    type: "product",
    title: "Space - Sirio",
    top: "",
    right: "26%",
    bottom: "14%",
    left: "",
    lang: "es",
  },
  {
    name: "giotto-plus-giotto",
    type: "product",
    title: "Giotto Plus - Giotto",
    top: "",
    right: "39%",
    bottom: "12%",
    left: "",
    lang: "es",
  },
  {
    name: "percorso-1",
    type: "path",
    title: "",
    top: "30%",
    right: "",
    bottom: "",
    left: "19%",
    lang: "es",
  },
  {
    name: "percorso-2",
    type: "path",
    title: "",
    top: "15%",
    right: "",
    bottom: "",
    left: "19%",
    lang: "es",
  },
  {
    name: "percorso-3",
    type: "path",
    title: "",
    top: "15%",
    right: "",
    bottom: "",
    left: "43%",
    lang: "es",
  },
  {
    name: "percorso-4",
    type: "path",
    title: "",
    top: "28%",
    right: "",
    bottom: "",
    left: "46%",
    lang: "es",
  },
  {
    name: "percorso-5",
    type: "path",
    title: "",
    top: "48%",
    right: "",
    bottom: "",
    left: "11%",
    lang: "es",
  },
  {
    name: "percorso-6",
    type: "path",
    title: "",
    top: "56%",
    right: "",
    bottom: "",
    left: "22%",
    lang: "es",
  },
  {
    name: "percorso-7",
    type: "path",
    title: "",
    top: "",
    right: "",
    bottom: "37%",
    left: "65%",
    lang: "es",
  },
  {
    name: "percorso-8",
    type: "path",
    title: "",
    top: "",
    right: "23%",
    bottom: "27%",
    left: "",
    lang: "es",
  },
  {
    name: "percorso-9",
    type: "path",
    title: "",
    top: "",
    right: "41%",
    bottom: "22%",
    left: "",
    lang: "es",
  },
  {
    title: getLanguageTranslation("Introduzione"),
    type: "product",
    name: "intro",
    top: "30%",
    right: "",
    bottom: "",
    left: "31%",
    lang: "fr",
  },
  {
    title: "2020 - 2010",
    type: "product",
    name: "2020-2010",
    top: "7%",
    right: "",
    bottom: "",
    left: "8%",
    lang: "fr",
  },
  {
    title: "GIANT - ZENITH",
    type: "product",
    name: "giant-zenith",
    top: "7%",
    right: "",
    bottom: "",
    left: "15%",
    lang: "fr",
  },
  {
    title: "2040 - 2020",
    type: "product",
    name: "2020-2040",
    top: "13%",
    right: "",
    bottom: "",
    left: "9%",
    lang: "fr",
  },
  {
    title: "ARMONY PLUS - 2040",
    type: "product",
    name: "armony-plus-2040",
    top: "18%",
    right: "",
    bottom: "",
    left: "9%",
    lang: "fr",
  },
  {
    title: "BASE LITE - ARMONY PLUS",
    type: "product",
    name: "base-lite-armony-plus",
    top: "23%",
    right: "",
    bottom: "",
    left: "9%",
    lang: "fr",
  },
  {
    title: "EPICA BASE LITE",
    type: "product",
    name: "epica-base-lite",
    top: "28%",
    right: "",
    bottom: "",
    left: "9%",
    lang: "fr",
  },
  {
    title: "BASE PLUS - EPICA",
    type: "product",
    name: "base-plus-epica",
    top: "33%",
    right: "",
    bottom: "",
    left: "9%",
    lang: "fr",
  },
  {
    title: "DOMEA - BASE PLUS",
    type: "product",
    name: "domea-base-plus",
    top: "38%",
    right: "",
    bottom: "",
    left: "9%",
    lang: "fr",
  },
  {
    title: /*"LEVA INCASTRO BQ - DOMEA"*/ "DOMEA",
    type: "product",
    name: "leva-incastro-bq-domea",
    top: "43%",
    right: "",
    bottom: "",
    left: "9%",
    lang: "fr",
  },
  {
    title: "130 GPZ TO",
    type: "product",
    name: "130-gpz-to",
    top: "35%",
    right: "",
    bottom: "",
    left: "23%",
    lang: "fr",
  },
  /*{
    title: "PARIGI PLUS - PARIGI PLUS LITE",
    type: "product",
    name: "parigi-plus-parigi-plus-lite",
    top: "35%",
    right: "",
    bottom: "",
    left: "36%",
    lang: "fr",
  },*/
  {
    title: "HELIX BQ - HELIX PS",
    type: "product",
    name: "helix-bq-helix-ps",
    top: "35%",
    right: "",
    bottom: "",
    left: "54%",
    lang: "fr",
  },
  {
    title: "MINI HELIX BQ - MINI HELIX PS",
    type: "product",
    name: "mini-helix-bq-mini-helix-ps",
    top: "35%",
    right: "",
    bottom: "",
    left: "71%",
    lang: "fr",
  },
  {
    title: "VERSO CASSONETTO",
    type: "product",
    name: "verso-cassonetto",
    top: "6%",
    right: "",
    bottom: "",
    left: "66%",
    lang: "fr",
  },
  {
    title:
      /*"SCREENY 150 STORM - SCREENY 130 GPZ UNICA M"*/ "SCREENY 130 GPZ UNICA M",
    type: "product",
    name: "screeny-150-storm-screeny-130-gpz-unica-m",
    top: "9%",
    right: "",
    bottom: "",
    left: "77%",
    lang: "fr",
  },
  {
    title: "SCREENY 110 GC - SCREENY 130 GPZ I",
    type: "product",
    name: "screeny-110-gc-screeny-130-gpz-i",
    top: "20%",
    right: "",
    bottom: "",
    left: "77%",
    lang: "fr",
  },
  {
    title: "SCREENY 130 GA - SCREENY 130 GPZ MB",
    type: "product",
    name: "screeny-130-ga-screeny-130-gpz-mb",
    top: "24%",
    right: "",
    bottom: "",
    left: "72%",
    lang: "fr",
  },
  {
    title: "SCREENY 110 GPZ UNICA AM - SCREENY 110 GC CABRIO",
    type: "product",
    name: "screeny-110-gpz-unica-am-screeny-110-gc-cabrio",
    top: "24%",
    right: "",
    bottom: "",
    left: "58%",
    lang: "fr",
  },
  {
    title: "ELY",
    type: "product",
    name: "ely",
    top: "59%",
    right: "",
    bottom: "",
    left: "39%",
    lang: "fr",
  },
  {
    title: "EPICA LITE",
    type: "product",
    name: "epica-lite",
    top: "56%",
    right: "",
    bottom: "",
    left: "30%",
    lang: "fr",
  },
  {
    title: "SIRIO",
    type: "product",
    name: "sirio",
    top: "59%",
    right: "",
    bottom: "",
    left: "51%",
    lang: "fr",
  },
  {
    title: "OMBRALSUN",
    type: "product",
    name: "ombralsun",
    top: "57%",
    right: "",
    bottom: "",
    left: "64%",
    lang: "fr",
  },
  {
    name: "percorso-1",
    type: "path",
    title: "",
    top: "30%",
    right: "",
    bottom: "",
    left: "19%",
    lang: "fr",
  },
  {
    name: "percorso-2",
    type: "path",
    title: "",
    top: "15%",
    right: "",
    bottom: "",
    left: "21%",
    lang: "fr",
  },
  {
    name: "percorso-3",
    type: "path",
    title: "",
    top: "15%",
    right: "",
    bottom: "",
    left: "43%",
    lang: "fr",
  },
  {
    name: "percorso-4",
    type: "path",
    title: "",
    top: "28%",
    right: "",
    bottom: "",
    left: "47%",
    lang: "fr",
  },
  {
    name: "percorso-5",
    type: "path",
    title: "",
    top: "49%",
    right: "",
    bottom: "",
    left: "13%",
    lang: "fr",
  },
  {
    name: "percorso-6",
    type: "path",
    title: "",
    top: "57%",
    right: "",
    bottom: "",
    left: "22%",
    lang: "fr",
  },
  {
    name: "percorso-8",
    type: "path",
    title: "",
    top: "46%",
    right: "",
    bottom: "",
    left: "66%",
    lang: "fr",
  },
  {
    title: getLanguageTranslation("Introduzione"),
    type: "product",
    name: "intro",
    top: "30%",
    right: "",
    bottom: "",
    left: "31%",
    lang: "sv",
  },
  /*{
    title: "SPACE - R 90",
    type: "product",
    name: "space-r90",
    top: "34%",
    right: "",
    bottom: "",
    left: "45%",
    lang: "sv",
  },*/
  {
    title: /*"R PITCH - 130 GPZ TO"*/ "130 GPZ TO",
    type: "product",
    name: "r-pitch-130-gpz-to",
    top: "34%",
    right: "",
    bottom: "",
    left: "27%",
    lang: "sv",
  },
  {
    title: "EPICA - GIANT",
    type: "product",
    name: "epica-giant",
    top: "29%",
    right: "",
    bottom: "",
    left: "8%",
    lang: "sv",
  },
  {
    title: "EPICA - EPICA LITE",
    type: "product",
    name: "epica-epica-lite",
    top: "34%",
    right: "",
    bottom: "",
    left: "8%",
    lang: "sv",
  },
  {
    title: "ZENITH - 2040",
    type: "product",
    name: "zenith-2040",
    top: "17%",
    right: "",
    bottom: "",
    left: "8%",
    lang: "sv",
  },
  /*{
    title: "HAVANT",
    type: "product",
    name: "havant",
    top: "7%",
    right: "",
    bottom: "",
    left: "57%",
    lang: "sv",
  },*/
  {
    title: "OMBRALSUN",
    type: "product",
    name: "ombralsun",
    top: "8%",
    right: "",
    bottom: "",
    left: "66%",
    lang: "sv",
  },
  {
    title: "SCREENY 130 GPZ UNICA M - SCREENY 110 GC",
    type: "product",
    name: "screeny-130-gpz-unica-m-screeny-110-gc",
    top: "10%",
    right: "",
    bottom: "",
    left: "76%",
    lang: "sv",
  },
  {
    title: "SCREENY 110 GC - SCREENY 130 GPZ I",
    type: "product",
    name: "screeny-110-gc-screeny-130-gpz-i",
    top: "19%",
    right: "",
    bottom: "",
    left: "76%",
    lang: "sv",
  },
  {
    title: "SCREENY 130 GA - SCREENY 130 GPZ MB",
    type: "product",
    name: "screeny-130-ga-screeny-130-gpz-mb",
    top: "24%",
    right: "",
    bottom: "",
    left: "72%",
    lang: "sv",
  },
  {
    title: "SCREENY 110 GPZ UNICA AM - SCREENY 110 GC CABRIO",
    type: "product",
    name: "screeny-110-gpz-unica-am-screeny-110-gc-cabrio",
    top: "24%",
    right: "",
    bottom: "",
    left: "57%",
    lang: "sv",
  },
  {
    name: "percorso-1",
    type: "path",
    title: "",
    top: "30%",
    right: "",
    bottom: "",
    left: "18%",
    lang: "sv",
  },
  {
    name: "percorso-2",
    type: "path",
    title: "",
    top: "23%",
    right: "",
    bottom: "",
    left: "18%",
    lang: "sv",
  },
  {
    name: "percorso-3",
    type: "path",
    title: "",
    top: "15%",
    right: "",
    bottom: "",
    left: "20%",
    lang: "sv",
  },
  {
    name: "percorso-4",
    type: "path",
    title: "",
    top: "15%",
    right: "",
    bottom: "",
    left: "44%",
    lang: "sv",
  },
  {
    name: "percorso-5",
    type: "path",
    title: "",
    top: "29%",
    right: "",
    bottom: "",
    left: "47%",
    lang: "sv",
  },

  //  IT & EN
  {
    title: getLanguageTranslation("Introduzione"),
    type: "product",
    name: "intro",
    top: "29%",
    right: "",
    bottom: "",
    left: "31%",
    lang: "it",
  },
  {
    title: "Patio V4 - R Pitch",
    type: "product",
    name: "patio-v4-r-pitch",
    top: "",
    right: "",
    bottom: "12%",
    left: "27%",
    lang: "it",
  },
  {
    title: "Giotto Plus - Giotto",
    type: "product",
    name: "giotto-plus-giotto",
    top: "",
    right: "42.5%",
    bottom: "12%",
    left: "",
    lang: "it",
  },
  {
    title: "Mini Helix BQ - Mini Helix PS",
    type: "product",
    name: "mini-helix-bq-mini-helix-ps",
    top: "33%",
    right: "33%",
    bottom: "",
    left: "",
    lang: "it",
  },
  {
    title: "Ely",
    type: "product",
    name: "new-ely",
    top: "",
    right: "",
    bottom: "35%",
    left: "39.5%",
    lang: "it",
  },
  {
    title: "Glass 1.0",
    type: "product",
    name: "glass-1",
    top: "",
    right: "",
    bottom: "27%",
    left: "36%",
    lang: "it",
  },
  {
    title: "Sirio - Space",
    type: "product",
    name: "sirio-space",
    top: "",
    right: "26%",
    bottom: "14%",
    left: "",
    lang: "it",
  },
  {
    title: "Base Lite - Epica",
    type: "product",
    name: "base-lite-epica",
    top: "28%",
    right: "",
    bottom: "",
    left: "11%",
    lang: "it",
  },
  {
    title: "Ombralsun",
    type: "product",
    name: "ombralsun",
    top: "",
    right: "36.5%",
    bottom: "35%",
    left: "",
    lang: "it",
  },
  {
    title: "Giant - Zenith",
    type: "product",
    name: "giant-zenith",
    top: "7%",
    right: "",
    bottom: "",
    left: "16%",
    lang: "it",
  },
  {
    title: "Screeny 110GC - Screeny 130 GPZ I",
    type: "product",
    name: "screeny-110-gc-screeny-130-gpz-i",
    top: "19%",
    right: "22%",
    bottom: "",
    left: "",
    lang: "it",
  },
  {
    title: "2010",
    type: "product",
    name: "2010",
    top: "7%",
    right: "",
    bottom: "",
    left: "11%",
    lang: "it",
  },
  {
    title: "Armony Plus - 2040",
    type: "product",
    name: "armony-plus-2040",
    top: "18%",
    right: "",
    bottom: "",
    left: "11%",
    lang: "it",
  },
  {
    title: "Base Plus - Epica",
    type: "product",
    name: "base-plus-epica",
    top: "33.5%",
    right: "",
    bottom: "",
    left: "11%",
    lang: "it",
  },
  {
    title: "2020 - 2040",
    type: "product",
    name: "2020-2040",
    top: "12.5%",
    right: "",
    bottom: "",
    left: "11%",
    lang: "it",
  },
  {
    title: "Screeny Storm - Screeny 130 GPZ Unica M",
    type: "product",
    name: "screeny-150-storm-screeny-130-gpz-unica-m",
    top: "9%",
    right: "22%",
    bottom: "",
    left: "",
    lang: "it",
  },
  {
    title: "Screeny 150 GPZ Unica AM - Screeny 110 GC Cabrio",
    type: "product",
    name: "screeny-150-gpz-unica-screeny-110-gc-cabrio",
    top: "20%",
    right: "40%",
    bottom: "",
    left: "",
    lang: "it",
  },
  {
    title: "Screeny 130 GA - Screeny 130 GPZ MB",
    type: "product",
    name: "screeny-130-ga-screeny-130-gpz-mb",
    top: "23%",
    right: "24%",
    bottom: "",
    left: "",
    lang: "it",
  },
  {
    title: "Helix BQ - Helix PS",
    type: "product",
    name: "helix-bq-helix-ps",
    top: "33%",
    right: "49%",
    bottom: "",
    left: "",
    lang: "it",
  },
  {
    title: "Domea - Grandangolo",
    type: "product",
    name: "domea-grandangolo",
    top: "",
    right: "",
    bottom: "50%",
    left: "11%",
    lang: "it",
  },
  {
    title: "Base Lite - Armony Plus",
    type: "product",
    name: "base-lite-armony-plus",
    top: "23%",
    right: "",
    bottom: "",
    left: "11%",
    lang: "it",
  },
  {
    title: "Base Plus - Domea",
    type: "product",
    name: "base-plus-domea",
    top: "39%",
    right: "",
    bottom: "",
    left: "11%",
    lang: "it",
  },
  {
    title: "Screeny 110GC - Screeny 130 GPZ Unica M",
    type: "product",
    name: "screeny-110-gc-screeny-130-gpz-unica-m",
    top: "14%",
    right: "22%",
    bottom: "",
    left: "",
    lang: "it",
  },
  {
    title: "Screeny 110 GPZ Unica AM - Screeny 55 GPXS",
    type: "product",
    name: "screeny-110-gpz-unica-am-screeny-55-gp-xs",
    top: "14%",
    right: "40%",
    bottom: "",
    left: "",
    lang: "it",
  },
  {
    title: "Epica Lite - 130 GPZ TO",
    type: "product",
    name: "parigi-plus-screeny-130-gpz-tens-to",
    top: "33%",
    right: "",
    bottom: "",
    left: "28%",
    lang: "it",
  },
  // {
  //   title: "New Roma",
  //   type: "product",
  //   name: "new-roma",
  //   top: "9%",
  //   right: "22%",
  //   bottom: "",
  //   left: ""
  // },
  {
    title: "Verso Cassonetto - Genova",
    type: "product",
    name: "verso-genova",
    top: "9%",
    right: "28%",
    bottom: "",
    left: "",
    lang: "it",
  },
  // {
  //   title: "Havant",
  //   type: "product",
  //   name: "havant",
  //   top: "9%",
  //   right: "34%",
  //   bottom: "",
  //   left: ""
  // },
  {
    title: "Screeny 110 GPZ Unica AM - Screeny 85 GPZS",
    type: "product",
    name: "screeny-110-gpz-unica-am-screeny-85-gpz-s",
    top: "9%",
    right: "40%",
    bottom: "",
    left: "",
    lang: "it",
  },
  {
    name: "percorso-1",
    type: "path",
    title: "",
    top: "15.5%",
    right: "",
    bottom: "",
    left: "24%",
    lang: "it",
  },
  {
    name: "percorso-2",
    type: "path",
    title: "",
    top: "13%",
    right: "48.5%",
    bottom: "",
    left: "",
    lang: "it",
  },
  // {
  //   name: "percorso-3",
  //   type: "path",
  //   title: "",
  //   top: "",
  //   right: "",
  //   bottom: "",
  //   left: ""
  // },
  {
    name: "percorso-4",
    type: "path",
    title: "",
    top: "",
    right: "",
    bottom: "45%",
    left: "15%",
    lang: "it",
  },
  {
    name: "percorso-5",
    type: "path",
    title: "",
    top: "",
    right: "",
    bottom: "37%",
    left: "23%",
    lang: "it",
  },
  {
    name: "percorso-6",
    type: "path",
    title: "",
    top: "",
    right: "",
    bottom: "6%",
    left: "26%",
    lang: "it",
  },
  {
    name: "percorso-7",
    type: "path",
    title: "",
    top: "",
    right: "28%",
    bottom: "32%",
    left: "",
    lang: "it",
  },
  {
    name: "percorso-8",
    type: "path",
    title: "",
    top: "",
    right: "28%",
    bottom: "38%",
    left: "",
    lang: "it",
  },

  {
    title: getLanguageTranslation("Introduzione"),
    type: "product",
    name: "intro",
    top: "29%",
    right: "",
    bottom: "",
    left: "31%",
    lang: "en",
  },
  {
    title: "Patio V4 - R Pitch",
    type: "product",
    name: "patio-v4-r-pitch",
    top: "",
    right: "",
    bottom: "12%",
    left: "27%",
    lang: "en",
  },
  {
    title: "Giotto Plus - Giotto",
    type: "product",
    name: "giotto-plus-giotto",
    top: "",
    right: "42.5%",
    bottom: "12%",
    left: "",
    lang: "en",
  },
  {
    title: "Mini Helix BQ - Mini Helix PS",
    type: "product",
    name: "mini-helix-bq-mini-helix-ps",
    top: "33%",
    right: "33%",
    bottom: "",
    left: "",
    lang: "en",
  },
  {
    title: "Ely",
    type: "product",
    name: "new-ely",
    top: "",
    right: "",
    bottom: "35%",
    left: "39.5%",
    lang: "en",
  },
  {
    title: "Glass 1.0",
    type: "product",
    name: "glass-1",
    top: "",
    right: "",
    bottom: "27%",
    left: "36%",
    lang: "en",
  },
  {
    title: "Sirio - Space",
    type: "product",
    name: "sirio-space",
    top: "",
    right: "26%",
    bottom: "14%",
    left: "",
    lang: "en",
  },
  {
    title: "Base Lite - Epica",
    type: "product",
    name: "base-lite-epica",
    top: "28%",
    right: "",
    bottom: "",
    left: "11%",
    lang: "en",
  },
  {
    title: "Ombralsun",
    type: "product",
    name: "ombralsun",
    top: "",
    right: "36.5%",
    bottom: "35%",
    left: "",
    lang: "en",
  },
  {
    title: "Giant - Zenith",
    type: "product",
    name: "giant-zenith",
    top: "7%",
    right: "",
    bottom: "",
    left: "16%",
    lang: "en",
  },
  {
    title: "Screeny 110GC - Screeny 130 GPZ I",
    type: "product",
    name: "screeny-110-gc-screeny-130-gpz-i",
    top: "19%",
    right: "22%",
    bottom: "",
    left: "",
    lang: "en",
  },
  {
    title: "2010",
    type: "product",
    name: "2010",
    top: "7%",
    right: "",
    bottom: "",
    left: "11%",
    lang: "en",
  },
  {
    title: "Armony Plus - 2040",
    type: "product",
    name: "armony-plus-2040",
    top: "18%",
    right: "",
    bottom: "",
    left: "11%",
    lang: "en",
  },
  {
    title: "Base Plus - Epica",
    type: "product",
    name: "base-plus-epica",
    top: "33.5%",
    right: "",
    bottom: "",
    left: "11%",
    lang: "en",
  },
  {
    title: "2020 - 2040",
    type: "product",
    name: "2020-2040",
    top: "12.5%",
    right: "",
    bottom: "",
    left: "11%",
    lang: "en",
  },
  {
    title: "Screeny Storm - Screeny 130 GPZ Unica M",
    type: "product",
    name: "screeny-150-storm-screeny-130-gpz-unica-m",
    top: "9%",
    right: "22%",
    bottom: "",
    left: "",
    lang: "en",
  },
  {
    title: "Screeny 150 GPZ Unica AM - Screeny 110 GC Cabrio",
    type: "product",
    name: "screeny-150-gpz-unica-screeny-110-gc-cabrio",
    top: "20%",
    right: "40%",
    bottom: "",
    left: "",
    lang: "en",
  },
  {
    title: "Screeny 130 GA - Screeny 130 GPZ MB",
    type: "product",
    name: "screeny-130-ga-screeny-130-gpz-mb",
    top: "23%",
    right: "24%",
    bottom: "",
    left: "",
    lang: "en",
  },
  {
    title: "Helix BQ - Helix PS",
    type: "product",
    name: "helix-bq-helix-ps",
    top: "33%",
    right: "49%",
    bottom: "",
    left: "",
    lang: "en",
  },
  {
    title: "Domea - Grandangolo",
    type: "product",
    name: "domea-grandangolo",
    top: "",
    right: "",
    bottom: "50%",
    left: "11%",
    lang: "en",
  },
  {
    title: "Base Lite - Armony Plus",
    type: "product",
    name: "base-lite-armony-plus",
    top: "23%",
    right: "",
    bottom: "",
    left: "11%",
    lang: "en",
  },
  {
    title: "Base Plus - Domea",
    type: "product",
    name: "base-plus-domea",
    top: "39%",
    right: "",
    bottom: "",
    left: "11%",
    lang: "en",
  },
  {
    title: "Screeny 110GC - Screeny 130 GPZ Unica M",
    type: "product",
    name: "screeny-110-gc-screeny-130-gpz-unica-m",
    top: "14%",
    right: "22%",
    bottom: "",
    left: "",
    lang: "en",
  },
  {
    title: "Screeny 110 GPZ Unica AM - Screeny 55 GPXS",
    type: "product",
    name: "screeny-110-gpz-unica-am-screeny-55-gp-xs",
    top: "14%",
    right: "40%",
    bottom: "",
    left: "",
    lang: "en",
  },
  {
    title: "Epica Lite - 130 GPZ TO",
    type: "product",
    name: "parigi-plus-screeny-130-gpz-tens-to",
    top: "33%",
    right: "",
    bottom: "",
    left: "28%",
    lang: "en",
  },
  // {
  //   title: "New Roma",
  //   type: "product",
  //   name: "new-roma",
  //   top: "9%",
  //   right: "22%",
  //   bottom: "",
  //   left: ""
  // },
  {
    title: "Verso Cassonetto - Genova",
    type: "product",
    name: "verso-genova",
    top: "9%",
    right: "28%",
    bottom: "",
    left: "",
    lang: "en",
  },
  // {
  //   title: "Havant",
  //   type: "product",
  //   name: "havant",
  //   top: "9%",
  //   right: "34%",
  //   bottom: "",
  //   left: ""
  // },
  {
    title: "Screeny 110 GPZ Unica AM - Screeny 85 GPZS",
    type: "product",
    name: "screeny-110-gpz-unica-am-screeny-85-gpz-s",
    top: "9%",
    right: "40%",
    bottom: "",
    left: "",
    lang: "en",
  },
  {
    name: "percorso-1",
    type: "path",
    title: "",
    top: "15.5%",
    right: "",
    bottom: "",
    left: "24%",
    lang: "en",
  },
  {
    name: "percorso-2",
    type: "path",
    title: "",
    top: "13%",
    right: "48.5%",
    bottom: "",
    left: "",
    lang: "en",
  },
  // {
  //   name: "percorso-3",
  //   type: "path",
  //   title: "",
  //   top: "",
  //   right: "",
  //   bottom: "",
  //   left: ""
  // },
  {
    name: "percorso-4",
    type: "path",
    title: "",
    top: "",
    right: "",
    bottom: "45%",
    left: "15%",
    lang: "en",
  },
  {
    name: "percorso-5",
    type: "path",
    title: "",
    top: "",
    right: "",
    bottom: "37%",
    left: "23%",
    lang: "en",
  },
  {
    name: "percorso-6",
    type: "path",
    title: "",
    top: "",
    right: "",
    bottom: "6%",
    left: "26%",
    lang: "en",
  },
  {
    name: "percorso-7",
    type: "path",
    title: "",
    top: "",
    right: "28%",
    bottom: "32%",
    left: "",
    lang: "en",
  },
  {
    name: "percorso-8",
    type: "path",
    title: "",
    top: "",
    right: "28%",
    bottom: "38%",
    left: "",
    lang: "en",
  },
];
