import { TechnicalSpecificsType } from "../types";
import { useCreateOptionalPoint } from "./use-create-optional-point";
import { useCreateInfoPoint } from "./use-create-info-point";
import { useCreatePositionPoint } from "./use-create-position-point";
import { useCreateVideoPoint } from "./use-create-video-point";
import {
  create2040GiantInfoPointsUsa, createEpicaLiteInfoPointsUsa,
  createIntroInfoPointsUsa,
  createNewItaliaInfoPointsUsa,
  createPatioV4ArmonyPlusInfoPointsUsa,
  createPercorso1InfoPointsUsa,
  createPercorso2InfoPointsUsa,
  createR90RPitchInfoPointsUsa,
  createSpaceInfoPointsUsa,
} from "./info-points/info-points-en-us";
import {
  create130GpzToInfoPointsEs,
  create20202010InfoPointsEs,
  createArmonyPlusGiantInfoPointsEs,
  createBravoVersoCassonettoInfoPointsEs,
  createDomeaBasePlusInfoPointsEs,
  createElyInfoPointsEs,
  createEpicaBaseLiteInfoPointsEs,
  createGiottoPlusGiottoInfoPointsEs,
  createIntroInfoPointsEs,
  createLineGlassInfoPointsEs,
  createMiniHelixPsHelixPsInfoPointsEs,
  createPercorso1InfoPointsEs,
  createPercorso2InfoPointsEs,
  createPercorso3InfoPointsEs,
  createPercorso4InfoPointsEs,
  createPercorso5InfoPointsEs,
  createPercorso6InfoPointsEs,
  createPercorso7InfoPointsEs,
  createPercorso8InfoPointsEs,
  createPercorso9InfoPointsEs,
  createScreeny110GcScreeny130GpzIInfoPointsEs,
  createScreeny110GpzUnicaAmScreeny130GpzUnicaMInfoPointsEs,
  createScreeny130GaScreeny130GpzMbInfoPointsEs,
  createScreeny150GpzUnicaAmScreeny110GcCabrioInfoPointsEs,
  createSpaceSirioInfoPointsEs,
  createVersoCassonettoMiniVersoInfoPointsEs,
} from "./info-points/info-points-es";
import { useCreateVrPoint } from "./use-create-vr-point";
import { useCreateColorPoint } from "./use-create-color-point";
import {
  create130GpzToInfoPointsFr,
  create20202010InfoPointsFr,
  create20202040InfoPointsFr,
  createArmonyPlus2040InfoPointsFr,
  createBaseLiteArmonyPlusInfoPointsFr,
  createBasePlusEpicaInfoPointsFr,
  createDomeaBasePlusInfoPointsFr,
  createElyInfoPointsFr,
  createEpicaBaseLiteInfoPointsFr,
  createEpicaLiteInfoPointsFr,
  createGiantZenithInfoPointsFr,
  createHelixBqHelixPsInfoPointsFr,
  createIntroInfoPointsFr,
  createLevaIncastroBqDomeaInfoPointsFr,
  createMiniHelixBqMiniHelixPsInfoPointsFr,
  createOmbralsunInfoPointsFr,
  createParigiPlusParigiPlusLiteInfoPointsFr,
  createPercorso1InfoPointsFr,
  createPercorso2InfoPointsFr,
  createPercorso3InfoPointsFr,
  createPercorso4InfoPointsFr,
  createPercorso5InfoPointsFr,
  createPercorso6InfoPointsFr,
  createPercorso8InfoPointsFr,
  createScreeny110GcScreeny130GpzIInfoPointsFr,
  createScreeny110GpzUnicaAMScreeny110GcCabrioInfoPointsFr,
  createScreeny130GaScreeny130GpzMbInfoPointsFr,
  createScreeny150StormScreeny130GpzUnicaMInfoPointsFr,
  createSirioInfoPointsFr,
  createVersoCassonettoInfoPointsFr,
} from "./info-points/info-points-fr";
import {
  createEpicaGiantInfoPointsDe,
  createEpicaLiteEpicaInfoPointsDe,
  createHavantInfoPointsDe,
  createIntroInfoPointsDe,
  createOmbralsunInfoPointsDe,
  createPercorso1InfoPointsDe,
  createPercorso2InfoPointsDe,
  createPercorso3InfoPointsDe,
  createPercorso4InfoPointsDe,
  createPercorso5InfoPointsDe,
  createRPitch130GpzToInfoPointsDe,
  createScreeny110GcScreeny130GpzIInfoPointsDe,
  createScreeny110GpzUnicaAmScreeny110GcCabrioInfoPointsDe,
  createScreeny130GaScreeny130GpzMbInfoPointsDe,
  createScreeny130GpzUnicaMScreeny110GcInfoPointsDe,
  createSpaceR90InfoPointsDe,
  createZenith2040InfoPointsDe,
} from "./info-points/info-points-de";
import { LOCALSTORAGE_LANGUAGE } from "../consts";
import {
  create2010InfoPointsEnIt,
  create20202040InfoPointsEnIt,
  createArmonyPlus2040InfoPointsEnIt,
  createBaseLiteArmonyPlusInfoPointsEnIt,
  createBaseLiteEpicaInfoPointsEnIt,
  createBasePlusDomeaInfoPointsEnIt,
  createBasePlusEpicaInfoPointsEnIt,
  createDomeaGrandangoloInfoPointsEnIt,
  createGiantZenithInfoPointsEnIt,
  createGiottoPlusGiottoInfoPointsEnIt,
  createGlass1InfoPointsEnIt,
  createHavantInfoPointsEnIt,
  createHelixBqHelixPsInfoPointsEnIt,
  createIntroInfoPointsEnIt,
  createMiniHelixBqMiniHelixPsInfoPointsEnIt,
  createNewElyInfoPointsEnIt,
  createNewRomaInfoPointsEnIt,
  createOmbralsunInfoPointsEnIt,
  createParigiPlusScreeny130GpzTensToInfoPointsEnIt,
  createPatioV4RPitchInfoPointsEnIt,
  createPercorso1InfoPointsEnIt,
  createPercorso2InfoPointsEnIt,
  createPercorso4InfoPointsEnIt,
  createPercorso5InfoPointsEnIt,
  createPercorso6InfoPointsEnIt,
  createPercorso7InfoPointsEnIt,
  createPercorso8InfoPointsEnIt,
  createScreeny110GcScreeny130GpzIInfoPointsEnIt,
  createScreeny110GcScreeny130GpzUnicaMInfoPointsEnIt,
  createScreeny110GpzUnicaAmScreeny55GpXsInfoPointsEnIt,
  createScreeny110GpzUnicaAmScreeny85GpzSInfoPointsEnIt,
  createScreeny130GaScreeny130GpzMbInfoPointsEnIt,
  createScreeny150GpzUnicaScreeny110GcCabrioInfoPointsEnIt,
  createScreeny150StormScreeny130GpzUnicaMEnIt,
  createSirioSpaceInfoPointsEnIt,
  createVersoGenovaInfoPointsEnIt,
} from "./info-points/info-points-en-it";
import { getLanguage } from "./get-language-code";

export const createInfoPoints: (
  setPopupInfos: any,
  setTechnicalSpecifics: any,
  technicalSpecifics: TechnicalSpecificsType
) => Record<string, any> = (
  setPopupInfos,
  setTechnicalSpecifics,
  technicalSpecifics
) => {
  const createOptionalPoint = useCreateOptionalPoint(
    setTechnicalSpecifics,
    technicalSpecifics
  );
  const createInfoPoint = useCreateInfoPoint(setPopupInfos);
  const createPositionPoint = useCreatePositionPoint(
    setTechnicalSpecifics,
    technicalSpecifics
  );
  const createVideoPoint = useCreateVideoPoint(setPopupInfos);
  const createVrPoint = useCreateVrPoint(setPopupInfos);
  const createColorPoint = useCreateColorPoint(setPopupInfos);

  let lang = undefined;
  if (typeof window !== `undefined`) lang = getLanguage();

  if (lang === "us") {
    return {
      // posizione: funzionePerCrearePosizione(funzioni)
      intro: createIntroInfoPointsUsa(createPositionPoint),
      /*"new-italia": createNewItaliaInfoPointsUsa(
        createPositionPoint,
        createInfoPoint,
        createVrPoint
      ),*/
      "percorso-1": createPercorso1InfoPointsUsa(createPositionPoint),
      "percorso-2": createPercorso2InfoPointsUsa(createPositionPoint),
      "r90-r-pitch": createR90RPitchInfoPointsUsa(
        createPositionPoint,
        createInfoPoint,
        createVrPoint
      ),
      space: createSpaceInfoPointsUsa(
        createPositionPoint,
        createInfoPoint,
        createVrPoint
      ),
      "epica-lite": createEpicaLiteInfoPointsUsa(
        createPositionPoint,
        createInfoPoint,
        createVrPoint,
        createVideoPoint
      ),
      "patio-v4-armony-plus": createPatioV4ArmonyPlusInfoPointsUsa(
        createPositionPoint,
        createInfoPoint,
        createVrPoint
      ),
      "2040-giant": create2040GiantInfoPointsUsa(
        createPositionPoint,
        createInfoPoint,
        createVrPoint
      ),
    };
  } else if (lang === "es") {
    return {
      intro: createIntroInfoPointsEs(createPositionPoint),
      "mini-helix-ps-helix-ps": createMiniHelixPsHelixPsInfoPointsEs(
        createPositionPoint,
        createInfoPoint,
        createVrPoint
      ),
      "domea-base-plus": createDomeaBasePlusInfoPointsEs(
        createPositionPoint,
        createInfoPoint,
        createVrPoint
      ),
      "130-gpz-to": create130GpzToInfoPointsEs(
        createPositionPoint,
        createInfoPoint,
        createVrPoint,
        createVideoPoint
      ),
      "armony-plus-giant": createArmonyPlusGiantInfoPointsEs(
        createPositionPoint,
        createInfoPoint,
        createVrPoint
      ),
      "epica-base-lite": createEpicaBaseLiteInfoPointsEs(
        createPositionPoint,
        createInfoPoint,
        createVrPoint
      ),
      "2020-2010": create20202010InfoPointsEs(
        createPositionPoint,
        createInfoPoint,
        createVrPoint
      ),
      "percorso-1": createPercorso1InfoPointsEs(createPositionPoint),
      "percorso-2": createPercorso2InfoPointsEs(createPositionPoint),
      "percorso-3": createPercorso3InfoPointsEs(createPositionPoint),
      "percorso-4": createPercorso4InfoPointsEs(createPositionPoint),
      "percorso-5": createPercorso5InfoPointsEs(createPositionPoint),
      "percorso-6": createPercorso6InfoPointsEs(createPositionPoint),
      "percorso-7": createPercorso7InfoPointsEs(createPositionPoint),
      "percorso-8": createPercorso8InfoPointsEs(createPositionPoint),
      "bravo-verso-cassonetto": createBravoVersoCassonettoInfoPointsEs(
        createPositionPoint,
        createInfoPoint,
        createVrPoint
      ),
      /*"verso-cassonetto-mini-verso": createVersoCassonettoMiniVersoInfoPointsEs(
        createPositionPoint,
        createInfoPoint,
        createVrPoint
      ),*/
      "screeny-110-gpz-unica-am-screeny-130-gpz-unica-m": createScreeny110GpzUnicaAmScreeny130GpzUnicaMInfoPointsEs(
        createPositionPoint,
        createInfoPoint,
        createVrPoint
      ),
      "screeny-110-gc-screeny-130-gpz-i": createScreeny110GcScreeny130GpzIInfoPointsEs(
        createPositionPoint,
        createInfoPoint,
        createVrPoint
      ),
      "screeny-130-ga-screeny-130-gpz-mb": createScreeny130GaScreeny130GpzMbInfoPointsEs(
        createPositionPoint,
        createInfoPoint,
        createVrPoint
      ),
      "screeny-150-gpz-unica-am-screeny-110-gc-cabrio": createScreeny150GpzUnicaAmScreeny110GcCabrioInfoPointsEs(
        createPositionPoint,
        createInfoPoint,
        createVrPoint
      ),
      "space-sirio": createSpaceSirioInfoPointsEs(
        createPositionPoint,
        createInfoPoint,
        createVrPoint,
        createVideoPoint
      ),
      "line-glass-1.0": createLineGlassInfoPointsEs(
        createPositionPoint,
        createInfoPoint,
        createVrPoint
      ),
      ely: createElyInfoPointsEs(
        createPositionPoint,
        createInfoPoint,
        createVrPoint
      ),
      "percorso-9": createPercorso9InfoPointsEs(createPositionPoint),
      "giotto-plus-giotto": createGiottoPlusGiottoInfoPointsEs(
        createPositionPoint,
        createInfoPoint,
        createVrPoint
      ),
    };
  }
    else if (lang === "fr") {
    return {
      intro: createIntroInfoPointsFr(createPositionPoint),
      "130-gpz-to": create130GpzToInfoPointsFr(
        createPositionPoint,
        createInfoPoint,
        createVrPoint
      ),
      "percorso-1": createPercorso1InfoPointsFr(createPositionPoint),
      "percorso-2": createPercorso2InfoPointsFr(createPositionPoint),
      "percorso-3": createPercorso3InfoPointsFr(createPositionPoint),
      "percorso-4": createPercorso4InfoPointsFr(createPositionPoint),
      "percorso-5": createPercorso5InfoPointsFr(createPositionPoint),
      "percorso-6": createPercorso6InfoPointsFr(createPositionPoint),
      "percorso-8": createPercorso8InfoPointsFr(createPositionPoint),
      "2020-2010": create20202010InfoPointsFr(
        createPositionPoint,
        createInfoPoint,
        createVrPoint
      ),
      "2020-2040": create20202040InfoPointsFr(
        createPositionPoint,
        createInfoPoint,
        createVrPoint
      ),
      "armony-plus-2040": createArmonyPlus2040InfoPointsFr(
        createPositionPoint,
        createInfoPoint,
        createVrPoint
      ),
      "epica-base-lite": createEpicaBaseLiteInfoPointsFr(
        createPositionPoint,
        createInfoPoint,
        createVrPoint
      ),
      "epica-lite": createEpicaLiteInfoPointsFr(
        createPositionPoint,
        createInfoPoint,
        createVrPoint,
        createVideoPoint
      ),
      "base-plus-epica": createBasePlusEpicaInfoPointsFr(
        createPositionPoint,
        createInfoPoint,
        createVrPoint
      ),
      "base-lite-armony-plus": createBaseLiteArmonyPlusInfoPointsFr(
        createPositionPoint,
        createInfoPoint,
        createVrPoint
      ),
      "domea-base-plus": createDomeaBasePlusInfoPointsFr(
        createPositionPoint,
        createInfoPoint,
        createVrPoint
      ),
      "leva-incastro-bq-domea": createLevaIncastroBqDomeaInfoPointsFr(
        createPositionPoint,
        createInfoPoint,
        createVrPoint
      ),
      ely: createElyInfoPointsFr(
        createPositionPoint,
        createInfoPoint,
        createVrPoint
      ),
      sirio: createSirioInfoPointsFr(
        createPositionPoint,
        createInfoPoint,
        createVrPoint,
        createVideoPoint
      ),
      "verso-cassonetto": createVersoCassonettoInfoPointsFr(
        createPositionPoint,
        createInfoPoint,
        createVrPoint
      ),
      "screeny-150-storm-screeny-130-gpz-unica-m": createScreeny150StormScreeny130GpzUnicaMInfoPointsFr(
        createPositionPoint,
        createInfoPoint,
        createVrPoint
      ),
      "screeny-110-gc-screeny-130-gpz-i": createScreeny110GcScreeny130GpzIInfoPointsFr(
        createPositionPoint,
        createInfoPoint,
        createVrPoint
      ),
      "screeny-110-gpz-unica-am-screeny-110-gc-cabrio": createScreeny110GpzUnicaAMScreeny110GcCabrioInfoPointsFr(
        createPositionPoint,
        createInfoPoint,
        createVrPoint
      ),
      "screeny-130-ga-screeny-130-gpz-mb": createScreeny130GaScreeny130GpzMbInfoPointsFr(
        createPositionPoint,
        createInfoPoint,
        createVrPoint
      ),
      "giant-zenith": createGiantZenithInfoPointsFr(
        createPositionPoint,
        createInfoPoint,
        createVrPoint
      ),
      /*"parigi-plus-parigi-plus-lite": createParigiPlusParigiPlusLiteInfoPointsFr(
        createPositionPoint,
        createInfoPoint,
        createVrPoint
      ),*/
      "helix-bq-helix-ps": createHelixBqHelixPsInfoPointsFr(
        createPositionPoint,
        createInfoPoint,
        createVrPoint
      ),
      "mini-helix-bq-mini-helix-ps": createMiniHelixBqMiniHelixPsInfoPointsFr(
        createPositionPoint,
        createInfoPoint,
        createVrPoint
      ),
      ombralsun: createOmbralsunInfoPointsFr(
        createPositionPoint,
        createInfoPoint,
        createVrPoint
      ),
    };
  }
    else if (lang === "sv") {
    return {
      intro: createIntroInfoPointsDe(createPositionPoint),
      "percorso-1": createPercorso1InfoPointsDe(createPositionPoint),
      "percorso-2": createPercorso2InfoPointsDe(createPositionPoint),
      "percorso-3": createPercorso3InfoPointsDe(createPositionPoint),
      "percorso-4": createPercorso4InfoPointsDe(createPositionPoint),
      "percorso-5": createPercorso5InfoPointsDe(createPositionPoint),
      "r-pitch-130-gpz-to": createRPitch130GpzToInfoPointsDe(
        createPositionPoint,
        createInfoPoint,
        createVrPoint
      ),
      /*"space-r90": createSpaceR90InfoPointsDe(
        createPositionPoint,
        createInfoPoint,
        createVrPoint
      ),*/
      "epica-giant": createEpicaGiantInfoPointsDe(
        createPositionPoint,
        createInfoPoint,
        createVrPoint
      ),
      "epica-epica-lite": createEpicaLiteEpicaInfoPointsDe(
        createPositionPoint,
        createInfoPoint,
        createVrPoint,
        createVideoPoint
      ),
      "zenith-2040": createZenith2040InfoPointsDe(
        createPositionPoint,
        createInfoPoint,
        createVrPoint
      ),
      /*havant: createHavantInfoPointsDe(
        createPositionPoint,
        createInfoPoint,
        createVrPoint
      ),*/
      ombralsun: createOmbralsunInfoPointsDe(
        createPositionPoint,
        createInfoPoint,
        createVrPoint
      ),
      "screeny-130-gpz-unica-m-screeny-110-gc": createScreeny130GpzUnicaMScreeny110GcInfoPointsDe(
        createPositionPoint,
        createInfoPoint,
        createVrPoint
      ),
      "screeny-110-gc-screeny-130-gpz-i": createScreeny110GcScreeny130GpzIInfoPointsDe(
        createPositionPoint,
        createInfoPoint,
        createVrPoint
      ),
      "screeny-130-ga-screeny-130-gpz-mb": createScreeny130GaScreeny130GpzMbInfoPointsDe(
        createPositionPoint,
        createInfoPoint,
        createVrPoint
      ),
      "screeny-110-gpz-unica-am-screeny-110-gc-cabrio": createScreeny110GpzUnicaAmScreeny110GcCabrioInfoPointsDe(
        createPositionPoint,
        createInfoPoint,
        createVrPoint
      ),
    };
  }
  if (lang === "it" || lang === "en") {
    return {
      intro: createIntroInfoPointsEnIt(createPositionPoint),
      "patio-v4-r-pitch": createPatioV4RPitchInfoPointsEnIt(
        createPositionPoint,
        createInfoPoint
      ),
      "giotto-plus-giotto": createGiottoPlusGiottoInfoPointsEnIt(
        createPositionPoint,
        createInfoPoint
      ),
      "mini-helix-bq-mini-helix-ps": createMiniHelixBqMiniHelixPsInfoPointsEnIt(
        createPositionPoint,
        createInfoPoint
      ),
      "new-ely": createNewElyInfoPointsEnIt(
        createPositionPoint,
        createInfoPoint
      ),
      "glass-1": createGlass1InfoPointsEnIt(
        createPositionPoint,
        createInfoPoint
      ),
      "sirio-space": createSirioSpaceInfoPointsEnIt(
        createPositionPoint,
        createInfoPoint,
        createVideoPoint
      ),
      "base-lite-epica": createBaseLiteEpicaInfoPointsEnIt(
        createPositionPoint,
        createInfoPoint
      ),
      ombralsun: createOmbralsunInfoPointsEnIt(
        createPositionPoint,
        createInfoPoint
      ),
      "giant-zenith": createGiantZenithInfoPointsEnIt(
        createPositionPoint,
        createInfoPoint
      ),
      "screeny-110-gc-screeny-130-gpz-i": createScreeny110GcScreeny130GpzIInfoPointsEnIt(
        createPositionPoint,
        createInfoPoint
      ),
      "2010": create2010InfoPointsEnIt(createPositionPoint, createInfoPoint),
      "armony-plus-2040": createArmonyPlus2040InfoPointsEnIt(
        createPositionPoint,
        createInfoPoint
      ),
      "base-plus-epica": createBasePlusEpicaInfoPointsEnIt(
        createPositionPoint,
        createInfoPoint
      ),
      "2020-2040": create20202040InfoPointsEnIt(
        createPositionPoint,
        createInfoPoint
      ),
      "screeny-150-gpz-unica-screeny-110-gc-cabrio": createScreeny150GpzUnicaScreeny110GcCabrioInfoPointsEnIt(
        createPositionPoint,
        createInfoPoint
      ),
      "screeny-130-ga-screeny-130-gpz-mb": createScreeny130GaScreeny130GpzMbInfoPointsEnIt(
        createPositionPoint,
        createInfoPoint
      ),
      "helix-bq-helix-ps": createHelixBqHelixPsInfoPointsEnIt(
        createPositionPoint,
        createInfoPoint
      ),
      "domea-grandangolo": createDomeaGrandangoloInfoPointsEnIt(
        createPositionPoint,
        createInfoPoint
      ),
      "base-lite-armony-plus": createBaseLiteArmonyPlusInfoPointsEnIt(
        createPositionPoint,
        createInfoPoint
      ),
      "base-plus-domea": createBasePlusDomeaInfoPointsEnIt(
        createPositionPoint,
        createInfoPoint
      ),
      "screeny-110-gc-screeny-130-gpz-unica-m": createScreeny110GcScreeny130GpzUnicaMInfoPointsEnIt(
        createPositionPoint,
        createInfoPoint
      ),
      "screeny-110-gpz-unica-am-screeny-55-gp-xs": createScreeny110GpzUnicaAmScreeny55GpXsInfoPointsEnIt(
        createPositionPoint,
        createInfoPoint
      ),
      "parigi-plus-screeny-130-gpz-tens-to": createParigiPlusScreeny130GpzTensToInfoPointsEnIt(
        createPositionPoint,
        createInfoPoint,
        createVrPoint,
        createVideoPoint
      ),
      "new-roma": createNewRomaInfoPointsEnIt(
        createPositionPoint,
        createInfoPoint
      ),
      "verso-genova": createVersoGenovaInfoPointsEnIt(
        createPositionPoint,
        createInfoPoint
      ),
      havant: createHavantInfoPointsEnIt(createPositionPoint),
      "screeny-110-gpz-unica-am-screeny-85-gpz-s": createScreeny110GpzUnicaAmScreeny85GpzSInfoPointsEnIt(
        createPositionPoint,
        createInfoPoint
      ),
      "screeny-150-storm-screeny-130-gpz-unica-m": createScreeny150StormScreeny130GpzUnicaMEnIt(
        createPositionPoint,
        createInfoPoint
      ),

      "percorso-1": createPercorso1InfoPointsEnIt(createPositionPoint),
      "percorso-2": createPercorso2InfoPointsEnIt(createPositionPoint),
      // "percorso-3": [],
      "percorso-4": createPercorso4InfoPointsEnIt(createPositionPoint),
      "percorso-5": createPercorso5InfoPointsEnIt(createPositionPoint),
      "percorso-6": createPercorso6InfoPointsEnIt(createPositionPoint),
      "percorso-7": createPercorso7InfoPointsEnIt(createPositionPoint),
      "percorso-8": createPercorso8InfoPointsEnIt(createPositionPoint),
    };
  }

  return {
    // posizione: funzionePerCrearePosizione(funzioni)
    intro: createIntroInfoPointsUsa(createPositionPoint),
    "new-italia": createNewItaliaInfoPointsUsa(
      createPositionPoint,
      createInfoPoint,
      createVrPoint
    ),
    "percorso-1": createPercorso1InfoPointsUsa(createPositionPoint),
    "percorso-2": createPercorso2InfoPointsUsa(createPositionPoint),
    "r90-r-pitch": createR90RPitchInfoPointsUsa(
      createPositionPoint,
      createInfoPoint,
      createVrPoint
    ),
    space: createSpaceInfoPointsUsa(
      createPositionPoint,
      createInfoPoint,
      createVrPoint
    ),
    "patio-v4-armony-plus": createPatioV4ArmonyPlusInfoPointsUsa(
      createPositionPoint,
      createInfoPoint,
      createVrPoint
    ),
    "2040-giant": create2040GiantInfoPointsUsa(
      createPositionPoint,
      createInfoPoint,
      createVrPoint
    ),
  };
};
