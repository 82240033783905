export const toggleFullscreen = () => {
  if (!document.fullscreenElement) {
    document.body.requestFullscreen().catch((err) => {
      alert(
        `Error attempting to enable full-screen mode: ${err.message} (${err.name})`
      );
    });
  } else {
    document.exitFullscreen().finally();
  }
};
