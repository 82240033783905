import { TechnicalSpecificsType } from "../../types";
import { getTechnicalSpecificsBooleanRoof } from "./get-technical-specifics-boolean-roof";
import { getLanguageCode } from "../get-language-code";

export const getVersoGenovaImageName = (
  technicalSpecifics: TechnicalSpecificsType
): string =>
  `verso-genova${getTechnicalSpecificsBooleanRoof(
    technicalSpecifics
  )}${getLanguageCode()}`;
