import { TechnicalSpecificsType } from "../../types";
import { getTechnicalSpecificsBooleanRoof } from "./get-technical-specifics-boolean-roof";
import { getLanguageCode } from "../get-language-code";

export const getBravoVersoCassonettoImageName = (
  technicalSpecifics: TechnicalSpecificsType
): string =>
  `bravo-verso-cassonetto${getTechnicalSpecificsBooleanRoof(
    technicalSpecifics
  )}${getLanguageCode()}`;
