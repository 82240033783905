export const getLanguageCode = () => {
  if (typeof window !== `undefined`) {
    const lang = location.pathname
      .replace("/", "")
      .replace("/", "")
      .toLowerCase();

    if (lang === "it-eng") {
      return "_en";
    } else if (lang === "it-it") {
      return "_it";
    } else {
      return `_${lang}`;
    }
  }
  return "";
};

export const getLanguage = () => {
  if (typeof window !== `undefined`) {
    const lang = location.pathname
      .replace("/", "")
      .replace("/", "")
      .toLowerCase();

    if (lang === "it-eng") {
      return "en";
    } else if (lang === "it-it") {
      return "it";
    } else {
      return lang;
    }
  }
  return "it";
};
