import { TechnicalSpecificsType } from "../../types";
import { getTechnicalSpecificsBooleanRoof } from "./get-technical-specifics-boolean-roof";
import { getLanguageCode } from "../get-language-code";

export const getNewRomaImageName = (
  technicalSpecifics: TechnicalSpecificsType
): string =>
  `new-roma${getTechnicalSpecificsBooleanRoof(
    technicalSpecifics
  )}${getLanguageCode()}`;
