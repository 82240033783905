// @ts-ignore
import React, { useEffect, useRef, useState } from "react";
if (typeof window !== `undefined`) {
  require("@google/model-viewer");
}

const VirtualRealityComponent: React.FC<{ GLB: any; USDZ: any }> = ({
  GLB,
  USDZ,
}) => {
  const ref = useRef(undefined);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    if (ref.current !== undefined) {
      ref.current.onload = () => setIsLoading(false);
    }
  }, [ref]);
  return (
    <div className="virtual-reality-content__container">
      <div className="virtual-reality__container">
        {isLoading && (
          <div className="loader">
            <div className="lds-roller">
              <div />
              <div />
              <div />
              <div />
              <div />
              <div />
              <div />
              <div />
            </div>
          </div>
        )}

        {typeof window !== `undefined` && (
          <>
            {/* @ts-ignore */}
            <model-viewer
              ref={ref}
              src={GLB}
              auto-rotate={true}
              camera-controls={true}
              alt="KEDRY SKYLIFE"
              shadow-intensity="1"
              interaction-prompt="auto"
              ar={true}
              loading="lazy"
              magic-leap={true}
              ios-src={USDZ}
            />
          </>
        )}
      </div>
    </div>
  );
};
export default VirtualRealityComponent;
