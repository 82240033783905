// @ts-ignore
import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { CloseWhite } from "../icons";

const PopupStoreLocator: React.FC<{
  popupInfos: any;
  setPopupInfos: any;
}> = ({ popupInfos, setPopupInfos }) => {
  return (
    <div className="store-locator-container">
      <div className="popup-header">
        <div className="close-button" onClick={() => setPopupInfos(null)}>
          <img src={CloseWhite} alt="close-icon-white" className="close-icon" />
        </div>
      </div>
      <div className="store-locator-content_container">
        <iframe
          id="store-locator"
          height="100%"
          width="100%"
          src="https://www.keoutdoordesign.com/#ke-store-locator-cta-section"
        />
      </div>
    </div>
  );
};

export default PopupStoreLocator;
