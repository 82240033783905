//  @ts-ignore
import React from "react";
import { Cardboard } from "./icons";

const BottomButtons: React.FC<{
  technicalSpecifics;
  setTechnicalSpecifics;
  toggleMode;
  toggleFullscreen;
}> = ({ toggleMode }) => {
  return (
    <div className="bottom-buttons__container" style={{ bottom: "0" }}>
      <button onClick={toggleMode} className="button-icon">
        <img className="cardboard" src={Cardboard} alt="cardboard-icon" />
      </button>
      <button
        className="button-icon"
        style={{ opacity: "0", pointerEvents: "none" }}
      >
        <img className="cardboard" src={Cardboard} alt="cardboard-icon" />
      </button>
    </div>
  );
};

export default BottomButtons;
