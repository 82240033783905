import {
  ArmonyPlusGLB,
  ArmonyPlusUSDZ,
  BaseLiteGLB,
  BaseLiteUSDZ,
  BasePlusGLB,
  BasePlusUSDZ,
  BravoGLB,
  BravoUSDZ,
  DomeaGLB,
  DomeaUSDZ,
  ElyGLB,
  ElyUSDZ,
  EpicaGLB,
  EpicaLiteGLB,
  EpicaLiteUSDZ,
  EpicaUSDZ,
  GiantGLB,
  GiantUSDZ,
  GiottoGLB,
  GiottoPlusGLB,
  GiottoPlusUSDZ,
  GiottoUSDZ,
  GlassGLB,
  GlassUSDZ,
  GLB2010,
  GLB2020,
  HelixPsGLB,
  HelixPsUSDZ,
  Image20101,
  Image20102,
  Image20103,
  Image20104,
  Image20105,
  Image20201,
  Image20202,
  Image20203,
  Image20204,
  Image20205,
  ImageArmonyPlus1,
  ImageArmonyPlus2,
  ImageArmonyPlus3,
  ImageArmonyPlus4,
  ImageArmonyPlus5,
  ImageBaseLite1,
  ImageBaseLite2,
  ImageBaseLite3,
  ImageBaseLite4,
  ImageBaseLite5,
  ImageBaseLite6,
  ImageBaseLite7,
  ImageBasePlus1,
  ImageBasePlus2,
  ImageBasePlus3,
  ImageBasePlus4,
  ImageBasePlus5,
  ImageBasePlus6,
  ImageBravo1,
  ImageBravo2,
  ImageBravo3,
  ImageBravo4,
  ImageDomea1,
  ImageDomea2,
  ImageDomea3,
  ImageDomea4,
  ImageDomea5,
  ImageDomea6,
  ImageEly1,
  ImageEly2,
  ImageEly3,
  ImageEly4,
  ImageEly5,
  ImageEly6,
  ImageEly7,
  ImageEly8,
  ImageEpica1,
  ImageEpica2,
  ImageEpica3,
  ImageEpica4,
  ImageEpicaLite1,
  ImageEpicaLite2,
  ImageEpicaLite3,
  ImageEpicaLite4,
  ImageEpicaLite5,
  ImageEpicaLite6,
  ImageEpicaLite7,
  ImageEpicaLite8,
  ImageEpicaLite9,
  ImageGiant1,
  ImageGiant2,
  ImageGiant3,
  ImageGiant4,
  ImageGiant5,
  ImageGiotto1,
  ImageGiotto2,
  ImageGiotto3,
  ImageGiotto4,
  ImageGiotto5,
  ImageGiottoPlus1,
  ImageGiottoPlus2,
  ImageGiottoPlus3,
  ImageGiottoPlus4,
  ImageGlass1,
  ImageGlass10,
  ImageGlass11,
  ImageGlass12,
  ImageGlass2,
  ImageGlass3,
  ImageGlass4,
  ImageGlass5,
  ImageGlass6,
  ImageGlass7,
  ImageGlass8,
  ImageGlass9,
  ImageHelixPs1,
  ImageHelixPs2,
  ImageHelixPs3,
  ImageHelixPs4,
  ImageHelixPs5,
  ImageMiniHelixPs1,
  ImageMiniHelixPs2,
  ImageMiniHelixPs3,
  ImageMiniHelixPs4,
  ImageMiniVerso1,
  ImageMiniVerso2,
  ImageMiniVerso3,
  ImageMiniVerso4,
  ImageScreeny110Gc1,
  ImageScreeny110Gc2,
  ImageScreeny110Gc3,
  ImageScreeny110Gc4,
  ImageScreeny110Gc5,
  ImageScreeny110Gc6,
  ImageScreeny110Gc7,
  ImageScreeny110GcCabrio1,
  ImageScreeny110GcCabrio2,
  ImageScreeny110GcCabrio3,
  ImageScreeny110GcCabrio4,
  ImageScreeny110GcCabrio5,
  ImageScreeny110GcCabrio6,
  ImageScreeny110GcCabrio7,
  ImageScreeny110GcCabrio8,
  ImageScreeny110GpzUnicaAm1,
  ImageScreeny110GpzUnicaAm2,
  ImageScreeny110GpzUnicaAm3,
  ImageScreeny110GpzUnicaAm4,
  ImageScreeny110GpzUnicaAm5,
  ImageScreeny110GpzUnicaAm6,
  ImageScreeny110GpzUnicaAm7,
  ImageScreeny130Ga1,
  ImageScreeny130Ga2,
  ImageScreeny130Ga3,
  ImageScreeny130Ga4,
  ImageScreeny130Ga5,
  ImageScreeny130Ga6,
  ImageScreeny130GpzI1,
  ImageScreeny130GpzI2,
  ImageScreeny130GpzI3,
  ImageScreeny130GpzI4,
  ImageScreeny130GpzI5,
  ImageScreeny130GpzI6,
  ImageScreeny130GpzMb1,
  ImageScreeny130GpzMb2,
  ImageScreeny130GpzMb3,
  ImageScreeny130GpzMb4,
  ImageScreeny130GpzTensTo1,
  ImageScreeny130GpzTensTo2,
  ImageScreeny130GpzTensTo3,
  ImageScreeny130GpzTensTo4,
  ImageScreeny130GpzTensTo5,
  ImageScreeny130GpzUnicaM1,
  ImageScreeny130GpzUnicaM2,
  ImageScreeny130GpzUnicaM3,
  ImageScreeny130GpzUnicaM4,
  ImageScreeny130GpzUnicaM5,
  ImageScreeny130GpzUnicaM6,
  ImageScreeny130GpzUnicaM7,
  ImageScreeny150GpzUnica1,
  ImageScreeny150GpzUnica2,
  ImageScreeny150GpzUnica3,
  ImageScreeny150GpzUnica4,
  ImageScreeny150GpzUnica5,
  ImageSirio1,
  ImageSirio2,
  ImageSirio3,
  ImageSirio4,
  ImageSirio5,
  ImageSirio6,
  ImageSpace1,
  ImageSpace2,
  ImageSpace3,
  ImageSpace4,
  ImageSpace5,
  ImageSpace6,
  ImageSpace7,
  ImageVerso1,
  ImageVerso2,
  ImageVerso3,
  ImageVerso4,
  ImageVerso5,
  MiniHelixPsGLB,
  MiniHelixPsUSDZ,
  Screeny110GcCabrioGLB,
  Screeny110GcCabrioUSDZ,
  Screeny110GcGLB,
  Screeny110GcUSDZ,
  Screeny110GpzUnicaAmGLB,
  Screeny110GpzUnicaAmUSDZ,
  Screeny130GaGLB,
  Screeny130GaUSDZ,
  Screeny130GpzIGLB,
  Screeny130GpzIUSDZ,
  Screeny130GpzMbGLB,
  Screeny130GpzMbUSDZ,
  Screeny130GpzToGLB,
  Screeny130GpzToUSDZ,
  Screeny130GpzUnicaMGLB,
  Screeny130GpzUnicaMUSDZ,
  Screeny150GpzUnicaAmGLB,
  Screeny150GpzUnicaAmUSDZ,
  SirioGLB,
  SirioUSDZ,
  SpaceGLB,
  SpaceUSDZ,
  TechnicalSheet2010,
  TechnicalSheet2020,
  TechnicalSheetArmonyPlus,
  TechnicalSheetBaseLite,
  TechnicalSheetBasePlus,
  TechnicalSheetDomea,
  TechnicalSheetEly,
  TechnicalSheetEpica,
  TechnicalSheetEpicaLite,
  TechnicalSheetGiant,
  TechnicalSheetGiotto,
  TechnicalSheetGiottoPlus,
  TechnicalSheetGlass,
  TechnicalSheetHelixPs,
  TechnicalSheetMiniHelixBs,
  TechnicalSheetScreeny110Gc,
  TechnicalSheetScreeny110GcCabrio,
  TechnicalSheetScreeny110GpzUnicaAm,
  TechnicalSheetScreeny130Ga,
  TechnicalSheetScreeny130GpzI,
  TechnicalSheetScreeny130GpzMb,
  TechnicalSheetScreeny130GpzTensTo,
  TechnicalSheetScreeny130GpzUnicaM,
  TechnicalSheetScreeny150GpzUnica,
  TechnicalSheetSirio,
  TechnicalSheetSpace,
  TechnicalSheetVerso,
  USDZ2010,
  USDZ2020,
  VersoCassonettoGLB,
  VersoCassonettoUSDZ,
} from ".";
import { getLanguageTranslation } from "../translations/get-language-translation";

export const createIntroInfoPointsEs = (createPositionPoint) => [
  createPositionPoint(
    [-3180.12, -1039.36, -3704.93],
    "mini-helix-ps-helix-ps",
    "Mini Helix PS - Helix PS"
  ),
  createPositionPoint(
    [-4506.26, -1482.54, 1561.04],
    "130-gpz-to",
    "Epica Lite - 130 GPZ TO"
  ),
  createPositionPoint(
    [-2371.56, -513.96, 4362.16],
    "domea-base-plus",
    "Domea - Base Plus"
  ),
  createPositionPoint([-661.41, -1035.23, 4837.5], "percorso-1", "Percorso 1"),
  createPositionPoint([4271.93, -522.32, 2533.13], "percorso-2", "Percorso 2"),
  createPositionPoint([4293.64, -522.32, -2486.45], "percorso-3", "Percorso 3"),
  createPositionPoint([-723.28, -732.07, -4884.59], "percorso-4", "Percorso 4"),
];

export const createMiniHelixPsHelixPsInfoPointsEs = (
  createPositionPoint,
  createInfoPoint,
  createVrPoint
) => [
  createPositionPoint([-3007.0, -755.21, -3914.96], "intro", "Intro"),
  createPositionPoint([51.5, -482.1, -4971.26], "130-gpz-to", "130 GPZ TO"),
  createPositionPoint(
    [-1437.14, -526.84, -4751.81],
    "percorso-1",
    "Percorso 1"
  ),
  createPositionPoint([-4783.62, -1238.35, 705.89], "percorso-4", "Percorso 4"),
  createVrPoint([3395.01, -608.49, 3608.46], MiniHelixPsGLB, MiniHelixPsUSDZ, undefined, "MINI HELIX PS"),
  createVrPoint([3008.86, -16.61, -3987.61], HelixPsGLB, HelixPsUSDZ, undefined, "HELIX PS"),
  createInfoPoint(
    [4657.1, -36.68, 1796.84],
    {
      type: "info",
      title: "",
      noCloseIcon: true,
      popupClass: "popup-info_container",
      sectionTitle: "MINI HELIX PS",
      description:
        "<p>" +
        getLanguageTranslation(
          "MINI HELIX PS è una tenda a supporti indipendenti. Concettualmente riprende le caratteristiche tecnico- meccaniche della sorella maggiore, Helix PS, come l’estrema facilità e versatilità di installazione e regolazione. Caratterizzata da un disegno gradevole e decisamente contemporaneo, al quale è stata sottoposta un’attenzione maniacale, è compatibile con le braccia della famiglia ECO, sia a cinghia che doppio cavetto."
        ) +
        "</p>",
      images: [
        ImageMiniHelixPs1,
        ImageMiniHelixPs2,
        ImageMiniHelixPs3,
        ImageMiniHelixPs4,
      ],
      buttons: [
        {
          text: getLanguageTranslation("Scheda Tecnica"),
          href: TechnicalSheetMiniHelixBs,
        },
      ],
    },
    undefined,
    "<p><strong>MINI HELIX PS</strong><br />" +
      getLanguageTranslation(
        "MINI HELIX PS è una tenda a supporti indipendenti. Concettualmente riprende le caratteristiche tecnico- meccaniche della sorella maggiore, Helix PS, come l’estrema facilità e versatilità di installazione e regolazione."
      ) +
      "</p>"
  ),
  createInfoPoint(
    [4495.95, -110.56, -2165.01],
    {
      type: "info",
      title: "",
      noCloseIcon: true,
      popupClass: "popup-info_container",
      sectionTitle: "HELIX PS",
      description:
        "<p>" +
        getLanguageTranslation(
          "HELIX PS tenda a supporti indipendenti; è caratterizzata da un supporto in pressofusione, del tipo cosidetto a 'cono' dal disegno curato e dalle prestazioni importanti: inclinazione in continuo, particolarmente agevole, da 0° a 90°. Versatile e facile da installare, dal design robusto ma al contempo elegante."
        ) +
        "</p>",
      images: [
        ImageHelixPs1,
        ImageHelixPs2,
        ImageHelixPs3,
        ImageHelixPs4,
        ImageHelixPs5,
      ],
      buttons: [
        {
          text: getLanguageTranslation("Scheda Tecnica"),
          href: TechnicalSheetHelixPs,
        },
      ],
    },
    undefined,
    "<p><strong>HELIX PS</strong><br />" +
      getLanguageTranslation(
        "HELIX PS tenda a supporti indipendenti; è caratterizzata da un supporto in pressofusione, del tipo cosidetto a 'cono' dal disegno curato e dalle prestazioni importanti: inclinazione in continuo, particolarmente agevole, da 0° a 90°."
      ) +
      "</p>"
  ),
];

export const createDomeaBasePlusInfoPointsEs = (
  createPositionPoint,
  createInfoPoint,
  createVrPoint
) => [
  createPositionPoint([-4516.18, -430.34, -2091.03], "intro", "Intro"),
  createPositionPoint(
    [-172.24, -1010.26, -4884.04],
    "epica-base-lite",
    "Epica - Base Lite"
  ),
  createPositionPoint(
    [-172.24, -460.76, -4972.99],
    "armony-plus-giant",
    "Armony Plus - Giant"
  ),
  createPositionPoint([-1811.91, -698.42, 4597.61], "percorso-5", "Percorso 5"),
  createPositionPoint(
    [-3521.82, -716.44, -3463.61],
    "percorso-1",
    "Percorso 1"
  ),
  createVrPoint([3479.79, -509.9, 3540.16], DomeaGLB, DomeaUSDZ, undefined, "DOMEA"),
  createVrPoint([2914.41, -497.04, -4027.89], BasePlusGLB, BasePlusUSDZ, undefined, "BASE PLUS"),
  createInfoPoint(
    [4693.77, -429.54, 1505.01],
    {
      type: "info",
      title: "",
      noCloseIcon: true,
      popupClass: "popup-info_container",
      sectionTitle: "DOMEA",
      description:
        "<p>" +
        getLanguageTranslation(
          "DOMEA, disegnata da Robby Cantarutti, designer di fama internazionale, è il primo prodotto della nuova “feel-experience” di BAT. Le sue forme accattivanti, che richiamano la tradizione e simboleggiano un connubio perfetto tra innovazione e classicità, forte di nuovi contenuti tecnologici, fanno di DOMEA uno dei prodotti di punta della gamma delle tende a cassonetto. Caratterizzata dall’estrema facilità di montaggio, regolazione e installazione, garantisce standard di sicurezza e qualità elevati per un modello di questa categoria."
        ) +
        "</p>",
      images: [
        ImageDomea1,
        ImageDomea2,
        ImageDomea3,
        ImageDomea4,
        ImageDomea5,
        ImageDomea6,
      ],
      buttons: [
        {
          text: getLanguageTranslation("Scheda Tecnica"),
          href: TechnicalSheetDomea,
        },
      ],
    },
    undefined,
    "<p><strong>DOMEA</strong><br />" +
      getLanguageTranslation(
        "DOMEA, disegnata da Robby Cantarutti, designer di fama internazionale, è il primo prodotto della nuova “feel-experience” di BAT. Le sue forme accattivanti, che richiamano la tradizione e simboleggiano un connubio perfetto tra innovazione e classicità, forte di nuovi contenuti tecnologici, fanno di DOMEA uno dei prodotti di punta della gamma delle tende a cassonetto."
      ) +
      "</p>"
  ),
  createInfoPoint(
    [4524.93, -429.54, -2074.08],
    {
      type: "info",
      title: "",
      noCloseIcon: true,
      popupClass: "popup-info_container",
      sectionTitle: "BASE PLUS",
      description:
        "<p>" +
        getLanguageTranslation(
          "BASE PLUS è una tenda a bracci estensibili con cassonetto su supporti laterali fissi con contropiastra di ancoraggio, bracci Balteus o spin. Il cassonetto, ispezionabile, viene fornito in tre profili separati. I supporti consentono di installare la tenda sia a parete che a soffitto e la regolazione può essere eseguita in un unica soluzione grazie al sistema con vite passante. Tutti i componenti sono realizzati in alluminio verniciato che garantiscono resa estetica e durabilità."
        ) +
        "</p>",
      images: [
        ImageBasePlus1,
        ImageBasePlus2,
        ImageBasePlus3,
        ImageBasePlus4,
        ImageBasePlus5,
        ImageBasePlus6,
      ],
      buttons: [
        {
          text: getLanguageTranslation("Scheda Tecnica"),
          href: TechnicalSheetBasePlus,
        },
      ],
    },
    undefined,
    "<p><strong>BASE PLUS</strong><br />" +
      getLanguageTranslation(
        "BASE PLUS è una tenda a bracci estensibili con cassonetto su supporti laterali fissi con contropiastra di ancoraggio, bracci Balteus o spin. Il cassonetto, ispezionabile, viene fornito in tre profili separati."
      ) +
      "</p>"
  ),
];

export const create130GpzToInfoPointsEs = (
  createPositionPoint,
  createInfoPoint,
  createVrPoint,
  createVideoPoint
) => [
  createPositionPoint([-4516.69, -1445.97, 1562.6], "intro", "Intro"),
  createPositionPoint(
    [-178.84, -868.44, 4911.91],
    "mini-helix-ps-helix-ps",
    "Mini Helix PS - Helix PS"
  ),
  createPositionPoint(
    [-4795.26, -417.16, -1325.05],
    "percorso-2",
    "Percorso 2"
  ),
  createPositionPoint([-4368.44, -417.16, 2391.64], "percorso-3", "Percorso 3"),
  createPositionPoint(
    [-3268.06, -1187.62, -3590.09],
    "percorso-1",
    "Percorso 1"
  ),
  createVrPoint(
    [4176.28, -1202.48, -2465.36],
    Screeny130GpzToGLB,
    Screeny130GpzToUSDZ
  ),
  createVideoPoint(
    [2731.25, 1110.00, 4029.01],
    "Video",
    "https://www.youtube.com/watch?v=rYrZhH8tMn4"
  ),
  createVrPoint([3768.37, -1175.88, 3057.55], EpicaLiteGLB, EpicaLiteUSDZ, undefined, "EPICA LITE"),
  createInfoPoint(
    [4366.60, 6.95, 2427.81],
    {
      type: "info",
      title: "",
      noCloseIcon: true,
      popupClass: "popup-info_container",
      sectionTitle: "EPICA LITE",
      description:
        "<p>" +
        getLanguageTranslation(
          "EPICA LITE è una tenda a bracci estensibili con cassonetto su supporti laterali fissi con contropiastra di ancoraggio, bracci Balteus o spin. Il cassonetto, ispezionabile, viene fornito in tre profili separati. I supporti consentono di installare la tenda sia a parete che a soffitto e la regolazione può essere eseguita in un’unica soluzione grazie al sistema con vite passante."
        ) +
        "</p>",
      images: [
        ImageEpicaLite1,
        ImageEpicaLite2,
        ImageEpicaLite3,
        ImageEpicaLite4,
        ImageEpicaLite5,
        ImageEpicaLite6,
        ImageEpicaLite7,
        ImageEpicaLite8,
        ImageEpicaLite9,
      ],
      buttons: [
        {
          text: getLanguageTranslation("Scheda Tecnica"),
          href: TechnicalSheetEpicaLite,
        },
      ],
    },
    undefined,
    "<p><strong>EPICA LITE</strong><br />" +
      getLanguageTranslation(
        "EPICA LITE è una tenda a bracci estensibili con cassonetto su supporti laterali fissi con contropiastra di ancoraggio, bracci Balteus o spin."
      ) +
      "</p>"
  ),
  createInfoPoint(
    [4892.81, 156.83, -987.11],
    {
      type: "info",
      title: "",
      noCloseIcon: true,
      popupClass: "popup-info_container",
      sectionTitle: "130 GPZ TO",
      description:
        "<p>" +
        getLanguageTranslation(
          "Tenda con cassonetto da 130mm disponibile nella sola variante Quadro, dotata di sistema di guida ZIP. Adatta, grazie al sistema di tensionamento , per installazioni come copertura dei giardini d’inverno, dei tetti in vetro e delle verande in generale. Funzionamento esclusivamente motorizzato."
        ) +
        "</p>",
      images: [
        ImageScreeny130GpzTensTo1,
        ImageScreeny130GpzTensTo2,
        ImageScreeny130GpzTensTo3,
        ImageScreeny130GpzTensTo4,
        ImageScreeny130GpzTensTo5,
      ],
      buttons: [
        {
          text: getLanguageTranslation("Scheda Tecnica"),
          href: TechnicalSheetScreeny130GpzTensTo,
        },
      ],
    },
    undefined,
    "<p><strong>130 GPZ TO</strong><br />" +
      getLanguageTranslation(
        "Tenda con cassonetto da 130mm disponibile nella sola variante Quadro, dotata di sistema di guida ZIP."
      ) +
      "</p>"
  ),
];

export const createArmonyPlusGiantInfoPointsEs = (
  createPositionPoint,
  createInfoPoint,
  createVrPoint
) => [
  createPositionPoint([-4083.44, -441.9, 2840.83], "intro", "Intro"),
  createPositionPoint(
    [-374.96, -1037.63, -4867.98],
    "2020-2010",
    "2020 - 2010"
  ),
  createPositionPoint(
    [-199.75, -1091.07, 4866.53],
    "epica-base-lite",
    "Epica - Base Lite"
  ),
  createPositionPoint(
    [-195.16, -514.49, 4959.9],
    "domea-base-plus",
    "Domea - Base Plus"
  ),
  createPositionPoint(
    [-4641.5, -1100.09, -1488.21],
    "percorso-2",
    "Percorso 2"
  ),
  createVrPoint([3545.34, -444.92, 3485.47], ArmonyPlusGLB, ArmonyPlusUSDZ, undefined, "ARMONY PLUS"),
  createVrPoint([2932.31, -563.37, -4004.62], GiantGLB, GiantUSDZ, undefined, "GIANT"),
  createInfoPoint(
    [4766.62, -513.38, 1388.01],
    {
      type: "info",
      title: "",
      noCloseIcon: true,
      popupClass: "popup-info_container",
      sectionTitle: "ARMONY PLUS",
      description:
        "<p>" +
        getLanguageTranslation(
          "ARMONY PLUS é una tenda a bracci estensibili con cassonetto a scomparsa totale. Design e stile uniti alla funzionalità e alla tecnologia di un sistema ribaltabile BAT che permette la chiusura ermetica del cassonetto anche nella sua massima inclinazione inoltre, grazie ad un profilo di guarnizione il cassonetto risulta essere perfettamente aderente al muro impedendo l’infiltrazione di acque ed impurità."
        ) +
        "</p>",
      images: [
        ImageArmonyPlus1,
        ImageArmonyPlus2,
        ImageArmonyPlus3,
        ImageArmonyPlus4,
        ImageArmonyPlus5,
      ],
      buttons: [
        {
          text: getLanguageTranslation("Scheda Tecnica"),
          href: TechnicalSheetArmonyPlus,
        },
      ],
    },
    undefined,
    "<p><strong>ARMONY PLUS</strong><br />" +
      getLanguageTranslation(
        "ARMONY PLUS é una tenda a bracci estensibili con cassonetto a scomparsa totale."
      ) +
      "</p>"
  ),
  createInfoPoint(
    [4653.28, -494.05, -1734.3],
    {
      type: "info",
      title: "",
      noCloseIcon: true,
      popupClass: "popup-info_container",
      sectionTitle: "GIANT",
      description:
        "<p>" +
        getLanguageTranslation(
          "GIANT BQ é una tenda a bracci su barra quadra in acciaio 50mm. I supporti in alluminio estruso garantiscono resistenza e compattezza e i tappi in alluminio verniciati ne caratterizzano eleganza e discrezione. Compattezza e design la rendono perfetta per coperture di terrazze pubbliche e per protezione di spazi e patii privati. Dispone di un braccio tipo spin, creato “ad hoc” con snodi e spalla forgiati."
        ) +
        "</p>",
      images: [ImageGiant1, ImageGiant2, ImageGiant3, ImageGiant4, ImageGiant5],
      buttons: [
        {
          text: getLanguageTranslation("Scheda Tecnica"),
          href: TechnicalSheetGiant,
        },
      ],
    },
    undefined,
    "<p><strong>GIANT</strong><br />" +
      getLanguageTranslation(
        "GIANT BQ é una tenda a bracci su barra quadra in acciaio 50mm. I supporti in alluminio estruso garantiscono resistenza e compattezza e i tappi in alluminio verniciati ne caratterizzano eleganza e discrezione."
      ) +
      "</p>"
  ),
];

export const createEpicaBaseLiteInfoPointsEs = (
  createPositionPoint,
  createInfoPoint,
  createVrPoint
) => [
  createPositionPoint([-4797.45, -1013.11, 942.37], "percorso-1", "Percorso 1"),
  createPositionPoint(
    [78.34, -996.46, -4891.71],
    "armony-plus-giant",
    "Armony Plus - Giant"
  ),
  createPositionPoint([78.34, -490.12, -4972.11], "2020-2010", "2020 - 2010"),
  createPositionPoint(
    [-2659.47, -619.01, -4176.26],
    "percorso-2",
    "Percorso 2"
  ),
  createPositionPoint([-972.5, -377.78, 4886.35], "percorso-5", "Percorso 5"),
  createPositionPoint(
    [78.34, -748.53, 4938.85],
    "domea-base-plus",
    "Domea - Base Plus"
  ),
  createVrPoint([3241.11, -655.72, 3740.51], EpicaGLB, EpicaUSDZ, undefined, "EPICA"),
  createVrPoint([3421.57, -1264.11, -3406.12], BaseLiteGLB, BaseLiteUSDZ, undefined, "BASE LITE"),
  createInfoPoint(
    [4771.4, -418.86, 1413.32],
    {
      type: "info",
      title: "",
      noCloseIcon: true,
      popupClass: "popup-info_container",
      sectionTitle: "EPICA",
      description:
        "<p>" +
        getLanguageTranslation(
          "EPICA è una tenda a bracci estensibili con cassonetto, caratterizzata da linee semplici ed essenziali. Gli elementi di fissaggio e le viterie sono totalmente nascosti alla vista. Pur avendo ingombri contenuti, raggiunge una sporgenza di 410cm con braccio Giant. Il prodotto viene completato con un’ampia gamma di optional come il kit luci e il terminale a cassonetto."
        ) +
        "</p>",
      images: [ImageEpica1, ImageEpica2, ImageEpica3, ImageEpica4],
      buttons: [
        {
          text: getLanguageTranslation("Scheda Tecnica"),
          href: TechnicalSheetEpica,
        },
      ],
    },
    undefined,
    "<p><strong>EPICA</strong><br />" +
      getLanguageTranslation(
        "EPICA è una tenda a bracci estensibili con cassonetto, caratterizzata da linee semplici ed essenziali. Gli elementi di fissaggio e le viterie sono totalmente nascosti alla vista."
      ) +
      "</p>"
  ),
  createInfoPoint(
    [4653.28, -494.05, -1734.3],
    {
      type: "info",
      title: "",
      noCloseIcon: true,
      popupClass: "popup-info_container",
      sectionTitle: "BASE LITE",
      description:
        "<p>" +
        getLanguageTranslation(
          "BASE LITE è una tenda a bracci estensibili con cassonetto su supporti laterali fissi con contropiastra di ancoraggio, bracci balteus lite, doppio cavetto inguainato o a cinghia. I supporti consentono di installare la tenda sia a parete che a soffitto e la regolazione può essere eseguita in un unica soluzione grazie al sistema con vite passante. Tutti i componenti sono realizzati in alluminio verniciato che garantiscono resa estetica e durabilità."
        ) +
        "</p>",
      images: [
        ImageBaseLite1,
        ImageBaseLite2,
        ImageBaseLite3,
        ImageBaseLite4,
        ImageBaseLite5,
        ImageBaseLite6,
        ImageBaseLite7,
      ],
      buttons: [
        {
          text: getLanguageTranslation("Scheda Tecnica"),
          href: TechnicalSheetBaseLite,
        },
      ],
    },
    undefined,
    "<p><strong>BASE LITE</strong><br />" +
      getLanguageTranslation(
        "BASE LITE è una tenda a bracci estensibili con cassonetto su supporti laterali fissi con contropiastra di ancoraggio, bracci balteus lite, doppio cavetto inguainato o a cinghia."
      ) +
      "</p>"
  ),
];

export const create20202010InfoPointsEs = (
  createPositionPoint,
  createInfoPoint,
  createVrPoint
) => [
  createPositionPoint(
    [1.61, -981.17, 4896.9],
    "armony-plus-giant",
    "Armony Plus - Giant"
  ),
  createPositionPoint([-2919.77, -252.18, 4045.72], "intro", "Intro"),
  createPositionPoint(
    [1.61, -423.06, 4980.86],
    "epica-base-lite",
    "Epica - Base Lite"
  ),
  createPositionPoint(
    [-3380.75, -1018.78, 3527.23],
    "percorso-2",
    "Percorso 2"
  ),
  createVrPoint([3555.64, -493.12, 3467.12], GLB2020, USDZ2020, undefined, "2020"),
  createVrPoint([3257.97, -450.77, -3758.03], GLB2010, USDZ2010, undefined, "2010"),
  createInfoPoint(
    [4702.23, -482.87, 1610.48],
    {
      type: "info",
      title: "",
      noCloseIcon: true,
      popupClass: "popup-info_container",
      sectionTitle: "2020",
      description:
        "<p>" +
        getLanguageTranslation(
          "2020 è una tenda a bracci estensibili compatta su barra quadra 40mm in acciaio zincato e verniciato, bracci a doppio cavetto inguainato o a cinghia e supporto braccio in estrusione di alluminio. E’ una tenda modulare: aggiungendo ulteriori profili si può raggiungere una larghezza maggiore. Il terminale frontale è intercambiabile con gli altri terminali BAT. Dispone come optional di un tettuccio di copertura in alluminio estruso e di una staffa speciale per il montaggio sottotetto."
        ) +
        "</p>",
      images: [Image20201, Image20202, Image20203, Image20204, Image20205],
      buttons: [
        {
          text: getLanguageTranslation("Scheda Tecnica"),
          href: TechnicalSheet2020,
        },
      ],
    },
    undefined,
    "<p><strong>2020</strong><br />" +
      getLanguageTranslation(
        "2020 è una tenda a bracci estensibili compatta su barra quadra 40mm in acciaio zincato e verniciato, bracci a doppio cavetto inguainato o a cinghia e supporto braccio in estrusione di alluminio. E’ una tenda modulare: aggiungendo ulteriori profili si può raggiungere una larghezza maggiore."
      ) +
      "</p>"
  ),
  createInfoPoint(
    [4754.49, -663.58, -1364.68],
    {
      type: "info",
      title: "",
      noCloseIcon: true,
      popupClass: "popup-info_container",
      sectionTitle: "2010",
      description:
        "<p>" +
        getLanguageTranslation(
          "2010 è una tenda a bracci estensibili compatta su barra quadra 40mm in acciaio zincato e verniciato con bracci a doppio cavetto inguainato o cinghia e supporto braccio con regolazione a cono"
        ) +
        "</p>",
      images: [Image20101, Image20102, Image20103, Image20104, Image20105],
      buttons: [
        {
          text: getLanguageTranslation("Scheda Tecnica"),
          href: TechnicalSheet2010,
        },
      ],
    },
    undefined,
    "<p><strong>2010</strong><br />" +
      getLanguageTranslation(
        "2010 è una tenda a bracci estensibili compatta su barra quadra 40mm in acciaio zincato e verniciato con bracci a doppio cavetto inguainato o cinghia e supporto braccio con regolazione a cono"
      ) +
      "</p>"
  ),
];

export const createPercorso1InfoPointsEs = (createPositionPoint) => [
  createPositionPoint(
    [4350.92, -698.32, 2345.62],
    "armony-plus-giant",
    "Armony Plus - Giant"
  ),
  createPositionPoint([547.68, -905.19, -4881.66], "intro", "Intro"),
  createPositionPoint(
    [-1880.66, -1164.27, -4479.32],
    "130-gpz-to",
    "130 GPZ TO"
  ),
  createPositionPoint(
    [-3268.59, -982.77, 3647.75],
    "domea-base-plus",
    "Domea - Base Plus"
  ),
  createPositionPoint(
    [1423.65, -1272.2, 4614.39],
    "epica-base-lite",
    "Epica - Base Lite"
  ),
  createPositionPoint([4945.5, -566.78, -351.77], "percorso-2", "Percorso 2"),
  createPositionPoint([-4965.28, -460.16, 215.1], "percorso-5", "Percorso 5"),
];

export const createPercorso2InfoPointsEs = (createPositionPoint) => [
  createPositionPoint(
    [-4857.77, -722.4, -899.88],
    "armony-plus-giant",
    "Armony Plus - Giant"
  ),
  createPositionPoint([-4106.69, -722.4, 2745.48], "2020-2010", "2020 - 2010"),
  createPositionPoint([1983.6, -612.62, -4542.57], "intro", "Intro"),
  createPositionPoint(
    [-3431.71, -611.48, -3571.26],
    "epica-base-lite",
    "Epica - Base Lite"
  ),
  createPositionPoint([4956.59, -569.77, 133.31], "percorso-3", "Percorso 3"),
];

export const createPercorso3InfoPointsEs = (createPositionPoint) => [
  createPositionPoint([-2600.89, -524.21, -4227.66], "intro", "Intro"),
  createPositionPoint([-4966.67, -507.8, 80.99], "percorso-2", "Percorso 2"),
  createPositionPoint([693.36, -628.57, -4901.71], "percorso-4", "Percorso 4"),
  createPositionPoint(
    [3924.1, -624.98, 3020.17],
    "bravo-verso-cassonetto",
    "Bravo - Verso Cassonetto"
  ),
  createPositionPoint(
    [4840.47, -470.77, 1131.84],
    "screeny-110-gpz-unica-am-screeny-130-gpz-unica-m",
    "Screeny 110 GPZ UNICA AM - Screeny 130 GPZ UNICA M"
  ),
  createPositionPoint(
    [3520.93, -710.04, -3465.56],
    "screeny-150-gpz-unica-am-screeny-110-gc-cabrio",
    "Screeny 150 GPZ UNICA AM - Screeny 110 GC CABRIO"
  ),
  createPositionPoint(
    [4451.71, -453.85, -2211.44],
    "screeny-130-ga-screeny-130-gpz-mb",
    "Screeny 130 GA - Screeny 130 GPZ MB"
  ),
];

export const createBravoVersoCassonettoInfoPointsEs = (
  createPositionPoint,
  createInfoPoint,
  createVrPoint
) => [
  createPositionPoint(
    [-1192.7, -989.52, -4743.42],
    "screeny-110-gpz-unica-am-screeny-130-gpz-unica-m",
    "Screeny 110 GPZ UNICA AM - Screeny 130 GPZ UNICA M"
  ),
  createPositionPoint(
    [-3805.37, -648.16, -3162.43],
    "screeny-110-gc-screeny-130-gpz-i",
    "Screeny 110 GC - Screeny 130 GPZ I"
  ),
  createPositionPoint([-2420.89, -578.71, 4328.43], "percorso-3", "Percorso 3"),
  createVrPoint([4031.18, -1561.53, 2500.52], BravoGLB, BravoUSDZ, undefined, "BRAVO"),
  createVrPoint(
    [3889.69, -1538.2, -2733.07],
    VersoCassonettoGLB,
    VersoCassonettoUSDZ
  ),
  createInfoPoint(
    [4669.65, 997.37, 1462.0],
    {
      type: "info",
      title: "",
      noCloseIcon: true,
      popupClass: "popup-info_container",
      sectionTitle: "BRAVO",
      description:
        "<p>BRAVO es un toldo vertical para balcón. La particularidad de este modelo está en el hecho de que los brazos se encuentran colocados en el perfil de carga del mismo y según necesidad se pueden desplegar. Este toldo puede ser motorizado o montado con máquina. Las medidas máximas aconsejadas son: 500cm de linea, 300 de altura y 50cm de salida.</p>",
      images: [ImageBravo1, ImageBravo2, ImageBravo3, ImageBravo4],
      buttons: [
        {
          text: getLanguageTranslation("Scheda Tecnica"),
          href: null,
        },
      ],
    },
    undefined,
    "<p><strong>BRAVO</strong><br />BRAVO es un toldo vertical para balcón. La particularidad de este modelo está en el hecho de que los brazos se encuentran colocados en el perfil de carga del mismo y según necesidad se pueden desplegar.</p>"
  ),
  createInfoPoint(
    [4699.27, 978.56, -1365.89],
    {
      type: "info",
      title: "",
      noCloseIcon: true,
      popupClass: "popup-info_container",
      sectionTitle: "VERSO CASSONETTO",
      description:
        "<p>" +
        getLanguageTranslation(
          "VERSO è una tenda dedicata alla posa su balconi e finestre con braccetti a molla. Di semplice e veloce installazione prevede anche un cassonetto opzionale per la protezione del tessuto. Grazie alla conformazione del braccio si adatta particolarmente alla installazione a nicchia."
        ) +
        "</p>",
      images: [ImageVerso1, ImageVerso2, ImageVerso3, ImageVerso4, ImageVerso5],
      buttons: [
        {
          text: getLanguageTranslation("Scheda Tecnica"),
          href: TechnicalSheetVerso,
        },
      ],
    },
    undefined,
    "<p><strong>VERSO CASSONETTO</strong><br />" +
      getLanguageTranslation(
        "VERSO è una tenda dedicata alla posa su balconi e finestre con braccetti a molla. Di semplice e veloce installazione prevede anche un cassonetto opzionale per la protezione del tessuto."
      ) +
      "</p>"
  ),
];

export const createVersoCassonettoMiniVersoInfoPointsEs = (
  createPositionPoint,
  createInfoPoint,
  createVrPoint
) => [
  createPositionPoint(
    [201.09, -1774.4, 4660.15],
    "bravo-verso-cassonetto",
    "Bravo - Verso Cassonetto"
  ),
  createPositionPoint(
    [-1775.53, -1530.57, -4409.43],
    "screeny-110-gpz-unica-am-screeny-130-gpz-unica-m",
    "Screeny 110 GPZ UNICA AM - Screeny 130 GPZ UNICA M"
  ),
  createPositionPoint(
    [-4390.84, -754.74, -2252.57],
    "screeny-110-gc-screeny-130-gpz-i",
    "Screeny 110 GC - Screeny 130 GPZ I"
  ),
  createPositionPoint([-1997.39, -511.32, 4550.1], "percorso-3", "Percorso 3"),
  createVrPoint(
    [3843.31, -1459.93, 2837.88],
    VersoCassonettoGLB,
    VersoCassonettoUSDZ
  ),
  createInfoPoint(
    [4623.25, 1174.0, 1497.44],
    {
      type: "info",
      title: "",
      noCloseIcon: true,
      popupClass: "popup-info_container",
      sectionTitle: "VERSO CASSONETTO",
      description:
        "<p>" +
        getLanguageTranslation(
          "VERSO è una tenda dedicata alla posa su balconi e finestre con braccetti a molla. Di semplice e veloce installazione prevede anche un cassonetto opzionale per la protezione del tessuto. Grazie alla conformazione del braccio si adatta particolarmente alla installazione a nicchia."
        ) +
        "</p>",
      images: [ImageVerso1, ImageVerso2, ImageVerso3, ImageVerso4, ImageVerso5],
      buttons: [
        {
          text: getLanguageTranslation("Scheda Tecnica"),
          href: TechnicalSheetVerso,
        },
      ],
    },
    undefined,
    "<p><strong>VERSO CASSONETTO</strong><br />" +
      getLanguageTranslation(
        "VERSO è una tenda dedicata alla posa su balconi e finestre con braccetti a molla. Di semplice e veloce installazione prevede anche un cassonetto opzionale per la protezione del tessuto."
      ) +
      "</p>"
  ),
  createInfoPoint(
    [4670.44, 1185.9, -1308.35],
    {
      type: "info",
      title: "",
      noCloseIcon: true,
      popupClass: "popup-info_container",
      sectionTitle: "MINI VERSO",
      description:
        "<p>MINI VERSO es un toldo de ventana con brazos tensionados. Su instalacion es fácil y rápida. Esto toldo puede ser utilizado con cofre (opcional) para la protección de su lona. Gracias a la forma de su brazos se adapta particularmente bien entre paredes. Las dimensiones máximas aconsejadas son 400cm de linea, 220cm de altura y 120cm de salida.</p>",
      images: [
        ImageMiniVerso1,
        ImageMiniVerso2,
        ImageMiniVerso3,
        ImageMiniVerso4,
      ],
      buttons: [
        {
          text: getLanguageTranslation("Scheda Tecnica"),
          href: null,
        },
      ],
    },
    undefined,
    "<p><strong>MINI VERSO</strong><br />MINI VERSO es un toldo de ventana con brazos tensionados. Su instalacion es fácil y rápida. Esto toldo puede ser utilizado con cofre (opcional) para la protección de su lona.</p>"
  ),
];

export const createScreeny110GpzUnicaAmScreeny130GpzUnicaMInfoPointsEs = (
  createPositionPoint,
  createInfoPoint,
  createVrPoint
) => [
  createPositionPoint(
    [-4485.41, -1123.89, 1887.19],
    "bravo-verso-cassonetto",
    "Bravo - Verso Cassonetto"
  ),
  createPositionPoint(
    [894.96, -946.78, -4818.29],
    "screeny-110-gc-screeny-130-gpz-i",
    "Screeny 110 GC - Screeny 130 GPZ I"
  ),
  createPositionPoint(
    [-454.03, -815.49, -4908.02],
    "screeny-130-ga-screeny-130-gpz-mb",
    "Screeny 130 GA - Screeny 130 GPZ MB"
  ),
  createPositionPoint(
    [-4842.26, -468.18, -1126.36],
    "percorso-3",
    "Percorso 3"
  ),
  createVrPoint(
    [4097.78, -1304.57, 2533.46],
    Screeny110GpzUnicaAmGLB,
    Screeny110GpzUnicaAmUSDZ
  ),
  createVrPoint(
    [3968.83, -1363.74, -2701.42],
    Screeny130GpzUnicaMGLB,
    Screeny130GpzUnicaMUSDZ
  ),
  createInfoPoint(
    [4660.82, 568.79, 1689.99],
    {
      type: "info",
      title: "",
      noCloseIcon: true,
      popupClass: "popup-info_container",
      sectionTitle: "SCREENY 110 GPZ UNICA AM",
      description:
        "<p>" +
        getLanguageTranslation(
          "Tenda con cassonetto da 110mm disponibile nelle varianti Quadro e Tondo dotata di sistema di guida ZIP e cassonetto autoportante. Adatta per installazioni su infissi, sia a nicchia che a parete; veloce ed agevole da installare ed ispezionare. La guida è composta da due profili: supporto e copertina; è sviluppata per sistemi sia ad argano che motorizzati."
        ) +
        "</p>",
      images: [
        ImageScreeny110GpzUnicaAm1,
        ImageScreeny110GpzUnicaAm2,
        ImageScreeny110GpzUnicaAm3,
        ImageScreeny110GpzUnicaAm4,
        ImageScreeny110GpzUnicaAm5,
        ImageScreeny110GpzUnicaAm6,
        ImageScreeny110GpzUnicaAm7,
      ],
      buttons: [
        {
          text: getLanguageTranslation("Scheda Tecnica"),
          href: TechnicalSheetScreeny110GpzUnicaAm,
        },
      ],
    },
    undefined,
    "<p><strong>SCREENY 110 GPZ UNICA AM</strong><br />" +
      getLanguageTranslation(
        "Tenda con cassonetto da 110mm disponibile nelle varianti Quadro e Tondo dotata di sistema di guida ZIP e cassonetto autoportante. Adatta per installazioni su infissi, sia a nicchia che a parete; veloce ed agevole da installare ed ispezionare."
      ) +
      "</p>"
  ),
  createInfoPoint(
    [4682.71, 568.26, -1664.15],
    {
      type: "info",
      title: "",
      noCloseIcon: true,
      popupClass: "popup-info_container",
      sectionTitle: "SCREENY 130 GPZ UNICA M",
      description:
        "<p>" +
        getLanguageTranslation(
          "Tenda con cassonetto da 130mm disponibile nelle varianti Quadro e Tondo dotata di sistema di guida ZIP. Adatta per installazioni su infissi, sia a nicchia che a parete; veloce ed agevole da installare ed ispezionare. La guida è composta da due profili: supporto e copertina; è sviluppata per i soli sistemi motorizzati riducendo la larghezza a 36 mm."
        ) +
        "</p>",
      images: [
        ImageScreeny130GpzUnicaM1,
        ImageScreeny130GpzUnicaM2,
        ImageScreeny130GpzUnicaM3,
        ImageScreeny130GpzUnicaM4,
        ImageScreeny130GpzUnicaM5,
        ImageScreeny130GpzUnicaM6,
        ImageScreeny130GpzUnicaM7,
      ],
      buttons: [
        {
          text: getLanguageTranslation("Scheda Tecnica"),
          href: TechnicalSheetScreeny130GpzUnicaM,
        },
      ],
    },
    undefined,
    "<p><strong>SCREENY 130 GPZ UNICA M</strong><br />" +
      getLanguageTranslation(
        "Tenda con cassonetto da 110mm disponibile nelle varianti Quadro e Tondo dotata di sistema di guida ZIP e cassonetto autoportante. Adatta per installazioni su infissi, sia a nicchia che a parete; veloce ed agevole da installare ed ispezionare."
      ) +
      "</p>"
  ),
];

export const createScreeny110GcScreeny130GpzIInfoPointsEs = (
  createPositionPoint,
  createInfoPoint,
  createVrPoint
) => [
  createPositionPoint(
    [1088.21, -992.11, 4770.89],
    "screeny-110-gpz-unica-am-screeny-130-gpz-unica-m",
    "Screeny 110 GPZ UNICA AM - Screeny 130 GPZ UNICA M"
  ),
  createPositionPoint(
    [-1273.06, -1918.25, -4432.38],
    "screeny-130-ga-screeny-130-gpz-mb",
    "Screeny 130 GA - Screeny 130 GPZ MB"
  ),
  createPositionPoint(
    [-4509.1, -844.46, -1980.34],
    "screeny-150-gpz-unica-am-screeny-110-gc-cabrio",
    "Screeny 150 GPZ UNICA AM - Screeny 110 GC CABRIO"
  ),
  createVrPoint(
    [4108.85, -1367.38, 2572.35],
    Screeny110GcGLB,
    Screeny110GcUSDZ
  ),
  createVrPoint(
    [4136.56, -1367.26, -2441.32],
    Screeny130GpzIGLB,
    Screeny130GpzIUSDZ
  ),
  createInfoPoint(
    [4788.32, 721.26, 1212.09],
    {
      type: "info",
      title: "",
      noCloseIcon: true,
      popupClass: "popup-info_container",
      sectionTitle: "SCREENY 110 GC",
      description:
        "<p>" +
        getLanguageTranslation(
          "Tenda con cassonetto da 110mm disponibile nelle varianti Quadro e Tondo, dotata di guida cavetto inox Ø4mm."
        ) +
        "</p>",
      images: [
        ImageScreeny110Gc1,
        ImageScreeny110Gc2,
        ImageScreeny110Gc3,
        ImageScreeny110Gc4,
        ImageScreeny110Gc5,
        ImageScreeny110Gc6,
        ImageScreeny110Gc7,
      ],
      buttons: [
        {
          text: getLanguageTranslation("Scheda Tecnica"),
          href: TechnicalSheetScreeny110Gc,
        },
      ],
    },
    undefined,
    "<p><strong>SCREENY 110 GC</strong><br />" +
      getLanguageTranslation(
        "Tenda con cassonetto da 110mm disponibile nelle varianti Quadro e Tondo, dotata di guida cavetto inox Ø4mm."
      ) +
      "</p>"
  ),
  createInfoPoint(
    [4723.74, 721.26, -1362.33],
    {
      type: "info",
      title: "",
      noCloseIcon: true,
      popupClass: "popup-info_container",
      sectionTitle: "SCREENY 130 GPZ I",
      description:
        "<p>" +
        getLanguageTranslation(
          "Tenda con cassonetto da 130mm disponibile nelle varianti Quadro e Tondo dotata di sistema di guida ZIP, composta da due profili con compensazione fino ad un max di 17 mm su ogni guida. Adatta per essere installata ad incasso e per applicazioni che presentano fuori squadra."
        ) +
        "</p>",
      images: [
        ImageScreeny130GpzI1,
        ImageScreeny130GpzI2,
        ImageScreeny130GpzI3,
        ImageScreeny130GpzI4,
        ImageScreeny130GpzI5,
        ImageScreeny130GpzI6,
      ],
      buttons: [
        {
          text: getLanguageTranslation("Scheda Tecnica"),
          href: TechnicalSheetScreeny130GpzI,
        },
      ],
    },
    undefined,
    "<p><strong>SCREENY 130 GPZ I</strong><br />" +
      getLanguageTranslation(
        "Tenda con cassonetto da 130mm disponibile nelle varianti Quadro e Tondo dotata di sistema di guida ZIP, composta da due profili con compensazione fino ad un max di 17 mm su ogni guida. Adatta per essere installata ad incasso e per applicazioni che presentano fuori squadra."
      ) +
      "</p>"
  ),
];

export const createScreeny130GaScreeny130GpzMbInfoPointsEs = (
  createPositionPoint,
  createInfoPoint,
  createVrPoint
) => [
  createPositionPoint(
    [-4772.37, -772.63, 1248.16],
    "screeny-110-gpz-unica-am-screeny-130-gpz-unica-m",
    "Screeny 110 GPZ UNICA AM - Screeny 130 GPZ UNICA M"
  ),
  createPositionPoint(
    [-2600.42, -1938.88, 3797.74],
    "screeny-110-gc-screeny-130-gpz-i",
    "Screeny 110 GC - Screeny 130 GPZ I"
  ),
  createPositionPoint(
    [385.06, -870.9, -4900.61],
    "screeny-150-gpz-unica-am-screeny-110-gc-cabrio",
    "Screeny 150 GPZ UNICA AM - Screeny 110 GC CABRIO"
  ),
  createPositionPoint(
    [-1575.46, -429.24, -4722.46],
    "percorso-3",
    "Percorso 3"
  ),
  createVrPoint([3980.13, -1312.2, 2710.67], Screeny130GaGLB, Screeny130GaUSDZ, undefined, "SCREENY 130 GA"),
  createVrPoint(
    [4001.43, -1393.3, -2635.93],
    Screeny130GpzMbGLB,
    Screeny130GpzMbUSDZ
  ),
  createInfoPoint(
    [4613.86, 702.01, 1768.42],
    {
      type: "info",
      title: "",
      noCloseIcon: true,
      popupClass: "popup-info_container",
      sectionTitle: "SCREENY 130 GA",
      description:
        "<p>" +
        getLanguageTranslation(
          "Tenda con cassonetto da 130mm disponibile nelle varianti Quadro e Tondo, dotata di guida asta inox Ø10mm."
        ) +
        "</p>",
      images: [
        ImageScreeny130Ga1,
        ImageScreeny130Ga2,
        ImageScreeny130Ga3,
        ImageScreeny130Ga4,
        ImageScreeny130Ga5,
        ImageScreeny130Ga6,
      ],
      buttons: [
        {
          text: getLanguageTranslation("Scheda Tecnica"),
          href: TechnicalSheetScreeny130Ga,
        },
      ],
    },
    undefined,
    "<p><strong>SCREENY 130 GA</strong><br />" +
      getLanguageTranslation(
        "Tenda con cassonetto da 130mm disponibile nelle varianti Quadro e Tondo, dotata di guida asta inox Ø10mm."
      ) +
      "</p>"
  ),
  createInfoPoint(
    [4746.41, 720.97, -1368.61],
    {
      type: "info",
      title: "",
      noCloseIcon: true,
      popupClass: "popup-info_container",
      sectionTitle: "SCREENY 130 GPZ MB",
      description:
        "<p>" +
        getLanguageTranslation(
          "Tenda con cassonetto da 130mm disponibile nella sola variante Quadro, dotata di guida profilo da incasso con sistema ZIP e cassonetto autoportante. Adatta per l’integrazione nei sistemi per serramento, migliorando il risparmio energetico."
        ) +
        "</p>",
      images: [
        ImageScreeny130GpzMb1,
        ImageScreeny130GpzMb2,
        ImageScreeny130GpzMb3,
        ImageScreeny130GpzMb4,
      ],
      buttons: [
        {
          text: getLanguageTranslation("Scheda Tecnica"),
          href: TechnicalSheetScreeny130GpzMb,
        },
      ],
    },
    undefined,
    "<p><strong>SCREENY 130 GPZ MB</strong><br />" +
      getLanguageTranslation(
        "Tenda con cassonetto da 130mm disponibile nella sola variante Quadro, dotata di guida profilo da incasso con sistema ZIP e cassonetto autoportante. Adatta per l’integrazione nei sistemi per serramento, migliorando il risparmio energetico."
      ) +
      "</p>"
  ),
];

export const createScreeny150GpzUnicaAmScreeny110GcCabrioInfoPointsEs = (
  createPositionPoint,
  createInfoPoint,
  createVrPoint
) => [
  createPositionPoint(
    [-856.77, -736.91, 4862.87],
    "screeny-110-gc-screeny-130-gpz-i",
    "Screeny 110 GC - Screeny 130 GPZ I"
  ),
  createPositionPoint(
    [946.57, -937.45, 4812.0],
    "screeny-130-ga-screeny-130-gpz-mb",
    "Screeny 130 GA - Screeny 130 GPZ MB"
  ),
  createPositionPoint([-2917.7, -755.86, -3987.17], "percorso-3", "Percorso 3"),
  createPositionPoint(
    [2123.31, -1137.98, -4373.74],
    "percorso-4",
    "Percorso 4"
  ),
  createVrPoint(
    [3710.33, -1090.62, 3157.27],
    Screeny150GpzUnicaAmGLB,
    Screeny150GpzUnicaAmUSDZ
  ),
  createVrPoint(
    [4176.28, -1202.48, -2465.36],
    Screeny110GcCabrioGLB,
    Screeny110GcCabrioUSDZ
  ),
  createInfoPoint(
    [4657.69, 660.58, 1667.9],
    {
      type: "info",
      title: "",
      noCloseIcon: true,
      popupClass: "popup-info_container",
      sectionTitle: "SCREENY 150 GPZ UNICA AM",
      description:
        "<p>" +
        getLanguageTranslation(
          "Tenda con cassonetto da 150mm disponibile nella sola variante Tondo, dotata di sistema di guida ZIP. Adatta per installazioni su grandi fori luce, sia a nicchia che a parete. La guida è composta da due profili: supporto e copertina; è veloce ed agevole da installare ed ispezionare."
        ) +
        "</p>",
      images: [
        ImageScreeny150GpzUnica1,
        ImageScreeny150GpzUnica2,
        ImageScreeny150GpzUnica3,
        ImageScreeny150GpzUnica4,
        ImageScreeny150GpzUnica5,
      ],
      buttons: [
        {
          text: getLanguageTranslation("Scheda Tecnica"),
          href: TechnicalSheetScreeny150GpzUnica,
        },
      ],
    },
    undefined,
    "<p><strong>SCREENY 150 GPZ UNICA AM</strong><br />" +
      getLanguageTranslation(
        "Tenda con cassonetto da 150mm disponibile nella sola variante Tondo, dotata di sistema di guida ZIP. Adatta per installazioni su grandi fori luce, sia a nicchia che a parete. La guida è composta da due profili: supporto e copertina; è veloce ed agevole da installare ed ispezionare."
      ) +
      "</p>"
  ),
  createInfoPoint(
    [4661.56, 690.37, -1647.99],
    {
      type: "info",
      title: "",
      noCloseIcon: true,
      popupClass: "popup-info_container",
      sectionTitle: "SCREENY 110 GC CABRIO",
      description:
        "<p>" +
        getLanguageTranslation(
          "Tenda senza cassonetto, dotata di guida cavetto inox Ø4mm. Realizzabile sia nella versione singola che doppia."
        ) +
        "</p>",
      images: [
        ImageScreeny110GcCabrio1,
        ImageScreeny110GcCabrio2,
        ImageScreeny110GcCabrio3,
        ImageScreeny110GcCabrio4,
        ImageScreeny110GcCabrio5,
        ImageScreeny110GcCabrio6,
        ImageScreeny110GcCabrio7,
        ImageScreeny110GcCabrio8,
      ],
      buttons: [
        {
          text: getLanguageTranslation("Scheda Tecnica"),
          href: TechnicalSheetScreeny110GcCabrio,
        },
      ],
    },
    undefined,
    "<p><strong>SCREENY 110 GC CABRIO</strong><br />" +
      getLanguageTranslation(
        "Tenda senza cassonetto, dotata di guida cavetto inox Ø4mm. Realizzabile sia nella versione singola che doppia."
      ) +
      "</p>"
  ),
];

export const createPercorso4InfoPointsEs = (createPositionPoint) => [
  createPositionPoint(
    [-4634.25, -1412.85, 1196.46],
    "mini-helix-ps-helix-ps",
    "Mini Helix PS - Helix PS"
  ),
  createPositionPoint([-1558.58, -1090.88, 4618.57], "intro", "Intro"),
  createPositionPoint([4920.29, -683.83, 534.33], "percorso-3", "Percorso 3"),
  createPositionPoint(
    [4439.46, -412.03, -2246.38],
    "bravo-verso-cassonetto",
    "Bravo - Verso Cassonetto"
  ),
];

export const createPercorso5InfoPointsEs = (createPositionPoint) => [
  createPositionPoint(
    [-4377.69, -1035.93, -2160.22],
    "domea-base-plus",
    "Domea - Base Plus"
  ),
  createPositionPoint([-4800.87, -550.78, 1243.1], "percorso-1", "Percorso 1"),
  createPositionPoint([3768.12, -106.79, 3274.29], "percorso-6", "Percorso 6"),
];

export const createPercorso6InfoPointsEs = (createPositionPoint) => [
  createPositionPoint(
    [-2385.75, -1023.13, 4265.63],
    "percorso-5",
    "Percorso 5"
  ),
  createPositionPoint(
    [4406.22, -603.03, -2261.89],
    "line-glass-1.0",
    "Line Glass 1.0"
  ),
  createPositionPoint([4964.66, -506.04, 123.35], "ely", "Ely"),
];

export const createPercorso7InfoPointsEs = (createPositionPoint) => [
  createPositionPoint([-842.89, -764.86, 4861.72], "percorso-8", "Percorso 8"),
  createPositionPoint([2658.63, -442.74, 4201.65], "percorso-9", "Percorso 9"),
  createPositionPoint(
    [4431.58, -369.92, 2268.93],
    "line-glass-1.0",
    "Line Glass 1.0"
  ),
  createPositionPoint([4900.79, -535.31, 769.65], "ely", "Ely"),
];

export const createPercorso8InfoPointsEs = (createPositionPoint) => [
  createPositionPoint([-1033.12, -490.83, -4864.38], "ely", "Ely"),
  createPositionPoint(
    [-4428.42, -1084.78, -2039.28],
    "percorso-7",
    "Percorso 7"
  ),
  createPositionPoint([3292.63, -540.72, -3716.28], "percorso-9", "Percorso 9"),
  createPositionPoint(
    [4959.05, -515.86, 208.2],
    "space-sirio",
    "Space - Sirio"
  ),
  createPositionPoint(
    [840.66, -235.89, -4912.87],
    "line-glass-1.0",
    "Line Glass 1.0"
  ),
];

export const createPercorso9InfoPointsEs = (createPositionPoint) => [
  createPositionPoint([-3474.85, -505.02, 3545.92], "percorso-7", "Percorso 7"),
  createPositionPoint([-1136.61, -557.79, 4828.55], "percorso-8", "Percorso 8"),
  createPositionPoint(
    [-2387.08, -829.87, -4308.03],
    "line-glass-1.0",
    "Line Glass 1.0"
  ),
  createPositionPoint(
    [4860.63, -756.79, 855.89],
    "giotto-plus-giotto",
    "Giotto Plus - Giotto"
  ),
  createPositionPoint([-4516.0, -653.78, -2033.23], "ely", "Ely"),
];

export const createSpaceSirioInfoPointsEs = (
  createPositionPoint,
  createInfoPoint,
  createVrPoint,
  createVideoPoint
) => [
  createPositionPoint([-1000.3, -619.96, -4854.13], "percorso-8", "Percorso 8"),
  createVrPoint([3483.79, -756.64, 3494.92], SpaceGLB, SpaceUSDZ, undefined, "SPACE"),
  createVrPoint([3609.93, -747.23, -3370.35], SirioGLB, SirioUSDZ, undefined, "SIRIO"),
  createInfoPoint(
    [4417.2, 748.01, 2212.48],
    {
      type: "info",
      title: "",
      noCloseIcon: true,
      popupClass: "popup-info_container",
      sectionTitle: "SPACE",
      description:
        "<p>" +
        getLanguageTranslation(
          "Struttura di copertura per verande e attici. Il tessuto scorre nella parte superiore su guide, regolabili in larghezza e contenenti il sistema di tensionamento con molle a gas. Sono disponibili degli optional come il sistema di blocco meccanico e il sistema flottante per il tubo avvolgitore. Dimensioni massime 500x700 cm e con guida centrale anche 700x700 cm."
        ) +
        "</p>",
      images: [
        ImageSpace1,
        ImageSpace2,
        ImageSpace3,
        ImageSpace4,
        ImageSpace5,
        ImageSpace6,
        ImageSpace7,
      ],
      buttons: [
        {
          text: getLanguageTranslation("Scheda Tecnica"),
          href: TechnicalSheetSpace,
        },
      ],
    },
    undefined,
    "<p><strong>SPACE</strong><br />" +
      getLanguageTranslation(
        "Struttura di copertura per verande e attici. Il tessuto scorre nella parte superiore su guide, regolabili in larghezza e contenenti il sistema di tensionamento con molle a gas."
      ) +
      "</p>"
  ),
  createInfoPoint(
    [4598.78, 748.9, -1792.23],
    {
      type: "info",
      title: "",
      noCloseIcon: true,
      popupClass: "popup-info_container",
      sectionTitle: "SIRIO",
      description:
        "<p>" +
        getLanguageTranslation(
          "SIRIO Struttura di copertura per giardini d’inverno, tetti in vetro e verande. Dotata di sistema di guida Zip e di sistema di tensionamento del telo con molla. Presenza di optional luci e terminale a cassonetto. Funzionamento esclusivamente motorizzato."
        ) +
        "</p>",
      images: [
        ImageSirio1,
        ImageSirio2,
        ImageSirio3,
        ImageSirio4,
        ImageSirio5,
        ImageSirio6,
      ],
      buttons: [
        {
          text: getLanguageTranslation("Scheda Tecnica"),
          href: TechnicalSheetSirio,
        },
      ],
    },
    undefined,
    "<p><strong>SIRIO</strong><br />" +
      getLanguageTranslation(
        "SIRIO Struttura di copertura per giardini d’inverno, tetti in vetro e verande. Dotata di sistema di guida Zip e di sistema di tensionamento del telo con molla."
      ) +
      "</p>"
  ),
  createVideoPoint(
    [3931.50, 367.36, -3056.29],
    "Video",
    "https://www.youtube.com/watch?v=C1RcttDd3D8"
  ),
];

export const createLineGlassInfoPointsEs = (
  createPositionPoint,
  createInfoPoint,
  createVrPoint
) => [
  createPositionPoint([-1995.81, -484.67, 4554.08], "percorso-7", "Percorso 7"),
  createPositionPoint(
    [-3789.66, -819.81, -3144.79],
    "percorso-6",
    "Percorso 6"
  ),
  createPositionPoint([179.46, -203.22, 4982.61], "percorso-8", "Percorso 8"),
  createPositionPoint([1729.44, -856.54, 4602.85], "percorso-9", "Percorso 9"),
  createPositionPoint([-4482.08, -981.52, 1974.76], "ely", "Ely"),
  createVrPoint([3845.53, 1844.74, -2594.81], GlassGLB, GlassUSDZ, undefined, "GLASS 1.0"),
  createInfoPoint(
    [3910.12, 1728.4, 2574.05],
    {
      type: "info",
      title: "",
      noCloseIcon: true,
      popupClass: "popup-info_container",
      sectionTitle: "GLASS 1.0",
      description:
        "<p>" +
        getLanguageTranslation(
          "Binario inferiore alto soli 18 mm. Movimentazione delle ante a sinistra o a destra, con cambio verso rapido anche in fase d’opera. Regolazione in altezza fino a 8 mm per ogni singola anta. Sistema scomponibile per una facile pulizia. In presenza di forti piogge o piogge accompagnate da vento non è garantita la completa impermeabilità."
        ) +
        "</p>",
      images: [
        ImageGlass1,
        ImageGlass2,
        ImageGlass3,
        ImageGlass4,
        ImageGlass5,
        ImageGlass6,
        ImageGlass7,
        ImageGlass8,
        ImageGlass9,
        ImageGlass10,
        ImageGlass11,
        ImageGlass12,
      ],
      buttons: [
        {
          text: getLanguageTranslation("Scheda Tecnica"),
          href: TechnicalSheetGlass,
        },
      ],
    },
    undefined,
    "<p><strong>GLASS 1.0</strong><br />" +
      getLanguageTranslation(
        "Binario inferiore alto soli 18 mm. Movimentazione delle ante a sinistra o a destra, con cambio verso rapido anche in fase d’opera."
      ) +
      "</p>"
  ),
];

export const createElyInfoPointsEs = (
  createPositionPoint,
  createInfoPoint,
  createVrPoint
) => [
  createPositionPoint([593.74, -492.14, -4933.82], "percorso-7", "Percorso 7"),
  createPositionPoint([1210.13, -764.13, 4783.6], "percorso-6", "Percorso 6"),
  createPositionPoint(
    [-1196.44, -404.88, -4831.23],
    "percorso-8",
    "Percorso 8"
  ),
  createPositionPoint(
    [-4595.22, -722.74, -1810.12],
    "percorso-9",
    "Percorso 9"
  ),
  createPositionPoint(
    [-4380.93, -1218.87, 2064.37],
    "line-glass-1.0",
    "Line Glass 1.0"
  ),
  createVrPoint([3643.91, -1782.18, 2907.22], ElyGLB, ElyUSDZ, undefined, "ELY"),
  createInfoPoint(
    [4689.35, -1713.61, 71.77],
    {
      type: "info",
      title: "",
      noCloseIcon: true,
      popupClass: "popup-info_container",
      sectionTitle: "ELY",
      description:
        "<p>" +
        getLanguageTranslation(
          "ELY è una nuova tenda verticale per uso esterno. Grazie alla sua struttura lineare, alla facilità di assemblaggio e installazione e all’innovativo ed accattivante design, Ely risulta essere la soluzione ideale per soddisfare le più esigenti richieste di protezione solare ed arredo esterno."
        ) +
        "</p>",
      images: [
        ImageEly1,
        ImageEly2,
        ImageEly3,
        ImageEly4,
        ImageEly5,
        ImageEly6,
        ImageEly7,
        ImageEly8,
      ],
      buttons: [
        {
          text: getLanguageTranslation("Scheda Tecnica"),
          href: TechnicalSheetEly,
        },
      ],
    },
    undefined,
    "<p><strong>ELY</strong><br />" +
      getLanguageTranslation(
        "ELY è una nuova tenda verticale per uso esterno. Grazie alla sua struttura lineare, alla facilità di assemblaggio e installazione e all’innovativo ed accattivante design, Ely risulta essere la soluzione ideale per soddisfare le più esigenti richieste di protezione solare ed arredo esterno."
      ) +
      "</p>"
  ),
];

export const createGiottoPlusGiottoInfoPointsEs = (
  createPositionPoint,
  createInfoPoint,
  createVrPoint
) => [
  createPositionPoint([-566.11, -670.11, 4916.89], "percorso-9", "Percorso 9"),
  createPositionPoint(
    [-2719.56, -403.65, 4167.59],
    "line-glass-1.0",
    "Line Glass 1.0"
  ),
  createVrPoint([3167.9, -223.59, 3848.38], GiottoPlusGLB, GiottoPlusUSDZ, undefined, "GIOTTO PLUS"),
  createVrPoint([3413.64, -405.77, -3623.72], GiottoGLB, GiottoUSDZ, undefined, "GIOTTO"),
  createInfoPoint(
    [3947.72, 600.92, 3044.08],
    {
      type: "info",
      title: "",
      noCloseIcon: true,
      popupClass: "popup-info_container",
      sectionTitle: "GIOTTO PLUS",
      description:
        "<p>" +
        getLanguageTranslation(
          "GIOTTO PLUS é la versione evoluta di Giotto, della quale mantiene tutte le caratteristiche tecniche. Sistema di protezione solare ideale per la copertura di terrazze, verande ed attici, può essere installata a piacere sia a parete che a soffitto e poggia, nella parte frontale su due colonne di design; per irrigidire la struttura vi è la possibilità di inserire un traverso che unisca le due colonne. È un sistema estremamente versatile ideale sia per gli ambienti privati che per quelli pubblici. Tenda con cassonetto dal design accattivante a scomparsa totale."
        ) +
        "</p>",
      images: [
        ImageGiottoPlus1,
        ImageGiottoPlus2,
        ImageGiottoPlus3,
        ImageGiottoPlus4,
      ],
      buttons: [
        {
          text: getLanguageTranslation("Scheda Tecnica"),
          href: TechnicalSheetGiottoPlus,
        },
      ],
    },
    undefined,
    "<p><strong>GIOTTO PLUS</strong><br />" +
      getLanguageTranslation(
        "GIOTTO PLUS é la versione evoluta di Giotto, della quale mantiene tutte le caratteristiche tecniche. Sistema di protezione solare ideale per la copertura di terrazze, verande ed attici, può essere installata a piacere sia a parete che a soffitto e poggia, nella parte frontale su due colonne di design; per irrigidire la struttura vi è la possibilità di inserire un traverso che unisca le due colonne."
      ) +
      "</p>"
  ),
  createInfoPoint(
    [4598.78, 600.9, -1792.23],
    {
      type: "info",
      title: "",
      noCloseIcon: true,
      popupClass: "popup-info_container",
      sectionTitle: "GIOTTO",
      description:
        "<p>" +
        getLanguageTranslation(
          "GIOTTO è un prodotto per la copertura dei giardini d’inverno, dei tetti in vetro e delle verande in generale. BAT, forte dell’esperienza acquisita nel settore, ha progettato e sviluppato un prodotto che unisce soluzioni tecniche innovative ad un design estremamente interessante. Giotto funziona esclusivamente tramite motorizzazione."
        ) +
        "</p>",
      images: [
        ImageGiotto1,
        ImageGiotto2,
        ImageGiotto3,
        ImageGiotto4,
        ImageGiotto5,
      ],
      buttons: [
        {
          text: getLanguageTranslation("Scheda Tecnica"),
          href: TechnicalSheetGiotto,
        },
      ],
    },
    undefined,
    "<p><strong>GIOTTO</strong><br />" +
      getLanguageTranslation(
        "GIOTTO è un prodotto per la copertura dei giardini d’inverno, dei tetti in vetro e delle verande in generale."
      ) +
      "</p>"
  ),
];
