//  @ts-ignore
import React, { useState } from "react";
import { TechnicalSpecificsType } from "../types";
import Map from "./map";
import { CloseWhite } from "../icons";

const PopupMap: React.FC<{
  currentPosition: string;
  map: any;
  setPopupInfos: any;
  setTechnicalSpecifics: (v: TechnicalSpecificsType) => any;
  technicalSpecifics: TechnicalSpecificsType;
}> = ({
  currentPosition,
  map,
  setPopupInfos,
  setTechnicalSpecifics,
  technicalSpecifics
}) => (
  <>
    <div className="close-button" onClick={() => setPopupInfos(null)}>
      <img src={CloseWhite} alt="close-icon-white" className="close-icon" />
    </div>
    <Map
      currentPosition={currentPosition}
      map={map}
      setPopupInfos={setPopupInfos}
      setTechnicalSpecifics={setTechnicalSpecifics}
      technicalSpecifics={technicalSpecifics}
    />
  </>
);

export default PopupMap;
