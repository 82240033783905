//  @ts-ignore
import React, { useState } from "react";
import { Formik, Field, Form, FormikHelpers } from "formik";
import { CloseWhite } from "../icons";
import { TechnicalSpecificsType } from "../types";
import { getLanguageTranslation } from "../utils/translations/get-language-translation";

const PopupForm: React.FC<{
  technicalSpecifics: TechnicalSpecificsType;
  setPopupInfos: any;
}> = ({ technicalSpecifics, setPopupInfos }) => {
  const [formMessage, setFormMessage] = useState({ text: "", color: "" });
  interface Values {
    firstName: string;
    lastName: string;
    email: string;
    message: string;
    phone: string;
    city: string;
  }
  return (
    <div className="form-wrapper">
      <div className="form-header">
        <div className="close-button" onClick={() => setPopupInfos(null)}>
          <img src={CloseWhite} alt="close-icon-white" className="close-icon" />
        </div>
      </div>
      <div className="form-initial">
        <h2>
          {getLanguageTranslation("Vuoi più informazioni sui prodotti BAT?")}
        </h2>
        <p>
          {getLanguageTranslation(
            "Scrivici: ti offriamo un preventivo gratuito e senza impegno."
          )}
        </p>
        <small>{getLanguageTranslation("Ti rispondiamo entro 24/48H")}</small>
      </div>
      <Formik
        initialValues={{
          firstName: "",
          lastName: "",
          email: "",
          message: "",
          phone: "",
          city: "",
          gdpr: false
        }}
        onSubmit={(
          values: Values,
          { setSubmitting, resetForm }: FormikHelpers<Values>
        ) => {
          fetch(
            "https://api.emailjs.com/api/v1.0/email/send",
            {
              method: "POST",
              body: JSON.stringify({
                service_id: 'service_0g2hqd5',
                template_id: 'template_5cl2fuv',
                user_id: '6TlnOi-Yye8CldyJS',
                template_params: {
                  'message': `Nome: ${values.firstName}\n\rE-mail: ${values.email}\n\rTelefono: ${values.phone}\n\rCittà: ${values.city}\n\rMessaggio: ${values.message}`,
                },
              }),
              headers: {
                "content-type": "application/json"
              }
            }
          ).then(res => {
            if (res.status === 200) {
              setFormMessage({
                text: getLanguageTranslation("Grazie per averci contattato!"),
                color: "#00C000"
              });
              // @ts-ignore
              window.dataLayer = window.dataLayer || [];
              // @ts-ignore
              window.dataLayer.push({
                'event': 'submit_contact_form'
              });
            } else {
              setFormMessage({
                text: getLanguageTranslation("Qualcosa è andato storto."),
                color: "#E02020"
              });
            }
            setTimeout(() => {
              setFormMessage({ text: "", color: "" });
            }, 4000);
            resetForm({});
          });
          setSubmitting(false);
        }}
        render={() => (
          <Form className="form-container">
            <div className="form-row">
              <label htmlFor="firstName">
                {getLanguageTranslation("Il tuo nome")}
              </label>
              <Field id="firstName" name="firstName" required type="text" />
            </div>
            <div className="form-row">
              <label htmlFor="email">
                {getLanguageTranslation("Il tuo indirizzo e-mail *")}
              </label>
              <Field id="email" name="email" required type="email" />
            </div>
            <div className="form-row">
              <label htmlFor="phone">
                {getLanguageTranslation("Telefono *")}
              </label>
              <Field
                id="phone"
                name="phone"
                type="tel"
                required
                title="1234567890"
                pattern="[0-9]{3}[0-9]{3}[0-9]{4}"
              />
            </div>
            <div className="form-row">
              <label htmlFor="city">
                {getLanguageTranslation("La tua città *")}
              </label>
              <Field id="city" name="city" type="text" required />
            </div>
            <div className="form-row">
              <label htmlFor="message">
                {getLanguageTranslation("Vuoi raccontarci la tua esigenza?")}
              </label>
              <Field
                id="message"
                name="message"
                type="text"
                as="textarea"
                rows="6"
              />
            </div>
            <div className="form-gdpr-row">
              <Field id="gdpr" name="gdpr" type="checkbox" required />
              <label htmlFor="gdpr">
                {getLanguageTranslation(
                  "Acconsento al trattamento dei miei dati personali"
                )}{" "}
                (
                <a
                  href="https://www.batgroup.com/privacy-cookie-policy/"
                  target="_blank"
                  className="privacy-policy__link"
                >
                  {getLanguageTranslation("Regolamento UE 2016/679")}
                </a>
                )*
              </label>
            </div>
            <div className="submit-row">
              <span style={{ color: formMessage.color }}>
                {formMessage.text}
              </span>
              <div className="button-container">
                <button type="submit" style={{ display: "block" }} id="form-submit-button">
                  {getLanguageTranslation("Desidero un preventivo gratuito")}
                </button>
                <small>
                  {getLanguageTranslation(
                    "Riceverai una risposta entro 24-48H"
                  )}
                </small>
              </div>
            </div>
          </Form>
        )}
      />
    </div>
  );
};

export default PopupForm;
