import { isMobile } from "react-device-detect";

let panolens = null;
let THREE = null;
if (typeof window !== `undefined`) {
  panolens = require("panolens");
  THREE = require("three");
}
const { Infospot } = panolens !== null ? panolens : { Infospot: null };

export const addInfospot = (ip, infoSpot, position, viewer) => {
  if (infoSpot[position] !== undefined && infoSpot[position].length) {
    const ispot = [];
    infoSpot[position].forEach((is) => {
      if (is !== null && is !== undefined) {
        let infospot;
        if (is.image !== null && is.image !== undefined) {
          if (isMobile) {
            infospot = new Infospot(350, is.image, !isMobile);
          } else {
            infospot = new Infospot(250, is.image, !isMobile);
          }
        } else {
          infospot = new Infospot();
        }
        if (is.type === "position") {
          infospot.opacity = 0.1;
        } else {
          infospot.opacity = 0.4;
        }
        infospot.position.set(...is.position);

        if (is.type === "info" && is.hoverText) {
          const panel = document.querySelector("#panel");
          panel.innerHTML = is.hoverText;
          infospot.addHoverElement(panel, 160);
        } else {
          infospot.addHoverText(is.text, 70);
        }

        infospot.addEventListener("click", () => {
          is.clickListener();
        });
        infospot.duration = 2500;
        ispot.push(infospot);
      }
    });
    ip.add(...ispot);
  }
  return ip;
};
