//  @ts-ignore
import React from "react";
import { CloseWhite } from "../icons";
import { getLanguageTranslation } from "../utils/translations/get-language-translation";

const PopupInstructions: React.FC<{ setPopupInfos: any }> = ({
  setPopupInfos
}) => {
  return (
    <>
      <div className="instruction__container">
        <div className="instruction-cards__container">
          <div
            className="instruction-close-button__container"
            onClick={() => setPopupInfos(null)}
          >
            <img
              src={CloseWhite}
              alt="close-icon-white"
              className="instruction-close-button"
            />
          </div>
          <div className="instruction-cards">
            <div className="instruction-card">
              <div className="instruction-text__container">
                <div className="instruction-title__container">
                  <h3 className="instruction-title">
                    {getLanguageTranslation("Ambiente a 360°")}
                  </h3>
                </div>
                <div className="instruction-description__container">
                  <span className="instruction-description">
                    {getLanguageTranslation(
                      "È possibile esplorare liberamente l'ambiente cliccando e trascinando il cursore, spostandosi attraverso i punti di movimento."
                    )}
                  </span>
                </div>
              </div>
              <div className="instruction-gesture__container">
                <div className="instruction-gesture__drag-icon">
                  <svg
                    version="1.1"
                    className="instruction-gesture__drag"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="140.2 0 293.8 500"
                    enableBackground="new 140.2 0 293.8 500"
                    xmlSpace="preserve"
                  >
                    <g>
                      <path
                        className="hand"
                        d="M173.2,170.2l1.8,86.2c-3.3,1.8-8.3,5.1-14.7,10.9c-22.1,20-29.3,68.7-4.3,106.3
                      c27.8,41.9,60.4,59.8,109,59.8c51.5,0,68.7-25.8,81.9-52.3c12.7-25.5,12.9-122.9,12.9-127.1c0-13-12.3-24.5-26.4-24.5
                      c-1.7,0-5.9,0.6-10.2,1.9c-1.1-14.7-13.4-26.4-28.5-26.4c-6.8,0-13,2.4-17.9,6.3c-3.6-11-13.2-18.5-25-18.5
                      c-7.8,0-14.8,3.2-19.8,8.5l-1.3-35.1l-0.6-15.9l-0.9-24.8c0-17.4-12.3-30.6-28.6-30.6S172,108,172,125.6l0.6,29.7L173.2,170.2z"
                      />
                    </g>
                  </svg>
                </div>
              </div>
            </div>
            <div className="instruction-card">
              <div className="instruction-text__container">
                <div className="instruction-title__container">
                  <h3 className="instruction-title">
                    {getLanguageTranslation("Interattivo")}
                  </h3>
                </div>
                <div className="instruction-description__container">
                  <span className="instruction-description">
                    {getLanguageTranslation(
                      "In ogni prodotto sono disponibili vari punti di informazione, con tutti i materiali relativi al prodotto che si sta visualizzando."
                    )}
                  </span>
                </div>
              </div>
              <div className="instruction-gesture__container">
                <div className="instruction-gesture__scroll-icon">
                  <svg
                    version="1.1"
                    height="50px"
                    width="50px"
                    viewBox="30.5 -9.5 554 813"
                    enableBackground="new 30.5 -9.5 554 813"
                    xmlSpace="preserve"
                  >
                    <path
                      d="M328,0h-44C271.68,0,42,5.28,42,242v308c0,9.9,3.96,242,242,242h44c13.86,0,242-6.16,242-242V242C570,232.1,566.04,0,328,0z
                 M526,550c0,191.62-177.76,198-198,198h-44C92.38,748,86,570.24,86,550V242C86,50.82,263.54,44,284,44h44
                 c191.62,0,198,177.76,198,198V550z"
                    />
                    <circle cx="306" cy="231" r="45" />
                  </svg>
                </div>
              </div>
            </div>
            <div className="instruction-card">
              <div className="instruction-text__container">
                <div className="instruction-title__container">
                  <h3 className="instruction-title">
                    {getLanguageTranslation("Immersivo")}
                  </h3>
                </div>
                <div className="instruction-description__container">
                  <span className="instruction-description">
                    {getLanguageTranslation(
                      "È possibile ingrandire o rimpicciolire a piacimento le immagini, per una visione più specifica del prodotto."
                    )}
                  </span>
                </div>
              </div>
              <div className="instruction-gesture__container">
                <div className="instruction-gesture__click-icon">
                  <svg
                    version="1.1"
                    className="instruction-gesture__click"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="140.2 0 293.8 500"
                    enableBackground="new 140.2 0 293.8 500"
                    xmlSpace="preserve"
                  >
                    <g>
                      <path
                        className="hand"
                        d="M173.2,170.2l1.8,86.2c-3.3,1.8-8.3,5.1-14.7,10.9c-22.1,20-29.3,68.7-4.3,106.3
                    c27.8,41.9,60.4,59.8,109,59.8c51.5,0,68.7-25.8,81.9-52.3c12.7-25.5,12.9-122.9,12.9-127.1c0-13-12.3-24.5-26.4-24.5
                    c-1.7,0-5.9,0.6-10.2,1.9c-1.1-14.7-13.4-26.4-28.5-26.4c-6.8,0-13,2.4-17.9,6.3c-3.6-11-13.2-18.5-25-18.5
                    c-7.8,0-14.8,3.2-19.8,8.5l-1.3-35.1l-0.6-15.9l-0.9-24.8c0-17.4-12.3-30.6-28.6-30.6S172,108,172,125.6l0.6,29.7L173.2,170.2z"
                      />
                    </g>
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PopupInstructions;
