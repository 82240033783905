import { Info } from "../icons";

export const useCreateInfoPoint: (
  setPopupInfos: any
) => (
  position: number[],
  infos: Record<string, any>,
  title?: string,
  hoverText?: string
) => Record<string, any> = (setPopupInfos) => (
  position,
  infos,
  title = "Info",
  hoverText = undefined
) => ({
  position,
  text: title,
  image: Info,
  type: "info",
  description: null,
  hoverText,
  clickListener: () => {
    setPopupInfos(infos);
    // @ts-ignore
    window.dataLayer = window.dataLayer || [];
    // @ts-ignore
    window.dataLayer.push({
      'event': 'click-on-product',
      'clickProduct': infos.sectionTitle
    });
  },
});
