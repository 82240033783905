import {
  EpicaGLB,
  EpicaLiteGLB,
  EpicaLiteUSDZ,
  EpicaUSDZ,
  GiantGLB,
  GiantUSDZ,
  GLB2040,
  HavantGLB,
  HavantUSDZ,
  Image20401,
  Image20402,
  Image20403,
  Image20404,
  Image20405,
  ImageEpica1,
  ImageEpica2,
  ImageEpica3,
  ImageEpica4,
  ImageEpicaLite1,
  ImageEpicaLite2,
  ImageEpicaLite3,
  ImageEpicaLite4,
  ImageEpicaLite5,
  ImageEpicaLite6,
  ImageEpicaLite7,
  ImageEpicaLite8,
  ImageEpicaLite9,
  ImageGiant1,
  ImageGiant2,
  ImageGiant3,
  ImageGiant4,
  ImageGiant5,
  ImageHavant1,
  ImageHavant2,
  ImageHavant3,
  ImageHavant4,
  ImageOmbralsun1,
  ImageOmbralsun2,
  ImageOmbralsun3,
  ImageOmbralsun4,
  ImageOmbralsun5,
  ImageR901,
  ImageR902,
  ImageR903,
  ImageR904,
  ImageR905,
  ImageRPitch1,
  ImageRPitch2,
  ImageRPitch3,
  ImageRPitch4,
  ImageScreeny110Gc1,
  ImageScreeny110Gc2,
  ImageScreeny110Gc3,
  ImageScreeny110Gc4,
  ImageScreeny110Gc5,
  ImageScreeny110Gc6,
  ImageScreeny110Gc7,
  ImageScreeny110GcCabrio1,
  ImageScreeny110GcCabrio2,
  ImageScreeny110GcCabrio3,
  ImageScreeny110GcCabrio4,
  ImageScreeny110GcCabrio5,
  ImageScreeny110GcCabrio6,
  ImageScreeny110GcCabrio7,
  ImageScreeny110GcCabrio8,
  ImageScreeny110GpzUnicaAm1,
  ImageScreeny110GpzUnicaAm2,
  ImageScreeny110GpzUnicaAm3,
  ImageScreeny110GpzUnicaAm4,
  ImageScreeny110GpzUnicaAm5,
  ImageScreeny110GpzUnicaAm6,
  ImageScreeny110GpzUnicaAm7,
  ImageScreeny130Ga1,
  ImageScreeny130Ga2,
  ImageScreeny130Ga3,
  ImageScreeny130Ga4,
  ImageScreeny130Ga5,
  ImageScreeny130Ga6,
  ImageScreeny130GpzI1,
  ImageScreeny130GpzI2,
  ImageScreeny130GpzI3,
  ImageScreeny130GpzI4,
  ImageScreeny130GpzI5,
  ImageScreeny130GpzI6,
  ImageScreeny130GpzMb1,
  ImageScreeny130GpzMb2,
  ImageScreeny130GpzMb3,
  ImageScreeny130GpzMb4,
  ImageScreeny130GpzTensTo1,
  ImageScreeny130GpzTensTo2,
  ImageScreeny130GpzTensTo3,
  ImageScreeny130GpzTensTo4,
  ImageScreeny130GpzTensTo5,
  ImageScreeny130GpzUnicaM1,
  ImageScreeny130GpzUnicaM2,
  ImageScreeny130GpzUnicaM3,
  ImageScreeny130GpzUnicaM4,
  ImageScreeny130GpzUnicaM5,
  ImageScreeny130GpzUnicaM6,
  ImageScreeny130GpzUnicaM7,
  ImageSpace1,
  ImageSpace2,
  ImageSpace3,
  ImageSpace4,
  ImageSpace5,
  ImageSpace6,
  ImageSpace7,
  ImageZenith1,
  ImageZenith2,
  ImageZenith3,
  ImageZenith4,
  ImageZenith5,
  ImageZenith6,
  OmbralsunGLB,
  OmbralsunUSDZ,
  R90GLB,
  R90USDZ,
  RPitchGLB,
  RPitchUSDZ,
  Screeny110GcCabrioGLB,
  Screeny110GcCabrioUSDZ,
  Screeny110GcGLB,
  Screeny110GcUSDZ,
  Screeny110GpzUnicaAmGLB,
  Screeny110GpzUnicaAmUSDZ,
  Screeny130GaGLB,
  Screeny130GaUSDZ,
  Screeny130GpzIGLB,
  Screeny130GpzIUSDZ,
  Screeny130GpzMbGLB,
  Screeny130GpzMbUSDZ,
  Screeny130GpzToGLB,
  Screeny130GpzToUSDZ,
  Screeny130GpzUnicaMGLB,
  Screeny130GpzUnicaMUSDZ,
  SpaceGLB,
  SpaceUSDZ,
  TechnicalSheet2040,
  TechnicalSheetEpica,
  TechnicalSheetEpicaLite,
  TechnicalSheetGiant,
  TechnicalSheetOmbralsun,
  TechnicalSheetR90,
  TechnicalSheetRPitch,
  TechnicalSheetScreeny110Gc,
  TechnicalSheetScreeny110GcCabrio,
  TechnicalSheetScreeny110GpzUnicaAm,
  TechnicalSheetScreeny130Ga,
  TechnicalSheetScreeny130GpzI,
  TechnicalSheetScreeny130GpzMb,
  TechnicalSheetScreeny130GpzTensTo,
  TechnicalSheetScreeny130GpzUnicaM,
  TechnicalSheetSpace,
  TechnicalSheetZenith,
  USDZ2040,
  ZenithGLB,
  ZenithUSDZ,
} from ".";
import { getLanguageTranslation } from "../translations/get-language-translation";

export const createIntroInfoPointsDe = (createPositionPoint) => [
  createPositionPoint([-661.41, -1035.23, 4837.5], "percorso-1", "Percorso 1"),
  createPositionPoint([4271.93, -522.32, 2533.13], "percorso-3", "Percorso 3"),
  createPositionPoint([4293.64, -522.32, -2486.45], "percorso-4", "Percorso 4"),
  createPositionPoint([-723.28, -732.07, -4884.59], "percorso-5", "Percorso 5"),
  createPositionPoint(
    [-4440.91, -1403.51, 1793.4],
    "r-pitch-130-gpz-to",
    "Epica Lite - 130 GPZ TO"
  ),
];

export const createPercorso1InfoPointsDe = (createPositionPoint) => [
  createPositionPoint([856.65, -1010.96, -4811.45], "intro", "Intro"),
  createPositionPoint([4836.33, -1199.39, -313.88], "percorso-2", "Percorso 2"),
  createPositionPoint(
    [1804.74, -1164.38, 4508.44],
    "epica-giant",
    "Epica - Giant"
  ),
  createPositionPoint(
    [-909.26, -1069.65, 4791.32],
    "epica-epica-lite",
    "Epica Lite - Epica"
  ),
  createPositionPoint(
    [-2363.4, -1198.17, -4235.11],
    "r-pitch-130-gpz-to",
    "130 GPZ TO"
  ),
];

export const createPercorso2InfoPointsDe = (createPositionPoint) => [
  createPositionPoint([4802.06, -1276.69, -520.44], "percorso-3", "Percorso 3"),
  createPositionPoint(
    [-4859.14, -1005.17, -562.96],
    "percorso-1",
    "Percorso 1"
  ),
  createPositionPoint(
    [-3120.28, -906.19, 3787.43],
    "epica-giant",
    "Epica - Giant"
  ),
  createPositionPoint(
    [2412.14, -941.16, 4270.46],
    "zenith-2040",
    "Zenith - 2040"
  ),
];

export const createPercorso3InfoPointsDe = (createPositionPoint) => [
  createPositionPoint([4947.12, -623.79, 192.11], "percorso-4", "Percorso 4"),
  createPositionPoint(
    [-2260.45, -1398.44, -4224.26],
    "percorso-2",
    "Percorso 2"
  ),
  createPositionPoint(
    [-4884.18, -762.83, -702.59],
    "zenith-2040",
    "Zenith - 2040"
  ),
];

export const createPercorso4InfoPointsDe = (createPositionPoint) => [
  createPositionPoint([-4950.31, -598.23, 188.93], "percorso-3", "Percorso 3"),
  createPositionPoint([391.6, -824.6, -4909.48], "percorso-5", "Percorso 5"),
  //createPositionPoint([3213.62, -871.45, 3720.72], "havant", "Havant"),
  createPositionPoint([4437.87, -571.1, 2208.08], "ombralsun", "Ombralsun"),
  createPositionPoint(
    [3507.79, -936.03, -3423.43],
    "screeny-110-gpz-unica-am-screeny-110-gc-cabrio",
    "Screeny 110 GPZ UNICA AM - Screeny 110 GC Cabrio"
  ),
];

export const createPercorso5InfoPointsDe = (createPositionPoint) => [
  createPositionPoint([871.55, -758.75, 4857.91], "intro", "Intro"),
  createPositionPoint([4919.03, -492.66, 686.68], "percorso-4", "Percorso 4"),
  //createPositionPoint([-4283.04, -2450.98, 750.33], "space-r90", "Space - R 90"),
];

export const createRPitch130GpzToInfoPointsDe = (
  createPositionPoint,
  createInfoPoint,
  createVrPoint
) => [
  createPositionPoint([-4196.48, -1589.94, 2187.61], "intro", "Intro"),
  createPositionPoint(
    [-2934.21, -1143.14, -3877.53],
    "percorso-1",
    "Percorso 1"
  ),
  createVrPoint([4483.94, -262.57, 2164.54], RPitchGLB, RPitchUSDZ, undefined, "130 GPZ TO"),
  createVrPoint(
    [4221.75, -883.68, -2516.99],
    Screeny130GpzToGLB,
    Screeny130GpzToUSDZ
  ),
  createInfoPoint(
    [4518.61, 1416.59, -1580.71],
    {
      type: "info",
      title: "",
      noCloseIcon: true,
      popupClass: "popup-info_container",
      sectionTitle: "130 GPZ TO",
      description:
        "<p>" +
        getLanguageTranslation(
          "Tenda con cassonetto da 130mm disponibile nella sola variante Quadro, dotata di sistema di guida ZIP. Adatta, grazie al sistema di tensionamento , per installazioni come copertura dei giardini d’inverno, dei tetti in vetro e delle verande in generale. Funzionamento esclusivamente motorizzato."
        ) +
        "</p>",
      images: [
        ImageScreeny130GpzTensTo1,
        ImageScreeny130GpzTensTo2,
        ImageScreeny130GpzTensTo3,
        ImageScreeny130GpzTensTo4,
        ImageScreeny130GpzTensTo5,
      ],
      buttons: [
        {
          text: getLanguageTranslation("Scheda Tecnica"),
          href: TechnicalSheetScreeny130GpzTensTo,
        },
      ],
    },
    undefined,
    "<p><strong>130 GPZ TO</strong><br />" +
      getLanguageTranslation(
        "Tenda con cassonetto da 130mm disponibile nella sola variante Quadro, dotata di sistema di guida ZIP."
      ) +
      "</p>"
  ),
];

export const createSpaceR90InfoPointsDe = (
  createPositionPoint,
  createInfoPoint,
  createVrPoint
) => [
  createPositionPoint([-1693.56, -757.06, -4635.62], "intro", "Intro"),
  createPositionPoint([-4795.26, -1396.82, -35.06], "percorso-5", "Percorso 5"),
  createPositionPoint(
    [173.28, -764.8, -4931.42],
    "r-pitch-130-gpz-to",
    "130 GPZ TO"
  ),
  createVrPoint([3797.25, -954.8, 3092.81], SpaceGLB, SpaceUSDZ, undefined, "SPACE"),
  createVrPoint([2526.35, -194.8, -4303.9], R90GLB, R90USDZ, undefined, "R 90"),
  createInfoPoint(
    [4565.69, 555.92, 1899.19],
    {
      type: "info",
      title: "",
      noCloseIcon: true,
      popupClass: "popup-info_container",
      sectionTitle: "SPACE",
      description:
        "<p>" +
        getLanguageTranslation(
          "Struttura di copertura per verande e attici. Il tessuto scorre nella parte superiore su guide, regolabili in larghezza e contenenti il sistema di tensionamento con molle a gas. Sono disponibili degli optional come il sistema di blocco meccanico e il sistema flottante per il tubo avvolgitore. Dimensioni massime 500x700 cm e con guida centrale anche 700x700 cm."
        ) +
        "</p>",
      images: [
        ImageSpace1,
        ImageSpace2,
        ImageSpace3,
        ImageSpace4,
        ImageSpace5,
        ImageSpace6,
        ImageSpace7,
      ],
      buttons: [
        {
          text: getLanguageTranslation("Scheda Tecnica"),
          href: TechnicalSheetSpace,
        },
      ],
    },
    undefined,
    "<p><strong>SPACE</strong><br />" +
      getLanguageTranslation(
        "Struttura di copertura per verande e attici. Il tessuto scorre nella parte superiore su guide, regolabili in larghezza e contenenti il sistema di tensionamento con molle a gas."
      ) +
      "</p>"
  ),
  createInfoPoint(
    [3799.08, 555.8, -3187.55],
    {
      type: "info",
      title: "",
      noCloseIcon: true,
      popupClass: "popup-info_container",
      sectionTitle: "R 90",
      description:
        "<p>" +
        getLanguageTranslation(
          "R-90 è una tenda a bracci estensibili su barra quadra 40mm in acciaio zincato e verniciato con bracci a doppio cavetto inguainato a catena inox o a cinghia. Il sistema risulta estremamente versatile e dall’ottimo rapporto qualità prezzo. Disponibili molti optional come il tettuccio di copertura in alluminio estruso e di una staffa speciale per il montaggio sottotetto."
        ) +
        "</p>",
      images: [ImageR901, ImageR902, ImageR903, ImageR904, ImageR905],
      buttons: [
        {
          text: getLanguageTranslation("Scheda Tecnica"),
          href: TechnicalSheetR90,
        },
      ],
    },
    undefined,
    "<p><strong>R 90</strong><br />" +
      getLanguageTranslation(
        "R-90 è una tenda a bracci estensibili su barra quadra 40mm in acciaio zincato e verniciato con bracci a doppio cavetto inguainato a catena inox o a cinghia."
      ) +
      "</p>"
  ),
];

export const createEpicaLiteEpicaInfoPointsDe = (
  createPositionPoint,
  createInfoPoint,
  createVrPoint,
  createVideoPoint
) => [
  createPositionPoint([-4696.36, -912.49, -1427.46], "percorso-1", "Percorso 1"),
  createPositionPoint(
    [854.28, -1602.43, -4651.27],
    "epica-giant",
    "Epica - Giant"
  ),
  createVideoPoint(
    [3858.96, -421.40, 3138.85],
    "Video",
    "https://www.youtube.com/watch?v=rYrZhH8tMn4"
  ),
  createVrPoint([4352.28, -1106.45, 2179.23], EpicaLiteGLB, EpicaLiteUSDZ, undefined, "EPICA LITE"),
  createVrPoint([4478.62, -1252.22, -1814.06], EpicaGLB, EpicaUSDZ, undefined, "EPICA"),
  createInfoPoint(
    [4531.43, 93.62, 2096.82],
    {
      type: "info",
      title: "",
      noCloseIcon: true,
      popupClass: "popup-info_container",
      sectionTitle: "EPICA LITE",
      description:
        "<p>" +
        getLanguageTranslation(
          "EPICA LITE è una tenda a bracci estensibili con cassonetto su supporti laterali fissi con contropiastra di ancoraggio, bracci Balteus o spin. Il cassonetto, ispezionabile, viene fornito in tre profili separati. I supporti consentono di installare la tenda sia a parete che a soffitto e la regolazione può essere eseguita in un’unica soluzione grazie al sistema con vite passante."
        ) +
        "</p>",
      images: [
        ImageEpicaLite1,
        ImageEpicaLite2,
        ImageEpicaLite3,
        ImageEpicaLite4,
        ImageEpicaLite5,
        ImageEpicaLite6,
        ImageEpicaLite7,
        ImageEpicaLite8,
        ImageEpicaLite9,
      ],
      buttons: [
        {
          text: getLanguageTranslation("Scheda Tecnica"),
          href: TechnicalSheetEpicaLite,
        },
      ],
    },
    undefined,
    "<p><strong>EPICA LITE</strong><br />" +
      getLanguageTranslation(
        "EPICA LITE è una tenda a bracci estensibili con cassonetto su supporti laterali fissi con contropiastra di ancoraggio, bracci Balteus o spin."
      ) +
      "</p>"
  ),
  createInfoPoint(
    [4532.58, -162.61, -2088.62],
    {
      type: "info",
      title: "",
      noCloseIcon: true,
      popupClass: "popup-info_container",
      sectionTitle: "EPICA",
      description:
        "<p>" +
        getLanguageTranslation(
          "EPICA è una tenda a bracci estensibili con cassonetto, caratterizzata da linee semplici ed essenziali. Gli elementi di fissaggio e le viterie sono totalmente nascosti alla vista. Pur avendo ingombri contenuti, raggiunge una sporgenza di 410cm con braccio Giant. Il prodotto viene completato con un’ampia gamma di optional come il kit luci e il terminale a cassonetto."
        ) +
        "</p>",
      images: [ImageEpica1, ImageEpica2, ImageEpica3, ImageEpica4],
      buttons: [
        {
          text: getLanguageTranslation("Scheda Tecnica"),
          href: TechnicalSheetEpica,
        },
      ],
    },
    undefined,
    "<p><strong>EPICA</strong><br />" +
      getLanguageTranslation(
        "EPICA è una tenda a bracci estensibili con cassonetto, caratterizzata da linee semplici ed essenziali. Gli elementi di fissaggio e le viterie sono totalmente nascosti alla vista."
      ) +
      "</p>"
  ),
];

export const createEpicaGiantInfoPointsDe = (
  createPositionPoint,
  createInfoPoint,
  createVrPoint
) => [
  createPositionPoint([-4832.64, -975.54, 767.88], "percorso-1", "Percorso 1"),
  createPositionPoint(
    [-3780.24, -967.44, -3109.82],
    "percorso-2",
    "Percorso 2"
  ),
  createPositionPoint(
    [-246.30, -1386.32, 4787.15],
    "epica-epica-lite",
    "Epica Lite - Epica"
  ),
  createPositionPoint(
    [-186.4, -1026.6, -4880.05],
    "zenith-2040",
    "Zenith - 2040"
  ),
  createVrPoint([3755.01, -1193.62, 3066.11], EpicaGLB, EpicaUSDZ, undefined, "EPICA"),
  createVrPoint([3634.1, -1118.88, -3242.49], GiantGLB, GiantUSDZ, undefined, "GIANT"),
  createInfoPoint(
    [4764.51, -352.09, 1457.29],
    {
      type: "info",
      title: "",
      noCloseIcon: true,
      popupClass: "popup-info_container",
      sectionTitle: "EPICA",
      description:
        "<p>" +
        getLanguageTranslation(
          "EPICA è una tenda a bracci estensibili con cassonetto, caratterizzata da linee semplici ed essenziali. Gli elementi di fissaggio e le viterie sono totalmente nascosti alla vista. Pur avendo ingombri contenuti, raggiunge una sporgenza di 410cm con braccio Giant. Il prodotto viene completato con un’ampia gamma di optional come il kit luci e il terminale a cassonetto."
        ) +
        "</p>",
      images: [ImageEpica1, ImageEpica2, ImageEpica3, ImageEpica4],
      buttons: [
        {
          text: getLanguageTranslation("Scheda Tecnica"),
          href: TechnicalSheetEpica,
        },
      ],
    },
    undefined,
    "<p><strong>EPICA</strong><br />" +
      getLanguageTranslation(
        "EPICA è una tenda a bracci estensibili con cassonetto, caratterizzata da linee semplici ed essenziali. Gli elementi di fissaggio e le viterie sono totalmente nascosti alla vista."
      ) +
      "</p>"
  ),
  createInfoPoint(
    [4799.55, -456.68, -1291.0],
    {
      type: "info",
      title: "",
      noCloseIcon: true,
      popupClass: "popup-info_container",
      sectionTitle: "GIANT",
      description:
        "<p>" +
        getLanguageTranslation(
          "GIANT BQ é una tenda a bracci su barra quadra in acciaio 50mm. I supporti in alluminio estruso garantiscono resistenza e compattezza e i tappi in alluminio verniciati ne caratterizzano eleganza e discrezione. Compattezza e design la rendono perfetta per coperture di terrazze pubbliche e per protezione di spazi e patii privati. Dispone di un braccio tipo spin, creato “ad hoc” con snodi e spalla forgiati."
        ) +
        "</p>",
      images: [ImageGiant1, ImageGiant2, ImageGiant3, ImageGiant4, ImageGiant5],
      buttons: [
        {
          text: getLanguageTranslation("Scheda Tecnica"),
          href: TechnicalSheetGiant,
        },
      ],
    },
    undefined,
    "<p><strong>GIANT</strong><br />" +
      getLanguageTranslation(
        "GIANT BQ é una tenda a bracci su barra quadra in acciaio 50mm. I supporti in alluminio estruso garantiscono resistenza e compattezza e i tappi in alluminio verniciati ne caratterizzano eleganza e discrezione."
      ) +
      "</p>"
  ),
];

export const createZenith2040InfoPointsDe = (
  createPositionPoint,
  createInfoPoint,
  createVrPoint
) => [
  createPositionPoint(
    [-4685.68, -1126.5, -1303.07],
    "percorso-3",
    "Percorso 3"
  ),
  createPositionPoint([-3730.83, -931.92, 3182.05], "percorso-2", "Percorso 2"),
  createPositionPoint(
    [122.81, -848.23, 4918.87],
    "epica-giant",
    "Epica - Giant"
  ),
  createVrPoint([4432.94, -1240.63, 1942.14], ZenithGLB, ZenithUSDZ, undefined, "ZENITH"),
  createVrPoint([4055.72, -1251.12, -2625.86], GLB2040, USDZ2040, undefined, "2040"),
  createInfoPoint(
    [4463.26, 1695.4, 1643.31],
    {
      type: "info",
      title: "",
      noCloseIcon: true,
      popupClass: "popup-info_container",
      sectionTitle: "ZENITH",
      description:
        "<p>" +
        getLanguageTranslation(
          "ZENITH è una tenda a bracci con cassonetto fisso su barra rettangolare 50x30mm a scomparsa totale. Funzionale ed affidabile è dotata di sistema ribaltabile BAT che garantisce la perfetta chiusura del terminale anche in caso di massima inclinazione."
        ) +
        "</p>",
      images: [
        ImageZenith1,
        ImageZenith2,
        ImageZenith3,
        ImageZenith4,
        ImageZenith5,
        ImageZenith6,
      ],
      buttons: [
        {
          text: getLanguageTranslation("Scheda Tecnica"),
          href: TechnicalSheetZenith,
        },
      ],
    },
    undefined,
    "<p><strong>ZENITH</strong><br />" +
      getLanguageTranslation(
        "ZENITH è una tenda a bracci con cassonetto fisso su barra rettangolare 50x30mm a scomparsa totale."
      ) +
      "</p>"
  ),
  createInfoPoint(
    [4415.05, 1695.77, -1594.57],
    {
      type: "info",
      title: "",
      noCloseIcon: true,
      popupClass: "popup-info_container",
      sectionTitle: "2040",
      description:
        "<p>" +
        getLanguageTranslation(
          "2040 è una tenda a bracci estensibili su barra quadra 40mm in acciaio zincato e verniciato con cassonetto a scomparsa parziale e con bracci a doppio cavetto inguainato o a cinghia. Questo modello viene fornito di un supporto braccio basculante brevettato che consente di regolare la tenda senza dover inclinare il cassonetto."
        ) +
        "</p>",
      images: [Image20401, Image20402, Image20403, Image20404, Image20405],
      buttons: [
        {
          text: getLanguageTranslation("Scheda Tecnica"),
          href: TechnicalSheet2040,
        },
      ],
    },
    undefined,
    "<p><strong>2040</strong><br />" +
      getLanguageTranslation(
        "2040 è una tenda a bracci estensibili su barra quadra 40mm in acciaio zincato e verniciato con cassonetto a scomparsa parziale e con bracci a doppio cavetto inguainato o a cinghia."
      ) +
      "</p>"
  ),
];

export const createHavantInfoPointsDe = (
  createPositionPoint,
  createInfoPoint,
  createVrPoint
) => [
  createPositionPoint([-3391.63, -945.33, 3536.97], "percorso-4", "Percorso 4"),
  createPositionPoint([-1354.15, -1601.36, -4531.98], "ombralsun", "Ombralsun"),
  createVrPoint([4344.51, -1617.69, 1858.62], HavantGLB, HavantUSDZ, undefined, "HAVANT"),
  createInfoPoint(
    [4782.45, 1155.38, -856.41],
    {
      type: "info",
      title: "",
      noCloseIcon: true,
      popupClass: "popup-info_container",
      sectionTitle: "HAVANT",
      description:
        "<p>HAVANT är en fallarmsmarkis, idealisk för fönster och fasader. Flera styrnings möjligheter kan väljas: manuellt med band, växel eller motor. Gavlar och ändskydd i gjuten aluminium med passande design och med exakt matchande färg. Alternativt HAVANT Light med gavlar och ändskydd i plast.</p>",
      images: [
        ImageHavant1,
        ImageHavant2,
        ImageHavant3,
        ImageHavant4,
      ],
      buttons: [
        {
          text: getLanguageTranslation("Scheda Tecnica"),
          href: null,
        },
      ],
    },
    undefined,
    "<p><strong>HAVANT</strong><br />HAVANT är en fallarmsmarkis, idealisk för fönster och fasader. Flera styrnings möjligheter kan väljas: manuellt med band, växel eller motor.</p>"
  ),
];

export const createOmbralsunInfoPointsDe = (
  createPositionPoint,
  createInfoPoint,
  createVrPoint
) => [
  createPositionPoint([2234.94, -575.27, -4423.64], "percorso-4", "Percorso 4"),
  createPositionPoint(
    [3236.32, -1111.06, 3641.09],
    "screeny-130-gpz-unica-m-screeny-110-gc",
    "Screeny 130 GPZ UNICA M - Screeny 110 GC"
  ),
  createVrPoint([4319.79, -1654.71, -1884.95], OmbralsunGLB, OmbralsunUSDZ, undefined, "OMBRALSUN"),
  createInfoPoint(
    [4470.2, -1818.59, 1275.49],
    {
      type: "info",
      title: "",
      noCloseIcon: true,
      popupClass: "popup-info_container",
      sectionTitle: "OMBRALSUN",
      description:
        "<p>" +
        getLanguageTranslation(
          "OMBRALSUN, per la sue dimensioni, compattezza e facilità di installazione e regolazione, si posiziona sicuramente in vetta alla già ampia gamma dei prodotti per terrazzi e patio completandone la gamma. Grazie ad un solo tubo avvolgitore “Rollerbat” Ø 80mm, riesce a coprire una superficie di ca 36m2, mantenendo comunque contenuti gli ingombri; supporti e staffe di ancoraggio sono studiati perché tutte le installazioni siano semplici e veloci. Il sistema è completato da un cassonetto integrale che racchiude, una volta ritratti, entrambi i lati della tenda."
        ) +
        "</p>",
      images: [
        ImageOmbralsun1,
        ImageOmbralsun2,
        ImageOmbralsun3,
        ImageOmbralsun4,
        ImageOmbralsun5,
      ],
      buttons: [
        {
          text: getLanguageTranslation("Scheda Tecnica"),
          href: TechnicalSheetOmbralsun,
        },
      ],
    },
    undefined,
    "<p><strong>OMBRALSUN</strong><br />" +
      getLanguageTranslation(
        "OMBRALSUN, per la sue dimensioni, compattezza e facilità di installazione e regolazione, si posiziona sicuramente in vetta alla già ampia gamma dei prodotti per terrazzi e patio completandone la gamma."
      ) +
      "</p>"
  ),
];

export const createScreeny130GpzUnicaMScreeny110GcInfoPointsDe = (
  createPositionPoint,
  createInfoPoint,
  createVrPoint
) => [
  createPositionPoint([-2989.4, -1080.01, 3848.98], "ombralsun", "Ombralsun"),
  createPositionPoint(
    [814.09, -1702.53, -4620.29],
    "screeny-110-gc-screeny-130-gpz-i",
    "Screeny 110 GC - Screeny 130 GPZ I"
  ),
  createVrPoint(
    [4198.65, -1445.81, 2281.33],
    Screeny130GpzUnicaMGLB,
    Screeny130GpzUnicaMUSDZ
  ),
  createVrPoint(
    [4319.79, -1654.71, -1884.95],
    Screeny110GcGLB,
    Screeny110GcUSDZ
  ),
  createInfoPoint(
    [4719.68, 904.09, 1346.89],
    {
      type: "info",
      title: "",
      noCloseIcon: true,
      popupClass: "popup-info_container",
      sectionTitle: "SCREENY 130 GPZ UNICA M",
      description:
        "<p>" +
        getLanguageTranslation(
          "130mm Screen kassett endast i rund version, utrustad med två stycken ZIP styrskenor och kassett monterad på styrskenorna. Passande både för vägg och nischmontage, snabb och enkel att montera och serva. Styrskenan består av två profiler: skena och täcklock; utvecklad endast för motorisering vilket minskar bredden med 36 mm."
        ) +
        "</p>",
      images: [
        ImageScreeny130GpzUnicaM1,
        ImageScreeny130GpzUnicaM2,
        ImageScreeny130GpzUnicaM3,
        ImageScreeny130GpzUnicaM4,
        ImageScreeny130GpzUnicaM5,
        ImageScreeny130GpzUnicaM6,
        ImageScreeny130GpzUnicaM7,
      ],
      buttons: [
        {
          text: getLanguageTranslation("Scheda Tecnica"),
          href: TechnicalSheetScreeny130GpzUnicaM,
        },
      ],
    },
    undefined,
    "<p><strong>SCREENY 130 GPZ UNICA M</strong><br />" +
      getLanguageTranslation(
        "130mm Screen kassett endast i rund version, utrustad med två stycken ZIP styrskenor och kassett monterad på styrskenorna. Passande både för vägg och nischmontage, snabb och enkel att montera och serva."
      ) +
      "</p>"
  ),
  createInfoPoint(
    [4751.59, 904.02, -1154.93],
    {
      type: "info",
      title: "",
      noCloseIcon: true,
      popupClass: "popup-info_container",
      sectionTitle: "SCREENY 110 GC",
      description:
        "<p>" +
        getLanguageTranslation(
          "Tenda con cassonetto da 110mm disponibile nelle varianti Quadro e Tondo, dotata di guida cavetto inox Ø4mm."
        ) +
        "</p>",
      images: [
        ImageScreeny110Gc1,
        ImageScreeny110Gc2,
        ImageScreeny110Gc3,
        ImageScreeny110Gc4,
        ImageScreeny110Gc5,
        ImageScreeny110Gc6,
        ImageScreeny110Gc7,
      ],
      buttons: [
        {
          text: getLanguageTranslation("Scheda Tecnica"),
          href: TechnicalSheetScreeny110Gc,
        },
      ],
    },
    undefined,
    "<p><strong>SCREENY 110 GC</strong><br />" +
      getLanguageTranslation(
        "Tenda con cassonetto da 110mm disponibile nelle varianti Quadro e Tondo, dotata di guida cavetto inox Ø4mm."
      ) +
      "</p>"
  ),
];

export const createScreeny110GcScreeny130GpzIInfoPointsDe = (
  createPositionPoint,
  createInfoPoint,
  createVrPoint
) => [
  createPositionPoint(
    [383.76, -1693.9, 4680.27],
    "screeny-130-gpz-unica-m-screeny-110-gc",
    "Screeny 130 GPZ UNICA M - Screeny 110 GC"
  ),
  createPositionPoint(
    [-1004.93, -2049.18, -4441.82],
    "screeny-130-ga-screeny-130-gpz-mb",
    "Screeny 130 GA - Screeny 130 GPZ MB"
  ),
  createVrPoint(
    [4139.43, -1387.02, 2426.68],
    Screeny110GcGLB,
    Screeny110GcUSDZ
  ),
  createVrPoint(
    [4059.19, -1461.95, -2511.49],
    Screeny130GpzIGLB,
    Screeny130GpzIUSDZ
  ),
  createInfoPoint(
    [4684.76, 1008.53, 1397.02],
    {
      type: "info",
      title: "",
      noCloseIcon: true,
      popupClass: "popup-info_container",
      sectionTitle: "SCREENY 110 GC",
      description:
        "<p>" +
        getLanguageTranslation(
          "Tenda con cassonetto da 110mm disponibile nelle varianti Quadro e Tondo, dotata di guida cavetto inox Ø4mm."
        ) +
        "</p>",
      images: [
        ImageScreeny110Gc1,
        ImageScreeny110Gc2,
        ImageScreeny110Gc3,
        ImageScreeny110Gc4,
        ImageScreeny110Gc5,
        ImageScreeny110Gc6,
        ImageScreeny110Gc7,
      ],
      buttons: [
        {
          text: getLanguageTranslation("Scheda Tecnica"),
          href: TechnicalSheetScreeny110Gc,
        },
      ],
    },
    undefined,
    "<p><strong>SCREENY 110 GC</strong><br />" +
      getLanguageTranslation(
        "Tenda con cassonetto da 110mm disponibile nelle varianti Quadro e Tondo, dotata di guida cavetto inox Ø4mm."
      ) +
      "</p>"
  ),
  createInfoPoint(
    [4767.28, 1008.91, -1130.24],
    {
      type: "info",
      title: "",
      noCloseIcon: true,
      popupClass: "popup-info_container",
      sectionTitle: "SCREENY 130 GPZ I",
      description:
        "<p>" +
        getLanguageTranslation(
          "Tenda con cassonetto da 130mm disponibile nelle varianti Quadro e Tondo dotata di sistema di guida ZIP, composta da due profili con compensazione fino ad un max di 17 mm su ogni guida. Adatta per essere installata ad incasso e per applicazioni che presentano fuori squadra."
        ) +
        "</p>",
      images: [
        ImageScreeny130GpzI1,
        ImageScreeny130GpzI2,
        ImageScreeny130GpzI3,
        ImageScreeny130GpzI4,
        ImageScreeny130GpzI5,
        ImageScreeny130GpzI6,
      ],
      buttons: [
        {
          text: getLanguageTranslation("Scheda Tecnica"),
          href: TechnicalSheetScreeny130GpzI,
        },
      ],
    },
    undefined,
    "<p><strong>SCREENY 130 GPZ I</strong><br />" +
      getLanguageTranslation(
        "Tenda con cassonetto da 130mm disponibile nelle varianti Quadro e Tondo dotata di sistema di guida ZIP, composta da due profili con compensazione fino ad un max di 17 mm su ogni guida. Adatta per essere installata ad incasso e per applicazioni che presentano fuori squadra."
      ) +
      "</p>"
  ),
];

export const createScreeny130GaScreeny130GpzMbInfoPointsDe = (
  createPositionPoint,
  createInfoPoint,
  createVrPoint
) => [
  createPositionPoint(
    [-2594.4, -2112.93, 3706.11],
    "screeny-110-gc-screeny-130-gpz-i",
    "Screeny 110 GC - Screeny 130 GPZ I"
  ),
  createPositionPoint(
    [211.31, -999.64, -4884.11],
    "screeny-110-gpz-unica-am-screeny-110-gc-cabrio",
    "Screeny 110 GPZ UNICA AM - Screeny 110 GC Cabrio"
  ),
  createVrPoint(
    [3825.12, -1232.58, 2962.08],
    Screeny130GaGLB,
    Screeny130GaUSDZ
  ),
  createVrPoint(
    [4020.66, -1338.77, -2634.52],
    Screeny130GpzMbGLB,
    Screeny130GpzMbUSDZ
  ),
  createInfoPoint(
    [4656.95, 1058.08, 1443.62],
    {
      type: "info",
      title: "",
      noCloseIcon: true,
      popupClass: "popup-info_container",
      sectionTitle: "SCREENY 130 GA",
      description:
        "<p>" +
        getLanguageTranslation(
          "Tenda con cassonetto da 130mm disponibile nelle varianti Quadro e Tondo, dotata di guida asta inox Ø10mm."
        ) +
        "</p>",
      images: [
        ImageScreeny130Ga1,
        ImageScreeny130Ga2,
        ImageScreeny130Ga3,
        ImageScreeny130Ga4,
        ImageScreeny130Ga5,
        ImageScreeny130Ga6,
      ],
      buttons: [
        {
          text: getLanguageTranslation("Scheda Tecnica"),
          href: TechnicalSheetScreeny130Ga,
        },
      ],
    },
    undefined,
    "<p><strong>SCREENY 130 GA</strong><br />" +
      getLanguageTranslation(
        "Tenda con cassonetto da 130mm disponibile nelle varianti Quadro e Tondo, dotata di guida asta inox Ø10mm."
      ) +
      "</p>"
  ),
  createInfoPoint(
    [4747.98, 1058.07, -1123.07],
    {
      type: "info",
      title: "",
      noCloseIcon: true,
      popupClass: "popup-info_container",
      sectionTitle: "SCREENY 130 GPZ MB",
      description:
        "<p>" +
        getLanguageTranslation(
          "Tenda con cassonetto da 130mm disponibile nella sola variante Quadro, dotata di guida profilo da incasso con sistema ZIP e cassonetto autoportante. Adatta per l’integrazione nei sistemi per serramento, migliorando il risparmio energetico."
        ) +
        "</p>",
      images: [
        ImageScreeny130GpzMb1,
        ImageScreeny130GpzMb2,
        ImageScreeny130GpzMb3,
        ImageScreeny130GpzMb4,
      ],
      buttons: [
        {
          text: getLanguageTranslation("Scheda Tecnica"),
          href: TechnicalSheetScreeny130GpzMb,
        },
      ],
    },
    undefined,
    "<p><strong>SCREENY 130 GPZ MB</strong><br />" +
      getLanguageTranslation(
        "Tenda con cassonetto da 130mm disponibile nella sola variante Quadro, dotata di guida profilo da incasso con sistema ZIP e cassonetto autoportante. Adatta per l’integrazione nei sistemi per serramento, migliorando il risparmio energetico."
      ) +
      "</p>"
  ),
];

export const createScreeny110GpzUnicaAmScreeny110GcCabrioInfoPointsDe = (
  createPositionPoint,
  createInfoPoint,
  createVrPoint
) => [
  createPositionPoint([-2964.89, -870.79, -3922.3], "percorso-4", "Percorso 4"),
  createPositionPoint(
    [1877.06, -1137.22, -4486.46],
    "percorso-5",
    "Percorso 5"
  ),
  createPositionPoint(
    [457.31, -970.45, 4877.02],
    "screeny-130-ga-screeny-130-gpz-mb",
    "Screeny 130 GA - Screeny 130 GPZ MB"
  ),
  createVrPoint(
    [3844.56, -1266.11, 2923.08],
    Screeny110GpzUnicaAmGLB,
    Screeny110GpzUnicaAmUSDZ
  ),
  createVrPoint(
    [3734.87, -1384.35, -3005.62],
    Screeny110GcCabrioGLB,
    Screeny110GcCabrioUSDZ
  ),
  createInfoPoint(
    [4671.49, 988.47, 1461.97],
    {
      type: "info",
      title: "",
      noCloseIcon: true,
      popupClass: "popup-info_container",
      sectionTitle: "SCREENY 110 GPZ UNICA AM",
      description:
        "<p>" +
        getLanguageTranslation(
          "Tenda con cassonetto da 110mm disponibile nelle varianti Quadro e Tondo dotata di sistema di guida ZIP e cassonetto autoportante. Adatta per installazioni su infissi, sia a nicchia che a parete; veloce ed agevole da installare ed ispezionare. La guida è composta da due profili: supporto e copertina; è sviluppata per sistemi sia ad argano che motorizzati."
        ) +
        "</p>",
      images: [
        ImageScreeny110GpzUnicaAm1,
        ImageScreeny110GpzUnicaAm2,
        ImageScreeny110GpzUnicaAm3,
        ImageScreeny110GpzUnicaAm4,
        ImageScreeny110GpzUnicaAm5,
        ImageScreeny110GpzUnicaAm6,
        ImageScreeny110GpzUnicaAm7,
      ],
      buttons: [
        {
          text: getLanguageTranslation("Scheda Tecnica"),
          href: TechnicalSheetScreeny110GpzUnicaAm,
        },
      ],
    },
    undefined,
    "<p><strong>SCREENY 110 GPZ UNICA AM</strong><br />" +
      getLanguageTranslation(
        "Tenda con cassonetto da 110mm disponibile nelle varianti Quadro e Tondo dotata di sistema di guida ZIP e cassonetto autoportante. Adatta per installazioni su infissi, sia a nicchia che a parete; veloce ed agevole da installare ed ispezionare."
      ) +
      "</p>"
  ),
  createInfoPoint(
    [4709.69, 988.76, -1209.42],
    {
      type: "info",
      title: "",
      noCloseIcon: true,
      popupClass: "popup-info_container",
      sectionTitle: "SCREENY 110 GC CABRIO",
      description:
        "<p>" +
        getLanguageTranslation(
          "Tenda senza cassonetto, dotata di guida cavetto inox Ø4mm. Realizzabile sia nella versione singola che doppia."
        ) +
        "</p>",
      images: [
        ImageScreeny110GcCabrio1,
        ImageScreeny110GcCabrio2,
        ImageScreeny110GcCabrio3,
        ImageScreeny110GcCabrio4,
        ImageScreeny110GcCabrio5,
        ImageScreeny110GcCabrio6,
        ImageScreeny110GcCabrio7,
        ImageScreeny110GcCabrio8,
      ],
      buttons: [
        {
          text: getLanguageTranslation("Scheda Tecnica"),
          href: TechnicalSheetScreeny110GcCabrio,
        },
      ],
    },
    undefined,
    "<p><strong>SCREENY 110 GC CABRIO</strong><br />" +
      getLanguageTranslation(
        "Tenda senza cassonetto, dotata di guida cavetto inox Ø4mm. Realizzabile sia nella versione singola che doppia."
      ) +
      "</p>"
  ),
];
