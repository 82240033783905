// @ts-ignore
import React from "react";
// @ts-ignore
import Logo from "../images/bat_logo.png";

const Header: React.FC = () => {
  return (
    <div
      className="buttons-container"
      style={{ top: "0", left: "50%", right: "50%", flexFlow: "row" }}
    >
      <a
        className="bat-logo__container"
        href="https://www.batgroup.com/"
        target="_blank"
      >
        <img className="bat-logo" src={Logo} alt="bat-logo" />
      </a>
    </div>
  );
};

export default Header;
