//  @ts-ignore
import React from "react";
import Popups from "./popups";
import BottomButtons from "./bottom-buttons";
import Header from "./header";
import { TechnicalSpecificsType } from "./types";

const PanoramaImage: React.FC<{
  reference: any;
  toggleMode: any;
  popupInfos: any;
  setPopupInfos: any;
  setTechnicalSpecifics: (v: TechnicalSpecificsType) => any;
  technicalSpecifics: TechnicalSpecificsType;
  toggleFullscreen: () => void;
}> = ({
  reference,
  toggleMode,
  popupInfos,
  setPopupInfos,
  setTechnicalSpecifics,
  technicalSpecifics,
  toggleFullscreen,
}) => {
  return (
    <div className="panorama-container">
      <div className="content-container">
        <div className="panorama" id="container" ref={reference} />
        <div className="panel" id="panel">
          <div className="content-container" />
        </div>
        <Header />
        {popupInfos !== undefined ? (
          <Popups
            popupInfos={popupInfos}
            setPopupInfos={setPopupInfos}
            setCurrentPosition={setTechnicalSpecifics}
            setTechnicalSpecifics={setTechnicalSpecifics}
            technicalSpecifics={technicalSpecifics}
          />
        ) : null}
        <BottomButtons
          setTechnicalSpecifics={setTechnicalSpecifics}
          technicalSpecifics={technicalSpecifics}
          toggleMode={toggleMode}
          toggleFullscreen={toggleFullscreen}
        />
      </div>
    </div>
  );
};

export default PanoramaImage;
