import { Vr } from "../icons";
import { getLanguageTranslation } from "./translations/get-language-translation";

export const useCreateVrPoint: (
  setPopupInfos: any
) => (
  position: number[],
  GLBFile: any,
  USDZFile: any,
  title?: string,
  productName?: string
) => Record<string, any> = (setPopupInfos) => (
  position,
  GLBFile,
  USDZFile,
  title = getLanguageTranslation("VR"),
  productName = "",
) => ({
  position,
  text: title,
  image: Vr,
  type: "vr",
  description: null,
  clickListener: () => {
    setPopupInfos({
      title: "",
      noCloseIcon: true,
      type: "vr",
      popupClass: "popup-vr_container",
      GLBFile,
      USDZFile,
    });
    // @ts-ignore
    window.dataLayer = window.dataLayer || [];
    // @ts-ignore
    window.dataLayer.push({
      'event': 'click-vr',
      'clickProduct': productName
    });
  },
});
