import {
  ArmonyPlusGLB,
  ArmonyPlusUSDZ,
  BaseLiteGLB,
  BaseLiteUSDZ,
  BasePlusGLB,
  BasePlusUSDZ,
  DomeaGLB,
  DomeaUSDZ,
  ElyGLB,
  ElyUSDZ,
  EpicaGLB,
  EpicaLiteGLB,
  EpicaLiteUSDZ,
  EpicaUSDZ,
  GiantGLB,
  GiantUSDZ,
  GLB2010,
  GLB2020,
  GLB2040,
  HelixBqGLB,
  HelixBqUSDZ,
  HelixPsGLB,
  HelixPsUSDZ,
  Image20101,
  Image20102,
  Image20103,
  Image20104,
  Image20105,
  Image20201,
  Image20202,
  Image20203,
  Image20204,
  Image20205,
  Image20401,
  Image20402,
  Image20403,
  Image20404,
  Image20405,
  ImageArmonyPlus1,
  ImageArmonyPlus2,
  ImageArmonyPlus3,
  ImageArmonyPlus4,
  ImageArmonyPlus5,
  ImageBaseLite1,
  ImageBaseLite2,
  ImageBaseLite3,
  ImageBaseLite4,
  ImageBaseLite5,
  ImageBaseLite6,
  ImageBaseLite7,
  ImageBasePlus1,
  ImageBasePlus2,
  ImageBasePlus3,
  ImageBasePlus4,
  ImageBasePlus5,
  ImageBasePlus6,
  ImageDomea1,
  ImageDomea2,
  ImageDomea3,
  ImageDomea4,
  ImageDomea5,
  ImageDomea6,
  ImageEly1,
  ImageEly2,
  ImageEly3,
  ImageEly4,
  ImageEly5,
  ImageEly6,
  ImageEly7,
  ImageEly8,
  ImageEpica1,
  ImageEpica2,
  ImageEpica3,
  ImageEpica4,
  ImageEpicaLite1,
  ImageEpicaLite2,
  ImageEpicaLite3,
  ImageEpicaLite4,
  ImageEpicaLite5,
  ImageEpicaLite6,
  ImageEpicaLite7,
  ImageEpicaLite8,
  ImageEpicaLite9,
  ImageGiant1,
  ImageGiant2,
  ImageGiant3,
  ImageGiant4,
  ImageGiant5,
  ImageHelixBq1,
  ImageHelixBq2,
  ImageHelixBq3,
  ImageHelixBq4,
  ImageHelixBq5,
  ImageHelixBq6,
  ImageHelixPs1,
  ImageHelixPs2,
  ImageHelixPs3,
  ImageHelixPs4,
  ImageHelixPs5,
  ImageLevaIncastroBq1,
  ImageLevaIncastroBq2,
  ImageLevaIncastroBq3,
  ImageLevaIncastroBq4,
  ImageMiniHelixPs1,
  ImageMiniHelixPs2,
  ImageMiniHelixPs3,
  ImageMiniHelixPs4,
  ImageOmbralsun1,
  ImageOmbralsun2,
  ImageOmbralsun3,
  ImageOmbralsun4,
  ImageOmbralsun5,
  ImageScreeny110Gc1,
  ImageScreeny110Gc2,
  ImageScreeny110Gc3,
  ImageScreeny110Gc4,
  ImageScreeny110Gc5,
  ImageScreeny110Gc6,
  ImageScreeny110Gc7,
  ImageScreeny110GcCabrio1,
  ImageScreeny110GcCabrio2,
  ImageScreeny110GcCabrio3,
  ImageScreeny110GcCabrio4,
  ImageScreeny110GcCabrio5,
  ImageScreeny110GcCabrio6,
  ImageScreeny110GcCabrio7,
  ImageScreeny110GcCabrio8,
  ImageScreeny110GpzUnicaAm1,
  ImageScreeny110GpzUnicaAm2,
  ImageScreeny110GpzUnicaAm3,
  ImageScreeny110GpzUnicaAm4,
  ImageScreeny110GpzUnicaAm5,
  ImageScreeny110GpzUnicaAm6,
  ImageScreeny110GpzUnicaAm7,
  ImageScreeny130Ga1,
  ImageScreeny130Ga2,
  ImageScreeny130Ga3,
  ImageScreeny130Ga4,
  ImageScreeny130Ga5,
  ImageScreeny130Ga6,
  ImageScreeny130GpzI1,
  ImageScreeny130GpzI2,
  ImageScreeny130GpzI3,
  ImageScreeny130GpzI4,
  ImageScreeny130GpzI5,
  ImageScreeny130GpzI6,
  ImageScreeny130GpzMb1,
  ImageScreeny130GpzMb2,
  ImageScreeny130GpzMb3,
  ImageScreeny130GpzMb4,
  ImageScreeny130GpzTensTo1,
  ImageScreeny130GpzTensTo2,
  ImageScreeny130GpzTensTo3,
  ImageScreeny130GpzTensTo4,
  ImageScreeny130GpzTensTo5,
  ImageScreeny130GpzUnicaM1,
  ImageScreeny130GpzUnicaM2,
  ImageScreeny130GpzUnicaM3,
  ImageScreeny130GpzUnicaM4,
  ImageScreeny130GpzUnicaM5,
  ImageScreeny130GpzUnicaM6,
  ImageScreeny130GpzUnicaM7,
  ImageScreeny150Storm1,
  ImageScreeny150Storm2,
  ImageScreeny150Storm3,
  ImageScreeny150Storm4,
  ImageScreeny150Storm5,
  ImageScreeny150Storm6,
  ImageSirio1,
  ImageSirio2,
  ImageSirio3,
  ImageSirio4,
  ImageSirio5,
  ImageSirio6,
  ImageVerso1,
  ImageVerso2,
  ImageVerso3,
  ImageVerso4,
  ImageVerso5,
  ImageZenith1,
  ImageZenith2,
  ImageZenith3,
  ImageZenith4,
  ImageZenith5,
  ImageZenith6,
  LevaIncastroBqGLB,
  LevaIncastroBqUSDZ,
  MiniHelixBqGLB,
  MiniHelixBqUSDZ,
  MiniHelixPsGLB,
  MiniHelixPsUSDZ,
  OmbralsunGLB,
  OmbralsunUSDZ,
  ParigiPlusGLB,
  ParigiPlusLiteGLB,
  ParigiPlusLiteUSDZ,
  ParigiPlusUSDZ,
  Screeny110GcCabrioGLB,
  Screeny110GcCabrioUSDZ,
  Screeny110GcGLB,
  Screeny110GcUSDZ,
  Screeny110GpzUnicaAmGLB,
  Screeny110GpzUnicaAmUSDZ,
  Screeny130GaGLB,
  Screeny130GaUSDZ,
  Screeny130GpzIGLB,
  Screeny130GpzIUSDZ,
  Screeny130GpzMbGLB,
  Screeny130GpzMbUSDZ,
  Screeny130GpzToGLB,
  Screeny130GpzToUSDZ,
  Screeny130GpzUnicaMGLB,
  Screeny130GpzUnicaMUSDZ,
  Screeny150StormGLB,
  Screeny150StormUSDZ,
  SirioGLB,
  SirioUSDZ,
  TechnicalSheet2010,
  TechnicalSheet2020,
  TechnicalSheet2040,
  TechnicalSheetArmonyPlus,
  TechnicalSheetBaseLite,
  TechnicalSheetBasePlus,
  TechnicalSheetDomea,
  TechnicalSheetEly,
  TechnicalSheetEpica,
  TechnicalSheetEpicaLite,
  TechnicalSheetGiant,
  TechnicalSheetHelixBq,
  TechnicalSheetHelixPs,
  TechnicalSheetMiniHelixBs,
  TechnicalSheetOmbralsun,
  TechnicalSheetScreeny110Gc,
  TechnicalSheetScreeny110GcCabrio,
  TechnicalSheetScreeny110GpzUnicaAm,
  TechnicalSheetScreeny130Ga,
  TechnicalSheetScreeny130GpzI,
  TechnicalSheetScreeny130GpzMb,
  TechnicalSheetScreeny130GpzTensTo,
  TechnicalSheetScreeny130GpzUnicaM,
  TechnicalSheetScreeny150Storm,
  TechnicalSheetSirio,
  TechnicalSheetVerso,
  TechnicalSheetZenith,
  USDZ2010,
  USDZ2020,
  USDZ2040,
  VersoCassonettoGLB,
  VersoCassonettoUSDZ,
} from ".";
import { getLanguageTranslation } from "../translations/get-language-translation";

export const createIntroInfoPointsFr = (createPositionPoint) => [
  createPositionPoint(
    [-3268.21, -1065.73, 3626.57],
    "130-gpz-to",
    "130 GPZ TO"
  ),
  createPositionPoint([-725.08, -1063.68, 4821.69], "percorso-1", "Percorso 1"),
  createPositionPoint([4271.93, -522.32, 2533.13], "percorso-2", "Percorso 2"),
  createPositionPoint([4293.64, -522.32, -2486.45], "percorso-3", "Percorso 3"),
  createPositionPoint([-723.28, -732.07, -4884.59], "percorso-4", "Percorso 4"),
];

export const create130GpzToInfoPointsFr = (
  createPositionPoint,
  createInfoPoint,
  createVrPoint
) => [
  createPositionPoint([-4952.82, -465.85, -440.11], "percorso-2", "Percorso 2"),
  createPositionPoint([4857.22, -850.44, -771.52], "percorso-5", "Percorso 5"),
  createPositionPoint([-2400.52, -943.41, 4275.87], "intro", "Intro"),
  createPositionPoint(
    [-704.04, -988.41, -4840.16],
    "base-plus-epica",
    "Base Plus - Epica"
  ),
  createPositionPoint(
    [3390.72, -746.93, -3589.61],
    "leva-incastro-bq-domea",
    "Domea"
  ),
  createPositionPoint(
    [1737.53, -1050.48, -4558.47],
    "domea-base-plus",
    "Domea - Base Plus"
  ),
  createVrPoint(
    [3987.46, -542.63, 2957.39],
    Screeny130GpzToGLB,
    Screeny130GpzToUSDZ
  ),
  createInfoPoint(
    [4670.9, 1218.62, 1271.02],
    {
      type: "info",
      title: "",
      noCloseIcon: true,
      popupClass: "popup-info_container",
      sectionTitle: "130 GPZ TO",
      description:
        "<p>" +
        getLanguageTranslation(
          "Tenda con cassonetto da 130mm disponibile nella sola variante Quadro, dotata di sistema di guida ZIP. Adatta, grazie al sistema di tensionamento , per installazioni come copertura dei giardini d’inverno, dei tetti in vetro e delle verande in generale. Funzionamento esclusivamente motorizzato."
        ) +
        "</p>",
      images: [
        ImageScreeny130GpzTensTo1,
        ImageScreeny130GpzTensTo2,
        ImageScreeny130GpzTensTo3,
        ImageScreeny130GpzTensTo4,
        ImageScreeny130GpzTensTo5,
      ],
      buttons: [
        {
          text: getLanguageTranslation("Scheda Tecnica"),
          href: TechnicalSheetScreeny130GpzTensTo,
        },
      ],
    },
    undefined,
    "<p><strong>130 GPZ TO</strong><br />" +
      getLanguageTranslation(
        "Tenda con cassonetto da 130mm disponibile nella sola variante Quadro, dotata di sistema di guida ZIP."
      ) +
      "</p>"
  ),
];

export const createPercorso1InfoPointsFr = (createPositionPoint) => [
  createPositionPoint([281.74, -926.16, -4895.03], "intro", "Intro"),
  createPositionPoint([-3983.4, -1412.45, -2653.8], "130-gpz-to", "130 GPZ TO"),
  createPositionPoint([-4953.42, -562.89, -209.35], "percorso-5", "Percorso 5"),
  createPositionPoint([4903.45, -582.53, -717.19], "percorso-2", "Percorso 2"),
  createPositionPoint(
    [1340.01, -1293.43, 4630.61],
    "epica-base-lite",
    "Epica - Base Lite"
  ),
  createPositionPoint(
    [3818.05, -862.83, 3096.15],
    "base-lite-armony-plus",
    "Base Lite - Armony Plus"
  ),
  createPositionPoint(
    [-1936.57, -1171.52, 4455.93],
    "base-plus-epica",
    "Base Plus - Epica"
  ),
  createPositionPoint(
    [-3682.65, -939.64, 3239.11],
    "domea-base-plus",
    "Domea - Base Plus"
  ),
  createPositionPoint(
    [-4582.2, -541.88, 1903.69],
    "leva-incastro-bq-domea",
    "Domea"
  ),
];

export const createPercorso2InfoPointsFr = (createPositionPoint) => [
  createPositionPoint([1983.6, -612.62, -4542.57], "intro", "Intro"),
  createPositionPoint([4956.59, -569.77, 133.31], "percorso-3", "Percorso 3"),
  createPositionPoint([-4086.25, -694.03, 2781.8], "2020-2010", "2020 - 2010"),
  createPositionPoint(
    [-4882.06, -731.11, -739.97],
    "armony-plus-2040",
    "Armony Plus - 2040"
  ),
  createPositionPoint([-4833.36, -697.21, 1054.99], "2020-2040", "2040 - 2020"),
];

export const createPercorso3InfoPointsFr = (createPositionPoint) => [
  createPositionPoint([4287.09, -499.08, -2514.83], "intro", "Intro"),
  createPositionPoint([52.76, -507.41, -4968.2], "percorso-2", "Percorso 2"),
  createPositionPoint([4912.79, -590.18, 652.4], "percorso-4", "Percorso 4"),
  createPositionPoint(
    [-2478.01, -639.83, 4291.17],
    "verso-cassonetto",
    "Verso Cassonetto"
  ),
  createPositionPoint(
    [3630.85, -820.24, 3332.9],
    "screeny-110-gpz-unica-am-screeny-110-gc-cabrio",
    "Screeny 110 GPZ UNICA AM - SCREENY 110 GC Cabrio"
  ),
];

export const createPercorso4InfoPointsFr = (createPositionPoint) => [
  createPositionPoint([-4883.51, -643.79, 796.81], "intro", "Intro"),
  createPositionPoint([-666.34, -481.0, 4923.65], "percorso-3", "Percorso 3"),
  createPositionPoint(
    [4267.36, -1890.49, -1779.15],
    "helix-bq-helix-ps",
    "Helix BQ - Helix PS"
  ),
  createPositionPoint(
    [4878.6, -638.09, -832.11],
    "mini-helix-bq-mini-helix-ps",
    "Mini Helix BQ - Mini Helix PS"
  ),
];

export const create20202010InfoPointsFr = (
  createPositionPoint,
  createInfoPoint,
  createVrPoint
) => [
  createPositionPoint([-22.0, -1490.25, 4368.89], "2020-2040", "2040 - 2020"),
  createPositionPoint(
    [-57.82, -953.7, 4901.29],
    "armony-plus-2040",
    "Armony Plus - 2040"
  ),
  createPositionPoint([-3682.38, -847.98, 3268.38], "percorso-2", "Percorso 2"),
  createPositionPoint(
    [-4793.52, -1378.26, 168.12],
    "giant-zenith",
    "Giant - Zenith"
  ),
  createVrPoint([3753.03, -385.47, -3275.55], GLB2020, USDZ2020, undefined, "2020"),
  createVrPoint([3900.93, -673.13, 3039.74], GLB2010, USDZ2010, undefined, "2010"),
  createInfoPoint(
    [4581.6, -380.36, 1955.89],
    {
      type: "info",
      title: "",
      noCloseIcon: true,
      popupClass: "popup-info_container",
      sectionTitle: "2020",
      description:
        "<p>" +
        getLanguageTranslation(
          "2020 è una tenda a bracci estensibili compatta su barra quadra 40mm in acciaio zincato e verniciato, bracci a doppio cavetto inguainato o a cinghia e supporto braccio in estrusione di alluminio. E’ una tenda modulare: aggiungendo ulteriori profili si può raggiungere una larghezza maggiore. Il terminale frontale è intercambiabile con gli altri terminali BAT. Dispone come optional di un tettuccio di copertura in alluminio estruso e di una staffa speciale per il montaggio sottotetto."
        ) +
        "</p>",
      images: [Image20201, Image20202, Image20203, Image20204, Image20205],
      buttons: [
        {
          text: getLanguageTranslation("Scheda Tecnica"),
          href: TechnicalSheet2020,
        },
      ],
    },
    undefined,
    "<p><strong>2020</strong><br />" +
      getLanguageTranslation(
        "2020 è una tenda a bracci estensibili compatta su barra quadra 40mm in acciaio zincato e verniciato, bracci a doppio cavetto inguainato o a cinghia e supporto braccio in estrusione di alluminio. E’ una tenda modulare: aggiungendo ulteriori profili si può raggiungere una larghezza maggiore."
      ) +
      "</p>"
  ),
  createInfoPoint(
    [4654.81, -380.36, -1743.4],
    {
      type: "info",
      title: "",
      noCloseIcon: true,
      popupClass: "popup-info_container",
      sectionTitle: "2010",
      description:
        "<p>" +
        getLanguageTranslation(
          "2010 è una tenda a bracci estensibili compatta su barra quadra 40mm in acciaio zincato e verniciato con bracci a doppio cavetto inguainato o cinghia e supporto braccio con regolazione a cono"
        ) +
        "</p>",
      images: [Image20101, Image20102, Image20103, Image20104, Image20105],
      buttons: [
        {
          text: getLanguageTranslation("Scheda Tecnica"),
          href: TechnicalSheet2010,
        },
      ],
    },
    undefined,
    "<p><strong>2010</strong><br />" +
      getLanguageTranslation(
        "2010 è una tenda a bracci estensibili compatta su barra quadra 40mm in acciaio zincato e verniciato con bracci a doppio cavetto inguainato o cinghia e supporto braccio con regolazione a cono"
      ) +
      "</p>"
  ),
];

export const create20202040InfoPointsFr = (
  createPositionPoint,
  createInfoPoint,
  createVrPoint
) => [
  createPositionPoint(
    [-195.78, -1477.84, -4763.99],
    "2020-2010",
    "2020 - 2010"
  ),
  createPositionPoint(
    [-3630.26, -1160.42, -3234.11],
    "giant-zenith",
    "GIANT - ZENITH"
  ),
  createPositionPoint(
    [-4685.71, -1152.66, 1282.89],
    "percorso-2",
    "Percorso 2"
  ),
  createPositionPoint(
    [-7.25, -1613.78, 4729.56],
    "armony-plus-2040",
    "Armony Plus - 2040"
  ),
  createPositionPoint(
    [-26.56, -843.12, 4924.93],
    "base-lite-armony-plus",
    "Base Lite - Armony Plus"
  ),
  createVrPoint([3753.03, -385.47, -3275.55], GLB2020, USDZ2020, undefined, "2020"),
  createVrPoint([3900.93, -673.13, 3039.74], GLB2040, USDZ2040, undefined, "2040"),
  createInfoPoint(
    [4752.61, -34.83, -1548.25],
    {
      type: "info",
      title: "",
      noCloseIcon: true,
      popupClass: "popup-info_container",
      sectionTitle: "2020",
      description:
        "<p>" +
        getLanguageTranslation(
          "2020 è una tenda a bracci estensibili compatta su barra quadra 40mm in acciaio zincato e verniciato, bracci a doppio cavetto inguainato o a cinghia e supporto braccio in estrusione di alluminio. E’ una tenda modulare: aggiungendo ulteriori profili si può raggiungere una larghezza maggiore. Il terminale frontale è intercambiabile con gli altri terminali BAT. Dispone come optional di un tettuccio di copertura in alluminio estruso e di una staffa speciale per il montaggio sottotetto."
        ) +
        "</p>",
      images: [Image20201, Image20202, Image20203, Image20204, Image20205],
      buttons: [
        {
          text: getLanguageTranslation("Scheda Tecnica"),
          href: TechnicalSheet2020,
        },
      ],
    },
    undefined,
    "<p><strong>2020</strong><br />" +
      getLanguageTranslation(
        "2020 è una tenda a bracci estensibili compatta su barra quadra 40mm in acciaio zincato e verniciato, bracci a doppio cavetto inguainato o a cinghia e supporto braccio in estrusione di alluminio. E’ una tenda modulare: aggiungendo ulteriori profili si può raggiungere una larghezza maggiore."
      ) +
      "</p>"
  ),
  createInfoPoint(
    [4719.77, -62.26, 1630.78],
    {
      type: "info",
      title: "",
      noCloseIcon: true,
      popupClass: "popup-info_container",
      sectionTitle: "2040",
      description:
        "<p>" +
        getLanguageTranslation(
          "2040 è una tenda a bracci estensibili su barra quadra 40mm in acciaio zincato e verniciato con cassonetto a scomparsa parziale e con bracci a doppio cavetto inguainato o a cinghia. Questo modello viene fornito di un supporto braccio basculante brevettato che consente di regolare la tenda senza dover inclinare il cassonetto."
        ) +
        "</p>",
      images: [Image20401, Image20402, Image20403, Image20404, Image20405],
      buttons: [
        {
          text: getLanguageTranslation("Scheda Tecnica"),
          href: TechnicalSheet2040,
        },
      ],
    },
    undefined,
    "<p><strong>2040</strong><br />" +
      getLanguageTranslation(
        "2040 è una tenda a bracci estensibili su barra quadra 40mm in acciaio zincato e verniciato con cassonetto a scomparsa parziale e con bracci a doppio cavetto inguainato o a cinghia."
      ) +
      "</p>"
  ),
];

export const createArmonyPlus2040InfoPointsFr = (
  createPositionPoint,
  createInfoPoint,
  createVrPoint
) => [
  createPositionPoint([-95.44, -800.29, -4929.84], "2020-2010", "2020 - 2010"),
  createPositionPoint(
    [-4655.84, -1065.7, -1461.11],
    "percorso-2",
    "Percorso 2"
  ),
  createPositionPoint([-82.84, -1504.31, -4762.44], "2020-2040", "2040 - 2020"),
  createPositionPoint(
    [-72.21, -1438.93, 4781.44],
    "base-lite-armony-plus",
    "Base Lite - Armony Plus"
  ),
  createPositionPoint(
    [-16.15, -669.5, 4950.93],
    "epica-base-lite",
    "Epica - Base Lite"
  ),
  createVrPoint([3748.65, -462.28, 3267.39], ArmonyPlusGLB, ArmonyPlusUSDZ, undefined, "ARMONY PLUS"),
  createVrPoint([3838.42, -481.33, -3152.95], GLB2040, USDZ2040, undefined, "2040"),
  createInfoPoint(
    [4719.77, -62.26, 1630.78],
    {
      type: "info",
      title: "",
      noCloseIcon: true,
      popupClass: "popup-info_container",
      sectionTitle: "ARMONY PLUS",
      description:
        "<p>" +
        getLanguageTranslation(
          "ARMONY PLUS é una tenda a bracci estensibili con cassonetto a scomparsa totale. Design e stile uniti alla funzionalità e alla tecnologia di un sistema ribaltabile BAT che permette la chiusura ermetica del cassonetto anche nella sua massima inclinazione inoltre, grazie ad un profilo di guarnizione il cassonetto risulta essere perfettamente aderente al muro impedendo l’infiltrazione di acque ed impurità."
        ) +
        "</p>",
      images: [
        ImageArmonyPlus1,
        ImageArmonyPlus2,
        ImageArmonyPlus3,
        ImageArmonyPlus4,
        ImageArmonyPlus5,
      ],
      buttons: [
        {
          text: getLanguageTranslation("Scheda Tecnica"),
          href: TechnicalSheetArmonyPlus,
        },
      ],
    },
    undefined,
    "<p><strong>ARMONY PLUS</strong><br />" +
      getLanguageTranslation(
        "ARMONY PLUS é una tenda a bracci estensibili con cassonetto a scomparsa totale."
      ) +
      "</p>"
  ),
  createInfoPoint(
    [4790.38, -98.13, -1399.22],
    {
      type: "info",
      title: "",
      noCloseIcon: true,
      popupClass: "popup-info_container",
      sectionTitle: "2040",
      description:
        "<p>" +
        getLanguageTranslation(
          "2040 è una tenda a bracci estensibili su barra quadra 40mm in acciaio zincato e verniciato con cassonetto a scomparsa parziale e con bracci a doppio cavetto inguainato o a cinghia. Questo modello viene fornito di un supporto braccio basculante brevettato che consente di regolare la tenda senza dover inclinare il cassonetto."
        ) +
        "</p>",
      images: [Image20401, Image20402, Image20403, Image20404, Image20405],
      buttons: [
        {
          text: getLanguageTranslation("Scheda Tecnica"),
          href: TechnicalSheet2040,
        },
      ],
    },
    undefined,
    "<p><strong>2040</strong><br />" +
      getLanguageTranslation(
        "2040 è una tenda a bracci estensibili su barra quadra 40mm in acciaio zincato e verniciato con cassonetto a scomparsa parziale e con bracci a doppio cavetto inguainato o a cinghia."
      ) +
      "</p>"
  ),
];

export const createBaseLiteArmonyPlusInfoPointsFr = (
  createPositionPoint,
  createInfoPoint,
  createVrPoint
) => [
  createPositionPoint([-3942.37, -824.88, 2954.6], "percorso-1", "Percorso 1"),
  createPositionPoint(
    [-177.4, -1822.87, -4643.5],
    "armony-plus-2040",
    "Armony Plus - 2040"
  ),
  createPositionPoint([-185.22, -919.44, -4901.43], "2020-2040", "2040 - 2020"),
  createPositionPoint(
    [-199.44, -1766.75, 4663.05],
    "epica-base-lite",
    "Epica - Base Lite"
  ),
  createPositionPoint(
    [-242.08, -755.27, 4928.94],
    "base-plus-epica",
    "Base Plus - Epica"
  ),
  createPositionPoint(
    [-3805.04, -724.95, -3148.42],
    "percorso-2",
    "Percorso 2"
  ),
  createVrPoint([3869.49, -661.79, 3080.69], BaseLiteGLB, BaseLiteUSDZ, undefined, "BASE LITE"),
  createVrPoint([3797.85, -286.19, -3226.39], ArmonyPlusGLB, ArmonyPlusUSDZ, undefined, "ARMONY PLUS"),
  createInfoPoint(
    [4852.48, -32.63, 1177.41],
    {
      type: "info",
      title: "",
      noCloseIcon: true,
      popupClass: "popup-info_container",
      sectionTitle: "BASE LITE",
      description:
        "<p>" +
        getLanguageTranslation(
          "BASE LITE è una tenda a bracci estensibili con cassonetto su supporti laterali fissi con contropiastra di ancoraggio, bracci balteus lite, doppio cavetto inguainato o a cinghia. I supporti consentono di installare la tenda sia a parete che a soffitto e la regolazione può essere eseguita in un unica soluzione grazie al sistema con vite passante. Tutti i componenti sono realizzati in alluminio verniciato che garantiscono resa estetica e durabilità."
        ) +
        "</p>",
      images: [
        ImageBaseLite1,
        ImageBaseLite2,
        ImageBaseLite3,
        ImageBaseLite4,
        ImageBaseLite5,
        ImageBaseLite6,
        ImageBaseLite7,
      ],
      buttons: [
        {
          text: getLanguageTranslation("Scheda Tecnica"),
          href: TechnicalSheetBaseLite,
        },
      ],
    },
    undefined,
    "<p><strong>BASE LITE</strong><br />" +
      getLanguageTranslation(
        "BASE LITE è una tenda a bracci estensibili con cassonetto su supporti laterali fissi con contropiastra di ancoraggio, bracci balteus lite, doppio cavetto inguainato o a cinghia."
      ) +
      "</p>"
  ),
  createInfoPoint(
    [4626.44, -32.63, -1866.09],
    {
      type: "info",
      title: "",
      noCloseIcon: true,
      popupClass: "popup-info_container",
      sectionTitle: "ARMONY PLUS",
      description:
        "<p>" +
        getLanguageTranslation(
          "ARMONY PLUS é una tenda a bracci estensibili con cassonetto a scomparsa totale. Design e stile uniti alla funzionalità e alla tecnologia di un sistema ribaltabile BAT che permette la chiusura ermetica del cassonetto anche nella sua massima inclinazione inoltre, grazie ad un profilo di guarnizione il cassonetto risulta essere perfettamente aderente al muro impedendo l’infiltrazione di acque ed impurità."
        ) +
        "</p>",
      images: [
        ImageArmonyPlus1,
        ImageArmonyPlus2,
        ImageArmonyPlus3,
        ImageArmonyPlus4,
        ImageArmonyPlus5,
      ],
      buttons: [
        {
          text: getLanguageTranslation("Scheda Tecnica"),
          href: TechnicalSheetArmonyPlus,
        },
      ],
    },
    undefined,
    "<p><strong>ARMONY PLUS</strong><br />" +
      getLanguageTranslation(
        "ARMONY PLUS é una tenda a bracci estensibili con cassonetto a scomparsa totale."
      ) +
      "</p>"
  ),
];

export const createEpicaBaseLiteInfoPointsFr = (
  createPositionPoint,
  createInfoPoint,
  createVrPoint
) => [
  createPositionPoint([-4821.86, -925.24, 899.79], "percorso-1", "Percorso 1"),
  createPositionPoint(
    [-200.17, -747.22, -4932.09],
    "armony-plus-2040",
    "Armony Plus - 2040"
  ),
  createPositionPoint(
    [-200.93, -1643.27, -4714.92],
    "base-lite-armony-plus",
    "Base Lite - Armony Plus"
  ),
  createPositionPoint(
    [-2799.77, -560.24, -4093.43],
    "percorso-2",
    "Percorso 2"
  ),
  createPositionPoint(
    [-154.37, -1879.15, 4623.95],
    "base-plus-epica",
    "Base Plus - Epica"
  ),
  createPositionPoint(
    [-154.42, -585.7, 4958.01],
    "domea-base-plus",
    "Domea - Base Plus"
  ),
  createVrPoint([3926.13, -756.58, 2992.95], EpicaGLB, EpicaUSDZ, undefined, "EPICA"),
  createVrPoint([3790.31, -867.11, -3129.06], BaseLiteGLB, BaseLiteUSDZ, undefined, "BASE LITE"),
  createInfoPoint(
    [4703.27, -178.96, 1661.0],
    {
      type: "info",
      title: "",
      noCloseIcon: true,
      popupClass: "popup-info_container",
      sectionTitle: "EPICA",
      description:
        "<p>" +
        getLanguageTranslation(
          "EPICA è una tenda a bracci estensibili con cassonetto, caratterizzata da linee semplici ed essenziali. Gli elementi di fissaggio e le viterie sono totalmente nascosti alla vista. Pur avendo ingombri contenuti, raggiunge una sporgenza di 410cm con braccio Giant. Il prodotto viene completato con un’ampia gamma di optional come il kit luci e il terminale a cassonetto."
        ) +
        "</p>",
      images: [ImageEpica1, ImageEpica2, ImageEpica3, ImageEpica4],
      buttons: [
        {
          text: getLanguageTranslation("Scheda Tecnica"),
          href: TechnicalSheetEpica,
        },
      ],
    },
    undefined,
    "<p><strong>EPICA</strong><br />" +
      getLanguageTranslation(
        "EPICA è una tenda a bracci estensibili con cassonetto, caratterizzata da linee semplici ed essenziali. Gli elementi di fissaggio e le viterie sono totalmente nascosti alla vista."
      ) +
      "</p>"
  ),
  createInfoPoint(
    [4618.56, -178.75, -1898.54],
    {
      type: "info",
      title: "",
      noCloseIcon: true,
      popupClass: "popup-info_container",
      sectionTitle: "BASE LITE",
      description:
        "<p>" +
        getLanguageTranslation(
          "BASE LITE è una tenda a bracci estensibili con cassonetto su supporti laterali fissi con contropiastra di ancoraggio, bracci balteus lite, doppio cavetto inguainato o a cinghia. I supporti consentono di installare la tenda sia a parete che a soffitto e la regolazione può essere eseguita in un unica soluzione grazie al sistema con vite passante. Tutti i componenti sono realizzati in alluminio verniciato che garantiscono resa estetica e durabilità."
        ) +
        "</p>",
      images: [
        ImageBaseLite1,
        ImageBaseLite2,
        ImageBaseLite3,
        ImageBaseLite4,
        ImageBaseLite5,
        ImageBaseLite6,
        ImageBaseLite7,
      ],
      buttons: [
        {
          text: getLanguageTranslation("Scheda Tecnica"),
          href: TechnicalSheetBaseLite,
        },
      ],
    },
    undefined,
    "<p><strong>BASE LITE</strong><br />" +
      getLanguageTranslation(
        "BASE LITE è una tenda a bracci estensibili con cassonetto su supporti laterali fissi con contropiastra di ancoraggio, bracci balteus lite, doppio cavetto inguainato o a cinghia."
      ) +
      "</p>"
  ),
];

export const createBasePlusEpicaInfoPointsFr = (
  createPositionPoint,
  createInfoPoint,
  createVrPoint
) => [
  createPositionPoint(
    [-4644.35, -920.07, -1586.09],
    "percorso-1",
    "Percorso 1"
  ),
  createPositionPoint(
    [-356.6, -1621.8, -4708.29],
    "epica-base-lite",
    "Epica - Base Lite"
  ),
  createPositionPoint(
    [-356.6, -826.87, -4916.97],
    "base-lite-armony-plus",
    "Base Lite - Armony Plus"
  ),
  createPositionPoint(
    [44.59, -1578.09, 4742.87],
    "domea-base-plus",
    "Domea - Base Plus"
  ),
  createPositionPoint(
    [44.47, -527.9, 4967.36],
    "leva-incastro-bq-domea",
    "Domea"
  ),
  createPositionPoint([-4814.09, -1004.8, 847.82], "130-gpz-to", "130 GPZ TO"),
  createVrPoint([4011.7, -900.7, 2834.24], BasePlusGLB, BasePlusUSDZ, undefined, "BASE PLUS"),
  createVrPoint([3846.5, -775.85, -3087.66], EpicaGLB, EpicaUSDZ, undefined, "EPICA"),
  createInfoPoint(
    [4743.76, -437.4, 1508.0],
    {
      type: "info",
      title: "",
      noCloseIcon: true,
      popupClass: "popup-info_container",
      sectionTitle: "BASE PLUS",
      description:
        "<p>" +
        getLanguageTranslation(
          "BASE PLUS è una tenda a bracci estensibili con cassonetto su supporti laterali fissi con contropiastra di ancoraggio, bracci Balteus o spin. Il cassonetto, ispezionabile, viene fornito in tre profili separati. I supporti consentono di installare la tenda sia a parete che a soffitto e la regolazione può essere eseguita in un unica soluzione grazie al sistema con vite passante. Tutti i componenti sono realizzati in alluminio verniciato che garantiscono resa estetica e durabilità."
        ) +
        "</p>",
      images: [
        ImageBasePlus1,
        ImageBasePlus2,
        ImageBasePlus3,
        ImageBasePlus4,
        ImageBasePlus5,
        ImageBasePlus6,
      ],
      buttons: [
        {
          text: getLanguageTranslation("Scheda Tecnica"),
          href: TechnicalSheetBasePlus,
        },
      ],
    },
    undefined,
    "<p><strong>BASE PLUS</strong><br />" +
      getLanguageTranslation(
        "BASE PLUS è una tenda a bracci estensibili con cassonetto su supporti laterali fissi con contropiastra di ancoraggio, bracci Balteus o spin. Il cassonetto, ispezionabile, viene fornito in tre profili separati."
      ) +
      "</p>"
  ),
  createInfoPoint(
    [4524.93, -429.54, -2074.08],
    {
      type: "info",
      title: "",
      noCloseIcon: true,
      popupClass: "popup-info_container",
      sectionTitle: "EPICA",
      description:
        "<p>" +
        getLanguageTranslation(
          "EPICA è una tenda a bracci estensibili con cassonetto, caratterizzata da linee semplici ed essenziali. Gli elementi di fissaggio e le viterie sono totalmente nascosti alla vista. Pur avendo ingombri contenuti, raggiunge una sporgenza di 410cm con braccio Giant. Il prodotto viene completato con un’ampia gamma di optional come il kit luci e il terminale a cassonetto."
        ) +
        "</p>",
      images: [ImageEpica1, ImageEpica2, ImageEpica3, ImageEpica4],
      buttons: [
        {
          text: getLanguageTranslation("Scheda Tecnica"),
          href: TechnicalSheetEpica,
        },
      ],
    },
    undefined,
    "<p><strong>EPICA</strong><br />" +
      getLanguageTranslation(
        "EPICA è una tenda a bracci estensibili con cassonetto, caratterizzata da linee semplici ed essenziali. Gli elementi di fissaggio e le viterie sono totalmente nascosti alla vista."
      ) +
      "</p>"
  ),
];

export const createDomeaBasePlusInfoPointsFr = (
  createPositionPoint,
  createInfoPoint,
  createVrPoint
) => [
  createPositionPoint(
    [-170.19, -1206.36, 4841.89],
    "leva-incastro-bq-domea",
    "Domea"
  ),
  createPositionPoint(
    [-3652.84, -735.33, -3329.43],
    "percorso-1",
    "Percorso 1"
  ),
  createPositionPoint(
    [-4611.51, -999.28, -1627.73],
    "130-gpz-to",
    "130 GPZ TO"
  ),
  createPositionPoint(
    [-195.76, -1765.7, -4663.64],
    "base-plus-epica",
    "Base Plus - Epica"
  ),
  createPositionPoint(
    [-195.21, -825.53, -4918.7],
    "epica-base-lite",
    "Epica - Base Lite"
  ),
  createVrPoint([4139.21, -882.71, 2644.45], DomeaGLB, DomeaUSDZ, undefined, "DOMEA"),
  createVrPoint([3310.51, -523.39, -3704.44], BasePlusGLB, BasePlusUSDZ, undefined, "BASE PLUS"),
  createInfoPoint(
    [4796.84, 19.69, 1387.0],
    {
      type: "info",
      title: "",
      noCloseIcon: true,
      popupClass: "popup-info_container",
      sectionTitle: "DOMEA",
      description:
        "<p>" +
        getLanguageTranslation(
          "DOMEA, disegnata da Robby Cantarutti, designer di fama internazionale, è il primo prodotto della nuova “feel-experience” di BAT. Le sue forme accattivanti, che richiamano la tradizione e simboleggiano un connubio perfetto tra innovazione e classicità, forte di nuovi contenuti tecnologici, fanno di DOMEA uno dei prodotti di punta della gamma delle tende a cassonetto. Caratterizzata dall’estrema facilità di montaggio, regolazione e installazione, garantisce standard di sicurezza e qualità elevati per un modello di questa categoria."
        ) +
        "</p>",
      images: [
        ImageDomea1,
        ImageDomea2,
        ImageDomea3,
        ImageDomea4,
        ImageDomea5,
        ImageDomea6,
      ],
      buttons: [
        {
          text: getLanguageTranslation("Scheda Tecnica"),
          href: TechnicalSheetDomea,
        },
      ],
    },
    undefined,
    "<p><strong>DOMEA</strong><br />" +
      getLanguageTranslation(
        "DOMEA, disegnata da Robby Cantarutti, designer di fama internazionale, è il primo prodotto della nuova “feel-experience” di BAT. Le sue forme accattivanti, che richiamano la tradizione e simboleggiano un connubio perfetto tra innovazione e classicità, forte di nuovi contenuti tecnologici, fanno di DOMEA uno dei prodotti di punta della gamma delle tende a cassonetto."
      ) +
      "</p>"
  ),
  createInfoPoint(
    [4608.7, 19.17, -1923.09],
    {
      type: "info",
      title: "",
      noCloseIcon: true,
      popupClass: "popup-info_container",
      sectionTitle: "BASE PLUS",
      description:
        "<p>" +
        getLanguageTranslation(
          "BASE PLUS è una tenda a bracci estensibili con cassonetto su supporti laterali fissi con contropiastra di ancoraggio, bracci Balteus o spin. Il cassonetto, ispezionabile, viene fornito in tre profili separati. I supporti consentono di installare la tenda sia a parete che a soffitto e la regolazione può essere eseguita in un unica soluzione grazie al sistema con vite passante. Tutti i componenti sono realizzati in alluminio verniciato che garantiscono resa estetica e durabilità."
        ) +
        "</p>",
      images: [
        ImageBasePlus1,
        ImageBasePlus2,
        ImageBasePlus3,
        ImageBasePlus4,
        ImageBasePlus5,
        ImageBasePlus6,
      ],
      buttons: [
        {
          text: getLanguageTranslation("Scheda Tecnica"),
          href: TechnicalSheetBasePlus,
        },
      ],
    },
    undefined,
    "<p><strong>BASE PLUS</strong><br />" +
      getLanguageTranslation(
        "BASE PLUS è una tenda a bracci estensibili con cassonetto su supporti laterali fissi con contropiastra di ancoraggio, bracci Balteus o spin. Il cassonetto, ispezionabile, viene fornito in tre profili separati."
      ) +
      "</p>"
  ),
];

export const createLevaIncastroBqDomeaInfoPointsFr = (
  createPositionPoint,
  createInfoPoint,
  createVrPoint
) => [
  createPositionPoint(
    [-188.0, -1646.5, -4708.02],
    "domea-base-plus",
    "Domea - Base Plus"
  ),
  createPositionPoint(
    [-188.94, -834.05, -4923.36],
    "base-plus-epica",
    "Base Plus - Epica"
  ),
  createPositionPoint([-2760.66, -621.0, -4110.27], "percorso-1", "Percorso 1"),
  createPositionPoint(
    [-3892.39, -1614.22, 2678.58],
    "percorso-5",
    "Percorso 5"
  ),
  createVrPoint([3918.3, -821.98, 2985.25], LevaIncastroBqGLB, LevaIncastroBqUSDZ, undefined, "DOMEA"),
  createVrPoint([4119.05, -847.51, -2688.32], DomeaGLB, DomeaUSDZ, undefined, "DOMEA"),
  createInfoPoint(
    [4707.53, -8.78, -1668.52],
    {
      type: "info",
      title: "",
      noCloseIcon: true,
      popupClass: "popup-info_container",
      sectionTitle: "DOMEA",
      description:
        "<p>" +
        getLanguageTranslation(
          "DOMEA, disegnata da Robby Cantarutti, designer di fama internazionale, è il primo prodotto della nuova “feel-experience” di BAT. Le sue forme accattivanti, che richiamano la tradizione e simboleggiano un connubio perfetto tra innovazione e classicità, forte di nuovi contenuti tecnologici, fanno di DOMEA uno dei prodotti di punta della gamma delle tende a cassonetto. Caratterizzata dall’estrema facilità di montaggio, regolazione e installazione, garantisce standard di sicurezza e qualità elevati per un modello di questa categoria."
        ) +
        "</p>",
      images: [
        ImageDomea1,
        ImageDomea2,
        ImageDomea3,
        ImageDomea4,
        ImageDomea5,
        ImageDomea6,
      ],
      buttons: [
        {
          text: getLanguageTranslation("Scheda Tecnica"),
          href: TechnicalSheetDomea,
        },
      ],
    },
    undefined,
    "<p><strong>DOMEA</strong><br />" +
      getLanguageTranslation(
        "DOMEA, disegnata da Robby Cantarutti, designer di fama internazionale, è il primo prodotto della nuova “feel-experience” di BAT. Le sue forme accattivanti, che richiamano la tradizione e simboleggiano un connubio perfetto tra innovazione e classicità, forte di nuovi contenuti tecnologici, fanno di DOMEA uno dei prodotti di punta della gamma delle tende a cassonetto."
      ) +
      "</p>"
  ),
];

export const createEpicaLiteInfoPointsFr = (
  createPositionPoint,
  createInfoPoint,
  createVrPoint,
  createVideoPoint
) => [
  createPositionPoint([810.14, -2021.06, -4492.08], "percorso-6", "Percorso 6"),
  createPositionPoint(
    [53.35, -2048.10, 4554.51],
    "ely",
    "Ely"
  ),
  createVrPoint([4297.01, -400.76, -2520.90], EpicaLiteGLB, EpicaLiteUSDZ, undefined, "EPICA LITE"),
  createInfoPoint(
    [4707.53, -8.78, -1668.52],
    {
      type: "info",
      title: "",
      noCloseIcon: true,
      popupClass: "popup-info_container",
      sectionTitle: "EPICA LITE",
      description:
        "<p>" +
        getLanguageTranslation(
          "EPICA LITE è una tenda a bracci estensibili con cassonetto su supporti laterali fissi con contropiastra di ancoraggio, bracci Balteus o spin. Il cassonetto, ispezionabile, viene fornito in tre profili separati. I supporti consentono di installare la tenda sia a parete che a soffitto e la regolazione può essere eseguita in un’unica soluzione grazie al sistema con vite passante."
        ) +
        "</p>",
      images: [
        ImageEpicaLite1,
        ImageEpicaLite2,
        ImageEpicaLite3,
        ImageEpicaLite4,
        ImageEpicaLite5,
        ImageEpicaLite6,
        ImageEpicaLite7,
        ImageEpicaLite8,
        ImageEpicaLite9,
      ],
      buttons: [
        {
          text: getLanguageTranslation("Scheda Tecnica"),
          href: TechnicalSheetEpicaLite,
        },
      ],
    },
    undefined,
    "<p><strong>EPICA LITE</strong><br />" +
      getLanguageTranslation(
        "EPICA LITE è una tenda a bracci estensibili con cassonetto su supporti laterali fissi con contropiastra di ancoraggio, bracci Balteus o spin."
      ) +
      "</p>"
  ),
  createVideoPoint(
    [3602.02, -397.09, -3436.78],
    "Video",
    "https://www.youtube.com/watch?v=rYrZhH8tMn4"
  ),
];

export const createPercorso5InfoPointsFr = (createPositionPoint) => [
  createPositionPoint([3624.32, -366.09, 3416.74], "percorso-6", "Percorso 6"),
  createPositionPoint([-4796.03, -541.4, 1265.44], "percorso-1", "Percorso 1"),
  createPositionPoint(
    [-3518.18, -1473.11, -3226.26],
    "leva-incastro-bq-domea",
    "Domea"
  ),
];

export const createPercorso6InfoPointsFr = (createPositionPoint) => [
  createPositionPoint(
    [-2004.93, -1358.41, 4367.11],
    "percorso-5",
    "Percorso 5"
  ),
  createPositionPoint([4956.2, -550.3, 184.88], "ely", "Ely"),
  createPositionPoint([4432.32, -1211.30, 1966.82], "epica-lite", "Epica Lite"),
];

export const createElyInfoPointsFr = (
  createPositionPoint,
  createInfoPoint,
  createVrPoint
) => [
  createPositionPoint([962.55, -784.16, 4840.29], "epica-lite", "Epica Lite"),
  createPositionPoint([959.75, -1016.64, -4793.98], "sirio", "Sirio"),
  createVrPoint([3476.72, -1595.43, 3212.82], ElyGLB, ElyUSDZ, undefined, "ELY"),
  createInfoPoint(
    [4336.29, -2182.15, 1162.26],
    {
      type: "info",
      title: "",
      noCloseIcon: true,
      popupClass: "popup-info_container",
      sectionTitle: "ELY",
      description:
        "<p>" +
        getLanguageTranslation(
          "ELY è una nuova tenda verticale per uso esterno. Grazie alla sua struttura lineare, alla facilità di assemblaggio e installazione e all’innovativo ed accattivante design, Ely risulta essere la soluzione ideale per soddisfare le più esigenti richieste di protezione solare ed arredo esterno."
        ) +
        "</p>",
      images: [
        ImageEly1,
        ImageEly2,
        ImageEly3,
        ImageEly4,
        ImageEly5,
        ImageEly6,
        ImageEly7,
        ImageEly8,
      ],
      buttons: [
        {
          text: getLanguageTranslation("Scheda Tecnica"),
          href: TechnicalSheetEly,
        },
      ],
    },
    undefined,
    "<p><strong>ELY</strong><br />" +
      getLanguageTranslation(
        "ELY è una nuova tenda verticale per uso esterno. Grazie alla sua struttura lineare, alla facilità di assemblaggio e installazione e all’innovativo ed accattivante design, Ely risulta essere la soluzione ideale per soddisfare le più esigenti richieste di protezione solare ed arredo esterno."
      ) +
      "</p>"
  ),
];

export const createSirioInfoPointsFr = (
  createPositionPoint,
  createInfoPoint,
  createVrPoint,
  createVideoPoint
) => [
  createPositionPoint([918.3, -1270.12, -4740.92], "ely", "Ely"),
  createPositionPoint([-3022.81, -973.23, 3849.22], "percorso-8", "Percorso 8"),
  createPositionPoint([-624.41, -1101.72, 4829.98], "ombralsun", "Ombralsun"),
  createVrPoint([3356.7, -667.99, 3636.82], SirioGLB, SirioUSDZ, undefined, "SIRIO"),
  createInfoPoint(
    [4347.93, 191.64, 2448.49],
    {
      type: "info",
      title: "",
      noCloseIcon: true,
      popupClass: "popup-info_container",
      sectionTitle: "SIRIO",
      description:
        "<p>" +
        getLanguageTranslation(
          "SIRIO Struttura di copertura per giardini d’inverno, tetti in vetro e verande. Dotata di sistema di guida Zip e di sistema di tensionamento del telo con molla. Presenza di optional luci e terminale a cassonetto. Funzionamento esclusivamente motorizzato."
        ) +
        "</p>",
      images: [
        ImageSirio1,
        ImageSirio2,
        ImageSirio3,
        ImageSirio4,
        ImageSirio5,
        ImageSirio6,
      ],
      buttons: [
        {
          text: getLanguageTranslation("Scheda Tecnica"),
          href: TechnicalSheetSirio,
        },
      ],
    },
    undefined,
    "<p><strong>SIRIO</strong><br />" +
      getLanguageTranslation(
        "SIRIO Struttura di copertura per giardini d’inverno, tetti in vetro e verande. Dotata di sistema di guida Zip e di sistema di tensionamento del telo con molla."
      ) +
      "</p>"
  ),
  createVideoPoint(
    [3652.09, 635.67, 3346.84],
    "Video",
    "https://www.youtube.com/watch?v=C1RcttDd3D8"
  ),
];

export const createPercorso8InfoPointsFr = (createPositionPoint) => [
  createPositionPoint([4992.38, -13.58, -135.17], "ombralsun", "Ombralsun"),
  createPositionPoint([177.35, -292.98, -4979.19], "percorso-5", "Percorso 5"),
];

export const createVersoCassonettoInfoPointsFr = (
  createPositionPoint,
  createInfoPoint,
  createVrPoint
) => [
  createPositionPoint(
    [-1700.49, -1496.09, -4448.64],
    "screeny-150-storm-screeny-130-gpz-unica-m",
    "Screeny 130 GPZ UNICA M"
  ),
  createPositionPoint(
    [-4259.69, -732.57, -2506.36],
    "screeny-110-gc-screeny-130-gpz-i",
    "Screeny 110 GC - Screeny 130 GPZ I"
  ),
  createPositionPoint([-2106.17, -627.41, 4482.33], "percorso-3", "Percorso 3"),
  createVrPoint(
    [3573.65, -1412.72, 3192.02],
    VersoCassonettoGLB,
    VersoCassonettoUSDZ
  ),
  createInfoPoint(
    [4767.97, 1403.33, 507.67],
    {
      type: "info",
      title: "",
      noCloseIcon: true,
      popupClass: "popup-info_container",
      sectionTitle: "VERSO CASSONETTO",
      description:
        "<p>" +
        getLanguageTranslation(
          "VERSO è una tenda dedicata alla posa su balconi e finestre con braccetti a molla. Di semplice e veloce installazione prevede anche un cassonetto opzionale per la protezione del tessuto. Grazie alla conformazione del braccio si adatta particolarmente alla installazione a nicchia."
        ) +
        "</p>",
      images: [ImageVerso1, ImageVerso2, ImageVerso3, ImageVerso4, ImageVerso5],
      buttons: [
        {
          text: getLanguageTranslation("Scheda Tecnica"),
          href: TechnicalSheetVerso,
        },
      ],
    },
    undefined,
    "<p><strong>VERSO CASSONETTO</strong><br />" +
      getLanguageTranslation(
        "VERSO è una tenda dedicata alla posa su balconi e finestre con braccetti a molla. Di semplice e veloce installazione prevede anche un cassonetto opzionale per la protezione del tessuto."
      ) +
      "</p>"
  ),
];

export const createScreeny150StormScreeny130GpzUnicaMInfoPointsFr = (
  createPositionPoint,
  createInfoPoint,
  createVrPoint
) => [
  createPositionPoint(
    [-4336.36, -1492.19, 1982.29],
    "verso-cassonetto",
    "Verso Cassonetto"
  ),
  createPositionPoint([-4832.58, -523.9, -1137.03], "percorso-3", "Percorso 3"),
  createPositionPoint(
    [845.64, -1009.67, -4813.46],
    "screeny-110-gc-screeny-110-gpz-i",
    "Screeny 110 Gc - Screeny 130 GPZ I"
  ),
  createVrPoint(
    [4254.47, -931.67, -2447.67],
    Screeny130GpzUnicaMGLB,
    Screeny130GpzUnicaMUSDZ
  ),
  createInfoPoint(
    [4652.49, 983.26, -1605.25],
    {
      type: "info",
      title: "",
      noCloseIcon: true,
      popupClass: "popup-info_container",
      sectionTitle: "SCREENY 130 GPZ UNICA M",
      description:
        "<p>" +
        getLanguageTranslation(
          "Tenda con cassonetto da 130mm disponibile nelle varianti Quadro e Tondo dotata di sistema di guida ZIP. Adatta per installazioni su infissi, sia a nicchia che a parete; veloce ed agevole da installare ed ispezionare. La guida è composta da due profili: supporto e copertina; è sviluppata per i soli sistemi motorizzati riducendo la larghezza a 36 mm."
        ) +
        "</p>",
      images: [
        ImageScreeny130GpzUnicaM1,
        ImageScreeny130GpzUnicaM2,
        ImageScreeny130GpzUnicaM3,
        ImageScreeny130GpzUnicaM4,
        ImageScreeny130GpzUnicaM5,
        ImageScreeny130GpzUnicaM6,
        ImageScreeny130GpzUnicaM7,
      ],
      buttons: [
        {
          text: getLanguageTranslation("Scheda Tecnica"),
          href: TechnicalSheetScreeny130GpzUnicaM,
        },
      ],
    },
    undefined,
    "<p><strong>SCREENY 130 GPZ UNICA M</strong><br />" +
      getLanguageTranslation(
        "Tenda con cassonetto da 110mm disponibile nelle varianti Quadro e Tondo dotata di sistema di guida ZIP e cassonetto autoportante. Adatta per installazioni su infissi, sia a nicchia che a parete; veloce ed agevole da installare ed ispezionare."
      ) +
      "</p>"
  ),
];

export const createScreeny110GcScreeny130GpzIInfoPointsFr = (
  createPositionPoint,
  createInfoPoint,
  createVrPoint
) => [
  createPositionPoint(
    [767.99, -1053.24, 4816.99],
    "screeny-150-storm-screeny-130-gpz-unica-m",
    "Screeny 130 GPZ UNICA M"
  ),
  createPositionPoint(
    [-1737.19, -836.23, 4604.19],
    "verso-cassonetto",
    "Verso Cassonetto"
  ),
  createPositionPoint(
    [-1083.27, -1983.1, -4452.32],
    "screeny-130-ga-screeny-130-gpz-mb",
    "Screeny 130 GA - Screeny 130 GPZ MB"
  ),
  createPositionPoint(
    [-4574.34, -938.07, -1758.5],
    "screeny-110-gpz-unica-am-screeny-110-gc-cabrio",
    "Screeny 110 GPZ UNICA AM - Screeny 110 GC CABRIO"
  ),
  createVrPoint(
    [4330.92, -1149.25, 2202.42],
    Screeny110GcGLB,
    Screeny110GcUSDZ
  ),
  createVrPoint(
    [4034.67, -1229.07, -2669.67],
    Screeny130GpzIGLB,
    Screeny130GpzIUSDZ
  ),
  createInfoPoint(
    [4699.04, 910.84, 1418.72],
    {
      type: "info",
      title: "",
      noCloseIcon: true,
      popupClass: "popup-info_container",
      sectionTitle: "SCREENY 110 GC",
      description:
        "<p>" +
        getLanguageTranslation(
          "Tenda con cassonetto da 110mm disponibile nelle varianti Quadro e Tondo, dotata di guida cavetto inox Ø4mm."
        ) +
        "</p>",
      images: [
        ImageScreeny110Gc1,
        ImageScreeny110Gc2,
        ImageScreeny110Gc3,
        ImageScreeny110Gc4,
        ImageScreeny110Gc5,
        ImageScreeny110Gc6,
        ImageScreeny110Gc7,
      ],
      buttons: [
        {
          text: getLanguageTranslation("Scheda Tecnica"),
          href: TechnicalSheetScreeny110Gc,
        },
      ],
    },
    undefined,
    "<p><strong>SCREENY 110 GC</strong><br />" +
      getLanguageTranslation(
        "Tenda con cassonetto da 110mm disponibile nelle varianti Quadro e Tondo, dotata di guida cavetto inox Ø4mm."
      ) +
      "</p>"
  ),
  createInfoPoint(
    [4750.89, 910.02, -977.98],
    {
      type: "info",
      title: "",
      noCloseIcon: true,
      popupClass: "popup-info_container",
      sectionTitle: "SCREENY 130 GPZ I",
      description:
        "<p>" +
        getLanguageTranslation(
          "Tenda con cassonetto da 130mm disponibile nelle varianti Quadro e Tondo dotata di sistema di guida ZIP, composta da due profili con compensazione fino ad un max di 17 mm su ogni guida. Adatta per essere installata ad incasso e per applicazioni che presentano fuori squadra."
        ) +
        "</p>",
      images: [
        ImageScreeny130GpzI1,
        ImageScreeny130GpzI2,
        ImageScreeny130GpzI3,
        ImageScreeny130GpzI4,
        ImageScreeny130GpzI5,
        ImageScreeny130GpzI6,
      ],
      buttons: [
        {
          text: getLanguageTranslation("Scheda Tecnica"),
          href: TechnicalSheetScreeny130GpzI,
        },
      ],
    },
    undefined,
    "<p><strong>SCREENY 130 GPZ I</strong><br />" +
      getLanguageTranslation(
        "Tenda con cassonetto da 130mm disponibile nelle varianti Quadro e Tondo dotata di sistema di guida ZIP, composta da due profili con compensazione fino ad un max di 17 mm su ogni guida. Adatta per essere installata ad incasso e per applicazioni che presentano fuori squadra."
      ) +
      "</p>"
  ),
];

export const createScreeny130GaScreeny130GpzMbInfoPointsFr = (
  createPositionPoint,
  createInfoPoint,
  createVrPoint
) => [
  createPositionPoint(
    [950.63, -986.28, -4801.53],
    "screeny-110-gpz-unica-am-screeny-110-gc-cabrio",
    "Screeny 110 GPZ UNICA AM - Screeny 110 GC CABRIO"
  ),
  createPositionPoint(
    [-2560.16, -2241.73, 3657.64],
    "screeny-110-gc-screeny-130-gpz-i",
    "Screeny 110 GC - Screeny 130 GPZ I"
  ),
  createVrPoint(
    [3984.53, -1096.71, 2804.59],
    Screeny130GaGLB,
    Screeny130GaUSDZ
  ),
  createVrPoint(
    [4023.33, -1178.61, -2713.23],
    Screeny130GpzMbGLB,
    Screeny130GpzMbUSDZ
  ),
  createInfoPoint(
    [4624.39, 1051.03, 1565.5],
    {
      type: "info",
      title: "",
      noCloseIcon: true,
      popupClass: "popup-info_container",
      sectionTitle: "SCREENY 130 GA",
      description:
        "<p>" +
        getLanguageTranslation(
          "Tenda con cassonetto da 130mm disponibile nelle varianti Quadro e Tondo, dotata di guida asta inox Ø10mm."
        ) +
        "</p>",
      images: [
        ImageScreeny130Ga1,
        ImageScreeny130Ga2,
        ImageScreeny130Ga3,
        ImageScreeny130Ga4,
        ImageScreeny130Ga5,
        ImageScreeny130Ga6,
      ],
      buttons: [
        {
          text: getLanguageTranslation("Scheda Tecnica"),
          href: TechnicalSheetScreeny130Ga,
        },
      ],
    },
    undefined,
    "<p><strong>SCREENY 130 GA</strong><br />" +
      getLanguageTranslation(
        "Tenda con cassonetto da 130mm disponibile nelle varianti Quadro e Tondo, dotata di guida asta inox Ø10mm."
      ) +
      "</p>"
  ),
  createInfoPoint(
    [4700.49, 1031.09, -1321.02],
    {
      type: "info",
      title: "",
      noCloseIcon: true,
      popupClass: "popup-info_container",
      sectionTitle: "SCREENY 130 GPZ MB",
      description:
        "<p>" +
        getLanguageTranslation(
          "Tenda con cassonetto da 130mm disponibile nella sola variante Quadro, dotata di guida profilo da incasso con sistema ZIP e cassonetto autoportante. Adatta per l’integrazione nei sistemi per serramento, migliorando il risparmio energetico."
        ) +
        "</p>",
      images: [
        ImageScreeny130GpzMb1,
        ImageScreeny130GpzMb2,
        ImageScreeny130GpzMb3,
        ImageScreeny130GpzMb4,
      ],
      buttons: [
        {
          text: getLanguageTranslation("Scheda Tecnica"),
          href: TechnicalSheetScreeny130GpzMb,
        },
      ],
    },
    undefined,
    "<p><strong>SCREENY 130 GPZ MB</strong><br />" +
      getLanguageTranslation(
        "Tenda con cassonetto da 130mm disponibile nella sola variante Quadro, dotata di guida profilo da incasso con sistema ZIP e cassonetto autoportante. Adatta per l’integrazione nei sistemi per serramento, migliorando il risparmio energetico."
      ) +
      "</p>"
  ),
];

export const createScreeny110GpzUnicaAMScreeny110GcCabrioInfoPointsFr = (
  createPositionPoint,
  createInfoPoint,
  createVrPoint
) => [
  createPositionPoint(
    [-2839.72, -798.33, -4032.38],
    "percorso-3",
    "Percorso 3"
  ),
  createPositionPoint([2402.28, -1015.7, -4258.86], "percorso-4", "Percorso 4"),
  createPositionPoint(
    [718.46, -999.5, 4835.65],
    "screeny-130-ga-screeny-130-gpz-mb",
    "Screeny 130 GA - Screeny 130 GPZ MB"
  ),
  createVrPoint(
    [4028.29, -1279.57, 2653.38],
    Screeny110GpzUnicaAmGLB,
    Screeny110GpzUnicaAmUSDZ
  ),
  createVrPoint(
    [4035.91, -1237.14, -2663.59],
    Screeny110GcCabrioGLB,
    Screeny110GcCabrioUSDZ
  ),
  createInfoPoint(
    [4655.09, 870.71, 1585.47],
    {
      type: "info",
      title: "",
      noCloseIcon: true,
      popupClass: "popup-info_container",
      sectionTitle: "SCREENY 110 GPZ UNICA AM",
      description:
        "<p>" +
        getLanguageTranslation(
          "Tenda con cassonetto da 110mm disponibile nelle varianti Quadro e Tondo dotata di sistema di guida ZIP e cassonetto autoportante. Adatta per installazioni su infissi, sia a nicchia che a parete; veloce ed agevole da installare ed ispezionare. La guida è composta da due profili: supporto e copertina; è sviluppata per sistemi sia ad argano che motorizzati."
        ) +
        "</p>",
      images: [
        ImageScreeny110GpzUnicaAm1,
        ImageScreeny110GpzUnicaAm2,
        ImageScreeny110GpzUnicaAm3,
        ImageScreeny110GpzUnicaAm4,
        ImageScreeny110GpzUnicaAm5,
        ImageScreeny110GpzUnicaAm6,
        ImageScreeny110GpzUnicaAm7,
      ],
      buttons: [
        {
          text: getLanguageTranslation("Scheda Tecnica"),
          href: TechnicalSheetScreeny110GpzUnicaAm,
        },
      ],
    },
    undefined,
    "<p><strong>SCREENY 110 GPZ UNICA AM</strong><br />" +
      getLanguageTranslation(
        "Tenda con cassonetto da 110mm disponibile nelle varianti Quadro e Tondo dotata di sistema di guida ZIP e cassonetto autoportante. Adatta per installazioni su infissi, sia a nicchia che a parete; veloce ed agevole da installare ed ispezionare."
      ) +
      "</p>"
  ),
  createInfoPoint(
    [4720.99, 870.14, -1241.4],
    {
      type: "info",
      title: "",
      noCloseIcon: true,
      popupClass: "popup-info_container",
      sectionTitle: "SCREENY 110 GC CABRIO",
      description:
        "<p>" +
        getLanguageTranslation(
          "Tenda senza cassonetto, dotata di guida cavetto inox Ø4mm. Realizzabile sia nella versione singola che doppia."
        ) +
        "</p>",
      images: [
        ImageScreeny110GcCabrio1,
        ImageScreeny110GcCabrio2,
        ImageScreeny110GcCabrio3,
        ImageScreeny110GcCabrio4,
        ImageScreeny110GcCabrio5,
        ImageScreeny110GcCabrio6,
        ImageScreeny110GcCabrio7,
        ImageScreeny110GcCabrio8,
      ],
      buttons: [
        {
          text: getLanguageTranslation("Scheda Tecnica"),
          href: TechnicalSheetScreeny110GcCabrio,
        },
      ],
    },
    undefined,
    "<p><strong>SCREENY 110 GC CABRIO</strong><br />" +
      getLanguageTranslation(
        "Tenda senza cassonetto, dotata di guida cavetto inox Ø4mm. Realizzabile sia nella versione singola che doppia."
      ) +
      "</p>"
  ),
];

export const createGiantZenithInfoPointsFr = (
  createPositionPoint,
  createInfoPoint,
  createVrPoint
) => [
  createPositionPoint(
    [1496.13, -1137.79, -4631.62],
    "percorso-2",
    "Percorso 2"
  ),
  createPositionPoint([-4848.79, -1205.9, 41.05], "2020-2010", "2020 - 2010"),
  createPositionPoint(
    [-3904.57, -1085.88, -2918.9],
    "2020-2040",
    "2040 - 2020"
  ),
  createVrPoint([3673.14, -908.94, 3260.54], GiantGLB, GiantUSDZ, undefined, "GIANT"),
  createVrPoint([3588.26, -866.48, -3362.5], GiantGLB, GiantUSDZ, undefined, "GIANT"),
  createInfoPoint(
    [4750.34, -134.85, 1543.07],
    {
      type: "info",
      title: "",
      noCloseIcon: true,
      popupClass: "popup-info_container",
      sectionTitle: "GIANT",
      description:
        "<p>" +
        getLanguageTranslation(
          "GIANT BQ é una tenda a bracci su barra quadra in acciaio 50mm. I supporti in alluminio estruso garantiscono resistenza e compattezza e i tappi in alluminio verniciati ne caratterizzano eleganza e discrezione. Compattezza e design la rendono perfetta per coperture di terrazze pubbliche e per protezione di spazi e patii privati. Dispone di un braccio tipo spin, creato “ad hoc” con snodi e spalla forgiati."
        ) +
        "</p>",
      images: [ImageGiant1, ImageGiant2, ImageGiant3, ImageGiant4, ImageGiant5],
      buttons: [
        {
          text: getLanguageTranslation("Scheda Tecnica"),
          href: TechnicalSheetGiant,
        },
      ],
    },
    undefined,
    "<p><strong>GIANT</strong><br />" +
      getLanguageTranslation(
        "GIANT BQ é una tenda a bracci su barra quadra in acciaio 50mm. I supporti in alluminio estruso garantiscono resistenza e compattezza e i tappi in alluminio verniciati ne caratterizzano eleganza e discrezione."
      ) +
      "</p>"
  ),
  createInfoPoint(
    [4868.61, -134.14, -1101.52],
    {
      type: "info",
      title: "",
      noCloseIcon: true,
      popupClass: "popup-info_container",
      sectionTitle: "ZENITH",
      description:
        "<p>" +
        getLanguageTranslation(
          "ZENITH è una tenda a bracci con cassonetto fisso su barra rettangolare 50x30mm a scomparsa totale. Funzionale ed affidabile è dotata di sistema ribaltabile BAT che garantisce la perfetta chiusura del terminale anche in caso di massima inclinazione."
        ) +
        "</p>",
      images: [
        ImageZenith1,
        ImageZenith2,
        ImageZenith3,
        ImageZenith4,
        ImageZenith5,
        ImageZenith6,
      ],
      buttons: [
        {
          text: getLanguageTranslation("Scheda Tecnica"),
          href: TechnicalSheetZenith,
        },
      ],
    },
    undefined,
    "<p><strong>ZENITH</strong><br />" +
      getLanguageTranslation(
        "ZENITH è una tenda a bracci con cassonetto fisso su barra rettangolare 50x30mm a scomparsa totale."
      ) +
      "</p>"
  ),
];

export const createParigiPlusParigiPlusLiteInfoPointsFr = (
  createPositionPoint,
  createInfoPoint,
  createVrPoint
) => [
  createPositionPoint([-4313.89, -1586.19, -1960.21], "intro", "Intro"),
  createPositionPoint([73.6, -981.88, -4894.86], "130-gpz-to", "130 GPZ TO"),
  createPositionPoint(
    [-83.27, -935.77, 4903.52],
    "helix-bq-heliz-ps",
    "HELIX BQ - HELIX PS"
  ),
  createVrPoint([3220.73, -465.86, 3785.89], ParigiPlusGLB, ParigiPlusUSDZ, undefined, "PARIGI PLUS"),
  createVrPoint(
    [3291.59, -384.69, -3740.08],
    ParigiPlusLiteGLB,
    ParigiPlusLiteUSDZ
  ),
  createInfoPoint(
    [4617.51, -332.24, 1867.62],
    {
      type: "info",
      title: "",
      noCloseIcon: true,
      popupClass: "popup-info_container",
      sectionTitle: "PARIGI PLUS",
      description:
        "<p>PARIGI PLUS Store avec un support indépendant avec réglage à double “cone”; étudié pour être installé même dans les situations difficiles. Le mini réglage de l’inclinaison, la même inclinaison de 0° à 90°, ainsi que les caractéristiques de ce modéle, rende celui-ci comme le produit de référence pour les supports indépendants. Il est compatible avec toute la gamme de bras SPIN.</p>",
      images: [
        ImageBaseLite1,
        ImageBaseLite2,
        ImageBaseLite3,
        ImageBaseLite4,
        ImageBaseLite5,
        ImageBaseLite6,
        ImageBaseLite7,
      ],
      buttons: [
        {
          text: getLanguageTranslation("Scheda Tecnica"),
          href: null,
        },
      ],
    },
    undefined,
    "<p><strong>PARIGI PLUS</strong><br />PARIGI PLUS Store avec un support indépendant avec réglage à double “cone”; étudié pour être installé même dans les situations difficiles.</p>"
  ),
  createInfoPoint(
    [4585.75, -332.83, -1968.8],
    {
      type: "info",
      title: "",
      noCloseIcon: true,
      popupClass: "popup-info_container",
      sectionTitle: "PARIGI PLUS LITE",
      description:
        "<p>PARIGI PLUS LITE Store à support indépendant avec réglage à double “cone”; étudié pour être installé même dans les situations difficiles. Le mini réglage donne la possibilité d’obtenir une inclinaison de 0° à 90°. Il est compatible avec toute la gamme de bras LITE.</p>",
      images: [
        ImageZenith1,
        ImageZenith2,
        ImageZenith3,
        ImageZenith4,
        ImageZenith5,
        ImageZenith6,
      ],
      buttons: [
        {
          text: getLanguageTranslation("Scheda Tecnica"),
          href: null,
        },
      ],
    },
    undefined,
    "<p><strong>PARIGI PLUS LITE</strong><br />PARIGI PLUS LITE Store à support indépendant avec réglage à double “cone”; étudié pour être installé même dans les situations difficiles. Le mini réglage donne la possibilité d’obtenir une inclinaison de 0° à 90°.</p>"
  ),
];

export const createHelixBqHelixPsInfoPointsFr = (
  createPositionPoint,
  createInfoPoint,
  createVrPoint
) => [
  createPositionPoint(
    [-4005.42, -1206.4, -2726.44],
    "percorso-4",
    "Percorso 4"
  ),
  createPositionPoint(
    [144.23, -838.51, 4919.68],
    "mini-helix-bq-mini-helix-ps",
    "MINI HELIX BQ - MINI HELIX PS"
  ),
  createVrPoint([3198.87, -609.58, 3781.73], HelixBqGLB, HelixBqUSDZ, undefined, "HELIX BQ"),
  createVrPoint([3452.94, -578.46, -3555.56], HelixPsGLB, HelixPsUSDZ, undefined, "HELIX PS"),
  createInfoPoint(
    [4188.49, 23.53, 2714.1],
    {
      type: "info",
      title: "",
      noCloseIcon: true,
      popupClass: "popup-info_container",
      sectionTitle: "HELIX BQ",
      description:
        "<p>" +
        getLanguageTranslation(
          "HELIX BQ tenda su barra quadra 40mm; è caratterizzata da un supporto in pressofusione, del tipo cosidetto a “cono” dal disegno curato e dalle prestazioni importanti: inclinazione in continuo, particolarmente agevole, da 0° a 90° gradi, sporgenza fino a 3,60m. In abbinamento con il supporto avvolgitore Gold, risulta essere una delle barre quadre più accattivanti presenti nel mercato."
        ) +
        "</p>",
      images: [
        ImageHelixBq1,
        ImageHelixBq2,
        ImageHelixBq3,
        ImageHelixBq4,
        ImageHelixBq5,
        ImageHelixBq6,
      ],
      buttons: [
        {
          text: getLanguageTranslation("Scheda Tecnica"),
          href: TechnicalSheetHelixBq,
        },
      ],
    },
    undefined,
    "<p><strong>HELIX BQ</strong><br />" +
      getLanguageTranslation(
        "HELIX BQ tenda su barra quadra 40mm; è caratterizzata da un supporto in pressofusione, del tipo cosidetto a “cono” dal disegno curato e dalle prestazioni importanti: inclinazione in continuo, particolarmente agevole, da 0° a 90° gradi, sporgenza fino a 3,60m."
      ) +
      "</p>"
  ),
  createInfoPoint(
    [4593.56, 23.45, -1963.82],
    {
      type: "info",
      title: "",
      noCloseIcon: true,
      popupClass: "popup-info_container",
      sectionTitle: "HELIX PS",
      description:
        "<p>" +
        getLanguageTranslation(
          "HELIX PS tenda a supporti indipendenti; è caratterizzata da un supporto in pressofusione, del tipo cosidetto a 'cono' dal disegno curato e dalle prestazioni importanti: inclinazione in continuo, particolarmente agevole, da 0° a 90°. Versatile e facile da installare, dal design robusto ma al contempo elegante."
        ) +
        "</p>",
      images: [
        ImageHelixPs1,
        ImageHelixPs2,
        ImageHelixPs3,
        ImageHelixPs4,
        ImageHelixPs5,
      ],
      buttons: [
        {
          text: getLanguageTranslation("Scheda Tecnica"),
          href: TechnicalSheetHelixPs,
        },
      ],
    },
    undefined,
    "<p><strong>HELIX PS</strong><br />" +
      getLanguageTranslation(
        "HELIX PS tenda a supporti indipendenti; è caratterizzata da un supporto in pressofusione, del tipo cosidetto a 'cono' dal disegno curato e dalle prestazioni importanti: inclinazione in continuo, particolarmente agevole, da 0° a 90°."
      ) +
      "</p>"
  ),
];

export const createMiniHelixBqMiniHelixPsInfoPointsFr = (
  createPositionPoint,
  createInfoPoint,
  createVrPoint
) => [
  createPositionPoint(
    [-2012.99, -464.58, -4550.03],
    "percorso-4",
    "Percorso 4"
  ),
  createPositionPoint(
    [23.78, -810.21, -4931.62],
    "helix-bq-helix-ps",
    "HELIX BQ - HELIX PS"
  ),
  createVrPoint([3407.09, -711.5, 3579.16], MiniHelixBqGLB, MiniHelixBqUSDZ, undefined, "HELIX BQ"),
  createVrPoint([3173.86, -433.22, -3828.81], MiniHelixPsGLB, MiniHelixPsUSDZ, undefined, "MINI HELIX PS"),
  createInfoPoint(
    [4391.76, -139.98, 2374.29],
    {
      type: "info",
      title: "",
      noCloseIcon: true,
      popupClass: "popup-info_container",
      sectionTitle: "HELIX BQ",
      description:
        "<p>" +
        getLanguageTranslation(
          "MINI HELIX BQ è una tenda a bracci su barra quadra da 40 in acciaio zincato e verniciato. Sorella minore dell’ormai nota Helix BQ, dalla quale eredita l’apprezzato sistema di regolazione, si caratterizza da una spiccata personalità, data dal design pulito, estremamente sobrio e gradevole; è compatibile con le braccia della famiglia ECO, sia a cinghia che doppio cavetto. Come optional può essere proposta con il supporto avvolgitore GOLD, che ne completa la finitura, rendendola veramente unica nel suo genere."
        ) +
        "</p>",
      images: [
        ImageHelixBq1,
        ImageHelixBq2,
        ImageHelixBq3,
        ImageHelixBq4,
        ImageHelixBq5,
        ImageHelixBq6,
      ],
      buttons: [
        {
          text: getLanguageTranslation("Scheda Tecnica"),
          href: TechnicalSheetHelixBq,
        },
      ],
    },
    undefined,
    "<p><strong>HELIX BQ</strong><br />" +
      getLanguageTranslation(
        "MINI HELIX BQ è una tenda a bracci su barra quadra da 40 in acciaio zincato e verniciato. Sorella minore dell’ormai nota Helix BQ, dalla quale eredita l’apprezzato sistema di regolazione, si caratterizza da una spiccata personalità, data dal design pulito, estremamente sobrio e gradevole; è compatibile con le braccia della famiglia ECO, sia a cinghia che doppio cavetto."
      ) +
      "</p>"
  ),
  createInfoPoint(
    [4157.15, -139.03, -2756.34],
    {
      type: "info",
      title: "",
      noCloseIcon: true,
      popupClass: "popup-info_container",
      sectionTitle: "MINI HELIX PS",
      description:
        "<p>" +
        getLanguageTranslation(
          "MINI HELIX PS è una tenda a supporti indipendenti. Concettualmente riprende le caratteristiche tecnico- meccaniche della sorella maggiore, Helix PS, come l’estrema facilità e versatilità di installazione e regolazione. Caratterizzata da un disegno gradevole e decisamente contemporaneo, al quale è stata sottoposta un’attenzione maniacale, è compatibile con le braccia della famiglia ECO, sia a cinghia che doppio cavetto."
        ) +
        "</p>",
      images: [
        ImageMiniHelixPs1,
        ImageMiniHelixPs2,
        ImageMiniHelixPs3,
        ImageMiniHelixPs4,
      ],
      buttons: [
        {
          text: getLanguageTranslation("Scheda Tecnica"),
          href: TechnicalSheetMiniHelixBs,
        },
      ],
    },
    undefined,
    "<p><strong>MINI HELIX PS</strong><br />" +
      getLanguageTranslation(
        "MINI HELIX PS è una tenda a supporti indipendenti. Concettualmente riprende le caratteristiche tecnico- meccaniche della sorella maggiore, Helix PS, come l’estrema facilità e versatilità di installazione e regolazione."
      ) +
      "</p>"
  ),
];

export const createOmbralsunInfoPointsFr = (
  createPositionPoint,
  createInfoPoint,
  createVrPoint
) => [
  createPositionPoint([1672.18, -1602.02, 4422.26], "percorso-8", "Percorso 8"),
  createPositionPoint([-4761.94, -1290.63, -748.65], "sirio", "SIRIO"),
  createVrPoint([4749.99, -200.26, -1534.46], OmbralsunGLB, OmbralsunUSDZ, undefined, "OMBRALSUN"),
  createInfoPoint(
    [4391.76, -139.98, 2374.29],
    {
      type: "info",
      title: "",
      noCloseIcon: true,
      popupClass: "popup-info_container",
      sectionTitle: "OMBRALSUN",
      description:
        "<p>" +
        getLanguageTranslation(
          "OMBRALSUN, per la sue dimensioni, compattezza e facilità di installazione e regolazione, si posiziona sicuramente in vetta alla già ampia gamma dei prodotti per terrazzi e patio completandone la gamma. Grazie ad un solo tubo avvolgitore “Rollerbat” Ø 80mm, riesce a coprire una superficie di ca 36m2, mantenendo comunque contenuti gli ingombri; supporti e staffe di ancoraggio sono studiati perché tutte le installazioni siano semplici e veloci. Il sistema è completato da un cassonetto integrale che racchiude, una volta ritratti, entrambi i lati della tenda."
        ) +
        "</p>",
      images: [
        ImageOmbralsun1,
        ImageOmbralsun2,
        ImageOmbralsun3,
        ImageOmbralsun4,
        ImageOmbralsun5,
      ],
      buttons: [
        {
          text: getLanguageTranslation("Scheda Tecnica"),
          href: TechnicalSheetOmbralsun,
        },
      ],
    },
    undefined,
    "<p><strong>OMBRALSUN</strong><br />" +
      getLanguageTranslation(
        "OMBRALSUN, per la sue dimensioni, compattezza e facilità di installazione e regolazione, si posiziona sicuramente in vetta alla già ampia gamma dei prodotti per terrazzi e patio completandone la gamma."
      ) +
      "</p>"
  ),
];
