import {
  ArmonyPlusGLB,
  ArmonyPlusUSDZ,
  EpicaLiteGLB,
  EpicaLiteUSDZ,
  GiantGLB,
  GiantUSDZ,
  GLB2040,
  Image20401,
  Image20402,
  Image20403,
  Image20404,
  Image20405,
  ImageArmonyPlus1,
  ImageArmonyPlus2,
  ImageArmonyPlus3,
  ImageArmonyPlus4,
  ImageArmonyPlus5,
  ImageEpicaLite1,
  ImageEpicaLite2,
  ImageEpicaLite3,
  ImageEpicaLite4,
  ImageEpicaLite5,
  ImageEpicaLite6, ImageEpicaLite7, ImageEpicaLite8, ImageEpicaLite9,
  ImageGiant1,
  ImageGiant2,
  ImageGiant3,
  ImageGiant4,
  ImageGiant5,
  ImageNewItalia1,
  ImageNewItalia2,
  ImageNewItalia3,
  ImageNewItalia4,
  ImagePatioV41,
  ImagePatioV42,
  ImagePatioV43,
  ImagePatioV44,
  ImagePatioV45,
  ImagePatioV46,
  ImageR901,
  ImageR902,
  ImageR903,
  ImageR904,
  ImageR905,
  ImageRPitch1,
  ImageRPitch2,
  ImageRPitch3,
  ImageRPitch4,
  ImageSpace1,
  ImageSpace2,
  ImageSpace3,
  ImageSpace4,
  ImageSpace5,
  ImageSpace6,
  ImageSpace7,
  NewItaliaGLB,
  NewItaliaUSDZ,
  PatioV4GLB,
  PatioV4USDZ,
  R90GLB,
  R90USDZ,
  RPitchGLB,
  RPitchUSDZ,
  SpaceGLB,
  SpaceUSDZ,
  TechnicalSheet2040,
  TechnicalSheetArmonyPlus, TechnicalSheetEpicaLite,
  TechnicalSheetPatioV4,
  TechnicalSheetR90,
  TechnicalSheetRPitch,
  TechnicalSheetSpace,
  USDZ2040,
} from ".";
import { getLanguageTranslation } from "../translations/get-language-translation";

export const createIntroInfoPointsUsa = (createPositionPoint) => [
  //createPositionPoint([-2504.4, -738.66, -4260.1], "new-italia", "New Italia"),
  createPositionPoint([-3237.3, -1179.84, 3621.37], "space", "Space"),
  createPositionPoint([-684.75, -1075.89, 4825.62], "percorso-1", "Percorso 1"),
];

export const createPercorso1InfoPointsUsa = (createPositionPoint) => [
  createPositionPoint(
    [4594.81, -1135.74, 1595.6],
    "r90-r-pitch",
    "R90 - R Pitch"
  ),
  createPositionPoint([-4549.66, -981.86, 1798.54], "space", "Space"),
  createPositionPoint([-4866.97, -1013.86, -484.47], "intro", "Intro"),
  createPositionPoint([26.57, -1038.76, -4886.01], "percorso-2", "Percorso 2"),
  createPositionPoint(
    [2367.96, -686.38, -4341.12],
    "patio-v4-armony-plus",
    "Patio V4 - Armony Plus"
  ),
];

export const createPercorso2InfoPointsUsa = (createPositionPoint) => [
  createPositionPoint(
    [-4756.25, -1248.83, -859.83],
    "percorso-1",
    "Percorso 1"
  ),
  createPositionPoint(
    [511.2, -1390.17, 4771.17],
    "patio-v4-armony-plus",
    "Patio V4 - Armony Plus"
  ),
  createPositionPoint([4289.18, -635.4, 2482.03], "2040-giant", "2040 - Giant"),
];

export const createR90RPitchInfoPointsUsa = (
  createPositionPoint,
  createInfoPoint,
  createVrPoint
) => [
  createPositionPoint([-4758.52, -844.6, 1247.59], "space", "Space"),
  createPositionPoint(
    [-4684.28, -978.59, -1421.81],
    "percorso-1",
    "Percorso 1"
  ),
  createPositionPoint(
    [-172.92, -421.87, -4972.02],
    "2040-giant",
    "2040 - Giant"
  ),
  createPositionPoint(
    [-172.92, -945.81, -4882.04],
    "patio-v4-armony-plus",
    "Patio V4 - Armony Plus"
  ),
  createVrPoint([4062.3, -1039.16, 2705.81], R90GLB, R90USDZ, undefined, "R 90"),
  createVrPoint([4417.11, -1372.45, -1879.79], RPitchGLB, RPitchUSDZ, undefined, "R PITCH"),
  createInfoPoint(
    [4520.98, 1374.42, 1607.65],
    {
      type: "info",
      title: "",
      noCloseIcon: true,
      popupClass: "popup-info_container",
      sectionTitle: "R 90",
      description: "<p>" + getLanguageTranslation("") + "</p>",
      images: [ImageR901, ImageR902, ImageR903, ImageR904, ImageR905],
      buttons: [
        {
          text: getLanguageTranslation("Scheda Tecnica"),
          href: TechnicalSheetR90,
        },
      ],
    },
    undefined,
    "<p><strong>R 90</strong><br />" + getLanguageTranslation("") + "</p>"
  ),
  createInfoPoint(
    [4495.17, 1503.18, -1573.71],
    {
      type: "info",
      title: "",
      noCloseIcon: true,
      popupClass: "popup-info_container",
      sectionTitle: "R PITCH",
      description:
        "<p>" +
        getLanguageTranslation(
          "Una tenda a bracci estensibili su barra quadra 40mm in acciaio zincato e verniciato con bracci a doppio cavetto inguainato o a cinghia e supporto braccio a cremagliera con inclinazione singola di ogni braccio. Tenda modulare: aggiungendo ulteriori profili si possono raggiungere misure diverse. Il terminale frontale è intercambiabile con quelli del catalogo BAT. Dispone come optional di un tettuccio di copertura in alluminio estruso e di una staffa speciale per il montaggio sottotetto."
        ) +
        "</p>",
      images: [ImageRPitch1, ImageRPitch2, ImageRPitch3, ImageRPitch4],
      buttons: [
        {
          text: getLanguageTranslation("Scheda Tecnica"),
          href: TechnicalSheetRPitch,
        },
      ],
    },
    undefined,
    "<p><strong>R PITCH</strong><br />" +
      getLanguageTranslation(
        "Una tenda a bracci estensibili su barra quadra 40mm in acciaio zincato e verniciato con bracci a doppio cavetto inguainato o a cinghia e supporto braccio a cremagliera con inclinazione singola di ogni braccio."
      ) +
      "</p>"
  ),
];

export const createNewItaliaInfoPointsUsa = (
  createPositionPoint,
  createInfoPoint,
  createVrPoint
) => [
  createPositionPoint([-4633.62, -801.7, 1679.66], "intro", "Intro"),
  createPositionPoint([-4938.85, -721.63, -129.0], "space", "Space"),
  createVrPoint([3802.67, -675.89, 3160.56], NewItaliaGLB, NewItaliaUSDZ, undefined, "NEW ITALIA"),
  createInfoPoint(
    [4735.13, 1524.53, -495.16],
    {
      type: "info",
      title: "",
      noCloseIcon: true,
      popupClass: "popup-info_container",
      sectionTitle: "NEW ITALIA",
      description:
        "<p>NEW ITALIA is an appropriate awning for balconies and window installation with spring arms. Easy and quick installation. An optional cassette is available for fabric protection. Thanks to the arm shape it can be installed inside niche.</p>",
      images: [
        ImageNewItalia1,
        ImageNewItalia2,
        ImageNewItalia3,
        ImageNewItalia4,
      ],
      buttons: [
        {
          text: getLanguageTranslation("Scheda Tecnica"),
          href: null,
        },
      ],
    },
    undefined,
    "<p><strong>NEW ITALIA</strong><br />NEW ITALIA is an appropriate awning for balconies and window installation with spring arms. Easy and quick installation.</p>"
  ),
];

export const createSpaceInfoPointsUsa = (
  createPositionPoint,
  createInfoPoint,
  createVrPoint
) => [
  createPositionPoint([-2071.11, -1111.28, 4406.29], "intro", "Intro"),
  createPositionPoint(
    [-635.45, -935.32, -4861.7],
    "r90-r-pitch",
    "R90 - R Pitch"
  ),
  createPositionPoint(
    [-4037.9, -2177.79, -1968.23],
    "percorso-1",
    "Percorso 1"
  ),
  createPositionPoint(
    [769.93, -1050.02, 4817.33],
    "epica-lite",
    "Epica Lite"
  ),
  createVrPoint([4829.17, -1101.82, 629.44], SpaceGLB, SpaceUSDZ, undefined, "SPACE"),
  createInfoPoint(
    [4669.65, 997.37, 1462.0],
    {
      type: "info",
      title: "",
      noCloseIcon: true,
      popupClass: "popup-info_container",
      sectionTitle: "SPACE",
      description:
        "<p>" +
        getLanguageTranslation(
          "Struttura di copertura per verande e attici. Il tessuto scorre nella parte superiore su guide, regolabili in larghezza e contenenti il sistema di tensionamento con molle a gas. Sono disponibili degli optional come il sistema di blocco meccanico e il sistema flottante per il tubo avvolgitore. Dimensioni massime 500x700 cm e con guida centrale anche 700x700 cm."
        ) +
        "</p>",
      images: [
        ImageSpace1,
        ImageSpace2,
        ImageSpace3,
        ImageSpace4,
        ImageSpace5,
        ImageSpace6,
        ImageSpace7,
      ],
      buttons: [
        {
          text: getLanguageTranslation("Scheda Tecnica"),
          href: TechnicalSheetSpace,
        },
      ],
    },
    undefined,
    "<p><strong>SPACE</strong><br />" +
      getLanguageTranslation(
        "Struttura di copertura per verande e attici. Il tessuto scorre nella parte superiore su guide, regolabili in larghezza e contenenti il sistema di tensionamento con molle a gas."
      ) +
      "</p>"
  ),
];

export const createEpicaLiteInfoPointsUsa = (
  createPositionPoint,
  createInfoPoint,
  createVrPoint,
  createVideoPoint
) => [
  createPositionPoint(
    [401.79, -1076.50, -4858.84],
    "space",
    "Space"
  ),
  createVrPoint([4297.01, -400.76, -2520.90], EpicaLiteGLB, EpicaLiteUSDZ, undefined, "EPICA LITE"),
  createInfoPoint(
    [4543.13, -395.65, -2047.19],
    {
      type: "info",
      title: "",
      noCloseIcon: true,
      popupClass: "popup-info_container",
      sectionTitle: "EPICA LITE",
      description:
        "<p>" +
        getLanguageTranslation(
          "EPICA LITE è una tenda a bracci estensibili con cassonetto su supporti laterali fissi con contropiastra di ancoraggio, bracci Balteus o spin. Il cassonetto, ispezionabile, viene fornito in tre profili separati. I supporti consentono di installare la tenda sia a parete che a soffitto e la regolazione può essere eseguita in un’unica soluzione grazie al sistema con vite passante."
        ) +
        "</p>",
      images: [
        ImageEpicaLite1,
        ImageEpicaLite2,
        ImageEpicaLite3,
        ImageEpicaLite4,
        ImageEpicaLite5,
        ImageEpicaLite6,
        ImageEpicaLite7,
        ImageEpicaLite8,
        ImageEpicaLite9,
      ],
      buttons: [
        {
          text: getLanguageTranslation("Scheda Tecnica"),
          href: TechnicalSheetEpicaLite,
        },
      ],
    },
    undefined,
    "<p><strong>EPICA LITE</strong><br />" +
    getLanguageTranslation(
      "EPICA LITE è una tenda a bracci estensibili con cassonetto su supporti laterali fissi con contropiastra di ancoraggio, bracci Balteus o spin."
    ) +
    "</p>"
  ),
  createVideoPoint(
    [3016.05, 593.32, -3932.25],
    "Video",
    "https://www.youtube.com/watch?v=rYrZhH8tMn4"
  ),
];

export const createPatioV4ArmonyPlusInfoPointsUsa = (
  createPositionPoint,
  createInfoPoint,
  createVrPoint
) => [
  createPositionPoint(
    [-1015.4, -1029.23, 4782.64],
    "r90-r-pitch",
    "R90 - R Pitch"
  ),
  createPositionPoint([-3891.49, -796.79, 3026.3], "percorso-1", "Percorso 1"),
  createPositionPoint(
    [-4667.03, -1298.44, -1198.62],
    "percorso-2",
    "Percorso 2"
  ),
  createPositionPoint(
    [-758.16, -725.61, -4879.83],
    "2040-giant",
    "2040 - Giant"
  ),
  createVrPoint([4566.73, -1391.5, 1471.7], PatioV4GLB, PatioV4USDZ, undefined, "PATIO V4"),
  createVrPoint([4396.22, -1117.62, -2088.1], ArmonyPlusGLB, ArmonyPlusUSDZ, undefined, "ARMONY PLUS"),
  createInfoPoint(
    [4545.21, 1568.23, 1351.94],
    {
      type: "info",
      title: "",
      noCloseIcon: true,
      popupClass: "popup-info_container",
      sectionTitle: "PATIO V4",
      description:
        "<p>" +
        getLanguageTranslation(
          "Una struttura per la copertura di verande, terrazze, attici da abbinarsi ad una struttura portante di supporto. Il tessuto a scorrimento manuale è caratterizzato dalla classica conformazione ad onda e si può raccogliere in entrambe le direzioni. La particolare attenzione posta alla soluzione tecnica e al design la rende adattabile ad una vasta gamma di strutture di sostegno."
        ) +
        "</p>",
      images: [
        ImagePatioV41,
        ImagePatioV42,
        ImagePatioV43,
        ImagePatioV44,
        ImagePatioV45,
        ImagePatioV46,
      ],
      buttons: [
        {
          text: getLanguageTranslation("Scheda Tecnica"),
          href: TechnicalSheetPatioV4,
        },
      ],
    },
    undefined,
    "<p><strong>PATIO V4</strong><br />" +
      getLanguageTranslation(
        "Una struttura per la copertura di verande, terrazze, attici da abbinarsi ad una struttura portante di supporto."
      ) +
      "</p>"
  ),
  createInfoPoint(
    [4622.63, 1456.39, -1192.45],
    {
      type: "info",
      title: "",
      noCloseIcon: true,
      popupClass: "popup-info_container",
      sectionTitle: "ARMONY PLUS",
      description:
        "<p>" +
        getLanguageTranslation(
          "ARMONY PLUS é una tenda a bracci estensibili con cassonetto a scomparsa totale. Design e stile uniti alla funzionalità e alla tecnologia di un sistema ribaltabile BAT che permette la chiusura ermetica del cassonetto anche nella sua massima inclinazione inoltre, grazie ad un profilo di guarnizione il cassonetto risulta essere perfettamente aderente al muro impedendo l’infiltrazione di acque ed impurità."
        ) +
        "</p>",
      images: [
        ImageArmonyPlus1,
        ImageArmonyPlus2,
        ImageArmonyPlus3,
        ImageArmonyPlus4,
        ImageArmonyPlus5,
      ],
      buttons: [
        {
          text: getLanguageTranslation("Scheda Tecnica"),
          href: TechnicalSheetArmonyPlus,
        },
      ],
    },
    undefined,
    "<p><strong>ARMONY PLUS</strong><br />" +
      getLanguageTranslation(
        "ARMONY PLUS é una tenda a bracci estensibili con cassonetto a scomparsa totale."
      ) +
      "</p>"
  ),
];

export const create2040GiantInfoPointsUsa = (
  createPositionPoint,
  createInfoPoint,
  createVrPoint
) => [
  createPositionPoint(
    [-89.34, -1016.38, 4718.06],
    "patio-v4-armony-plus",
    "Patio V4 - Armony Plus"
  ),
  createPositionPoint([-3029.17, -791.51, 3890.52], "percorso-2", "Percorso 2"),
  createPositionPoint(
    [-89.34, -471.63, 4970.51],
    "r90-r-pitch",
    "R90 - R Pitch"
  ),
  createVrPoint([4201.4, -711.86, 2600.6], GLB2040, USDZ2040, undefined, "2040"),
  createVrPoint([4071.96, -493.12, -2847.39], GiantGLB, GiantUSDZ, undefined, "GIANT"),
  createInfoPoint(
    [4445.55, 1774.67, 1430.0],
    {
      type: "info",
      title: "",
      noCloseIcon: true,
      popupClass: "popup-info_container",
      sectionTitle: "2040",
      description:
        "<p>" +
        getLanguageTranslation(
          "2040 è una tenda a bracci estensibili su barra quadra 40mm in acciaio zincato e verniciato con cassonetto a scomparsa parziale e con bracci a doppio cavetto inguainato o a cinghia. Questo modello viene fornito di un supporto braccio basculante brevettato che consente di regolare la tenda senza dover inclinare il cassonetto."
        ) +
        "</p>",
      images: [Image20401, Image20402, Image20403, Image20404, Image20405],
      buttons: [
        {
          text: getLanguageTranslation("Scheda Tecnica"),
          href: TechnicalSheet2040,
        },
      ],
    },
    undefined,
    "<p><strong>2040</strong><br />" +
      getLanguageTranslation(
        "2040 è una tenda a bracci estensibili su barra quadra 40mm in acciaio zincato e verniciato con cassonetto a scomparsa parziale e con bracci a doppio cavetto inguainato o a cinghia."
      ) +
      "</p>"
  ),
  createInfoPoint(
    [4401.6, 1650.15, -1676.52],
    {
      type: "info",
      title: "",
      noCloseIcon: true,
      popupClass: "popup-info_container",
      sectionTitle: "GIANT",
      description:
        "<p>" +
        getLanguageTranslation(
          "GIANT BQ é una tenda a bracci su barra quadra in acciaio 50mm. I supporti in alluminio estruso garantiscono resistenza e compattezza e i tappi in alluminio verniciati ne caratterizzano eleganza e discrezione. Compattezza e design la rendono perfetta per coperture di terrazze pubbliche e per protezione di spazi e patii privati. Dispone di un braccio tipo spin, creato “ad hoc” con snodi e spalla forgiati."
        ) +
        "</p>",
      images: [ImageGiant1, ImageGiant2, ImageGiant3, ImageGiant4, ImageGiant5],
      buttons: [
        {
          text: getLanguageTranslation("Scheda Tecnica"),
          href: null,
        },
      ],
    },
    undefined,
    "<p><strong>GIANT</strong><br />" +
      getLanguageTranslation(
        "GIANT BQ é una tenda a bracci su barra quadra in acciaio 50mm. I supporti in alluminio estruso garantiscono resistenza e compattezza e i tappi in alluminio verniciati ne caratterizzano eleganza e discrezione."
      ) +
      "</p>"
  ),
];
