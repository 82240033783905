//  @ts-ignore
import React, { useCallback, useEffect, useState } from "react";
//  @ts-ignore
import DeMap from "../../images/de-map.png";
//  @ts-ignore
import EsMap from "../../images/es-map.png";
//  @ts-ignore
import FrMap from "../../images/fr-map.png";
//  @ts-ignore
import ItMap from "../../images/it-map.png";
//  @ts-ignore
import EnMap from "../../images/en-map.png";
// @ts-ignore
import UsaMap from "../../images/usa-map.png";
import {
  DarkOpen,
  DarkClose,
  DarkForm,
  DarkMap,
  DarkPosition,
  DarkHelp,
  DarkFlag,
} from "../icons";
import { TechnicalSpecificsType } from "../types";
import { getLanguageTranslation } from "../utils/translations/get-language-translation";
import { LOCALSTORAGE_LANGUAGE } from "../consts";
import { getLanguage } from "../utils/get-language-code";

const HamburgerMenu: React.FC<{
  setPopupInfos: any;
  technicalSpecifics: TechnicalSpecificsType;
  setTechnicalSpecifics: (v: TechnicalSpecificsType) => void;
}> = ({ setPopupInfos, technicalSpecifics, setTechnicalSpecifics }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [classes, setClasses] = useState<string>("initial");
  const [headerItems, setHeaderItems] = useState([]);
  const [map, setMap] = useState<React.FC>(null);
  useEffect(() => {
    const lang = getLanguage();
    setMap(
      lang === "sv"
        ? DeMap
        : lang === "es"
        ? EsMap
        : lang === "fr"
        ? FrMap
        : lang === "us"
        ? UsaMap
        : lang === "en"
        ? EnMap
        : lang === "it"
        ? ItMap
        : null
    );
  }, []);

  const openMap = useCallback(() => {
    setPopupInfos({
      title: "",
      type: "map",
      currentPosition: technicalSpecifics.position,
      map,
      popupClass: "popup-map_container",
      noCloseIcon: true,
    });
  }, [map, technicalSpecifics, setPopupInfos]);

  const openPosition = useCallback(() => {
    setPopupInfos({
      title: "",
      type: "positions",
      currentPosition: technicalSpecifics.position,
      popupClass: "popup-positions_container",
      noCloseIcon: true,
    });
  }, [technicalSpecifics, setPopupInfos]);

  const openPositionMap = useCallback(() => {
    setPopupInfos({
      title: "",
      type: "positions-map",
      currentPosition: technicalSpecifics.position,
      map,
      popupClass: "popup-positions-map_container",
      noCloseIcon: true,
    });
  }, [map, technicalSpecifics, setPopupInfos]);

  const baseMenuElements = [
    {
      onClick: () =>
        setTechnicalSpecifics({
          ...technicalSpecifics,
          roof: "open",
        }),
      image: {
        src: DarkOpen,
        alt: "set-open",
      },
      text: getLanguageTranslation("APERTO"),
    },
    {
      onClick: () =>
        setTechnicalSpecifics({
          ...technicalSpecifics,
          roof: "close",
        }),
      image: {
        src: DarkClose,
        alt: "set-close",
      },
      text: getLanguageTranslation("CHIUSO"),
    },
  ];

  const endingMenuElements = [
    {
      onClick: () =>
        setPopupInfos({
          title: "",
          type: "form",
          popupClass: "popup-form_container",
          noCloseIcon: true,
        }),
      image: {
        src: DarkForm,
        alt: "open-form",
      },
      text: getLanguageTranslation("FORM"),
    },
  ];

  const endingMenu2Elements =
    typeof window !== `undefined` &&
    (getLanguage() === "it" || getLanguage() === "en")
      ? [
          {
            text: getLanguageTranslation("LINGUA"),
            image: {
              src: DarkFlag,
              alt: "language",
            },
            onClick: () =>
              setPopupInfos({
                title: "",
                type: "languages",
                popupClass: "popup-colors_container",
                noCloseIcon: true,
              }),
          },
        ]
      : [];

  const endingMenu3Elements = [
    {
      text: getLanguageTranslation("INFO"),
      image: {
        src: DarkHelp,
        alt: "help",
      },
      onClick: () =>
        setPopupInfos({
          title: "",
          type: "instructions",
          popupClass: "popup-instructions_container",
        }),
    },
  ];

  useEffect(() => {
    let isMobile = true;
    if (typeof window !== `undefined`) isMobile = window.innerWidth <= 768;
    const optionalMenuElements = isMobile
      ? [
          {
            onClick: openMap,
            image: {
              src: DarkMap,
              alt: "open-map",
            },
            text: getLanguageTranslation("MAPPA"),
          },
          {
            text: getLanguageTranslation("POSIZIONE"),
            image: {
              src: DarkPosition,
              alt: "change-position",
            },
            onClick: openPosition,
          },
        ]
      : [
          {
            onClick: openPositionMap,
            image: {
              src: DarkMap,
              alt: "open-map",
            },
            text: getLanguageTranslation("MAPPA"),
          },
        ];
    setHeaderItems(optionalMenuElements);
  }, [technicalSpecifics, setPopupInfos, setHeaderItems]);

  return (
    <div className="hamburger-menu__container">
      <div className="hamburger-menu__voices">
        <ul
          className={
            isOpen
              ? "hamburger-menu__voices-container active"
              : "hamburger-menu__voices-container"
          }
        >
          <li
            className={
              isOpen
                ? "hamburger-item__toggle active"
                : "hamburger-item__toggle"
            }
            onClick={() => {
              setIsOpen(!isOpen);
              if (!isOpen) {
                setClasses(" close show-item");
                setTimeout(() => {
                  if (isOpen) setClasses("open");
                }, 1200);
              } else {
                setClasses("open hide-item");
                setTimeout(() => {
                  if (!isOpen) setClasses("close");
                }, 1200);
              }
            }}
          >
            <div className="hamburger-item__content hamburger-item__circle">
              <div className="menu-icon_container">
                <div
                  className={
                    isOpen ? "hamburger-menu animate" : "hamburger-menu"
                  }
                />
              </div>
            </div>
          </li>
          {[
            ...baseMenuElements,
            ...headerItems,
            ...endingMenuElements,
            ...endingMenu2Elements,
            ...endingMenu3Elements,
          ].map((h, i) => (
            <li
              key={i}
              className={
                isOpen
                  ? "hamburger-item " + classes
                  : "hamburger-item " + classes
              }
              id="hamburger-item"
              onClick={h.onClick}
            >
              <div className="hamburger-item__content hamburger-item__circle">
                <img
                  src={h.image.src}
                  alt={h.image.alt}
                  className="menu-icon"
                />
              </div>
              <div className="hamburger-item__content hamburger-item__title">
                {h.text}
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default HamburgerMenu;
