import { Play } from "../icons";
import { getLanguageTranslation } from "./translations/get-language-translation";

export const useCreateVideoPoint: (
  setPopupInfos: any
) => (
  position: number[],
  title?: string,
  video?: string
) => Record<string, any> = (setPopupInfos) => (
  position,
  title = getLanguageTranslation("Video"),
  video
) => ({
  position,
  text: title,
  image: Play,
  type: "video",
  description: null,
  clickListener: () => {
    setPopupInfos({
      title: "",
      noCloseIcon: true,
      type: "video",
      popupClass: "popup-video_container",
      video,
    });
  },
});
