//  @ts-ignore
import ItalianFlag from "../images/sidebar/it.svg";
//  @ts-ignore
import EnglishFlag from "../images/sidebar/en.svg";
//  @ts-ignore
import SpanishFlag from "../images/sidebar/es.svg";
//  @ts-ignore
import FrenchFlag from "../images/sidebar/fr.svg";
//  @ts-ignore
import SwedishFlag from "../images/sidebar/se.svg";
//  @ts-ignore
import UsFlag from "../images/sidebar/us.svg";

export const LOCALSTORAGE_LANGUAGE = "bat-group/language";

export const languages: Array<{
  code: string;
  title: string;
  image: any;
}> = [
  {
    code: "it",
    title: "Italiano",
    image: ItalianFlag,
  },
  {
    code: "en",
    title: "English",
    image: EnglishFlag,
  },
  {
    code: "us",
    title: "American",
    image: UsFlag,
  },
  {
    code: "es",
    title: "Español",
    image: SpanishFlag,
  },
  {
    code: "fr",
    title: "French",
    image: FrenchFlag,
  },
  {
    code: "sv",
    title: "Swedish",
    image: SwedishFlag,
  },
];
