import { TechnicalSpecificsType } from "../../types";
import { getTechnicalSpecificsBooleanRoof } from "./get-technical-specifics-boolean-roof";
import { getLanguageCode } from "../get-language-code";

export const getParigiPlusScreeny130GpzTensToImageName = (
  technicalSpecifics: TechnicalSpecificsType
): string =>
  `parigi-plus-screeny-130-gpz-tens-to${getTechnicalSpecificsBooleanRoof(
    technicalSpecifics
  )}${getLanguageCode()}`;
