//  @ts-ignore
import React from "react";
import { TechnicalSpecificsType } from "../types";
import { Close, CloseWhite } from "../icons";
import { languages, LOCALSTORAGE_LANGUAGE } from "../consts";
import { getLanguageTranslation } from "../utils/translations/get-language-translation";

const PopupLanguages: React.FC<{
  popupInfos: any;
  setPopupInfos: any;
  technicalSpecifics: TechnicalSpecificsType;
  setTechnicalSpecifics: (v: TechnicalSpecificsType) => any;
}> = ({
  popupInfos,
  setPopupInfos,
  technicalSpecifics,
  setTechnicalSpecifics,
}) => (
  <div className="colors-container">
    <div className="popup-header">
      <div className="close-button" onClick={() => setPopupInfos(null)}>
        <img
          src={CloseWhite}
          alt="close-icon-white"
          className="close-icon only-mobile"
        />
        <img
          src={Close}
          alt="close-icon-white"
          className="close-icon only-desktop"
        />
      </div>
    </div>
    <div className="choose-colors__container">
      {languages
        .filter((l) => l.code === "it" || l.code === "en")
        .map((l, i) => (
          <div className="button-circle__container" key={i}>
            <button
              onClick={() => {
                setPopupInfos(null);
                if (l.code === "it") window.location.replace("../it-it");
                else window.location.replace("../it-eng");
              }}
              className="button-circle"
              style={{
                backgroundImage: `url(${l.image})`,
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
              }}
            />
            <span>{l.title}</span>
          </div>
        ))}
    </div>
    <div className="subtitle">
      <small>
        {getLanguageTranslation(
          "Dopo aver cambiato lingua, lo showroom verrà ricaricato."
        )}
      </small>
    </div>
  </div>
);

export default PopupLanguages;
