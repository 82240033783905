export const toggleMode = (viewer, isNormalMode, setIsNormalMode) => {
  setIsNormalMode(!isNormalMode);
  try {
    console.log(viewer);
    if (isNormalMode) {
      viewer.enableEffect(2);
      //  @ts-ignore
      if (typeof DeviceOrientationEvent.requestPermission === "function")
        //  @ts-ignore
        DeviceOrientationEvent.requestPermission();

      //  @ts-ignore
      if (typeof DeviceMotionEvent.requestPermission === "function")
        //  @ts-ignore
        DeviceMotionEvent.requestPermission();

      viewer.DeviceOrientationControls.connect();
    } else {
      viewer.enableEffect(1);
      viewer.DeviceOrientationControls.disconnect();
    }
  } catch (e) {
    console.log(e);
  }
};
