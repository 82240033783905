//  @ts-ignore
import React, { useRef, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Arrow } from "../../icons";

const InfoSlider: React.FC<{ popupInfos: any }> = ({ popupInfos }) => {
  const [mainSlider, setMainSlider] = useState<any>(undefined);
  const [gallerySlider, setGallerySlider] = useState<any>(undefined);

  const PrevArrow = props => (
    <img
      src={Arrow}
      className="info-slider-arrow-left"
      onClick={props.onClick}
      alt="slider-left-arrow"
    />
  );
  const NextArrow = props => (
    <img
      src={Arrow}
      className="info-slider-arrow-right"
      onClick={props.onClick}
      alt="slider-right-arrow"
    />
  );

  return (
    <div className="info-slider_container">
      <Slider
        ref={sl => setMainSlider(sl)}
        asNavFor={gallerySlider}
        dots={false}
        arrows={true}
        lazyLoad={true}
        infinite={true}
        autoplay={true}
        autoplaySpeed={5000}
        slidesToShow={1}
        pauseOnHover={true}
        className="info-slider"
        style={{ width: "100%", height: "80%" }}
        prevArrow={<PrevArrow />}
        nextArrow={<NextArrow />}
      >
        {popupInfos.images.map((img, i) => (
          <div key={"img_" + i} className={"images-container"}>
            <div className={"images-slide-container"}>
              <img src={img} alt={"img_" + i} className={"image"} />
            </div>
          </div>
        ))}
      </Slider>
      <Slider
        ref={sl => setGallerySlider(sl)}
        asNavFor={mainSlider}
        dots={false}
        arrows={false}
        lazyLoad={true}
        infinite={true}
        slidesToShow={3}
        swipeToSlide={true}
        focusOnSelect={true}
        pauseOnHover={true}
        centerMode={true}
        className="info-slider"
        style={{ width: "100%", height: "20%" }}
      >
        {popupInfos.images.map((img, i) => (
          <div key={"img_" + i} className={"images-container"}>
            <div className={"images-slide-container"} style={{ padding: '.75rem' }}>
              <img src={img} alt={"img_" + i} className={"image"} />
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default InfoSlider;
