// @ts-ignore
import React from "react";
import { Close } from "../icons";
import VirtualRealityComponent from "../virtual-reality-component";

const PopupVr: React.FC<{
  GLBFile;
  USDZFile;
  setPopupInfos: any;
}> = ({ GLBFile, USDZFile, setPopupInfos }) => {
  return (
    <>
      <div className="close-button" onClick={() => setPopupInfos(null)}>
        <img src={Close} alt="close-icon-white" className="close-icon" />
      </div>
      <VirtualRealityComponent GLB={GLBFile} USDZ={USDZFile} />
    </>
  );
};

export default PopupVr;
