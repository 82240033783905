//  @ts-ignore
import React from "react";
import { Close, CloseWhite } from "../icons";
import InfoSlider from "./info-slider";

const PopupInfo: React.FC<{ popupInfos: any; setPopupInfos: any }> = ({
  popupInfos,
  setPopupInfos,
}) => {
  return (
    <div className="info-container">
      <div className="popup-header">
        <div
          className="close-button"
          onClick={() => {
            const panels = document.querySelectorAll("#panel");
            panels.forEach((panel) => {
              //  @ts-ignore
              panel.style.display = "none";
            });
            setPopupInfos(null);
          }}
        >
          <img
            src={CloseWhite}
            alt="close-icon-white"
            className="close-icon only-mobile"
          />
          <img
            src={Close}
            alt="close-icon-white"
            className="close-icon only-desktop"
          />
        </div>
      </div>
      <InfoSlider popupInfos={popupInfos} />
      <div className="info-content_container">
        <h3>{popupInfos.sectionTitle}</h3>
        <div
          className="info-description"
          dangerouslySetInnerHTML={{ __html: popupInfos.description }}
        />

        {popupInfos.descriptionImages !== undefined &&
        popupInfos.descriptionImages !== null &&
        Array.isArray(popupInfos.descriptionImages) &&
        popupInfos.descriptionImages.length ? (
          <div className={"info-description-images-container"}>
            {popupInfos.descriptionImages.map((image, i) => (
              <div className={"info-description-image"}>
                <img src={image} alt={"img_" + i} className={"image"} />
              </div>
            ))}
          </div>
        ) : null}

        <div className="info-buttons-container">
          {popupInfos.buttons.map((b, i) => (
            <a
              key={"link_" + i}
              href={b.href}
              style={{ width: "100%", maxWidth: "250px" }}
              target="_blank"
            >
              <span>{b.text}</span>
            </a>
          ))}
        </div>
      </div>
    </div>
  );
};

export default PopupInfo;
