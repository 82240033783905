//  @ts-ignore
import React from "react";
import { positionsTitles } from "../map-positions";
import { TechnicalSpecificsType } from "../../types";
import { getLanguageTranslation } from "../../utils/translations/get-language-translation";
import { LOCALSTORAGE_LANGUAGE } from "../../consts";
import { getLanguage } from "../../utils/get-language-code";

const Positions: React.FC<{
  currentPosition: any;
  technicalSpecifics: TechnicalSpecificsType;
  setTechnicalSpecifics: (v: TechnicalSpecificsType) => any;
  setPopupInfos: any;
  popupInfos: any;
}> = ({
  currentPosition,
  technicalSpecifics,
  setTechnicalSpecifics,
  setPopupInfos,
}) => {
  return (
    <div className="positions-list">
      <div className="position-title__container title" key={"product-title"}>
        <span className="position-title">
          {getLanguageTranslation("PRODOTTI")}
        </span>
      </div>
      {positionsTitles
        .filter((p) => p.type === "product")
        .filter((p) => p.lang === getLanguage())
        .map((p, i) => (
          <div
            className={
              currentPosition === p.name
                ? "position-title__container active"
                : "position-title__container"
            }
            key={i}
            onClick={() => {
              setTechnicalSpecifics({
                ...technicalSpecifics,
                position: p.name,
              });
              setPopupInfos(null);
            }}
          >
            <span className="position-title">{p.title}</span>
          </div>
        ))}
      <div className="position-title__container title" key={"path-title"}>
        <span className="position-title">
          {getLanguageTranslation("PERCORSO")}
        </span>
      </div>
      {positionsTitles
        .filter((p) => p.type === "path")
        .filter((p) => p.lang === getLanguage())
        .map((p, i) => (
          <div
            className={
              currentPosition === p.name
                ? "position-title__container active"
                : "position-title__container"
            }
            key={i}
            onClick={() => {
              setTechnicalSpecifics({
                ...technicalSpecifics,
                position: p.name,
              });
              setPopupInfos(null);
            }}
          >
            <span className="position-title">{p.title}</span>
          </div>
        ))}
    </div>
  );
};

export default Positions;
