import { TechnicalSpecificsType } from "../../types";
import { getTechnicalSpecificsBooleanRoof } from "./get-technical-specifics-boolean-roof";
import { getLanguageCode } from "../get-language-code";

export const getR90RPitchImageName = (
  technicalSpecifics: TechnicalSpecificsType
): string =>
  `r90-r-pitch${getTechnicalSpecificsBooleanRoof(
    technicalSpecifics
  )}${getLanguageCode()}`;
